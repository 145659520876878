import { v4 as uuidv4 } from 'uuid';
import { SalesPaceReportData } from '../types/sales-pace-report.types';

// interface DynamicObject {
//     [key: string]: any;
// }

// function generateUniqueId(): string {
//     return uuidv4();
// }

// function addIdToObject(input: any): any {
//     if (typeof input !== 'object' || input === null) {
//         return input;
//     }

//     if (Array.isArray(input)) {
//         return input.map(addIdToObject);
//     }

//     const result: DynamicObject = {};
//     for (const key in input) {
//         if (input.hasOwnProperty(key)) {
//             result[key] = addIdToObject(input[key]);
//         }
//     }

//     if ('name' in result) {
//         result['name'] = result['name'] === null ? "NaN" : result['name'];
//         result['id'] = generateUniqueId();
//     }

//     return result;
// }

// export function transformData(originalObject: SalesPaceReportData): SalesPaceReportData {
//     return addIdToObject(originalObject);
// }

export const transformData = (data: SalesPaceReportData) => {
    if (Object.keys(data).length === 0) {
      return {};
    }
    
    const transformObject = (obj: any, objName: any) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value).map(
              ([nestedKey, nestedValue]) => {
                if (nestedKey === "") {
                  return transformObject(nestedValue, "NaN");
                }
                return transformObject(nestedValue, nestedKey);
              }
            );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    Object.entries(data).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue).map(
          ([nestedKey, nestedValue]) => {
            if (nestedKey === "") {
              return transformObject(nestedValue, "NaN");
            }
            return transformObject(nestedValue, nestedKey);
          }
        );
      }
    );
    return result;
  };
