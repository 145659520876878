import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DateOrTimeView,
  DateTimePicker, DateTimePickerToolbarProps,
  LocalizationProvider,
  PickersActionBarProps,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import Calender from "../../../assets/icons/Calender";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  menuClasses,
  MenuItem,
  outlinedInputClasses,
  Select,
  SelectChangeEvent,
  selectClasses,
} from "@mui/material";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { generateRangeConfigs } from "../../../types/enums/RangeConfigs";
import "./TableDatePickerComponent.css";
import useMediaQuery from "../../../hooks/useMediaQuery";
import CloseIcon from "../../../assets/icons/CloseIcon";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../buttons/DefaultButton";
import { UserContext } from "../../../context/UserContext/UserContext";
import ReportName from "../../../odinForgeService/Enums/ReportName";

interface dateTimePickerProps {
  handleBlur: () => void;
  startDate: string | Dayjs;
  endDate: string | Dayjs;
  setStartDate: (newValue: string | Dayjs) => void;
  setEndDate: (newValue: string | Dayjs) => void;
  dateRange: number;
  setDateRange: (newValue: number) => void;
  disableFutureDates?: boolean;
  customRangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
},
customDateRangeList?: string[]
isNotNeedFetchData?: boolean,
  reportName?: ReportName | string;
  customGenerateRangeConfigs?: (timezone: string) => { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } };
}
const TableDateTimePicker: FC<dateTimePickerProps> = ({
  handleBlur,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  disableFutureDates,
  customDateRangeList,
  isNotNeedFetchData = false,
  customGenerateRangeConfigs,
  reportName = ReportName.SalesPaceReportTable,
}: dateTimePickerProps) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [initialState, setInitialState] = useState({
    startDate,
    endDate,
    dateRange,
  });
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [firstPickerOpened, setFirstPickerOpened] = useState(false)
  const [secondPickerOpened, setSecondPickerOpened] = useState(false)
  const { theme, themedColors } = useContext(ThemeContext)!;

  const userContext = useContext(UserContext)!;
  const { timezone } = userContext;
  dayjs.tz.setDefault(timezone);

  useEffect(() => {
    if (
      startDate !== initialState.startDate ||
      endDate !== initialState.endDate ||
      dateRange !== initialState.dateRange
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [startDate, endDate, dateRange, initialState]);

  const dateRangeList = customDateRangeList ?? [
    "Custom Range",
    "Today",
    "Yesterday",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
  ];

  const onStartDateChange = (newValue: string | Dayjs | null) => {
    if (newValue === null) {
      return;
    }

    if (dateRange) {
      setDateRange(0)
    }
    setStartDate(newValue)
  }

  const onEndDateChange = (newValue: string | Dayjs | null) => {
    if (newValue === null) {
      return;
    }

    if (dateRange) {
      setDateRange(0)
    }
    setEndDate(newValue)
  }

  const onRangeSelectionChange = (e: SelectChangeEvent<number>) => {
    const value: string | number = e.target.value;
    setDateRange(e.target.value as number);

    // Custom date range
    if (!value) {
      return
    }

    /*
    Preset date range. Set dates now.
     */
    // @ts-ignore
    const { startDate, endDate } = customGenerateRangeConfigs ? customGenerateRangeConfigs(timezone)[value] : generateRangeConfigs(timezone)[value]

    setStartDate(startDate);
    setEndDate(endDate);

    setHasChanges(true);
    !isNotNeedFetchData && handleApply();
  };

  const handleApply = () => {
    if (reportName === ReportName.DeclineResponseReport && dateRange !== 0) {
    } else {
      handleBlur()
    }

    setInitialState({ startDate, endDate, dateRange });
    setHasChanges(false);
  };

  const viewsDateFormat: DateOrTimeView[] = ["year", "month", "day", "hours", "minutes"];

  const CustomActionBar: React.FC<PickersActionBarProps> = ({ onAccept, onCancel, onClear, actions }) => {

    // if no actions are passed, like in the desktop config of the DateTimePicker, render nothing
    if (!actions?.length) {
      return null
    }

    const onClickApply = () => {
      onAccept()
      handleBlur()
    }

    return (
        <div className="w-full row-start-3 col-span-2  flex flex-row justify-center">

          <div className={"flex flex-row flex-1 gap-2 justify-between items-center p-4"}>
            <DefaultButton disabled={false} type='button' label='Clear Filter' variant='outlined'  className="w-full h-9 !text-odin-light-blue-medium" onClick={onCancel} />
            <DefaultButton disabled={false} type='button' label='Apply'  className="w-full h-9" onClick={onClickApply} />
          </div>
        </div>
    )
        ;
  };

  const CustomToolbar: React.FC<DateTimePickerToolbarProps<string | Dayjs>> = ({hidden, className}) => {
    if (hidden) {
      return null
    }

    // Hack to determine which picker we have. Only className is a useful prop that is allowed to pass into the component.
    const getOnClose = () => {
      if (className?.includes('first-picker')) {
        return setFirstPickerOpened
      } else if (className?.includes('second-picker')) {
        return setSecondPickerOpened
      } else {
        return () => {}
      }
    }

    const onClose = getOnClose()

    return (
        <div className={"row-start-1 col-span-2 flex justify-center"}>
          <div className={"flex flex-col items-stretch w-full"}>
            <div className={"flex flex-1 w-full justify-between items-center p-4 border-b"} style={{ borderColor: themedColors.border }}>
              <div style={{color: themedColors.content}} className="text-xl font-bold">Select Date</div>
              <div onClick={() => onClose(false)} className={"cursor-pointer p-2"}>
                <CloseIcon></CloseIcon>
              </div>
            </div>
            <div className={"flex flex-1 justify-between items-center w-full p-4  border-b"}  style={{ borderColor: themedColors.border }}>
              <div className={'text-sm text-[#677C9F]'}>Data range:</div>
              <DateRangeDropDown/>
            </div>
          </div>
        </div>
    );
  };

  const DateRangeDropDown = () => {
    return <Select
        className="mui-select-daterange-picker"
        variant="outlined"
        inputProps={{MenuProps: {disableScrollLock: true}}}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: {
            marginBlock: "0.5rem",
            [`& .${menuClasses.paper}`]: {
              borderRadius: "6px",
              width: 150,
            },
            [`& .${menuClasses.list}`]: {
              paddingTop: 0,
              paddingBottom: 0,
              background: themedColors.background_1,
              "& li": {
                paddingTop: "12px",
                paddingBottom: "12px",
                justifyContent: "space-between",
                background: themedColors.background_1,
                fontWeight: 400,
                fontSize: "14px",
              },
              "& li:hover": {
                background: themedColors.background_1,
              },
              "& li.Mui-selected": {
                fontWeight: 500,
                background: themedColors.background_1,
              },
            },
          },
        }}
        IconComponent={ChevronDownIcon}
        value={dateRange}
        renderValue={(selected) => {
          return dateRangeList[selected];
        }}
        sx={{
          [`& .${selectClasses.select}`]: {
            background: themedColors.background_1,
            color: themedColors.content,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: "20px",
            paddingLeft: "20px",
            paddingRight: "55px !important",
            paddingTop: "11px",
            paddingBottom: "10px",
            transitionDuration: "0.3s",
            "&:focus": {
              borderRadius: "12px",
              background: themedColors.background_1,
              border: 'none'
            },
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#0079FF",
            borderWidth: 1,
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: themedColors.gray,
            borderWidth: 1,
            borderRadius: 12,
          },
          "&:hover": {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0079FF",
            },
          },
          "& .MuiSelect-icon": {
            width: "20px",
            color: themedColors.content,
            fontSize: "20px",
            right: "23px",
            top: "11px",
          },
          "& .MuiSelect-iconOpen": {
            color: "#0079FF",
          },
          "&.Mui-focused": {
            border: 'none !important',
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme === 'light' ? '#ACBFDF' : '#495D80',
            borderRadius: '50px',
          },
        }}
        onChange={onRangeSelectionChange}
    >
      {dateRangeList.map((range: string, index: number) => (
          <MenuItem 
            value={index} 
            key={index} 
            style={{color: themedColors.content}}
            className="datarange-item-mui"
            sx={{
              "&.MuiMenuItem-root.MuiMenuItem-root:hover": {
                backgroundColor: theme === 'light' ? "rgba(25, 118, 210, 0.08)" : "rgba(144, 202, 249, 0.16)",
              }
            }}
          >
            {range}
          </MenuItem>
      ))}
    </Select>
  }


  return (
    <>
      <div className="flex gap-3 items-center flex-wrap">
        <div>
          <DateRangeDropDown />
        </div>
        {!dateRange && <div className="flex gap-3 max-w-[500px]">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              desktopModeMediaQuery={'@media (min-width: 1023px)'}
              disableFuture={disableFutureDates}
              ampmInClock={true}
              open={firstPickerOpened}
              onOpen={() => setFirstPickerOpened(true)}
              onClose={() => setFirstPickerOpened(false)}
              value={startDate}
              timezone={timezone}
              onChange={onStartDateChange}
              disableHighlightToday={true}
              views={viewsDateFormat}
              slots={{
                openPickerIcon: Calender,
                actionBar: CustomActionBar,
                toolbar: CustomToolbar,
              }}

              sx={{
                fontSize: "12px",
              }}
              slotProps={{
                tabs: {
                   hidden: false,
                },
                inputAdornment: {
                  position: "start",
                  sx: {
                    "& .MuiIconButton-root svg path": {
                      stroke: themedColors.content
                    },

                  },
                },
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#0079FF",
                      fontSize: "14px !important",
                      color: "#fff",
                    },
                  },
                },
                desktopPaper: {
                  sx: {
                    ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersYear-yearButton.Mui-selected:hover, .MuiPickersYear-yearButton.Mui-selected:focus":
                      {
                        backgroundColor: "#0079FF",
                        fontSize: "12px !important",
                      },
                  },
                },
                textField: {
                  sx: {
                    "& .MuiInputBase-input": { fontSize: !isMobile ? "14px" : "12px", marginLeft: isMobile ? "0px" : "-8px"},
                    "& .MuiInputBase-input:focus": { boxShadow: "none"},
                    "& .MuiInputBase-root": { borderRadius: "6px",  color: themedColors.content, borderColor: "#ACBFDF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme === 'light' ? "#ACBFDF" : "#495D80"}
                  },
                },
                actionBar: ({ wrapperVariant }) => ({
                   actions: wrapperVariant === 'desktop' ? [] : ['clear', 'accept'],
                }),
                toolbar: {
                  className: 'first-picker'
                }


              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
            <DateTimePicker
              minDate={startDate}
              ampmInClock={true}
              desktopModeMediaQuery={'@media (min-width: 1023px)'}
              value={endDate}
              timezone={timezone}
              onChange={onEndDateChange}
              disableHighlightToday={true}
              open={secondPickerOpened}
              onOpen={() => setSecondPickerOpened(true)}
              onClose={() => setSecondPickerOpened(false)}
              views={viewsDateFormat}
              slots={{
                openPickerIcon: Calender,
                actionBar: CustomActionBar,
                toolbar: CustomToolbar
              }}
              slotProps={{
                tabs: {
                  hidden: false,
                },
                inputAdornment: {
                  position: "start",
                  sx: {
                    "& .MuiIconButton-root svg path": {
                      stroke: themedColors.content
                    },
                  },
                },
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#0079FF",
                      fontSize: "12px !important",
                    },
                  },
                },
                desktopPaper: {
                  sx: {
                    ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersYear-yearButton.Mui-selected:hover, .MuiPickersYear-yearButton.Mui-selected:focus":
                      {
                        backgroundColor: "#0079FF",
                        fontSize: "12px !important",
                      },
                  },
                },
                textField: {
                  sx: {
                    "& .MuiInputBase-input": { fontSize: !isMobile ? "14px" : "12px", marginLeft: isMobile ? "0px" : "-8px"},
                    "& .MuiInputBase-input:focus": { boxShadow: "none"},
                    "& .MuiInputBase-root": { borderRadius: "6px",  color: themedColors.content, },
                    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme === 'light' ? "#ACBFDF" : "#495D80"}
                  },
                },
                actionBar: ({ wrapperVariant }) => ({
                  actions: wrapperVariant === 'desktop' ? [] : ['clear', 'accept'],
                }),
                toolbar: {
                  className: 'second-picker'
                },

              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </LocalizationProvider>
        </div>}

        {!dateRange && <DefaultButton disabled={!hasChanges} type='button' label='Apply'  className="flex-grow !text-base max-w-[168px] !hidden tablet-size:!flex h-9" onClick={handleApply} />}
      </div>
    </>
  );
};

export default TableDateTimePicker;
