import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

const SettingsIcon = ({ isHovered = false }: { isHovered?: boolean }) => {
  const { theme, themedColors } = useContext(ThemeContext)!;

  const color = isHovered ? (theme === 'light' ? "#FFFFFF" : "#FFFFFF") : (theme === 'light' ? "#677C9F" : "#677C9F");
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_730_53615)">
        <path
          d="M23 19H20.337C19.904 17.559 18.58 16.5 17 16.5C15.42 16.5 14.097 17.559 13.663 19H0.999984C0.447984 19 0 19.448 0 20C0 20.552 0.447984 21 0.999984 21H13.663C14.096 22.441 15.42 23.5 17 23.5C18.5799 23.5 19.903 22.441 20.337 21H23C23.553 21 24 20.552 24 20C24 19.448 23.553 19 23 19ZM17 21.5C16.173 21.5 15.5 20.827 15.5 20C15.5 19.173 16.173 18.5 17 18.5C17.827 18.5 18.5 19.173 18.5 20C18.5 20.827 17.827 21.5 17 21.5Z"
          fill={color}
        />
        <path
          d="M23 2.99998H20.337C19.903 1.559 18.58 0.5 17 0.5C15.42 0.5 14.097 1.559 13.663 2.99998H0.999984C0.447984 2.99998 0 3.44797 0 3.99997C0 4.55197 0.447984 4.99995 0.999984 4.99995H13.663C14.097 6.44098 15.42 7.49998 17 7.49998C18.58 7.49998 19.903 6.44098 20.337 5H23C23.553 5 24 4.55202 24 4.00002C24 3.44802 23.553 2.99998 23 2.99998ZM17 5.49997C16.173 5.49997 15.5 4.82698 15.5 3.99997C15.5 3.17295 16.173 2.49997 17 2.49997C17.827 2.49997 18.5 3.17295 18.5 3.99997C18.5 4.82698 17.827 5.49997 17 5.49997Z"
          fill={color}
        />
        <path
          d="M23 11H10.337C9.903 9.559 8.58 8.5 7.00003 8.5C5.42006 8.5 4.09702 9.559 3.66305 11H0.999984C0.447984 11 0 11.448 0 12C0 12.552 0.447984 13 0.999984 13H3.663C4.09702 14.4409 5.42002 15.4999 6.99998 15.4999C8.57995 15.4999 9.903 14.4409 10.337 13H23C23.553 13 24 12.552 24 12C24 11.448 23.553 11 23 11ZM6.99998 13.5C6.17297 13.5 5.49998 12.827 5.49998 12C5.49998 11.173 6.17297 10.5 6.99998 10.5C7.827 10.5 8.49998 11.173 8.49998 12C8.49998 12.827 7.827 13.5 6.99998 13.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_730_53615">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SettingsIcon;
