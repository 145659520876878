import * as React from "react"
import {ImgHTMLAttributes, SVGProps} from "react"
import AccessDeniedSVG from './access_denied.svg'
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
interface AccessDeniedProps extends ImgHTMLAttributes<HTMLImageElement> {
    height?: string;
    width?: string;
}

const AccessDeniedIcon: React.FC<AccessDeniedProps> = (props) => {
    const { height = '24', width = '24', ...rest} = props;

    const { theme } = React.useContext(ThemeContext)!;

    return (
        <>
            
            {
                theme === 'light' ? (
                    <svg width='400' height='230' viewBox="0 0 343 198" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M171.5 197.344C266.217 197.344 343 193.693 343 189.189C343 184.686 266.217 181.035 171.5 181.035C76.7832 181.035 1.52588e-05 184.686 1.52588e-05 189.189C1.52588e-05 193.693 76.7832 197.344 171.5 197.344Z" fill="#C6E5FD"/>
    <path d="M67.7883 179.429C67.8837 178.802 67.8613 178.118 67.5025 177.595C67.1439 177.072 66.3626 176.809 65.8477 177.179C65.6074 177.351 65.4623 177.624 65.339 177.893C64.6913 179.306 64.4155 180.887 64.5469 182.436C63.8711 181.381 62.7448 180.627 61.5128 180.403C61.142 180.336 60.7176 180.329 60.4359 180.58C60.0382 180.933 60.1481 181.592 60.419 182.051C61.1053 183.212 64.0232 184.954 65.4067 184.268C66.5427 183.704 67.6141 180.573 67.7883 179.429Z" fill="#C6E5FD"/>
    <path d="M170.139 180.82C171.769 182.052 176.376 183.362 176.907 180.192C177.01 179.577 176.759 178.785 176.14 178.704C175.349 178.599 174.995 179.632 174.492 180.251C174.699 179.22 174.575 178.124 174.142 177.166C173.92 176.673 173.492 176.154 172.957 176.234C172.328 176.328 172.126 177.14 172.173 177.775C172.234 178.595 172.469 179.402 172.857 180.127C172.373 179.542 171.667 179.145 170.916 179.034C170.328 178.947 169.567 179.17 169.494 179.76C169.441 180.19 169.793 180.558 170.139 180.82Z" fill="#C6E5FD"/>
    <path d="M311.978 188.889C311.815 188.266 311.078 188 310.501 187.971C309.863 187.939 309.248 188.117 308.698 188.422C309.134 187.751 309.485 186.991 309.589 186.211C309.667 185.622 309.564 184.838 308.926 184.6C308.148 184.31 307.483 185.099 307.13 185.683C306.666 186.451 306.419 187.311 306.347 188.196C305.962 187.322 305.392 186.035 304.355 186.812C302.983 187.839 304.153 189.667 305.002 190.555C305.116 190.674 305.297 190.493 305.183 190.374C304.733 189.903 304.267 189.383 304.082 188.742C303.921 188.182 303.996 187.387 304.516 187.022C305.559 186.289 306.005 188.143 306.332 188.772C306.392 188.888 306.571 188.83 306.571 188.707C306.572 187.868 306.733 187.042 307.096 186.283C307.246 185.97 307.422 185.667 307.641 185.398C307.983 184.98 308.774 184.53 309.174 185.112C309.902 186.17 308.737 188.002 308.103 188.827C308.006 188.953 308.203 189.063 308.305 188.982C308.899 188.513 309.633 188.215 310.398 188.227C311.203 188.24 312.14 188.826 311.524 189.703C310.908 190.581 309.516 190.859 308.534 191.105C308.373 191.145 308.442 191.392 308.602 191.352C309.602 191.102 312.387 190.46 311.978 188.889Z" fill="#F9F9F9"/>
    <path d="M314.328 52.5538C331.98 83.8109 324.832 128.417 295.274 148.788C284.472 156.232 271.632 160.236 258.711 162.513C240.592 165.707 185.339 164.959 174.949 165.172C142.283 165.841 107.486 161.014 95.5417 126.71C84.9006 96.1487 89.073 55.4895 111.477 31.1124C157.107 -18.5376 280.779 -6.85089 314.328 52.5538Z" fill="#E1F2FF"/>
    <path d="M19.1866 118.679C18.5505 103.069 24.1775 87.2915 34.5479 75.607C38.5042 71.1495 43.2655 67.191 48.9343 65.3507C54.603 63.5105 61.2918 64.0925 65.8724 67.9056C72.3148 73.2685 72.8261 82.8057 72.8255 91.1881C72.8251 96.784 72.8247 102.38 72.8242 107.976C72.8237 116.192 75.1403 124.204 79.2803 131.302C82.4452 136.727 84.4863 142.559 83.3026 148.591C81.5442 157.551 72.6499 163.867 63.58 164.92C58.0972 165.556 52.2214 165.312 47.0714 163.206C42.1537 161.195 34.5744 156.792 28.2694 148.254C25.3662 144.323 23.6548 139.619 22.1405 134.972C20.4251 129.708 19.4122 124.212 19.1866 118.679Z" fill="#E1F2FF"/>
    <path d="M309.363 15.7473C309.561 13.4623 308.52 11.1071 306.696 9.71592C304.873 8.32473 302.5 8.01354 300.349 8.80814C297.571 2.59562 290.073 -0.79811 283.371 0.380034C276.668 1.55818 271.532 5.89724 268.936 12.1877C263.216 9.19441 254.731 11.9216 251.683 17.2001H309.251L309.363 15.7473Z" fill="#E1F2FF"/>
    <path d="M101.693 39.4976H33.6134C34.911 35.8774 37.8043 32.8689 41.426 31.5753C45.0477 30.2815 49.2397 30.6964 52.5375 32.6751C55.4725 26.07 62.1185 20.5566 69.3123 19.8575C76.5063 19.1583 83.9585 22.5851 88.1103 28.5015C89.7803 27.651 91.6476 27.2208 93.5218 27.4047C97.1173 27.7577 100.054 29.8894 101.183 33.3778C101.779 35.2202 101.976 37.3017 101.693 39.4976Z" fill="#E1F2FF"/>
    <path d="M180.792 41.6076C177.858 40.8054 175.311 38.7245 174.053 35.6969C172.795 32.6687 173.119 29.3946 174.622 26.7495C172.771 24.2879 170.578 22.0976 168.113 20.2505C165.47 21.7594 162.196 22.0898 159.165 20.8375C156.135 19.586 154.049 17.0435 153.241 14.1117C151.747 13.9011 150.224 13.7852 148.672 13.7852C147.099 13.7852 145.553 13.9034 144.04 14.1197C143.238 17.0535 141.157 19.6008 138.129 20.8582C135.101 22.116 131.827 21.7925 129.182 20.2892C126.72 22.1409 124.53 24.3337 122.683 26.799C124.192 29.4416 124.522 32.7155 123.27 35.7468C122.019 38.7765 119.476 40.8621 116.544 41.6708C116.334 43.1641 116.218 44.688 116.218 46.2395C116.218 47.8128 116.336 49.358 116.552 50.8716C119.486 51.6737 122.033 53.7544 123.29 56.782C124.548 59.8104 124.225 63.0843 122.722 65.7294C124.573 68.1911 126.766 70.3815 129.231 72.2286C131.874 70.7198 135.148 70.3894 138.179 71.6414C141.209 72.8931 143.295 75.4355 144.103 78.3675C145.596 78.5779 147.12 78.694 148.672 78.694C150.245 78.694 151.79 78.5758 153.304 78.3594C154.106 75.4256 156.187 72.8783 159.214 71.621C162.243 70.3632 165.517 70.6867 168.161 72.1899C170.623 70.3381 172.814 68.1454 174.661 65.6801C173.152 63.0375 172.822 59.7634 174.074 56.7322C175.325 53.7025 177.868 51.6167 180.799 50.8082C181.01 49.3147 181.126 47.791 181.126 46.2395C181.126 44.6662 181.008 43.1211 180.792 41.6076ZM148.672 62.0746C139.927 62.0746 132.837 54.985 132.837 46.2395C132.837 37.494 139.927 30.4045 148.672 30.4045C157.418 30.4045 164.507 37.4942 164.507 46.2395C164.507 54.9851 157.418 62.0746 148.672 62.0746Z" fill="#C0E2FC"/>
    <path d="M148.672 55.4993C153.787 55.4993 157.933 51.3533 157.933 46.2389C157.933 41.1245 153.787 36.9785 148.672 36.9785C143.558 36.9785 139.412 41.1245 139.412 46.2389C139.412 51.3533 143.558 55.4993 148.672 55.4993Z" fill="#C0E2FC"/>
    <path d="M145.204 92.9637C142.896 92.5023 140.818 91.0333 139.685 88.7642C138.551 86.4946 138.624 83.9499 139.642 81.8274C138.08 80.027 136.27 78.4544 134.268 77.1615C132.309 78.4683 129.8 78.8996 127.393 78.0961C124.987 77.2932 123.24 75.443 122.459 73.2235C121.295 73.1414 120.112 73.1338 118.915 73.2173C117.7 73.302 116.514 73.4764 115.357 73.7248C114.896 76.0325 113.427 78.1106 111.158 79.244C108.888 80.3779 106.344 80.3043 104.221 79.2864C102.421 80.8481 100.848 82.6585 99.5553 84.6608C100.862 86.6192 101.293 89.1285 100.49 91.5355C99.6871 93.9414 97.8368 95.688 95.6174 96.4699C95.5353 97.6339 95.5278 98.8163 95.6112 100.014C95.6959 101.228 95.8702 102.415 96.1187 103.571C98.4264 104.033 100.504 105.501 101.638 107.771C102.772 110.04 102.698 112.585 101.68 114.707C103.242 116.508 105.052 118.08 107.055 119.373C109.013 118.067 111.522 117.635 113.93 118.439C116.335 119.242 118.082 121.092 118.864 123.311C120.028 123.394 121.21 123.401 122.408 123.318C123.622 123.233 124.808 123.059 125.965 122.81C126.426 120.502 127.895 118.424 130.164 117.291C132.434 116.157 134.979 116.231 137.101 117.249C138.902 115.687 140.474 113.876 141.767 111.874C140.46 109.916 140.029 107.406 140.832 104.999C141.635 102.593 143.485 100.847 145.705 100.065C145.787 98.9008 145.795 97.7184 145.711 96.5209C145.627 95.3065 145.452 94.1203 145.204 92.9637ZM121.513 110.49C114.763 110.961 108.91 105.87 108.439 99.1196C107.968 92.3694 113.059 86.5156 119.809 86.0451C126.559 85.5745 132.413 90.665 132.884 97.4152C133.354 104.166 128.264 110.019 121.513 110.49Z" fill="#C0E2FC"/>
    <path d="M122.185 105.264C126.053 104.425 128.508 100.61 127.669 96.7432C126.831 92.8759 123.016 90.4206 119.149 91.2591C115.282 92.0976 112.826 95.9124 113.665 99.7797C114.503 103.647 118.318 106.102 122.185 105.264Z" fill="#C0E2FC"/>
    <path d="M218.726 186.162C175.724 167.392 147.934 124.187 147.934 76.0953V40.0307L225.821 10.1064L303.709 40.0307V76.0951C303.709 124.187 275.918 167.392 232.909 186.164L225.822 189.264L218.726 186.162Z" fill="#A6D8F9"/>
    <path d="M222.911 176.589C183.712 159.478 158.382 120.032 158.382 76.0959V47.2109L225.821 21.3008L293.26 47.2109V76.0959C293.26 120.032 267.931 159.478 228.73 176.589L225.821 177.86L222.911 176.589Z" fill="#70BEED"/>
    <path d="M227.562 31.624L284.247 53.4031V76.0946C284.247 115.526 262.086 150.996 227.562 167.222V31.624Z" fill="#A6D8F9"/>
    <path d="M196.032 141.679C194.967 141.679 193.964 141.219 193.281 140.417C178.191 122.716 169.882 99.8725 169.882 76.0957V55.3381L219.337 36.3364C219.76 36.1742 220.202 36.0908 220.649 36.0908C222.155 36.0908 223.53 37.0357 224.07 38.4418C224.793 40.3286 223.85 42.4519 221.966 43.1752L177.209 60.3689V76.0956C177.209 98.1045 184.881 119.242 198.81 135.613C200.084 137.108 199.981 139.327 198.579 140.663C197.891 141.318 196.986 141.679 196.033 141.679C196.032 141.679 196.033 141.679 196.032 141.679Z" fill="#7FC8F4"/>
    <path d="M244.285 84.0025V76.304C244.285 66.99 236.708 59.4131 227.395 59.4131H222.901C213.588 59.4131 206.012 66.99 206.012 76.304V84.0025C202.707 84.561 200.188 87.4289 200.188 90.8924V121.879C200.188 125.743 203.32 128.875 207.184 128.875H243.113C246.976 128.875 250.109 125.743 250.109 121.879V90.8924C250.109 87.4289 247.589 84.561 244.285 84.0025ZM228.203 105.439V114.693C228.203 116.379 226.835 117.747 225.148 117.747C223.461 117.747 222.094 116.379 222.094 114.693V105.439C220.074 104.35 218.7 102.216 218.7 99.7593C218.7 96.198 221.587 93.3111 225.148 93.3111C228.71 93.3111 231.596 96.198 231.596 99.7593C231.596 102.216 230.223 104.35 228.203 105.439ZM237.629 83.8964H212.668V76.304C212.668 70.661 217.259 66.0692 222.901 66.0692H227.395C233.038 66.0692 237.629 70.661 237.629 76.304V83.8964Z" fill="white"/>
    <path d="M55.3846 188.1C40.3853 192.526 24.5827 183.923 20.1574 168.923C15.7321 153.924 24.3338 138.121 39.3332 133.695C54.3326 129.27 70.1366 137.872 74.5619 152.872C78.9874 167.871 70.384 183.675 55.3846 188.1ZM43.0935 146.441C35.1217 148.793 30.5504 157.191 32.9024 165.163C35.2544 173.135 43.6524 177.707 51.6243 175.355C59.5961 173.003 64.1689 164.604 61.8169 156.632C59.4649 148.66 51.0654 144.089 43.0935 146.441Z" fill="#7FC8F4"/>
    <path d="M154.155 136.314L70.0696 161.122C66.5504 162.161 62.8552 160.149 61.8169 156.63C60.7786 153.111 62.79 149.416 66.3092 148.377L150.394 123.569C153.914 122.531 157.609 124.542 158.647 128.062C159.685 131.581 157.674 135.276 154.155 136.314Z" fill="#7FC8F4"/>
    <path d="M149.495 160.333C145.976 161.371 142.281 159.359 141.242 155.84L135.097 135.01C134.058 131.491 136.07 127.796 139.589 126.758C143.108 125.719 146.804 127.731 147.842 131.25L153.987 152.08C155.026 155.599 153.014 159.294 149.495 160.333Z" fill="#7FC8F4"/>
    <path d="M126.637 167.081C123.118 168.119 119.422 166.107 118.384 162.588L112.239 141.758C111.2 138.239 113.212 134.544 116.731 133.506C120.25 132.467 123.945 134.479 124.984 137.998L131.129 158.828C132.168 162.347 130.156 166.042 126.637 167.081Z" fill="#7FC8F4"/>
    <path d="M77.9488 91.1592C78.1462 91.1792 78.3383 91.174 78.5085 91.1318C79.1252 90.9789 79.1939 90.0504 79.6211 89.6301C80.5919 88.675 81.5243 88.4359 82.8358 88.4245C84.4297 88.4106 85.8952 88.1104 86.8398 87.176C88.2128 85.8178 88.9207 82.3874 87.7216 80.672C87.0993 79.7817 86.7793 79.2467 86.3678 78.3626C85.2406 75.9409 81.256 74.9779 78.4936 75.7396C77.0938 76.1255 75.8031 76.8694 74.7648 77.85C73.4156 79.1242 71.8728 81.1833 71.8797 83.0885C71.8832 84.0699 72.2259 85.044 72.3891 86.007C72.5281 86.8266 72.4056 87.755 72.9231 88.4741C73.4533 89.2108 74.4375 89.4497 75.2489 89.8781C75.7614 90.1487 76.2204 90.5079 76.7322 90.7797C77.0447 90.9459 77.5101 91.1148 77.9488 91.1592Z" fill="#193368"/>
    <path d="M72.8031 76.2696C72.9578 77.5578 74.0187 78.7297 75.0921 79.4183C76.0423 80.0278 77.8746 80.2534 78.8702 79.6323C80.3957 78.6808 80.0904 75.9981 79.3803 74.6694C78.8685 73.7117 77.9227 73.1221 76.8651 72.8699C74.7043 72.3544 72.541 74.0867 72.8031 76.2696Z" fill="#193368"/>
    <path d="M114.736 167.519L111.482 160.744L116.571 158.82L119.368 164.894C119.75 165.733 120.264 166.168 120.943 166.518L125.603 168.86C126.721 169.435 126.273 171.128 125.017 171.076L114.302 170.628L114.736 167.519Z" fill="#FFC5BD"/>
    <path d="M114.123 170.768C114.103 170.97 114.119 171.149 114.171 171.33C114.326 171.908 114.843 172.346 115.473 172.373L130.732 173.011C131.183 173.03 131.568 172.653 131.589 172.154C131.591 172.118 131.592 172.082 131.581 172.058C131.57 171.748 131.403 171.467 131.124 171.325L124.527 168.239C121.301 169.151 118.131 168.997 114.789 167.9C114.668 167.86 114.539 167.937 114.521 168.063L114.123 170.768Z" fill="#193368"/>
    <path d="M114.171 171.34C114.325 171.918 114.843 172.356 115.472 172.382L130.731 173.021C131.183 173.039 131.567 172.663 131.588 172.164C131.59 172.128 131.591 172.092 131.581 172.068L114.171 171.34Z" fill="#193972"/>
    <path d="M86.5795 174.858L86.2957 174.64L85.8693 175.542C85.6573 175.991 85.6505 176.508 85.8502 176.963C86.8212 179.173 89.3877 183.883 90.5515 185.4C91.3643 186.459 92.5102 187.066 93.6954 187.066C93.7063 187.066 93.7172 187.066 93.7279 187.066C95.6383 187.066 95.8126 184.692 95.2276 183.03L86.5795 174.858Z" fill="#193972"/>
    <path d="M90.9295 173.267L87.676 172.53C87.2313 172.479 86.2962 173.698 86.177 175.454C86.177 175.454 89.8112 182.957 91.2653 184.851C92.9062 186.989 95.3878 186.4 95.3176 184.003C95.2442 181.5 93.62 177.357 93.4046 176.208L90.9295 173.267Z" fill="#193368"/>
    <path d="M92.7488 173.466L93.9576 167.173L89.1887 165.905L87.6755 172.927C88.221 175.741 90.4283 180.17 91.4568 181.256C92.557 182.419 94.1822 181.893 94.1822 180.67C94.1822 179.662 93.4834 176.365 92.7488 173.466Z" fill="#FFC5BD"/>
    <path d="M79.2462 101.757C79.9554 103.882 82.2529 105.03 84.3777 104.32C86.5024 103.611 87.6499 101.313 86.9405 99.1887C86.2311 97.0639 83.9337 95.9165 81.809 96.6259C79.6842 97.3351 78.5368 99.6326 79.2462 101.757Z" fill="#FFC5BD"/>
    <path d="M86.8542 120.802C87.3557 122.305 88.9803 123.116 90.4826 122.614C91.985 122.113 92.7964 120.488 92.2949 118.986C91.7933 117.484 90.1688 116.672 88.6664 117.174C87.1639 117.675 86.3526 119.3 86.8542 120.802Z" fill="#FFC5BD"/>
    <path d="M86.9395 99.1885L86.9687 99.3271C88.3651 105.975 90.1432 112.536 92.2941 118.98L86.8534 120.796L86.8242 120.733C83.9448 114.581 81.4221 108.269 79.2687 101.827L79.2454 101.757L86.9395 99.1885Z" fill="#FFC5BD"/>
    <path d="M114.441 166.369C112.234 162.357 109.654 158.205 106.335 153.321C104.563 150.715 103.177 147.811 101.837 145.003C101.093 143.442 100.323 141.829 99.4938 140.279C98.8253 139.03 98.0581 137.821 97.3162 136.653C96.429 135.255 95.5127 133.811 94.7693 132.301C95.485 131.955 97.7856 130.846 97.8174 130.831C98.8571 130.33 99.9166 130.019 100.969 129.909C101.115 129.893 101.258 129.882 101.399 129.873C103.669 129.741 105.131 130.54 106.138 132.462C107.354 134.782 108.39 137.247 109.391 139.631L109.888 140.81C111.658 144.999 118.201 162.66 119.025 164.886L114.441 166.369Z" fill="#FFC5BD"/>
    <path d="M101.12 145.28L99.7934 150.233L98.6314 154.517C97.7043 157.955 95.627 164.61 94.2773 168.823L89.1719 167.943C89.7119 163.823 90.0639 159.68 90.4278 155.631L90.7095 152.427C90.8387 151.042 91.0264 149.587 91.2494 148.085C91.4725 146.641 91.7188 145.186 91.9888 143.754L92.1649 142.757C92.4466 141.172 92.74 139.541 93.2564 137.968C93.3033 137.828 93.3502 137.687 93.4089 137.546C95.2749 138.227 97.0822 138.907 98.8075 139.823C99.5117 140.198 100.298 140.691 100.744 141.548C101.319 142.686 101.225 143.942 101.12 145.28Z" fill="#FFC5BD"/>
    <path d="M71.6777 143.28C76.5296 144.024 85.9936 145.703 90.6815 147.208C91.8296 147.576 95.2763 148.673 95.2763 148.673L100.702 145.173C100.89 143.796 101.078 142.49 100.56 141.243C100.193 140.359 99.5104 139.786 98.7268 139.289C97.0672 138.22 95.438 137.101 93.5938 136.431C92.926 136.189 92.3718 136.03 91.744 135.788C90.2507 135.213 83.1399 132.634 83.1399 132.634L83.4849 128.438C83.4849 128.438 68.8904 127.654 65.7371 127.391L65.4237 129.403C64.1774 134.535 66.433 142.476 71.6777 143.28Z" fill="#193972"/>
    <path d="M118.986 161.954C116.828 156.147 110.409 139.196 110.409 139.196C109.4 136.793 108.356 134.309 107.12 131.951C105.995 129.805 104.278 128.759 101.871 128.755C101.544 128.754 101.205 128.773 100.852 128.811C99.6749 128.934 98.492 129.279 97.3368 129.835L93.2892 131.788C93.2892 131.788 97.8664 139.595 98.5154 140.808C99.333 142.335 110.08 161.428 111.924 164.589L118.986 161.954Z" fill="#193972"/>
    <path d="M101.283 141.053C100.698 139.91 99.6892 139.274 98.8747 138.847C97.039 137.871 92.5411 136.334 92.5411 136.334C92.5411 136.334 87.7143 164.634 87.1068 169.046L94.3995 170.682C95.7156 166.55 98.2622 158.505 99.2603 154.804C99.9514 152.242 100.64 149.69 101.332 147.125C101.87 145.131 102.275 142.991 101.283 141.053Z" fill="#193972"/>
    <path d="M65.6129 142.703V142.707C67.2961 144.743 69.6934 146.173 72.4895 146.528C75.3933 146.895 78.5324 146.065 81.3046 145.31C81.5479 145.243 81.7912 145.173 82.0304 145.098C82.0384 145.098 82.0423 145.093 82.0503 145.093C84.4476 144.347 86.6454 143.233 88.9987 142.34C91.0968 141.544 92.8916 140.827 95.1334 140.063C96.4656 139.609 99.8719 138.97 101.404 141.052C101.404 141.052 102.74 140.109 103.342 140.247C103.47 139.425 104.012 129.102 104.012 129.102C102.142 128.506 96.4775 128.794 93.9767 128.94C89.2541 129.211 83.5183 129.624 78.7039 129.982L77.3756 128.214L63.5983 130.127L63.0441 134.912C62.8807 137.803 63.854 140.58 65.6129 142.703Z" fill="#193972"/>
    <path d="M101.855 146.437C101.833 146.437 101.811 146.434 101.79 146.429C101.631 146.393 101.533 146.236 101.569 146.077C101.818 144.996 102.062 143.742 101.809 142.537C101.551 141.318 100.842 140.471 99.8112 140.153C97.998 139.594 96.0038 140.256 94.2755 140.919L82.7662 145.314C82.6127 145.373 82.4453 145.295 82.388 145.145C82.3295 144.994 82.4062 144.824 82.5576 144.766L94.0657 140.371C95.8846 139.673 97.9957 138.978 99.9842 139.592C101.227 139.976 102.078 140.978 102.382 142.416C102.648 143.675 102.423 144.983 102.14 146.209C102.109 146.345 101.989 146.437 101.855 146.437Z" fill="#193368"/>
    <path d="M65.598 142.705V142.709C67.1439 144.828 69.696 146.265 72.4948 146.619C75.4013 146.986 78.6169 146.266 81.3044 145.312C81.5427 145.227 81.7914 145.174 82.031 145.099C80.2863 145.596 76.8449 146 73.8505 145.621C70.5966 145.208 67.9416 144.286 65.598 142.705Z" fill="#193368"/>
    <path d="M65.4642 131.954C69.1669 129.405 78.832 129.973 86.0152 129.459C85.8295 127.358 85.592 125.238 85.4928 123.846C85.2421 120.327 85.3479 118.223 85.7891 115.166C85.9369 114.141 86.8378 110.403 87.1371 108.89C87.5993 106.553 87.3568 104.228 86.5025 102.003C85.9793 100.641 85.3487 97.0641 85.3487 97.0641C84.4617 96.2775 82.585 95.1348 81.5257 94.7411L74.5164 94.1787C69.884 95.2246 65.7079 97.2729 64.4879 102.484C63.5407 106.53 63.0768 111.161 62.7378 117.184C62.4787 121.785 62.6659 129.329 63.0264 134.596C63.3212 133.678 64.0032 132.96 65.4642 131.954Z" fill="#FFD683"/>
    <path d="M85.8722 106.672C85.8661 106.588 85.8371 106.522 85.7851 106.475C85.7339 106.428 85.6558 106.404 85.5509 106.404C85.484 106.404 85.4292 106.413 85.3865 106.43C85.3445 106.447 85.3133 106.47 85.2931 106.499C85.2728 106.529 85.2623 106.562 85.2614 106.6C85.2598 106.631 85.2648 106.659 85.2764 106.684C85.2888 106.709 85.3082 106.731 85.3346 106.751C85.361 106.771 85.3948 106.788 85.4359 106.804C85.4771 106.82 85.5261 106.835 85.5828 106.847L85.7786 106.893C85.9106 106.923 86.0237 106.963 86.1176 107.013C86.2116 107.063 86.2884 107.121 86.3482 107.189C86.4079 107.255 86.4517 107.33 86.4795 107.413C86.5081 107.496 86.5228 107.587 86.5234 107.685C86.5223 107.855 86.4828 107.998 86.4049 108.115C86.3269 108.233 86.2156 108.322 86.071 108.383C85.9271 108.444 85.7541 108.474 85.552 108.474C85.3445 108.474 85.1634 108.44 85.0089 108.374C84.8551 108.307 84.7355 108.205 84.6503 108.067C84.5658 107.928 84.5234 107.751 84.523 107.535L85.1385 107.536C85.1423 107.615 85.1604 107.682 85.193 107.736C85.2256 107.789 85.2713 107.83 85.3304 107.858C85.3902 107.886 85.4613 107.9 85.5436 107.9C85.6128 107.9 85.6707 107.891 85.7174 107.872C85.7641 107.854 85.7995 107.828 85.8236 107.796C85.8478 107.763 85.8603 107.726 85.8611 107.684C85.8604 107.644 85.8484 107.61 85.8252 107.58C85.8027 107.55 85.7654 107.523 85.7134 107.5C85.6614 107.475 85.5911 107.453 85.5025 107.431L85.2647 107.376C85.0534 107.326 84.8868 107.243 84.765 107.127C84.644 107.01 84.584 106.851 84.5851 106.649C84.5846 106.485 84.6253 106.342 84.7071 106.22C84.7897 106.097 84.9037 106.001 85.0492 105.932C85.1955 105.864 85.363 105.83 85.5519 105.83C85.7446 105.83 85.9113 105.865 86.0519 105.935C86.1924 106.005 86.3007 106.104 86.3766 106.23C86.4534 106.356 86.492 106.504 86.4925 106.673L85.8722 106.672Z" fill="#AAB4E1"/>
    <path d="M83.6526 106.668C83.6466 106.584 83.6175 106.518 83.5655 106.471C83.5143 106.424 83.4362 106.4 83.3313 106.4C83.2645 106.4 83.2097 106.409 83.1669 106.426C83.1249 106.443 83.0938 106.466 83.0735 106.495C83.0532 106.525 83.0427 106.558 83.0418 106.596C83.0402 106.627 83.0452 106.655 83.0569 106.68C83.0692 106.705 83.0886 106.727 83.115 106.747C83.1414 106.767 83.1752 106.784 83.2164 106.8C83.2575 106.816 83.3065 106.831 83.3632 106.843L83.559 106.889C83.6911 106.919 83.8041 106.96 83.8981 107.009C83.992 107.059 84.0689 107.117 84.1286 107.185C84.1883 107.251 84.2321 107.326 84.26 107.409C84.2886 107.492 84.3032 107.583 84.3038 107.681C84.3027 107.851 84.2632 107.994 84.1853 108.111C84.1074 108.229 83.9961 108.318 83.8514 108.379C83.7075 108.44 83.5345 108.47 83.3324 108.47C83.1249 108.47 82.9438 108.436 82.7893 108.37C82.6355 108.303 82.516 108.201 82.4307 108.063C82.3462 107.924 82.3038 107.747 82.3034 107.532L82.919 107.533C82.9227 107.612 82.9409 107.678 82.9734 107.732C83.006 107.785 83.0518 107.826 83.1108 107.854C83.1706 107.882 83.2417 107.896 83.3241 107.896C83.3932 107.896 83.4512 107.887 83.4978 107.869C83.5445 107.85 83.5799 107.825 83.6041 107.792C83.6282 107.759 83.6407 107.722 83.6416 107.68C83.6409 107.64 83.6289 107.606 83.6056 107.577C83.5831 107.546 83.5459 107.519 83.4938 107.496C83.4418 107.471 83.3715 107.449 83.2829 107.427L83.0452 107.372C82.8338 107.322 82.6673 107.239 82.5454 107.123C82.4244 107.006 82.3644 106.847 82.3655 106.645C82.365 106.482 82.4057 106.339 82.4875 106.216C82.5701 106.093 82.6842 105.997 82.8296 105.928C82.9759 105.86 83.1434 105.826 83.3323 105.826C83.5251 105.826 83.6917 105.861 83.8323 105.931C83.9729 106.001 84.0811 106.1 84.1571 106.227C84.2338 106.353 84.2724 106.5 84.2729 106.669L83.6526 106.668Z" fill="#AAB4E1"/>
    <path d="M82.0271 105.86L82.0227 108.438L81.3745 108.437L81.3789 105.858L82.0271 105.86Z" fill="#AAB4E1"/>
    <path d="M78.3568 108.431L77.6571 105.852L78.3799 105.853L78.7036 107.44L78.7223 107.44L79.1121 105.854L79.681 105.855L80.0654 107.447L80.084 107.447L80.4132 105.856L81.1361 105.858L80.4274 108.435L79.8072 108.434L79.4039 106.993L79.3853 106.993L78.977 108.432L78.3568 108.431Z" fill="#AAB4E1"/>
    <path d="M76.8214 106.656C76.8154 106.572 76.7863 106.506 76.7343 106.459C76.6831 106.412 76.605 106.389 76.5001 106.388C76.4333 106.388 76.3785 106.397 76.3357 106.415C76.2937 106.431 76.2625 106.454 76.2423 106.484C76.222 106.513 76.2115 106.547 76.2106 106.584C76.209 106.615 76.214 106.644 76.2256 106.669C76.238 106.693 76.2574 106.715 76.2838 106.736C76.3102 106.755 76.344 106.773 76.3852 106.789C76.4263 106.805 76.4753 106.819 76.532 106.832L76.7278 106.877C76.8599 106.908 76.9729 106.948 77.0668 106.998C77.1608 107.047 77.2377 107.106 77.2974 107.173C77.3571 107.239 77.4009 107.314 77.4287 107.397C77.4574 107.48 77.472 107.571 77.4726 107.669C77.4715 107.839 77.432 107.982 77.3541 108.1C77.2762 108.217 77.1649 108.306 77.0202 108.367C76.8763 108.428 76.7033 108.459 76.5012 108.458C76.2937 108.458 76.1126 108.425 75.9581 108.358C75.8043 108.291 75.6848 108.189 75.5995 108.051C75.515 107.913 75.4726 107.736 75.4722 107.52L76.0878 107.521C76.0915 107.6 76.1097 107.666 76.1422 107.72C76.1748 107.774 76.2206 107.814 76.2796 107.842C76.3394 107.87 76.4105 107.884 76.4929 107.884C76.562 107.884 76.62 107.875 76.6666 107.857C76.7133 107.838 76.7487 107.813 76.7729 107.78C76.797 107.747 76.8095 107.71 76.8104 107.668C76.8096 107.629 76.7977 107.594 76.7744 107.565C76.7519 107.535 76.7146 107.508 76.6626 107.484C76.6106 107.46 76.5403 107.437 76.4517 107.416L76.214 107.36C76.0026 107.31 75.8361 107.227 75.7142 107.111C75.5932 106.994 75.5332 106.835 75.5343 106.634C75.5338 106.47 75.5745 106.327 75.6563 106.204C75.7389 106.081 75.853 105.985 75.9984 105.917C76.1447 105.848 76.3122 105.814 76.5011 105.814C76.6939 105.815 76.8605 105.85 77.0011 105.92C77.1416 105.99 77.2499 106.088 77.3259 106.215C77.4026 106.341 77.4412 106.488 77.4417 106.657L76.8214 106.656Z" fill="#AAB4E1"/>
    <path d="M85.5762 106.672C85.5701 106.588 85.5411 106.522 85.4891 106.475C85.4379 106.428 85.3598 106.404 85.2549 106.404C85.188 106.404 85.1332 106.413 85.0904 106.43C85.0484 106.447 85.0173 106.47 84.997 106.499C84.9768 106.529 84.9662 106.562 84.9654 106.6C84.9638 106.631 84.9688 106.659 84.9804 106.684C84.9928 106.709 85.0122 106.731 85.0386 106.751C85.065 106.771 85.0988 106.788 85.1399 106.804C85.1811 106.82 85.23 106.835 85.2868 106.847L85.4825 106.893C85.6146 106.923 85.7276 106.963 85.8216 107.013C85.9156 107.063 85.9924 107.121 86.0521 107.189C86.1119 107.255 86.1557 107.33 86.1835 107.413C86.2121 107.496 86.2267 107.587 86.2273 107.685C86.2263 107.855 86.1868 107.998 86.1088 108.115C86.0309 108.233 85.9196 108.322 85.7749 108.383C85.631 108.444 85.458 108.474 85.256 108.474C85.0484 108.474 84.8674 108.44 84.7128 108.374C84.5591 108.307 84.4395 108.205 84.3543 108.067C84.2698 107.928 84.2273 107.751 84.2269 107.535L84.8425 107.536C84.8463 107.615 84.8644 107.682 84.897 107.736C84.9295 107.789 84.9753 107.83 85.0343 107.858C85.0941 107.886 85.1652 107.9 85.2476 107.9C85.3168 107.9 85.3747 107.891 85.4214 107.872C85.4681 107.854 85.5035 107.828 85.5276 107.796C85.5518 107.763 85.5643 107.726 85.5651 107.684C85.5644 107.644 85.5524 107.61 85.5292 107.58C85.5067 107.55 85.4694 107.523 85.4174 107.5C85.3653 107.475 85.295 107.453 85.2065 107.431L84.9687 107.376C84.7574 107.326 84.5908 107.243 84.469 107.127C84.3479 107.01 84.288 106.851 84.2891 106.649C84.2886 106.485 84.3293 106.342 84.4111 106.22C84.4937 106.097 84.6077 106.001 84.7532 105.932C84.8994 105.864 85.067 105.83 85.2559 105.83C85.4486 105.83 85.6153 105.865 85.7558 105.935C85.8964 106.005 86.0047 106.104 86.0806 106.23C86.1574 106.356 86.196 106.504 86.1964 106.673L85.5762 106.672Z" fill="white"/>
    <path d="M83.3566 106.668C83.3505 106.584 83.3215 106.518 83.2695 106.471C83.2183 106.424 83.1402 106.4 83.0353 106.4C82.9685 106.4 82.9136 106.409 82.8709 106.426C82.8289 106.443 82.7977 106.466 82.7775 106.495C82.7572 106.525 82.7467 106.558 82.7458 106.596C82.7442 106.627 82.7492 106.655 82.7608 106.68C82.7732 106.705 82.7926 106.727 82.819 106.747C82.8454 106.767 82.8792 106.784 82.9204 106.8C82.9615 106.816 83.0105 106.831 83.0672 106.843L83.263 106.889C83.395 106.919 83.5081 106.96 83.602 107.009C83.696 107.059 83.7728 107.117 83.8326 107.185C83.8923 107.251 83.9361 107.326 83.9639 107.409C83.9925 107.492 84.0072 107.583 84.0078 107.681C84.0067 107.851 83.9672 107.994 83.8893 108.111C83.8113 108.229 83.7 108.318 83.5554 108.379C83.4115 108.44 83.2385 108.47 83.0364 108.47C82.8289 108.47 82.6478 108.436 82.4933 108.37C82.3395 108.303 82.22 108.201 82.1347 108.063C82.0502 107.924 82.0078 107.747 82.0074 107.532L82.623 107.533C82.6267 107.612 82.6449 107.678 82.6774 107.732C82.71 107.785 82.7557 107.826 82.8148 107.854C82.8746 107.882 82.9457 107.896 83.0281 107.896C83.0972 107.896 83.1551 107.887 83.2018 107.869C83.2485 107.85 83.2839 107.825 83.308 107.792C83.3322 107.759 83.3447 107.722 83.3455 107.68C83.3448 107.64 83.3328 107.606 83.3096 107.577C83.2871 107.546 83.2498 107.519 83.1978 107.496C83.1458 107.471 83.0755 107.449 82.9869 107.427L82.7491 107.372C82.5378 107.322 82.3712 107.239 82.2494 107.123C82.1284 107.006 82.0684 106.847 82.0695 106.645C82.069 106.482 82.1097 106.339 82.1915 106.216C82.2741 106.093 82.3882 105.997 82.5336 105.928C82.6799 105.86 82.8474 105.826 83.0363 105.826C83.2291 105.826 83.3957 105.861 83.5363 105.931C83.6768 106.001 83.7851 106.1 83.861 106.227C83.9378 106.353 83.9764 106.5 83.9769 106.669L83.3566 106.668Z" fill="white"/>
    <path d="M81.7311 105.86L81.7267 108.438L81.0784 108.437L81.0829 105.858L81.7311 105.86Z" fill="white"/>
    <path d="M78.0608 108.431L77.361 105.852L78.0839 105.853L78.4076 107.44L78.4262 107.44L78.8161 105.854L79.385 105.855L79.7693 107.447L79.788 107.447L80.1172 105.856L80.84 105.858L80.1314 108.435L79.5111 108.434L79.1079 106.993L79.0892 106.993L78.681 108.432L78.0608 108.431Z" fill="white"/>
    <path d="M76.5254 106.656C76.5193 106.572 76.4903 106.506 76.4383 106.459C76.3871 106.412 76.309 106.389 76.2041 106.388C76.1372 106.388 76.0824 106.397 76.0397 106.415C75.9977 106.431 75.9665 106.454 75.9463 106.484C75.926 106.513 75.9155 106.547 75.9146 106.584C75.913 106.615 75.918 106.644 75.9296 106.669C75.942 106.693 75.9614 106.715 75.9878 106.736C76.0142 106.755 76.048 106.773 76.0891 106.789C76.1303 106.805 76.1793 106.819 76.236 106.832L76.4318 106.877C76.5638 106.908 76.6769 106.948 76.7708 106.998C76.8648 107.047 76.9416 107.106 77.0014 107.173C77.0611 107.239 77.1049 107.314 77.1327 107.397C77.1613 107.48 77.176 107.571 77.1766 107.669C77.1755 107.839 77.136 107.982 77.0581 108.1C76.9801 108.217 76.8688 108.306 76.7242 108.367C76.5803 108.428 76.4073 108.459 76.2052 108.458C75.9977 108.458 75.8166 108.425 75.6621 108.358C75.5083 108.291 75.3887 108.189 75.3035 108.051C75.219 107.913 75.1766 107.736 75.1762 107.52L75.7917 107.521C75.7955 107.6 75.8136 107.666 75.8462 107.72C75.8787 107.774 75.9245 107.814 75.9836 107.842C76.0434 107.87 76.1145 107.884 76.1968 107.884C76.266 107.884 76.3239 107.875 76.3706 107.857C76.4173 107.838 76.4527 107.813 76.4768 107.78C76.501 107.747 76.5135 107.71 76.5143 107.668C76.5136 107.629 76.5016 107.594 76.4784 107.565C76.4559 107.535 76.4186 107.508 76.3666 107.484C76.3146 107.46 76.2443 107.437 76.1557 107.416L75.9179 107.36C75.7066 107.31 75.54 107.227 75.4182 107.111C75.2972 106.994 75.2372 106.835 75.2383 106.634C75.2378 106.47 75.2785 106.327 75.3603 106.204C75.4429 106.081 75.5569 105.985 75.7024 105.917C75.8487 105.848 76.0162 105.814 76.2051 105.814C76.3978 105.815 76.5645 105.85 76.7051 105.92C76.8456 105.99 76.9539 106.088 77.0298 106.215C77.1066 106.341 77.1452 106.488 77.1457 106.657L76.5254 106.656Z" fill="white"/>
    <path d="M82.3781 97.0434C82.3037 97.6601 81.4078 97.8994 80.7306 97.8127C78.7354 97.577 75.9078 96.5163 74.4001 95.024L76.0193 83.3311L82.4023 84.7409L81.5261 94.7425C82.1046 95.4336 82.4646 96.3783 82.3781 97.0434Z" fill="#FFC5BD"/>
    <path d="M82.4016 84.7409L81.5254 94.7425C78.9519 93.6583 76.5771 90.8206 75.4005 87.7808L76.0184 83.3311L82.4016 84.7409Z" fill="#EF9B8F"/>
    <path d="M80.1975 76.5403C84.0894 76.8269 86.6762 79.2578 86.6301 81.9993C86.5974 83.9359 86.3683 85.8654 85.981 87.7641C85.6145 89.5601 85.021 91.4506 83.6297 92.6709C82.0682 94.04 79.2295 93.1592 77.51 91.8829C74.3124 89.5095 73.0223 85.9468 73.2497 82.5803C73.485 79.0918 75.8226 76.2181 80.1975 76.5403Z" fill="#FFC5BD"/>
    <path d="M87.0407 82.8006C87.3323 79.9497 85.1338 77.341 81.7245 76.4774C81.414 76.3901 81.085 76.3266 80.7536 76.2762C78.6042 75.9563 76.7095 76.4366 75.2746 77.647C73.999 78.7381 73.1294 80.4128 72.8372 82.349C72.5025 84.6457 73.2387 87.7319 75.1478 89.4846L75.243 88.2479L75.552 85.0739C79.03 84.9271 82.0397 83.8014 83.9381 81.866C84.6193 82.6776 85.9023 83.5127 86.9212 83.8423C86.9611 83.4949 87.0008 83.1477 87.0407 82.8006Z" fill="#193368"/>
    <path d="M75.8218 84.4877C76.519 85.1617 77.0103 86.0536 77.2925 86.9813C78.0993 86.319 78.4676 85.1674 78.1951 84.1599C77.5771 84.0024 77.0237 83.959 76.4028 84.1048C76.3269 84.1225 76.249 84.1438 76.1882 84.1924C76.1272 84.241 76.0873 84.3241 76.1114 84.3984C76.0863 84.4294 75.8468 84.4569 75.8218 84.4877Z" fill="#193368"/>
    <path d="M74.4284 87.1363C74.756 88.1374 75.7441 88.7126 76.6355 88.421C77.5268 88.1294 77.9838 87.0813 77.6564 86.0802C77.3289 85.0791 76.3407 84.504 75.4493 84.7955C74.5578 85.0873 74.1008 86.1354 74.4284 87.1363Z" fill="#FFC5BD"/>
    <path d="M76.9479 86.783C76.9541 86.7838 76.9604 86.7841 76.9668 86.7843C77.0484 86.7843 77.1138 86.7191 77.1143 86.6382C77.1148 86.4925 77.0881 86.3478 77.0352 86.2083C76.8596 85.7389 76.4041 85.4167 75.9508 85.4418C75.8722 85.4457 75.7932 85.4606 75.7175 85.4862C75.568 85.536 75.4329 85.6277 75.3285 85.7507C75.2763 85.8125 75.2841 85.9048 75.3458 85.9576C75.4088 86.0096 75.5006 86.0017 75.5528 85.9404C75.623 85.8572 75.7101 85.7979 75.8113 85.7641C75.8616 85.7471 75.9134 85.7367 75.9662 85.7346C76.2968 85.7098 76.6293 85.9591 76.761 86.3117C76.8011 86.4183 76.821 86.5274 76.8207 86.6367C76.8205 86.7114 76.8758 86.7732 76.9479 86.783Z" fill="#EF9B8F"/>
    <path d="M85.2858 87.0299C85.5452 87.0086 85.7729 87.2017 85.7942 87.4611C85.8156 87.7205 85.6228 87.9483 85.363 87.9696C85.1036 87.9908 84.8759 87.7978 84.8546 87.5384C84.8333 87.279 85.026 87.0514 85.2858 87.0299Z" fill="#193368"/>
    <path d="M81.9585 86.4909C82.2181 86.4696 82.4456 86.6626 82.4671 86.922C82.4884 87.1815 82.2953 87.4092 82.0359 87.4305C81.7763 87.4518 81.5487 87.2587 81.5273 86.9993C81.506 86.7399 81.6991 86.5121 81.9585 86.4909Z" fill="#193368"/>
    <path d="M80.7773 84.8618C80.815 84.768 80.9483 84.7316 81.0667 84.7324C81.6184 84.7365 82.1465 84.8833 82.6549 85.082C82.8337 85.1519 83.3269 85.2984 83.2088 85.535C83.1322 85.6885 82.8195 85.7602 82.6377 85.7135C82.2321 85.6094 81.8294 85.4954 81.4304 85.3716C81.2045 85.3017 80.653 85.171 80.7773 84.8618Z" fill="#193368"/>
    <path d="M87.1204 85.8282C87.1103 85.7275 86.9925 85.6554 86.8784 85.6234C86.3473 85.4736 85.7993 85.4676 85.2557 85.517C85.0645 85.5344 84.55 85.5376 84.5976 85.7979C84.6285 85.9666 84.9088 86.1225 85.0965 86.1283C85.515 86.1414 85.9336 86.1441 86.3512 86.1362C86.5877 86.1317 87.1537 86.1598 87.1204 85.8282Z" fill="#193368"/>
    <path d="M83.8179 89.9157C83.8394 89.9192 83.8617 89.9182 83.8831 89.9112C84.1747 89.8242 84.3771 89.6778 84.4854 89.4758C84.5836 89.2927 84.5957 89.078 84.5213 88.8378C84.4806 88.7058 84.414 88.5389 84.3393 88.352C84.2469 88.1223 84.1429 87.8622 84.0621 87.6006C84.0381 87.523 83.956 87.4818 83.8786 87.5041C83.8017 87.5277 83.7581 87.61 83.7821 87.6876C83.8662 87.9601 83.9729 88.2264 84.0668 88.461C84.1383 88.6391 84.202 88.7981 84.2414 88.9247C84.2913 89.0865 84.2862 89.2256 84.2268 89.3374C84.1578 89.4661 84.0103 89.567 83.7997 89.6305C83.7217 89.6533 83.6777 89.7351 83.7011 89.8125C83.7175 89.8686 83.7641 89.907 83.8179 89.9157Z" fill="#EF9B8F"/>
    <path d="M83.0391 90.811C83.293 90.811 83.5273 90.7634 83.6992 90.6609C83.7686 90.619 83.7909 90.5292 83.7497 90.4591C83.7089 90.3897 83.6196 90.3674 83.5479 90.4087C83.1353 90.6574 82.1761 90.4712 81.8104 90.0717C81.7554 90.0108 81.6626 90.0075 81.6036 90.062C81.5434 90.1164 81.5394 90.2093 81.5939 90.2688C81.9009 90.6064 82.5119 90.811 83.0391 90.811Z" fill="#EF9B8F"/>
    <path d="M70.0187 104.353C72.2585 104.319 74.0463 102.476 74.0121 100.236C73.9778 97.9962 72.1344 96.2084 69.8946 96.2426C67.6549 96.2769 65.867 98.1203 65.9013 100.36C65.9356 102.6 67.779 104.388 70.0187 104.353Z" fill="#FFC5BD"/>
    <path d="M60.8217 118.842C60.326 120.346 61.1438 121.967 62.6482 122.463C64.1526 122.958 65.7739 122.141 66.2696 120.636C66.7653 119.132 65.9475 117.511 64.4431 117.015C62.9386 116.519 61.3172 117.337 60.8217 118.842Z" fill="#FFC5BD"/>
    <path d="M73.803 101.575L73.7435 101.703C70.8906 107.868 68.3951 114.192 66.2693 120.643L60.8216 118.848L60.8355 118.781C62.2032 112.127 63.9527 105.558 66.0756 99.106L66.0986 99.0361L73.803 101.575Z" fill="#FFC5BD"/>
    <path d="M92.4373 119.948C92.4832 118.365 91.2369 117.044 89.6536 116.998C88.0703 116.952 86.7495 118.198 86.7036 119.782C86.6577 121.365 87.904 122.686 89.4873 122.732C91.0706 122.778 92.3914 121.531 92.4373 119.948Z" fill="#FFC5BD"/>
    <path d="M92.0656 127.572C92.6206 128.58 93.8879 128.947 94.896 128.392C95.9042 127.837 96.2715 126.57 95.7165 125.562C95.1615 124.553 93.8942 124.186 92.8861 124.741C91.8779 125.296 91.5106 126.563 92.0656 127.572Z" fill="#FFC5BD"/>
    <path d="M92.0855 118.492C92.9859 120.518 94.2429 123.287 95.7161 125.554L92.0639 127.565C90.3359 125.604 88.5966 123.487 87.0609 121.259L92.0855 118.492Z" fill="#FFC5BD"/>
    <path d="M98.0235 126.742C97.9802 126.736 97.9367 126.733 97.8933 126.732C97.6685 126.731 97.451 126.804 97.2288 126.838C97.0066 126.872 96.7579 126.858 96.5893 126.709C96.4973 126.628 96.4397 126.516 96.3865 126.405C96.227 126.074 96.0879 125.733 95.9703 125.385C96.6161 125.343 97.262 125.302 97.9078 125.26C98.0258 125.253 98.1485 125.246 98.2577 125.291C99.2416 125.694 100.124 126.729 100.828 127.509C100.937 127.63 101.053 127.788 100.999 127.943C100.972 128.019 100.907 128.076 100.836 128.115C100.635 128.222 100.385 128.196 100.176 128.105C99.9679 128.013 99.7913 127.864 99.6125 127.723C99.1761 127.38 98.6092 126.821 98.0235 126.742Z" fill="#FFC5BD"/>
    <path d="M95.792 126.973C95.9676 125.886 95.2289 124.862 94.1421 124.687C93.0553 124.511 92.0319 125.25 91.8563 126.337C91.6807 127.424 92.4194 128.447 93.5062 128.623C94.593 128.798 95.6164 128.06 95.792 126.973Z" fill="#FFC5BD"/>
    <path d="M92.9406 125.308C92.4752 125.601 92.0846 126.019 91.8369 126.51C91.7124 126.757 91.6086 127.038 91.5798 127.315C91.5515 127.589 91.7479 127.72 91.9812 127.828C92.3487 127.999 92.7168 128.17 93.1008 128.3C93.4765 128.428 93.8712 128.515 94.2676 128.498C94.7949 128.475 95.3118 128.261 95.7003 127.904C95.8729 127.745 96.0204 127.56 96.2012 127.411C96.382 127.262 96.6089 127.148 96.8422 127.169C97.041 127.188 97.2194 127.302 97.3629 127.441C97.5063 127.58 97.6226 127.744 97.7555 127.892C97.935 128.093 98.1518 128.27 98.4086 128.352C98.6652 128.434 98.9661 128.408 99.1763 128.24C99.215 128.209 99.2512 128.172 99.2673 128.125C99.2998 128.03 99.2413 127.93 99.1848 127.847C98.6931 127.121 98.1641 126.128 97.3148 125.772C96.8943 125.596 96.503 125.538 96.1248 125.273C95.7496 125.009 95.2222 124.83 94.761 124.863C93.7445 124.939 93.4245 125.004 92.9406 125.308Z" fill="#FFC5BD"/>
    <path d="M91.0319 127.983C91.0947 127.921 91.1621 127.868 91.2338 127.836C91.418 127.756 91.631 127.81 91.8207 127.877C92.0102 127.943 92.2077 128.023 92.4057 127.989C92.6559 127.946 92.8463 127.724 92.9212 127.482C92.9963 127.239 92.9759 126.979 92.9428 126.727C92.8937 126.353 92.9135 126.001 92.8988 125.629C92.8973 125.591 92.8919 125.549 92.8605 125.529C92.8272 125.507 92.7833 125.524 92.7473 125.54C92.1664 125.809 91.5278 126.037 90.998 126.4C90.7455 126.574 90.5362 126.801 90.3329 127.031C89.8687 127.553 89.4231 128.092 88.9775 128.631C88.9556 128.657 88.9328 128.686 88.9269 128.72C88.9177 128.772 88.9514 128.823 88.9893 128.861C89.158 129.03 89.4228 129.065 89.6594 129.03C89.9009 128.995 90.133 128.898 90.3271 128.75C90.5075 128.613 90.6513 128.436 90.7934 128.261C90.8625 128.177 90.9424 128.071 91.0319 127.983Z" fill="#FFC5BD"/>
    <path d="M96.4968 128.927C95.9679 128.703 95.7104 128.113 95.5103 127.615C95.4089 127.362 95.3261 127.097 95.2048 126.852C95.0404 126.354 94.7563 125.969 94.3235 125.687C94.3082 125.677 94.292 125.666 94.2861 125.649C94.2821 125.637 94.2837 125.624 94.2868 125.612C94.3107 125.513 94.4083 125.453 94.4973 125.404C94.6848 125.301 94.8812 125.147 95.0992 125.124C95.3268 125.099 95.5545 125.203 95.7273 125.354C96.0164 125.607 96.2302 125.967 96.3802 126.314C96.7152 127.09 97.0436 127.852 97.4485 128.592C97.4807 128.651 97.5137 128.721 97.4874 128.782C97.4706 128.822 97.4328 128.848 97.3956 128.87C97.1264 129.026 96.7834 129.048 96.4968 128.927Z" fill="#FFC5BD"/>
    <path d="M65.397 121.928C66.6014 120.899 66.744 119.089 65.7153 117.885C64.6867 116.68 62.8765 116.538 61.6721 117.566C60.4676 118.595 60.3251 120.405 61.3537 121.61C62.3823 122.814 64.1926 122.956 65.397 121.928Z" fill="#FFC5BD"/>
    <path d="M80.6597 128.38C81.7407 128.774 82.9371 128.218 83.3318 127.137C83.7264 126.056 83.17 124.86 82.089 124.465C81.0079 124.07 79.8115 124.627 79.4169 125.708C79.0224 126.789 79.5788 127.985 80.6597 128.38Z" fill="#FFC5BD"/>
    <path d="M64.5288 117.052C65.9661 117.575 68.5246 118.788 71.3964 120.049C74.7651 121.528 79.7207 123.598 82.0891 124.461L80.6591 128.377C75.8753 126.909 68.2325 124.317 62.5614 122.44L64.5288 117.052Z" fill="#FFC5BD"/>
    <path d="M80.6597 128.381C81.7407 128.775 82.9371 128.219 83.3318 127.138C83.7264 126.057 83.17 124.861 82.089 124.466C81.0079 124.071 79.8115 124.628 79.4169 125.709C79.0224 126.79 79.5788 127.986 80.6597 128.381Z" fill="#FFC5BD"/>
    <path d="M81.4719 124.342C82.9014 124.098 84.5224 124.401 86.2044 124.366C87.8444 124.332 89.8406 126.027 90.5709 127.429C90.7695 127.811 90.9681 128.192 91.1665 128.573C91.2201 128.676 91.2672 128.818 91.1789 128.894C91.0334 129.018 90.471 128.933 90.3093 128.89C90.1627 128.852 90.0303 128.772 89.9066 128.685C89.6769 128.522 89.4696 128.327 89.2926 128.108C89.396 128.236 89.5882 128.762 89.4657 128.904C89.2799 129.119 88.7828 129.056 88.5331 129.01C88.2099 128.95 87.9188 128.793 87.6822 128.566C87.4407 128.334 87.2381 128.063 86.9843 127.844C86.5599 127.479 86.0196 127.282 85.4922 127.093C86.2146 127.422 86.5925 128.022 87.0772 128.651C87.3014 128.942 87.6357 129.46 86.9986 129.398C86.5696 129.357 86.2986 128.99 85.9874 128.737C85.6903 128.495 85.4126 128.16 85.0409 128.031C84.5898 127.874 84.149 128.186 83.7259 128.317C83.4237 128.41 83.1033 128.568 82.7905 128.604C82.429 128.645 81.6941 128.591 81.2995 128.506C81.3131 127.934 81.4325 124.949 81.4719 124.342Z" fill="#FFC5BD"/>
    <path d="M88.7909 124.496C89.2265 124.565 89.5299 124.954 89.7756 125.32C89.9362 125.559 90.092 125.802 90.2286 126.056C90.4828 126.529 90.668 127.039 90.7762 127.565C90.792 127.641 90.8061 127.719 90.7935 127.796C90.7442 128.097 90.3137 128.182 90.053 128.024C89.7922 127.866 89.6535 127.573 89.4899 127.316C88.9466 126.461 87.9753 125.894 86.9641 125.842C86.7506 125.831 86.5031 125.826 86.3727 125.656C86.2561 125.504 86.2938 125.276 86.4081 125.123C86.7927 124.605 88.1942 124.402 88.7909 124.496Z" fill="#FFC5BD"/>
    <path d="M88.6852 129.512L106.914 128.933C107.802 128.905 108.544 128.246 108.677 127.368L110.615 114.582C110.715 113.926 110.195 113.34 109.531 113.361L92.5975 113.899C91.698 113.928 90.947 114.594 90.8121 115.484L88.6852 129.512Z" fill="#69B0E8"/>
    <path d="M79.1582 128.605L107.986 127.69C108.155 127.684 108.297 127.817 108.303 127.987L108.322 128.585C108.327 128.755 108.194 128.897 108.024 128.902L79.1967 129.818C79.0272 129.823 78.8854 129.69 78.88 129.521L78.8611 128.922C78.8556 128.753 78.9886 128.611 79.1582 128.605Z" fill="#69B0E8"/>
    <path d="M89.5686 129.486L107.797 128.906C108.685 128.878 109.427 128.22 109.56 127.341L111.499 114.556C111.598 113.899 111.078 113.313 110.414 113.335L93.481 113.872C92.5814 113.901 91.8305 114.568 91.6956 115.458L89.5686 129.486Z" fill="#539FDA"/>
    <path d="M99.6392 121.419C99.662 122.137 100.262 122.7 100.98 122.678C101.698 122.655 102.262 122.054 102.239 121.336C102.216 120.618 101.616 120.055 100.898 120.078C100.18 120.1 99.6165 120.701 99.6392 121.419Z" fill="white"/>
            </svg>
                ) : (
                    <svg width='400' height='230' viewBox="0 0 632 364" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M316 363.62C490.522 363.62 632 356.893 632 348.595C632 340.297 490.522 333.57 316 333.57C141.478 333.57 0 340.297 0 348.595C0 356.893 141.478 363.62 316 363.62Z" fill="#033052"/>
<path d="M124.906 330.609C125.082 329.454 125.04 328.193 124.379 327.23C123.719 326.267 122.279 325.782 121.33 326.463C120.887 326.781 120.62 327.284 120.393 327.779C119.2 330.382 118.691 333.296 118.933 336.149C117.688 334.207 115.613 332.817 113.343 332.405C112.66 332.281 111.878 332.268 111.359 332.729C110.626 333.381 110.828 334.594 111.328 335.44C112.592 337.58 117.969 340.789 120.518 339.525C122.611 338.486 124.585 332.718 124.906 330.609Z" fill="#033052"/>
<path d="M313.491 333.172C316.494 335.443 324.983 337.856 325.961 332.016C326.151 330.882 325.688 329.424 324.548 329.273C323.091 329.08 322.439 330.984 321.511 332.124C321.893 330.224 321.665 328.206 320.867 326.439C320.457 325.531 319.669 324.575 318.684 324.722C317.524 324.895 317.151 326.393 317.238 327.562C317.35 329.073 317.783 330.56 318.499 331.896C317.606 330.818 316.306 330.086 314.922 329.881C313.838 329.721 312.437 330.132 312.303 331.219C312.205 332.013 312.854 332.689 313.491 333.172Z" fill="#033052"/>
<path d="M574.84 348.039C574.541 346.892 573.183 346.403 572.12 346.349C570.944 346.289 569.81 346.618 568.798 347.179C569.601 345.943 570.247 344.543 570.438 343.106C570.583 342.02 570.392 340.576 569.217 340.138C567.784 339.603 566.559 341.058 565.908 342.133C565.052 343.548 564.597 345.132 564.465 346.763C563.757 345.154 562.706 342.782 560.795 344.213C558.267 346.105 560.423 349.475 561.987 351.11C562.197 351.33 562.53 350.995 562.32 350.776C561.491 349.908 560.634 348.95 560.293 347.769C559.995 346.738 560.133 345.274 561.091 344.601C563.014 343.25 563.835 346.666 564.438 347.825C564.549 348.038 564.878 347.932 564.878 347.706C564.879 346.16 565.176 344.638 565.846 343.238C566.122 342.661 566.446 342.104 566.85 341.608C567.48 340.837 568.938 340.009 569.675 341.082C571.015 343.03 568.87 346.406 567.702 347.926C567.523 348.159 567.885 348.36 568.073 348.212C569.168 347.348 570.519 346.798 571.93 346.82C573.413 346.844 575.14 347.924 574.005 349.54C572.869 351.159 570.304 351.67 568.494 352.123C568.199 352.197 568.325 352.653 568.62 352.579C570.462 352.118 575.595 350.935 574.84 348.039Z" fill="#131313"/>
<path d="M579.17 96.8338C611.696 154.427 598.525 236.617 544.063 274.151C524.158 287.868 500.5 295.246 476.693 299.441C443.307 305.326 341.5 303.948 322.356 304.34C262.166 305.572 198.05 296.679 176.042 233.472C156.435 177.16 164.123 102.243 205.403 57.3265C289.48 -34.1568 517.354 -12.6232 579.17 96.8338Z" fill="#3E5983"/>
<path d="M35.3514 218.673C34.1793 189.911 44.5473 160.839 63.6556 139.31C70.9452 131.096 79.7182 123.803 90.1633 120.412C100.608 117.021 112.933 118.094 121.373 125.119C133.243 135.001 134.186 152.574 134.185 168.019C134.184 178.33 134.183 188.64 134.182 198.951C134.181 214.091 138.45 228.853 146.078 241.931C151.909 251.928 155.67 262.674 153.489 273.787C150.249 290.297 133.861 301.935 117.149 303.875C107.047 305.047 96.2201 304.597 86.7309 300.717C77.6696 297.011 63.7044 288.898 52.0869 273.167C46.7376 265.923 43.5842 257.255 40.7941 248.694C37.6333 238.995 35.7669 228.868 35.3514 218.673Z" fill="#3E5983"/>
<path d="M570.02 29.0154C570.385 24.805 568.467 20.4655 565.107 17.9022C561.747 15.3388 557.375 14.7654 553.411 16.2295C548.293 4.78254 534.478 -1.47064 522.128 0.700169C509.779 2.87098 500.315 10.866 495.531 22.4566C484.992 16.9412 469.358 21.9663 463.742 31.6923H569.815L570.02 29.0154Z" fill="#3E5983"/>
<path d="M187.375 72.7797H61.9336C64.3246 66.1091 69.6556 60.5658 76.3288 58.1823C83.002 55.7984 90.7262 56.5629 96.8025 60.2087C102.21 48.0384 114.456 37.8797 127.711 36.5914C140.967 35.3031 154.698 41.6173 162.348 52.5186C165.425 50.9515 168.866 50.1587 172.319 50.4977C178.944 51.1482 184.354 55.0759 186.435 61.5035C187.533 64.8982 187.897 68.7335 187.375 72.7797Z" fill="#3E5983"/>
<path d="M333.119 76.6631C327.713 75.185 323.02 71.3508 320.703 65.7724C318.385 60.1926 318.982 54.1599 321.751 49.286C318.339 44.7504 314.299 40.7147 309.757 37.3113C304.887 40.0914 298.854 40.7002 293.269 38.3929C287.687 36.087 283.844 31.4021 282.354 26.0001C279.603 25.612 276.795 25.3984 273.936 25.3984C271.037 25.3984 268.19 25.6162 265.401 26.0149C263.923 31.4206 260.089 36.1141 254.511 38.4309C248.931 40.7485 242.899 40.1524 238.025 37.3826C233.489 40.7945 229.453 44.8349 226.049 49.3774C228.829 54.2465 229.438 60.2789 227.131 65.8642C224.825 71.4466 220.14 75.2895 214.738 76.7795C214.35 79.5311 214.137 82.3389 214.137 85.1977C214.137 88.0966 214.354 90.9437 214.753 93.7326C220.159 95.2106 224.852 99.0443 227.169 104.623C229.487 110.203 228.89 116.235 226.121 121.109C229.532 125.645 233.573 129.681 238.115 133.084C242.984 130.304 249.017 129.695 254.602 132.002C260.184 134.309 264.028 138.993 265.517 144.396C268.269 144.783 271.076 144.997 273.935 144.997C276.834 144.997 279.681 144.779 282.47 144.381C283.948 138.975 287.782 134.281 293.361 131.965C298.941 129.647 304.973 130.243 309.847 133.013C314.382 129.601 318.419 125.561 321.822 121.018C319.042 116.149 318.433 110.116 320.74 104.531C323.046 98.9488 327.731 95.1056 333.133 93.6159C333.521 90.864 333.735 88.0565 333.735 85.1977C333.736 82.2988 333.518 79.4519 333.119 76.6631ZM273.936 114.375C257.822 114.375 244.759 101.312 244.759 85.1977C244.759 69.0836 257.822 56.0207 273.936 56.0207C290.05 56.0207 303.113 69.0839 303.113 85.1977C303.113 101.312 290.05 114.375 273.936 114.375Z" fill="#043357"/>
<path d="M273.938 102.259C283.361 102.259 291.001 94.6193 291.001 85.1957C291.001 75.7721 283.361 68.1328 273.938 68.1328C264.514 68.1328 256.875 75.7721 256.875 85.1957C256.875 94.6193 264.514 102.259 273.938 102.259Z" fill="#043357"/>
<path d="M267.549 171.29C263.297 170.44 259.468 167.733 257.379 163.552C255.29 159.37 255.426 154.681 257.301 150.77C254.424 147.453 251.088 144.556 247.399 142.173C243.79 144.581 239.166 145.376 234.731 143.895C230.298 142.416 227.08 139.007 225.64 134.917C223.495 134.766 221.316 134.752 219.109 134.906C216.872 135.062 214.686 135.383 212.555 135.841C211.705 140.093 208.998 143.922 204.817 146.01C200.635 148.1 195.947 147.964 192.036 146.089C188.719 148.966 185.821 152.302 183.439 155.991C185.846 159.6 186.641 164.223 185.161 168.658C183.681 173.091 180.272 176.31 176.183 177.75C176.031 179.895 176.018 182.074 176.171 184.28C176.327 186.518 176.649 188.704 177.106 190.835C181.359 191.685 185.187 194.391 187.276 198.573C189.365 202.755 189.229 207.443 187.354 211.354C190.232 214.671 193.567 217.569 197.257 219.951C200.865 217.544 205.489 216.749 209.924 218.229C214.357 219.709 217.575 223.118 219.016 227.208C221.16 227.359 223.339 227.373 225.546 227.219C227.783 227.063 229.969 226.742 232.1 226.284C232.95 222.032 235.657 218.203 239.838 216.114C244.02 214.025 248.709 214.161 252.619 216.036C255.937 213.158 258.834 209.823 261.217 206.134C258.809 202.525 258.015 197.901 259.494 193.466C260.974 189.033 264.383 185.815 268.473 184.374C268.624 182.229 268.638 180.051 268.484 177.844C268.328 175.607 268.007 173.421 267.549 171.29ZM223.898 203.583C211.46 204.45 200.675 195.07 199.807 182.632C198.94 170.195 208.32 159.409 220.758 158.542C233.195 157.675 243.981 167.054 244.848 179.492C245.716 191.93 236.336 202.716 223.898 203.583Z" fill="#043357"/>
<path d="M225.134 193.952C232.26 192.407 236.784 185.378 235.239 178.253C233.694 171.127 226.665 166.603 219.539 168.148C212.413 169.693 207.889 176.722 209.434 183.848C210.979 190.973 218.008 195.497 225.134 193.952Z" fill="#043357"/>
<path d="M403.017 343.015C323.783 308.43 272.578 228.821 272.578 140.21V73.7585L416.09 18.6211L559.604 73.7585V140.21C559.604 228.823 508.398 308.43 429.151 343.019L416.092 348.73L403.017 343.015Z" fill="#64A8D5"/>
<path d="M410.727 325.374C338.5 293.848 291.828 221.166 291.828 140.21V86.9872L416.089 39.2461L540.35 86.9872V140.21C540.35 221.165 493.679 293.847 421.449 325.374L416.089 327.718L410.727 325.374Z" fill="#87C8F0"/>
<path d="M419.301 58.2695L523.746 98.399V140.21C523.746 212.865 482.913 278.221 419.301 308.118V58.2695Z" fill="#64A8D5"/>
<path d="M361.203 261.054C359.24 261.054 357.392 260.206 356.133 258.729C328.329 226.113 313.02 184.022 313.02 140.212V101.964L404.144 66.9525C404.922 66.6537 405.736 66.5 406.562 66.5C409.336 66.5 411.87 68.241 412.865 70.8319C414.197 74.3084 412.459 78.2207 408.988 79.5534L326.52 111.234V140.211C326.52 180.764 340.656 219.711 366.321 249.875C368.668 252.631 368.479 256.719 365.896 259.181C364.627 260.387 362.96 261.053 361.205 261.053C361.203 261.054 361.205 261.054 361.203 261.054Z" fill="#7FC7F4"/>
<path d="M450.111 154.78V140.595C450.111 123.434 436.151 109.473 418.99 109.473H410.711C393.551 109.473 379.591 123.434 379.591 140.595V154.78C373.502 155.809 368.859 161.094 368.859 167.475V224.57C368.859 231.69 374.631 237.462 381.75 237.462H447.951C455.071 237.462 460.842 231.69 460.842 224.57V167.475C460.843 161.094 456.2 155.809 450.111 154.78ZM420.479 194.278V211.329C420.479 214.437 417.959 216.957 414.851 216.957C411.743 216.957 409.223 214.437 409.223 211.329V194.278C405.501 192.271 402.97 188.339 402.97 183.813C402.97 177.251 408.289 171.932 414.851 171.932C421.413 171.932 426.732 177.251 426.732 183.813C426.732 188.339 424.201 192.272 420.479 194.278ZM437.847 154.585H391.855V140.595C391.855 130.198 400.314 121.737 410.711 121.737H418.99C429.388 121.737 437.847 130.198 437.847 140.595V154.585Z" fill="#1B4F71"/>
<path d="M102.05 346.589C74.4127 354.743 45.2955 338.892 37.1415 311.254C28.9876 283.617 44.8369 254.499 72.4742 246.345C100.112 238.19 129.232 254.041 137.386 281.679C145.54 309.316 129.687 338.435 102.05 346.589ZM79.4029 269.828C64.7143 274.162 56.2914 289.637 60.6251 304.326C64.9589 319.014 80.4327 327.439 95.1214 323.105C109.81 318.772 118.236 303.296 113.902 288.607C109.568 273.918 94.0916 265.495 79.4029 269.828Z" fill="#7FC7F4"/>
<path d="M284.041 251.17L129.108 296.881C122.624 298.795 115.815 295.088 113.902 288.604C111.989 282.12 115.695 275.311 122.179 273.398L277.112 227.687C283.596 225.774 290.405 229.48 292.318 235.965C294.231 242.449 290.525 249.257 284.041 251.17Z" fill="#7FC7F4"/>
<path d="M275.455 295.422C268.971 297.335 262.162 293.629 260.249 287.145L248.925 248.764C247.012 242.28 250.718 235.471 257.203 233.558C263.687 231.645 270.496 235.351 272.409 241.836L283.733 280.216C285.646 286.7 281.939 293.509 275.455 295.422Z" fill="#7FC7F4"/>
<path d="M233.338 307.86C226.854 309.773 220.045 306.067 218.132 299.582L206.808 261.202C204.895 254.717 208.601 247.909 215.086 245.996C221.57 244.083 228.379 247.789 230.292 254.273L241.615 292.654C243.529 299.138 239.822 305.946 233.338 307.86Z" fill="#7FC7F4"/>
<path d="M143.628 167.968C143.992 168.005 144.346 167.995 144.659 167.917C145.796 167.636 145.922 165.925 146.709 165.15C148.498 163.391 150.216 162.95 152.633 162.929C155.57 162.903 158.27 162.35 160.01 160.629C162.54 158.126 163.845 151.805 161.635 148.645C160.488 147.004 159.899 146.018 159.141 144.389C157.064 139.927 149.722 138.153 144.632 139.556C142.053 140.267 139.674 141.638 137.761 143.445C135.275 145.793 132.433 149.587 132.445 153.097C132.452 154.905 133.083 156.7 133.384 158.475C133.64 159.985 133.414 161.695 134.368 163.02C135.345 164.378 137.158 164.818 138.653 165.607C139.598 166.106 140.443 166.768 141.386 167.269C141.962 167.575 142.82 167.886 143.628 167.968Z" fill="#1E3D7D"/>
<path d="M134.145 140.531C134.43 142.904 136.385 145.063 138.363 146.332C140.114 147.455 143.49 147.871 145.324 146.727C148.135 144.973 147.573 140.03 146.264 137.582C145.321 135.817 143.579 134.731 141.63 134.266C137.648 133.317 133.662 136.508 134.145 140.531Z" fill="#1E3D7D"/>
<path d="M211.41 308.665L205.414 296.182L214.79 292.637L219.945 303.829C220.649 305.374 221.596 306.176 222.847 306.821L231.433 311.135C233.494 312.196 232.668 315.315 230.353 315.218L210.61 314.392L211.41 308.665Z" fill="#FFC5BD"/>
<path d="M210.281 314.652C210.244 315.024 210.274 315.354 210.37 315.687C210.654 316.751 211.607 317.559 212.768 317.608L240.883 318.784C241.715 318.818 242.424 318.124 242.463 317.204C242.466 317.139 242.468 317.073 242.448 317.028C242.428 316.457 242.121 315.94 241.605 315.677L229.45 309.992C223.506 311.673 217.665 311.388 211.508 309.367C211.285 309.294 211.048 309.434 211.014 309.667L210.281 314.652Z" fill="#C9D8F5"/>
<path d="M210.367 315.703C210.652 316.768 211.605 317.575 212.765 317.624L240.881 318.8C241.713 318.835 242.422 318.141 242.46 317.221C242.463 317.155 242.466 317.09 242.446 317.045L210.367 315.703Z" fill="#1E4487"/>
<path d="M159.529 322.188L159.006 321.785L158.22 323.448C157.83 324.275 157.817 325.228 158.185 326.065C159.974 330.139 164.703 338.817 166.848 341.612C168.345 343.562 170.457 344.681 172.64 344.681C172.66 344.681 172.68 344.681 172.7 344.681C176.22 344.681 176.541 340.308 175.464 337.245L159.529 322.188Z" fill="#1E4487"/>
<path d="M167.546 319.256L161.551 317.897C160.732 317.804 159.009 320.05 158.789 323.286C158.789 323.286 165.485 337.11 168.165 340.6C171.188 344.539 175.761 343.454 175.631 339.038C175.496 334.426 172.503 326.791 172.106 324.675L167.546 319.256Z" fill="#C9D8F5"/>
<path d="M170.895 319.623L173.122 308.028L164.335 305.691L161.547 318.63C162.552 323.815 166.619 331.975 168.514 333.977C170.541 336.119 173.536 335.149 173.536 332.896C173.536 331.039 172.248 324.964 170.895 319.623Z" fill="#FFC5BD"/>
<path d="M146.016 187.498C147.322 191.413 151.556 193.527 155.471 192.22C159.386 190.913 161.5 186.68 160.193 182.765C158.886 178.85 154.653 176.736 150.738 178.043C146.823 179.35 144.708 183.583 146.016 187.498Z" fill="#FFC5BD"/>
<path d="M160.035 222.584C160.959 225.352 163.953 226.847 166.721 225.923C169.489 224.999 170.984 222.006 170.06 219.237C169.136 216.469 166.142 214.974 163.374 215.898C160.606 216.822 159.111 219.816 160.035 222.584Z" fill="#FFC5BD"/>
<path d="M160.193 182.762L160.246 183.017C162.819 195.266 166.096 207.356 170.059 219.228L160.034 222.575L159.98 222.46C154.675 211.124 150.026 199.493 146.059 187.623L146.016 187.494L160.193 182.762Z" fill="#FFC5BD"/>
<path d="M210.868 306.545C206.8 299.152 202.046 291.501 195.931 282.503C192.667 277.701 190.113 272.35 187.644 267.176C186.272 264.3 184.855 261.328 183.326 258.473C182.094 256.172 180.681 253.944 179.314 251.791C177.679 249.214 175.991 246.555 174.621 243.773C175.94 243.135 180.179 241.092 180.237 241.063C182.153 240.14 184.105 239.568 186.044 239.364C186.313 239.336 186.577 239.314 186.837 239.299C191.02 239.056 193.713 240.527 195.569 244.069C197.809 248.343 199.718 252.885 201.563 257.278L202.478 259.451C205.74 267.17 217.795 299.71 219.313 303.812L210.868 306.545Z" fill="#FFC5BD"/>
<path d="M186.319 267.688L183.875 276.814L181.734 284.707C180.026 291.043 176.199 303.304 173.712 311.067L164.305 309.445C165.3 301.855 165.948 294.222 166.619 286.761L167.138 280.857C167.376 278.306 167.722 275.624 168.133 272.856C168.544 270.196 168.998 267.515 169.495 264.877L169.819 263.039C170.339 260.119 170.879 257.114 171.831 254.216C171.917 253.957 172.003 253.697 172.112 253.438C175.55 254.692 178.88 255.946 182.059 257.633C183.356 258.325 184.805 259.233 185.627 260.811C186.687 262.909 186.514 265.223 186.319 267.688Z" fill="#FFC5BD"/>
<path d="M132.071 264C141.011 265.37 158.449 268.465 167.087 271.237C169.203 271.916 175.553 273.938 175.553 273.938L185.551 267.487C185.897 264.951 186.243 262.545 185.289 260.247C184.612 258.617 183.355 257.562 181.911 256.645C178.853 254.676 175.851 252.615 172.453 251.381C171.223 250.934 170.202 250.641 169.045 250.196C166.293 249.136 153.191 244.383 153.191 244.383L153.827 236.653C153.827 236.653 126.936 235.207 121.125 234.723L120.548 238.43C118.252 247.887 122.408 262.519 132.071 264Z" fill="#1E4487"/>
<path d="M219.238 298.411C215.263 287.71 203.435 256.476 203.435 256.476C201.575 252.05 199.653 247.472 197.374 243.127C195.301 239.172 192.138 237.245 187.704 237.238C187.101 237.237 186.476 237.273 185.826 237.341C183.657 237.569 181.477 238.204 179.349 239.229L171.891 242.828C171.891 242.828 180.324 257.212 181.52 259.446C183.027 262.26 202.829 297.44 206.227 303.265L219.238 298.411Z" fill="#1E4487"/>
<path d="M186.621 259.898C185.543 257.793 183.684 256.619 182.183 255.833C178.801 254.035 170.513 251.203 170.513 251.203C170.513 251.203 161.619 303.347 160.5 311.478L173.937 314.492C176.362 306.879 181.055 292.055 182.894 285.236C184.167 280.514 185.436 275.813 186.711 271.087C187.702 267.413 188.449 263.47 186.621 259.898Z" fill="#1E4487"/>
<path d="M120.895 262.94V262.948C123.996 266.698 128.413 269.334 133.565 269.987C138.916 270.663 144.7 269.134 149.808 267.743C150.256 267.62 150.704 267.49 151.145 267.351C151.16 267.351 151.167 267.344 151.182 267.344C155.599 265.968 159.649 263.916 163.985 262.271C167.851 260.803 171.158 259.482 175.288 258.075C177.743 257.238 184.019 256.062 186.841 259.897C186.841 259.897 189.304 258.16 190.413 258.414C190.648 256.9 191.648 237.879 191.648 237.879C188.201 236.78 177.765 237.311 173.157 237.579C164.455 238.079 153.887 238.84 145.016 239.501L142.568 236.242L117.183 239.768L116.162 248.584C115.861 253.91 117.654 259.028 120.895 262.94Z" fill="#1E4487"/>
<path d="M187.675 269.817C187.635 269.817 187.594 269.813 187.554 269.803C187.263 269.736 187.081 269.447 187.149 269.155C187.607 267.163 188.057 264.851 187.59 262.632C187.115 260.385 185.808 258.824 183.909 258.238C180.568 257.21 176.894 258.429 173.709 259.65L152.503 267.749C152.22 267.856 151.911 267.714 151.806 267.437C151.698 267.158 151.839 266.846 152.118 266.739L173.323 258.641C176.674 257.354 180.564 256.075 184.228 257.206C186.517 257.912 188.086 259.76 188.646 262.408C189.136 264.728 188.722 267.138 188.2 269.398C188.143 269.648 187.922 269.817 187.675 269.817Z" fill="#1E3D7D"/>
<path d="M120.871 262.941V262.949C123.72 266.852 128.422 269.501 133.579 270.154C138.934 270.83 144.859 269.503 149.811 267.745C150.25 267.589 150.708 267.491 151.15 267.353C147.935 268.268 141.594 269.013 136.077 268.314C130.081 267.552 125.189 265.854 120.871 262.941Z" fill="#1E3D7D"/>
<path d="M120.625 243.131C127.447 238.434 145.256 239.48 158.491 238.533C158.149 234.663 157.711 230.757 157.529 228.191C157.067 221.707 157.262 217.831 158.075 212.197C158.347 210.309 160.007 203.422 160.558 200.634C161.41 196.329 160.963 192.043 159.389 187.945C158.425 185.435 157.263 178.844 157.263 178.844C155.629 177.395 152.171 175.289 150.219 174.564L137.304 173.527C128.768 175.454 121.074 179.229 118.826 188.83C117.08 196.285 116.226 204.819 115.601 215.916C115.124 224.394 115.469 238.295 116.133 247.999C116.676 246.307 117.933 244.984 120.625 243.131Z" fill="#FFD683"/>
<path d="M158.227 196.542C158.216 196.388 158.162 196.267 158.066 196.18C157.972 196.093 157.828 196.05 157.635 196.049C157.512 196.049 157.411 196.065 157.332 196.098C157.254 196.128 157.197 196.171 157.16 196.225C157.122 196.279 157.103 196.341 157.101 196.41C157.098 196.468 157.108 196.519 157.129 196.566C157.152 196.611 157.188 196.652 157.236 196.689C157.285 196.725 157.347 196.757 157.423 196.787C157.499 196.816 157.589 196.843 157.693 196.866L158.054 196.95C158.298 197.006 158.506 197.08 158.679 197.172C158.852 197.263 158.994 197.371 159.104 197.495C159.214 197.617 159.294 197.755 159.346 197.908C159.398 198.062 159.425 198.229 159.427 198.41C159.425 198.722 159.352 198.986 159.208 199.203C159.065 199.419 158.86 199.583 158.593 199.696C158.328 199.808 158.009 199.864 157.637 199.863C157.254 199.863 156.921 199.801 156.636 199.678C156.353 199.556 156.132 199.367 155.975 199.114C155.82 198.858 155.741 198.532 155.741 198.134L156.875 198.136C156.882 198.281 156.915 198.404 156.975 198.503C157.035 198.602 157.12 198.677 157.228 198.728C157.339 198.779 157.47 198.805 157.621 198.806C157.749 198.806 157.856 198.789 157.942 198.755C158.028 198.721 158.093 198.674 158.137 198.614C158.182 198.554 158.205 198.485 158.206 198.407C158.205 198.335 158.183 198.271 158.14 198.217C158.099 198.161 158.03 198.112 157.934 198.068C157.838 198.023 157.709 197.981 157.545 197.942L157.107 197.84C156.718 197.748 156.411 197.595 156.187 197.381C155.964 197.166 155.853 196.872 155.855 196.501C155.854 196.199 155.929 195.936 156.08 195.71C156.232 195.483 156.442 195.307 156.71 195.18C156.98 195.054 157.289 194.991 157.637 194.992C157.992 194.992 158.299 195.057 158.558 195.186C158.817 195.315 159.016 195.496 159.156 195.73C159.298 195.962 159.369 196.233 159.37 196.544L158.227 196.542Z" fill="#AAB4E1"/>
<path d="M154.137 196.538C154.126 196.384 154.072 196.263 153.976 196.176C153.882 196.089 153.738 196.046 153.545 196.046C153.422 196.045 153.321 196.061 153.242 196.094C153.164 196.125 153.107 196.167 153.07 196.221C153.032 196.275 153.013 196.337 153.011 196.407C153.009 196.464 153.018 196.516 153.039 196.562C153.062 196.607 153.098 196.648 153.146 196.685C153.195 196.721 153.257 196.753 153.333 196.783C153.409 196.812 153.499 196.839 153.604 196.862L153.964 196.946C154.208 197.002 154.416 197.076 154.589 197.168C154.762 197.259 154.904 197.367 155.014 197.491C155.124 197.613 155.205 197.751 155.256 197.904C155.309 198.058 155.336 198.225 155.337 198.406C155.335 198.718 155.262 198.982 155.118 199.199C154.975 199.415 154.77 199.579 154.503 199.692C154.238 199.804 153.919 199.86 153.547 199.86C153.164 199.859 152.831 199.797 152.546 199.675C152.263 199.552 152.043 199.364 151.885 199.11C151.73 198.854 151.652 198.528 151.651 198.13L152.785 198.132C152.792 198.278 152.825 198.4 152.885 198.499C152.945 198.598 153.03 198.673 153.139 198.724C153.249 198.776 153.38 198.801 153.531 198.802C153.659 198.802 153.766 198.785 153.852 198.751C153.938 198.717 154.003 198.67 154.047 198.61C154.092 198.55 154.115 198.481 154.116 198.404C154.115 198.331 154.093 198.267 154.05 198.213C154.009 198.157 153.94 198.108 153.844 198.064C153.748 198.019 153.619 197.977 153.456 197.938L153.018 197.836C152.628 197.744 152.321 197.591 152.097 197.377C151.874 197.162 151.763 196.868 151.765 196.497C151.764 196.196 151.839 195.932 151.99 195.706C152.142 195.479 152.352 195.303 152.62 195.176C152.89 195.05 153.199 194.987 153.547 194.988C153.902 194.988 154.209 195.053 154.468 195.182C154.727 195.311 154.926 195.492 155.066 195.726C155.208 195.958 155.279 196.229 155.28 196.54L154.137 196.538Z" fill="#AAB4E1"/>
<path d="M151.144 195.045L151.135 199.796L149.941 199.794L149.949 195.043L151.144 195.045Z" fill="#AAB4E1"/>
<path d="M144.379 199.785L143.09 195.031L144.422 195.034L145.018 197.958L145.053 197.958L145.771 195.036L146.819 195.038L147.527 197.971L147.562 197.971L148.168 195.04L149.5 195.042L148.194 199.791L147.052 199.789L146.309 197.134L146.274 197.134L145.522 199.787L144.379 199.785Z" fill="#AAB4E1"/>
<path d="M141.551 196.515C141.54 196.36 141.486 196.24 141.39 196.153C141.296 196.066 141.152 196.022 140.959 196.022C140.836 196.022 140.735 196.038 140.656 196.07C140.579 196.101 140.521 196.144 140.484 196.198C140.447 196.252 140.427 196.313 140.426 196.383C140.423 196.44 140.432 196.492 140.453 196.539C140.476 196.583 140.512 196.625 140.56 196.662C140.609 196.697 140.671 196.73 140.747 196.759C140.823 196.789 140.913 196.815 141.018 196.839L141.378 196.923C141.622 196.979 141.83 197.053 142.003 197.144C142.176 197.236 142.318 197.344 142.428 197.468C142.538 197.59 142.619 197.728 142.67 197.881C142.723 198.034 142.75 198.201 142.751 198.382C142.749 198.695 142.676 198.959 142.532 199.175C142.389 199.392 142.184 199.556 141.917 199.668C141.652 199.781 141.333 199.837 140.961 199.836C140.579 199.835 140.245 199.774 139.96 199.651C139.677 199.528 139.457 199.34 139.299 199.086C139.144 198.831 139.066 198.504 139.065 198.107L140.199 198.109C140.206 198.254 140.239 198.376 140.299 198.475C140.359 198.575 140.444 198.65 140.553 198.701C140.663 198.752 140.794 198.778 140.946 198.778C141.073 198.778 141.18 198.762 141.266 198.728C141.352 198.694 141.417 198.647 141.461 198.586C141.506 198.526 141.529 198.457 141.531 198.38C141.529 198.307 141.507 198.244 141.464 198.19C141.423 198.134 141.354 198.084 141.258 198.041C141.162 197.996 141.033 197.954 140.87 197.915L140.432 197.812C140.042 197.72 139.735 197.567 139.511 197.354C139.288 197.138 139.177 196.845 139.179 196.474C139.178 196.172 139.253 195.909 139.404 195.683C139.556 195.456 139.767 195.279 140.035 195.153C140.304 195.026 140.613 194.964 140.961 194.964C141.316 194.965 141.623 195.03 141.882 195.158C142.141 195.287 142.34 195.468 142.48 195.702C142.622 195.934 142.693 196.206 142.694 196.517L141.551 196.515Z" fill="#AAB4E1"/>
<path d="M157.684 196.542C157.673 196.388 157.619 196.267 157.523 196.18C157.429 196.093 157.285 196.05 157.092 196.049C156.969 196.049 156.868 196.065 156.789 196.098C156.711 196.128 156.654 196.171 156.617 196.225C156.579 196.279 156.56 196.341 156.558 196.41C156.555 196.468 156.565 196.519 156.586 196.566C156.609 196.611 156.645 196.652 156.693 196.689C156.742 196.725 156.804 196.757 156.88 196.787C156.956 196.816 157.046 196.843 157.15 196.866L157.511 196.95C157.755 197.006 157.963 197.08 158.136 197.172C158.309 197.263 158.451 197.371 158.561 197.495C158.671 197.617 158.752 197.755 158.803 197.908C158.856 198.062 158.882 198.229 158.884 198.41C158.882 198.722 158.809 198.986 158.665 199.203C158.522 199.419 158.317 199.583 158.05 199.696C157.785 199.808 157.466 199.864 157.094 199.863C156.711 199.863 156.378 199.801 156.093 199.678C155.81 199.556 155.589 199.367 155.432 199.114C155.277 198.858 155.198 198.532 155.198 198.134L156.332 198.136C156.339 198.281 156.372 198.404 156.432 198.503C156.492 198.602 156.577 198.677 156.685 198.728C156.796 198.779 156.927 198.805 157.078 198.806C157.206 198.806 157.313 198.789 157.399 198.755C157.485 198.721 157.55 198.674 157.594 198.614C157.639 198.554 157.662 198.485 157.663 198.407C157.662 198.335 157.64 198.271 157.597 198.217C157.556 198.161 157.487 198.112 157.391 198.068C157.295 198.023 157.166 197.981 157.003 197.942L156.564 197.84C156.175 197.748 155.868 197.595 155.644 197.381C155.421 197.166 155.31 196.872 155.312 196.501C155.311 196.199 155.386 195.936 155.537 195.71C155.689 195.483 155.899 195.307 156.167 195.18C156.437 195.054 156.746 194.991 157.094 194.992C157.449 194.992 157.756 195.057 158.015 195.186C158.274 195.315 158.473 195.496 158.613 195.73C158.755 195.962 158.826 196.233 158.827 196.544L157.684 196.542Z" fill="white"/>
<path d="M153.594 196.538C153.583 196.384 153.529 196.263 153.433 196.176C153.339 196.089 153.195 196.046 153.002 196.046C152.879 196.045 152.778 196.061 152.699 196.094C152.622 196.125 152.564 196.167 152.527 196.221C152.489 196.275 152.47 196.337 152.468 196.407C152.466 196.464 152.475 196.516 152.496 196.562C152.519 196.607 152.555 196.648 152.603 196.685C152.652 196.721 152.714 196.753 152.79 196.783C152.866 196.812 152.956 196.839 153.061 196.862L153.421 196.946C153.665 197.002 153.873 197.076 154.046 197.168C154.219 197.259 154.361 197.367 154.471 197.491C154.581 197.613 154.662 197.751 154.713 197.904C154.766 198.058 154.793 198.225 154.794 198.406C154.792 198.718 154.719 198.982 154.575 199.199C154.432 199.415 154.227 199.579 153.96 199.692C153.695 199.804 153.376 199.86 153.004 199.86C152.622 199.859 152.288 199.797 152.003 199.675C151.72 199.552 151.5 199.364 151.342 199.11C151.187 198.854 151.109 198.528 151.108 198.13L152.242 198.132C152.249 198.278 152.282 198.4 152.342 198.499C152.402 198.598 152.487 198.673 152.596 198.724C152.706 198.776 152.837 198.801 152.989 198.802C153.116 198.802 153.223 198.785 153.309 198.751C153.395 198.717 153.46 198.67 153.504 198.61C153.549 198.55 153.572 198.481 153.574 198.404C153.572 198.331 153.55 198.267 153.507 198.213C153.466 198.157 153.397 198.108 153.301 198.064C153.205 198.019 153.076 197.977 152.913 197.938L152.475 197.836C152.085 197.744 151.778 197.591 151.554 197.377C151.331 197.162 151.22 196.868 151.222 196.497C151.221 196.196 151.296 195.932 151.447 195.706C151.599 195.479 151.809 195.303 152.078 195.176C152.347 195.05 152.656 194.987 153.004 194.988C153.359 194.988 153.666 195.053 153.925 195.182C154.184 195.311 154.383 195.492 154.523 195.726C154.665 195.958 154.736 196.229 154.737 196.54L153.594 196.538Z" fill="white"/>
<path d="M150.601 195.045L150.592 199.796L149.398 199.794L149.406 195.043L150.601 195.045Z" fill="white"/>
<path d="M143.836 199.785L142.547 195.031L143.879 195.034L144.475 197.958L144.51 197.958L145.228 195.036L146.276 195.038L146.984 197.971L147.019 197.971L147.625 195.04L148.957 195.042L147.651 199.791L146.509 199.789L145.766 197.134L145.731 197.134L144.979 199.787L143.836 199.785Z" fill="white"/>
<path d="M141.008 196.515C140.997 196.36 140.943 196.24 140.848 196.153C140.753 196.066 140.609 196.022 140.416 196.022C140.293 196.022 140.192 196.038 140.113 196.07C140.036 196.101 139.978 196.144 139.941 196.198C139.904 196.252 139.884 196.313 139.883 196.383C139.88 196.44 139.889 196.492 139.91 196.539C139.933 196.583 139.969 196.625 140.017 196.662C140.066 196.697 140.128 196.73 140.204 196.759C140.28 196.789 140.37 196.815 140.475 196.839L140.835 196.923C141.079 196.979 141.287 197.053 141.46 197.144C141.633 197.236 141.775 197.344 141.885 197.468C141.995 197.59 142.076 197.728 142.127 197.881C142.18 198.034 142.207 198.201 142.208 198.382C142.206 198.695 142.133 198.959 141.989 199.175C141.846 199.392 141.641 199.556 141.374 199.668C141.109 199.781 140.79 199.837 140.418 199.836C140.036 199.835 139.702 199.774 139.417 199.651C139.134 199.528 138.914 199.34 138.757 199.086C138.601 198.831 138.523 198.504 138.522 198.107L139.656 198.109C139.663 198.254 139.697 198.376 139.756 198.475C139.816 198.575 139.901 198.65 140.01 198.701C140.12 198.752 140.251 198.778 140.403 198.778C140.53 198.778 140.637 198.762 140.723 198.728C140.809 198.694 140.874 198.647 140.918 198.586C140.963 198.526 140.986 198.457 140.988 198.38C140.986 198.307 140.964 198.244 140.921 198.19C140.88 198.134 140.811 198.084 140.715 198.041C140.619 197.996 140.49 197.954 140.327 197.915L139.889 197.812C139.499 197.72 139.192 197.567 138.968 197.354C138.745 197.138 138.634 196.845 138.636 196.474C138.636 196.172 138.71 195.909 138.861 195.683C139.013 195.456 139.224 195.279 139.492 195.153C139.761 195.026 140.07 194.964 140.418 194.964C140.773 194.965 141.08 195.03 141.339 195.158C141.598 195.287 141.797 195.468 141.937 195.702C142.079 195.934 142.15 196.206 142.151 196.517L141.008 196.515Z" fill="white"/>
<path d="M151.786 178.809C151.649 179.945 149.998 180.386 148.75 180.226C145.074 179.792 139.864 177.838 137.086 175.088L140.069 153.543L151.83 156.141L150.216 174.569C151.282 175.843 151.945 177.583 151.786 178.809Z" fill="#FFC5BD"/>
<path d="M151.83 156.141L150.215 174.569C145.473 172.572 141.098 167.343 138.93 161.742L140.068 153.543L151.83 156.141Z" fill="#EF9C8F"/>
<path d="M147.77 141.03C154.941 141.558 159.708 146.037 159.623 151.088C159.563 154.657 159.141 158.212 158.427 161.71C157.752 165.02 156.658 168.503 154.094 170.751C151.217 173.274 145.987 171.651 142.818 169.3C136.927 164.926 134.55 158.362 134.969 152.159C135.402 145.731 139.709 140.436 147.77 141.03Z" fill="#FFC5BD"/>
<path d="M160.377 152.563C160.914 147.31 156.864 142.503 150.582 140.912C150.01 140.751 149.404 140.634 148.793 140.541C144.832 139.952 141.341 140.837 138.698 143.067C136.347 145.077 134.745 148.163 134.206 151.731C133.59 155.962 134.946 161.649 138.464 164.878L138.639 162.6L139.209 156.751C145.617 156.481 151.163 154.407 154.66 150.841C155.916 152.336 158.28 153.875 160.157 154.482C160.231 153.842 160.304 153.202 160.377 152.563Z" fill="#1E3D7D"/>
<path d="M139.707 155.675C140.992 156.916 141.897 158.56 142.417 160.269C143.903 159.049 144.582 156.927 144.08 155.071C142.941 154.78 141.922 154.7 140.778 154.969C140.638 155.002 140.494 155.041 140.382 155.13C140.27 155.22 140.196 155.373 140.241 155.51C140.194 155.567 139.753 155.618 139.707 155.675Z" fill="#1E3D7D"/>
<path d="M137.141 160.55C137.744 162.395 139.565 163.455 141.207 162.917C142.85 162.38 143.692 160.449 143.088 158.604C142.485 156.76 140.664 155.7 139.022 156.237C137.379 156.775 136.537 158.706 137.141 160.55Z" fill="#FFC5BD"/>
<path d="M141.782 159.903C141.793 159.905 141.805 159.906 141.817 159.906C141.967 159.906 142.088 159.786 142.089 159.637C142.089 159.368 142.04 159.101 141.943 158.845C141.619 157.98 140.78 157.386 139.945 157.432C139.8 157.439 139.654 157.467 139.515 157.514C139.239 157.606 138.991 157.775 138.798 158.001C138.702 158.115 138.716 158.285 138.83 158.383C138.946 158.478 139.115 158.464 139.211 158.351C139.341 158.198 139.501 158.088 139.688 158.026C139.78 157.995 139.876 157.976 139.973 157.972C140.582 157.926 141.195 158.385 141.438 159.035C141.511 159.231 141.548 159.432 141.548 159.634C141.547 159.772 141.649 159.885 141.782 159.903Z" fill="#EF9C8F"/>
<path d="M157.145 160.362C157.623 160.323 158.043 160.679 158.082 161.157C158.121 161.635 157.766 162.054 157.287 162.094C156.809 162.133 156.39 161.777 156.351 161.299C156.311 160.821 156.666 160.402 157.145 160.362Z" fill="#1E3D7D"/>
<path d="M151.012 159.366C151.491 159.327 151.91 159.683 151.949 160.161C151.989 160.639 151.633 161.058 151.155 161.098C150.677 161.137 150.257 160.781 150.218 160.303C150.179 159.825 150.534 159.405 151.012 159.366Z" fill="#1E3D7D"/>
<path d="M148.838 156.36C148.907 156.187 149.153 156.12 149.371 156.121C150.388 156.129 151.361 156.399 152.297 156.765C152.627 156.894 153.536 157.164 153.318 157.6C153.177 157.883 152.601 158.015 152.266 157.929C151.518 157.737 150.776 157.527 150.041 157.299C149.625 157.17 148.609 156.929 148.838 156.36Z" fill="#1E3D7D"/>
<path d="M160.525 158.148C160.507 157.962 160.29 157.83 160.079 157.771C159.101 157.495 158.091 157.483 157.089 157.575C156.737 157.607 155.789 157.613 155.877 158.092C155.934 158.403 156.45 158.69 156.796 158.701C157.567 158.725 158.338 158.73 159.108 158.715C159.544 158.707 160.587 158.759 160.525 158.148Z" fill="#1E3D7D"/>
<path d="M154.442 165.677C154.481 165.684 154.522 165.682 154.562 165.669C155.099 165.509 155.472 165.239 155.672 164.867C155.852 164.529 155.875 164.134 155.738 163.691C155.663 163.448 155.54 163.14 155.402 162.796C155.232 162.373 155.04 161.894 154.892 161.412C154.847 161.269 154.696 161.193 154.553 161.234C154.412 161.277 154.331 161.429 154.376 161.572C154.531 162.074 154.727 162.565 154.9 162.997C155.032 163.325 155.149 163.618 155.222 163.851C155.314 164.15 155.304 164.406 155.195 164.612C155.068 164.849 154.796 165.035 154.408 165.152C154.264 165.194 154.183 165.345 154.226 165.487C154.257 165.591 154.343 165.661 154.442 165.677Z" fill="#EF9C8F"/>
<path d="M153.007 167.326C153.475 167.326 153.906 167.239 154.223 167.05C154.351 166.972 154.392 166.807 154.316 166.678C154.241 166.55 154.077 166.509 153.944 166.585C153.184 167.043 151.417 166.7 150.743 165.964C150.642 165.852 150.471 165.846 150.362 165.946C150.251 166.046 150.244 166.217 150.344 166.327C150.91 166.949 152.035 167.326 153.007 167.326Z" fill="#EF9C8F"/>
<path d="M129.014 192.287C133.14 192.224 136.435 188.828 136.371 184.701C136.308 180.574 132.912 177.28 128.785 177.343C124.658 177.406 121.364 180.802 121.427 184.929C121.49 189.056 124.887 192.35 129.014 192.287Z" fill="#FFC5BD"/>
<path d="M112.068 218.976C111.154 221.748 112.661 224.736 115.433 225.649C118.205 226.562 121.192 225.055 122.106 222.283C123.019 219.511 121.512 216.524 118.74 215.61C115.968 214.697 112.981 216.204 112.068 218.976Z" fill="#FFC5BD"/>
<path d="M135.985 187.161L135.876 187.398C130.619 198.757 126.021 210.409 122.104 222.297L112.066 218.989L112.092 218.865C114.612 206.606 117.836 194.501 121.747 182.613L121.79 182.484L135.985 187.161Z" fill="#FFC5BD"/>
<path d="M170.323 221.012C170.408 218.095 168.112 215.661 165.194 215.577C162.277 215.492 159.843 217.789 159.759 220.706C159.674 223.623 161.97 226.057 164.888 226.142C167.805 226.226 170.239 223.93 170.323 221.012Z" fill="#FFC5BD"/>
<path d="M169.637 235.059C170.66 236.917 172.995 237.594 174.852 236.571C176.71 235.548 177.387 233.213 176.364 231.356C175.341 229.498 173.006 228.821 171.149 229.844C169.291 230.867 168.614 233.202 169.637 235.059Z" fill="#FFC5BD"/>
<path d="M169.676 218.328C171.335 222.061 173.651 227.162 176.366 231.341L169.636 235.046C166.452 231.432 163.248 227.532 160.418 223.425L169.676 218.328Z" fill="#FFC5BD"/>
<path d="M180.615 233.525C180.536 233.514 180.455 233.508 180.375 233.508C179.961 233.505 179.56 233.64 179.151 233.702C178.742 233.764 178.283 233.738 177.973 233.464C177.803 233.315 177.697 233.108 177.599 232.905C177.305 232.294 177.049 231.666 176.832 231.024C178.022 230.948 179.212 230.872 180.402 230.795C180.62 230.781 180.846 230.769 181.047 230.851C182.86 231.593 184.485 233.501 185.782 234.938C185.984 235.162 186.197 235.453 186.098 235.738C186.048 235.878 185.928 235.984 185.797 236.054C185.428 236.253 184.966 236.204 184.582 236.036C184.198 235.868 183.873 235.593 183.543 235.333C182.739 234.7 181.694 233.671 180.615 233.525Z" fill="#FFC5BD"/>
<path d="M176.506 233.952C176.829 231.949 175.468 230.064 173.466 229.74C171.463 229.417 169.577 230.778 169.254 232.78C168.93 234.783 170.291 236.669 172.294 236.992C174.296 237.316 176.182 235.955 176.506 233.952Z" fill="#FFC5BD"/>
<path d="M171.251 230.89C170.393 231.428 169.673 232.199 169.217 233.104C168.988 233.56 168.796 234.077 168.743 234.587C168.691 235.091 169.053 235.333 169.483 235.533C170.16 235.848 170.838 236.163 171.546 236.403C172.238 236.637 172.965 236.799 173.696 236.767C174.667 236.725 175.62 236.33 176.336 235.672C176.654 235.38 176.925 235.038 177.259 234.763C177.592 234.489 178.01 234.279 178.44 234.319C178.806 234.353 179.135 234.563 179.399 234.819C179.663 235.075 179.878 235.377 180.122 235.651C180.453 236.021 180.853 236.347 181.326 236.498C181.799 236.649 182.353 236.602 182.74 236.291C182.812 236.234 182.878 236.166 182.908 236.08C182.968 235.904 182.86 235.72 182.756 235.567C181.85 234.23 180.875 232.4 179.31 231.744C178.536 231.42 177.815 231.314 177.118 230.824C176.426 230.338 175.455 230.008 174.605 230.07C172.732 230.208 172.142 230.329 171.251 230.89Z" fill="#FFC5BD"/>
<path d="M167.733 235.814C167.849 235.7 167.973 235.602 168.105 235.544C168.444 235.396 168.837 235.496 169.186 235.619C169.536 235.742 169.899 235.888 170.264 235.826C170.725 235.746 171.076 235.338 171.214 234.891C171.352 234.444 171.315 233.964 171.254 233.5C171.164 232.812 171.2 232.163 171.173 231.476C171.17 231.408 171.16 231.329 171.102 231.292C171.041 231.253 170.96 231.283 170.894 231.314C169.823 231.809 168.647 232.228 167.671 232.898C167.205 233.217 166.82 233.637 166.445 234.059C165.59 235.022 164.769 236.015 163.948 237.008C163.907 237.057 163.865 237.109 163.854 237.171C163.837 237.269 163.899 237.362 163.969 237.432C164.28 237.744 164.768 237.807 165.204 237.744C165.649 237.679 166.077 237.499 166.434 237.227C166.767 236.974 167.032 236.649 167.294 236.327C167.421 236.171 167.568 235.977 167.733 235.814Z" fill="#FFC5BD"/>
<path d="M177.804 237.553C176.83 237.14 176.355 236.054 175.987 235.136C175.8 234.67 175.647 234.181 175.424 233.731C175.121 232.812 174.597 232.103 173.8 231.583C173.772 231.565 173.742 231.544 173.731 231.513C173.724 231.491 173.727 231.467 173.732 231.445C173.776 231.263 173.956 231.153 174.12 231.062C174.466 230.872 174.827 230.588 175.229 230.546C175.648 230.501 176.068 230.692 176.386 230.97C176.919 231.437 177.313 232.1 177.589 232.74C178.207 234.169 178.812 235.573 179.558 236.937C179.617 237.045 179.678 237.174 179.63 237.287C179.599 237.36 179.529 237.408 179.46 237.448C178.964 237.737 178.332 237.776 177.804 237.553Z" fill="#FFC5BD"/>
<path d="M120.499 224.66C122.718 222.764 122.981 219.429 121.085 217.21C119.19 214.99 115.855 214.728 113.635 216.623C111.416 218.518 111.154 221.854 113.049 224.073C114.944 226.292 118.28 226.555 120.499 224.66Z" fill="#FFC5BD"/>
<path d="M148.621 236.545C150.613 237.272 152.818 236.247 153.545 234.255C154.272 232.263 153.247 230.059 151.255 229.332C149.263 228.604 147.059 229.63 146.331 231.621C145.605 233.613 146.63 235.817 148.621 236.545Z" fill="#FFC5BD"/>
<path d="M118.899 215.676C121.547 216.64 126.261 218.876 131.553 221.199C137.76 223.924 146.891 227.739 151.255 229.327L148.62 236.543C139.805 233.839 125.723 229.063 115.273 225.603L118.899 215.676Z" fill="#FFC5BD"/>
<path d="M148.621 236.545C150.613 237.272 152.818 236.247 153.545 234.255C154.272 232.263 153.247 230.059 151.255 229.332C149.263 228.604 147.059 229.63 146.331 231.621C145.605 233.613 146.63 235.817 148.621 236.545Z" fill="#FFC5BD"/>
<path d="M150.119 229.106C152.752 228.657 155.739 229.215 158.838 229.151C161.86 229.089 165.538 232.212 166.884 234.795C167.25 235.498 167.616 236.2 167.981 236.902C168.08 237.092 168.167 237.354 168.004 237.493C167.736 237.721 166.7 237.566 166.402 237.487C166.132 237.415 165.888 237.269 165.66 237.108C165.237 236.808 164.855 236.45 164.529 236.046C164.719 236.282 165.073 237.25 164.848 237.512C164.505 237.908 163.589 237.792 163.129 237.707C162.534 237.597 161.997 237.308 161.561 236.889C161.116 236.462 160.743 235.962 160.276 235.559C159.493 234.886 158.498 234.523 157.526 234.175C158.857 234.782 159.554 235.887 160.447 237.046C160.86 237.583 161.476 238.537 160.302 238.423C159.511 238.346 159.012 237.671 158.439 237.204C157.891 236.758 157.379 236.141 156.695 235.903C155.863 235.614 155.051 236.19 154.272 236.43C153.715 236.602 153.124 236.893 152.548 236.959C151.882 237.035 150.528 236.935 149.801 236.78C149.826 235.726 150.046 230.224 150.119 229.106Z" fill="#FFC5BD"/>
<path d="M163.606 229.389C164.409 229.516 164.968 230.232 165.421 230.907C165.716 231.348 166.004 231.795 166.255 232.263C166.724 233.134 167.065 234.074 167.264 235.043C167.293 235.183 167.319 235.328 167.296 235.47C167.205 236.024 166.412 236.181 165.932 235.89C165.451 235.599 165.195 235.058 164.894 234.584C163.893 233.01 162.103 231.966 160.24 231.869C159.847 231.848 159.391 231.839 159.15 231.527C158.936 231.247 159.005 230.827 159.216 230.544C159.924 229.589 162.507 229.215 163.606 229.389Z" fill="#FFC5BD"/>
<path d="M163.41 238.639L196.997 237.572C198.634 237.52 200.001 236.307 200.246 234.688L203.818 211.131C204.001 209.921 203.043 208.841 201.82 208.88L170.619 209.871C168.961 209.924 167.578 211.152 167.329 212.792L163.41 238.639Z" fill="#7FBCEB"/>
<path d="M145.856 236.965L198.973 235.278C199.286 235.268 199.547 235.513 199.557 235.825L199.592 236.928C199.602 237.24 199.356 237.501 199.044 237.511L145.927 239.199C145.615 239.209 145.354 238.964 145.344 238.651L145.309 237.549C145.299 237.236 145.544 236.975 145.856 236.965Z" fill="#7FBCEB"/>
<path d="M165.039 238.588L198.626 237.521C200.263 237.469 201.629 236.256 201.875 234.637L205.447 211.08C205.63 209.87 204.672 208.79 203.448 208.829L172.248 209.82C170.59 209.873 169.207 211.101 168.958 212.741L165.039 238.588Z" fill="#68ABDF"/>
<path d="M183.595 223.722C183.637 225.045 184.743 226.083 186.066 226.041C187.389 225.999 188.427 224.893 188.385 223.57C188.343 222.247 187.237 221.209 185.914 221.251C184.591 221.293 183.553 222.399 183.595 223.722Z" fill="#87C8F0"/>
</svg>

                )
            }
        </>
    );
}
export default AccessDeniedIcon

