import React, {createContext, ReactNode, useContext, useState} from 'react';

interface SelectedItemContextType {
    selectedItem: string | null;
    setSelectedItem: (item: string | null) => void;
}

interface SelectedItemProviderProps {
    children: ReactNode;
}

const defaultContextValue: SelectedItemContextType = {
    selectedItem: null,
    setSelectedItem: () => {},
};

export const SelectedItemContext = createContext<SelectedItemContextType>(defaultContextValue);

export const useSelectedItemContext = () => useContext(SelectedItemContext);

export const SelectedItemProvider: React.FC<SelectedItemProviderProps> = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState(localStorage.getItem('selectedItem') || null);

    return (
        <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem }}>
            {children}
        </SelectedItemContext.Provider>
    );
};
