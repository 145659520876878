import { DeclineResponseReport, SubCategory } from "../../../../../types/decline-response-report.types";

const transformDataToCSVFormat = (
    data: DeclineResponseReport[],
    namesEnum: { [key: string]: string },
    fieldsForCSV: { [key: string]: string },
): {
    [key: string]: string | number | SubCategory[];
  }[] => {
    if (!data || !data.length) {
        return [];
    }

    const categoryField = fieldsForCSV.grouping;
    const categoryValue =  namesEnum[fieldsForCSV.grouping];
    if (!categoryField) {
        throw new Error(`The grouping field '${fieldsForCSV.grouping}' does not exist in namesEnum.`);
    }

    const flattenedData: {
        [key: string]: string | number | SubCategory[];
      }[] = [];

    data.forEach((report) => {
        const baseData: { [key: string]: string | number | SubCategory[] } = {};
        baseData[categoryField] = (report)[categoryValue];
        baseData['total_count'] = report.count;
        flattenedData.push(baseData);

        report.subCategories.forEach((subCategory) => {
            const subCategoryData: { [key: string]: string | number | SubCategory[] } = {};
            subCategoryData[categoryField] = "";
            subCategoryData['reason'] = subCategory.reason;
            subCategoryData['subCategory_count'] = subCategory.count;
            flattenedData.push(subCategoryData);
        });
    });

    return flattenedData;
}

export default transformDataToCSVFormat;