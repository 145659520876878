import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";
import { Link } from "react-router-dom";

import blueTem from '../../../../assets/sideBarIcons/blueTem.png'

interface HomeBtnProps {
  handleButtonClick: (arg: string) => void;
  buttonClass: (arg: string) => string;
  iconStyle: (item: string) => {
    filter: string;
  };
  isHomeActive: boolean;
}

const HomeBtn = ({
  handleButtonClick,
  buttonClass,
  iconStyle,
  isHomeActive,
}: HomeBtnProps) => {
  const { theme, themedColors } = useContext(ThemeContext)!;

  const [isHovered, setIsHovered] = useState(false);

  let color = theme === "light" ? themedColors.content : themedColors.content;

  if (isHovered) {
    color = "#0079FF";
  }

  return (
    <Link
      to="/dashboard"
      className="w-[90%] tablet-size:w-full"
      onClick={() => handleButtonClick("home")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        style={{ width: "100%" }}
        className={`${buttonClass("home")} ${
          isHomeActive
            ? `sidebar-button rounded-tr-lg rounded-br-lg border-l-2 bg-blue-200 dark:bg-odin-dark-blue-medium_2 border-[#0079FF] flex items-center w-full p-2 pl-4  text-odin-light-content font-medium`
            : `sidebar-button flex items-center  text-odin-light-content hover:text-[#0079FF] hover:font-medium p-2 pl-4 focus:outline-none`
        }`}
      >
        <div className="relative flex items-center w-[100%] gap-2">
          {
            isHomeActive ? (
              <>
              <img width={20} height={20} src={blueTem} alt="" /></>
            ) : (
              <svg
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={iconStyle("home")}
            >
              <path
                d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7V5Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 13C4 12.4477 4.44772 12 5 12H11C11.5523 12 12 12.4477 12 13V19C12 19.5523 11.5523 20 11 20H5C4.44772 20 4 19.5523 4 19V13Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 13C16 12.4477 16.4477 12 17 12H19C19.5523 12 20 12.4477 20 13V19C20 19.5523 19.5523 20 19 20H17C16.4477 20 16 19.5523 16 19V13Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            )
          }

          <div
            className={`flex items-center svg-icon ml-1 w-[33%] text-[${color}] ${isHomeActive ? 'font-semibold' : ''} `}
          >
            <p>Home</p>
          </div>
        </div>
      </button>
    </Link>
  );
};

export default HomeBtn;
