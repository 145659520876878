import React, { useContext } from "react";
import '../removePopup/remove.css';
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../buttons/DefaultButton";
import ReactDOM from 'react-dom';
import CloseIcon from "../../../assets/icons/CloseIcon";

interface SuccessResetPasswordPopupProps {
    successText: string,
    onClick: () => void
    onClose: () => void
    buttonMessage?: string
}

const SuccessResetPasswordPopup: React.FC<SuccessResetPasswordPopupProps> = ({ successText, onClick, onClose, buttonMessage }) => {
    const { theme, themedColors } = useContext(ThemeContext)!;

    const handleButtonClick = () => {
        onClick();
    };

    const handlePopupClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const modalContent = (
        <div className={`remove-overlay ${theme === 'dark' ? 'bg-[rgba(0,0,0,0.8)]' : 'bg-[rgba(105,105,105,0.8)]'}`} onClick={onClose}>
            <div className="remove-popup max-w-[297px] tablet-size:max-w-[390px] !p-6 tablet-size:!p-10" style={{backgroundColor: `${theme === 'dark' ? themedColors.blue.dark : themedColors.white}`}} onClick={handlePopupClick}>
                <div onClick={onClose} className={"cursor-pointer p-2"} style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer', color: `${themedColors.content}` }}>
                    <CloseIcon />
                </div>
                <div className="loading-text-cover w-full">
                    <div className="remove-text text-odin-light-content dark:text-odin-dark-content leading-6 pt-4 text-start">{successText}</div>
                </div>
                <div className="w-full flex gap-4 justify-between items-center pt-6">
                    <DefaultButton disabled={false} type='submit' label={buttonMessage ?? ""}  className="w-full h-10 !py-[10px]" onClick={() => handleButtonClick()} />
                </div>
            </div>
        </div>
    )

    return ReactDOM.createPortal(
        modalContent,
        document.getElementById('modal-root')!
    );

};

export default SuccessResetPasswordPopup;
