import { v4 as uuidv4 } from 'uuid';
import { AffiliateReportData } from '../types/affiliate-report.types';

export const transformData = (data: AffiliateReportData) => {
  if (Object.keys(data).length === 0) {
    return {};
  }
  
  const transformObject = (obj: any, objName: any) => {
    const transformed = { ...obj.stats, name: objName, id: uuidv4() };
    Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
      if (key !== "stats") {
        if (typeof value === "object" && !Array.isArray(value)) {
          transformed[key] = Object.entries(value).map(
            ([nestedKey, nestedValue]) => {
              if (nestedKey === "") {
                return transformObject(nestedValue, "NaN");
              }
              return transformObject(nestedValue, nestedKey);
            }
          );
        } else {
          transformed[key] = value;
        }
      }
    });
    return transformed;
  };

  const result: { [key: string]: any } = {};
  Object.entries(data).forEach(
    ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
      result[mainKey] = Object.entries(mainValue).map(
        ([nestedKey, nestedValue]) => {
          if (nestedKey === "") {
            return transformObject(nestedValue, "NaN");
          }
          return transformObject(nestedValue, nestedKey);
        }
      );
    }
  );
  return result;
};
