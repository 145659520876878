type ColorEnum = Record<number, string>
export const mainColorEnum: ColorEnum = {
    0 : '#FF827C',
    1 : '#0095AC',
    2 : '#FBC849',
    3 : '#87D0F9',
    4 : '#F28397',
    5 : '#92D493',
    6 : '#BEA2EC',
    7 : '#FFAA4FE3',
    8 : '#57B3B9C9',
    9 : '#EB76C3CC'
}

export const subColorEnum: ColorEnum = {
    0 : '#FFF9F8',
    1 : '#EFFDFA',
    2 : '#FFFAED',
    3 : '#E4F5FF8F',
    4 : '#FFEBEB8F',
    5 : '#F1FCEE',
    6 : '#F7F4FC',
    7 : '#DFE9FE',
    // 7 : '#fffbf8',
    8 : '#ECFFEC',
    9 : '#fdf2f9'
}

export const borderColorEnum: ColorEnum = {
    0 : '#fadddf',
    1 : '#C1F0F0',
    2 : '#fce6a7',
    3 : '#C1F0F0',
    4 : '#FFCAD3',
    5 : '#A9EAAA',
    6 : '#D2BBF8',
    7 : '#ffbf7a',
    8 : '#57B3B9C9',
    9 : '#EB76C3CC'
}

export const darkMainColorEnum: ColorEnum = {
    0 : '#c44a4e',
    1 : '#209FB2',
    2 : '#B38309',
    3 : '#2689BA',
    4 : '#F28397',
    5 : '#96334C',
    6 : '#296F33',
    7 : '#7242C2',
    8 : '#A26321',
    9 : '#9A4DA7'
}

export const darkSubColorEnum: ColorEnum = {
    0 : '#242E3D',
    1 : '#283344',
    2 : '#242E3D',
    3 : '#253241',
    4 : '#242E3D',
    5 : '#293342',
    6 : '#282D41',
    7 : '#2D313A',
    8 : '#2D313A',
    9 : '#2D313A'
}

export const darkBorderColorEnum: ColorEnum = {
    0 : '#3C414D',
    1 : '#32465A',
    2 : '#303C50',
    3 : '#32465A',
    4 : '#3C414D',
    5 : '#32465A',
    6 : '#353E50',
    7 : '#353E50',
    8 : '#353E50',
    9 : '#353E50'
}