import { DeclineResponseReport, DeclineResponseReportData, SubCategory } from "../../../../../types/decline-response-report.types";

function transformData(input: DeclineResponseReportData, categoryKey: string): DeclineResponseReport[] {
    return Object.keys(input).map((gateway: string) => {
      const errors: SubCategory[] = input[gateway];
      const subCategoriesMap: { [reason: string]: SubCategory } = {};
  
      let totalCount = 0;
  
      errors.forEach(([reason, count] : any) => {
        const countNumber: number = typeof count === 'string' ? parseInt(count, 10) : count;
        totalCount += countNumber;
  
        if (subCategoriesMap[reason]) {
          subCategoriesMap[reason].count += countNumber;
        } else {
          subCategoriesMap[reason] = { reason, count: countNumber };
        }
      });
  
      const subCategories = Object.values(subCategoriesMap);
  
      return {
        [categoryKey]: gateway,
        count: totalCount,
        subCategories
      };
    });
}

export default transformData;