import React from 'react'

const CheckedFilter = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#0079FF"/>
        <circle cx="12" cy="12" r="8" fill="#0079FF"/>
    </svg>
  )
}

export default CheckedFilter