// SideBarClickContext.js
import React, { createContext, useContext, useState } from 'react';

interface SideBarClickProviderProps {
    children: React.ReactNode;
}

const SideBarClickContext = createContext<(item: string) => void>(()=>{})

export function useSideBarClick() {
    return useContext(SideBarClickContext);
}

export function SideBarClickProvider({ children }: SideBarClickProviderProps) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);

    const handleButtonClick = (item: string) => {
        setSelectedItem(item);
        setDropdownOpen(item === 'home' ? !dropdownOpen : false);
    };

    return (
        <SideBarClickContext.Provider value={handleButtonClick}>
            {children}
        </SideBarClickContext.Provider>
    );
}
