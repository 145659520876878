import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

const BurgerIcon = () => {
  const { theme } = useContext(ThemeContext)!;

  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33362 8.55C1.53281 8.55 0.883618 9.19919 0.883618 10C0.883618 10.8008 1.53281 11.45 2.33362 11.45V8.55ZM23.667 11.45C24.4678 11.45 25.117 10.8008 25.117 10C25.117 9.19919 24.4678 8.55 23.667 8.55V11.45ZM2.33362 0.55C1.53281 0.55 0.883618 1.19919 0.883618 2C0.883618 2.80081 1.53281 3.45 2.33362 3.45V0.55ZM23.667 3.45C24.4678 3.45 25.117 2.80081 25.117 2C25.117 1.19919 24.4678 0.55 23.667 0.55V3.45ZM2.33362 16.55C1.53281 16.55 0.883618 17.1992 0.883618 18C0.883618 18.8008 1.53281 19.45 2.33362 19.45V16.55ZM23.667 19.45C24.4678 19.45 25.117 18.8008 25.117 18C25.117 17.1992 24.4678 16.55 23.667 16.55V19.45ZM2.33362 11.45H23.667V8.55H2.33362V11.45ZM2.33362 3.45H23.667V0.55H2.33362V3.45ZM2.33362 19.45H23.667V16.55H2.33362V19.45Z"
        fill={theme === "light" ? "#000" : "#fff"}
      />
    </svg>
  );
};

export default BurgerIcon;
