import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { Checkbox, styled, Accordion, ListItemText, AccordionSummary, Typography, AccordionDetails, MenuItem, Fade } from '@mui/material';
import EmptyCheckbox from '../../../assets/icons/EmptyCheckbox';
import CheckedCheckbox from '../../../assets/icons/CheckedCheckbox';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Icon } from '@tremor/react';

interface MenuItemProp {
    id: string;
    label: string;
}

interface SelectedFilters {
  [key: string]: {
      [subItemId: string]: boolean;
  };
}
  
interface DropdownProps {
    menuItems: {
      id: string;
      label: string;
      subItems?: MenuItemProp[];
    }[];
    selectedItems: { [key: string]: { [subItemId: string]: boolean } };
    setSelectedFilters?: React.Dispatch<React.SetStateAction<SelectedFilters>> 
}

  const CustomAccordion = styled(Accordion)({
    marginTop: '0 !important',
    padding: '0',
    boxShadow: 'none !important',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        margin: '0',
    },
});

function FilterDropdownDeclineReport({menuItems, selectedItems, setSelectedFilters}: DropdownProps) {
    const [expanded, setExpanded] = useState<string[]>([]);
    const { themedColors } = useContext(ThemeContext)!;

    useEffect(() => {
      const allPanels = menuItems.map((_, index) => `panel${index}`);
      setExpanded(allPanels);
    }, [menuItems]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded((prevExpanded) => 
          isExpanded ? [...prevExpanded, panel] : prevExpanded.filter(p => p !== panel)
        );
    };

    const handleCheckboxChange = (parentId: string, itemId: string, checked: boolean) => {
        const newSelectedItems = { ...selectedItems, [parentId]: {...selectedItems[parentId], [itemId]: checked} };
        if (setSelectedFilters) setSelectedFilters(newSelectedItems);
    };

    // Styled accordion header
    const CustomAccordionSummary = styled(AccordionSummary)({
        // @ts-ignore
        backgroundColor: 'transparent',
        borderTop: `1px solid ${themedColors.border}`,
        width: '100% !important',
        marginBottom: -1,
        padding: '0 0px',
        minHeight: 44,
        '&.Mui-expanded': {
            minHeight: 44,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
        // @ts-ignore
        color: (props) => (props.$expanded ? 'black' : '#9e9e9e'),
    });

  return (
    <div className='w-full rounded-xl mt-1'>
      {menuItems.map((item, index) => (
        <CustomAccordion 
        // @ts-ignore
            slots={{ transition: Fade }}
            expanded={expanded.includes(`panel${index}`)} 
            onChange={handleChange(`panel${index}`)} 
            key={item.id} 
            sx={{
                "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAccordion-root": {
                  border: 'none !important',
                },
                "& .MuiAccordionSummary-expandIconWrapper span": {
                    color: themedColors.content,
                },
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded span": {
                    color: "#0079FF",
                }
            }}
        >
          <CustomAccordionSummary
            expandIcon={
              <Icon
                icon={
                  expanded.includes(`panel${index}`)
                    ? ChevronDownIcon
                    : ChevronDownIcon
                } 
              />
            }
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
            // @ts-ignore
            expanded={expanded === `panel${index}`}
            sx={{
                height: '40px',
            }}
          >
            <Typography>{item.label}</Typography>
          </CustomAccordionSummary>
          <AccordionDetails style={{
            padding: '0',
          
          }}>
            {item.subItems?.map((subItem) => {
              return (
                <MenuItem
                  key={subItem.id}
                  value={subItem.id}
                  onClick={() =>
                    handleCheckboxChange(
                      item.id,
                      subItem.id,
                      !(selectedItems[item.id]?.[subItem.id] || false)
                    )
                  }
                  sx={{
                    '&.MuiMenuItem-root': {
                        marginTop: '1px',
                        padding: '7px 0px',
                        height: '40px',
                      '&.MuiMenuItem-root:hover, &.Mui-selected': {
                        backgroundColor: themedColors.background_1,
                      },
                      '& .MuiTypography-root': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '14px !important',
                      },
                      '& .MuiCheckbox-root': {
                        padding: '9px 9px 9px 0px',
                      }
                    },
                  }}
                >
                  <Checkbox
                    checked={selectedItems[item.id]?.[subItem.id] || false}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxChange(
                        item.id,
                        subItem.id,
                        e.target.checked
                      );
                    }}
                    inputProps={{
                      'aria-label': `Checkbox for ${subItem.label}`,
                    }}
                    icon={<EmptyCheckbox />}
                    checkedIcon={<CheckedCheckbox />}
                  />
                  <ListItemText primary={subItem.label} />
                </MenuItem>
              );
            })}
          </AccordionDetails>
        </CustomAccordion>
      ))}
    </div>
  );
};

export default FilterDropdownDeclineReport;