import * as React from "react"
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

const EmptyCheckbox = () => {
    const { theme, themedColors } = React.useContext(ThemeContext)!;

    const bgColor = theme === "dark" ? "#17202E" : "#fff";

    const textColor = theme === "dark" ? "#17202E" : "#fff";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="23" height="23" x="0.5" y="0.5" fill={bgColor} rx="11.5"></rect>
            <rect
                width="23"
                height="23"
                x="0.5"
                y="0.5"
                stroke="#677C9F"
                rx="11.5"
            ></rect>
            <path
                fill={textColor}
                d="M13.534 6.818V17h-1.542V8.36h-.06L9.498 9.95V8.48l2.54-1.66h1.497z"
            ></path>
        </svg>
    )
}
export default EmptyCheckbox
