import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const LineChartRange: { [key: string | number]: { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs } } = {
  1: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().startOf('day') },
  2: { startDate: dayjs.tz().startOf('D').subtract(2, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
  // 3: { startDate: dayjs.tz().startOf('week'), endDate: dayjs.tz().endOf('day') },
  // 4: { startDate: dayjs.tz().startOf('month'), endDate: dayjs.tz().endOf('day') },
  // 5: { startDate: dayjs.tz().startOf('year'), endDate: dayjs.tz().endOf('day') },
  // 6: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().endOf('w').subtract(1, "w") },
  // 7: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
  // 8: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().startOf('day').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('day').subtract(2, "day").tz(timezone), endDate: dayjs.tz().endOf('day').subtract(1, "day").tz(timezone) },
        // 3: { startDate: dayjs.tz().startOf('week').tz(timezone), endDate: dayjs.tz().tz(timezone) },
        // 4: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().tz(timezone) },
        // 5: { startDate: dayjs.tz().startOf('year').tz(timezone), endDate: dayjs.tz().tz(timezone) },
        // 6: { startDate: dayjs.tz().startOf('week').subtract(1, "week").tz(timezone), endDate: dayjs.tz().endOf('week').subtract(1, "week").tz(timezone) },
        // 7: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "second").tz(timezone) },
        // 8: { startDate: dayjs.tz().startOf('year').subtract(1, "year").tz(timezone), endDate: dayjs.tz().startOf('year').subtract(1, "second").tz(timezone) }
    };
  };