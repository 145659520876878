import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

interface ToggleProps {
    style?: React.CSSProperties;
}

export function Toggle1({style}: ToggleProps) {
    const { theme } = useContext(ThemeContext)!;

    return (

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"  width="32px" height="32px" 
             viewBox="-0.5 -0.5 230 230" className="ge-export-svg-dark"
             style={{backgroundColor: 'transparent', width: '32px', fill: '#FF3B30'}}>

            <g>
                <image className={`${theme === 'dark' ? 'dark-image' : ''}`} x="14.23" y="17.17" width="56" height="49.18"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjIuODggMTA4LjA2IiBkYXRhLW5hbWU9IkxheWVyIDEiIGlkPSJMYXllcl8xIj48dGl0bGU+YmFjay1hcnJvdzwvdGl0bGU+PHBhdGggZD0iTTYzLjk0LDI0LjI4YTE0LjI4LDE0LjI4LDAsMCwwLTIwLjM2LTIwTDQuMSw0NC40MmExNC4yNywxNC4yNywwLDAsMCwwLDIwbDM4LjY5LDM5LjM1YTE0LjI3LDE0LjI3LDAsMCwwLDIwLjM1LTIwTDQ4LjA2LDY4LjQxbDYwLjY2LS4yOWExNC4yNywxNC4yNywwLDEsMC0uMjMtMjguNTRsLTU5Ljg1LjI4LDE1LjMtMTUuNThaIi8+PC9zdmc+"
                       preserveAspectRatio="none" transform="rotate(45,42.73,42.26)"/>
                <image  className={`${theme === 'dark' ? 'dark-image' : ''}`} x="14.23" y="159.5" width="56" height="49.18"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjIuODggMTA4LjA2IiBkYXRhLW5hbWU9IkxheWVyIDEiIGlkPSJMYXllcl8xIj48dGl0bGU+YmFjay1hcnJvdzwvdGl0bGU+PHBhdGggZD0iTTYzLjk0LDI0LjI4YTE0LjI4LDE0LjI4LDAsMCwwLTIwLjM2LTIwTDQuMSw0NC40MmExNC4yNywxNC4yNywwLDAsMCwwLDIwbDM4LjY5LDM5LjM1YTE0LjI3LDE0LjI3LDAsMCwwLDIwLjM1LTIwTDQ4LjA2LDY4LjQxbDYwLjY2LS4yOWExNC4yNywxNC4yNywwLDEsMC0uMjMtMjguNTRsLTU5Ljg1LjI4LDE1LjMtMTUuNThaIi8+PC9zdmc+"
                       preserveAspectRatio="none" transform="rotate(-45,42.73,184.59)"/>
                <image  className={`${theme === 'dark' ? 'dark-image' : ''}`} x="159.5" y="159.5" width="56" height="49.18"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjIuODggMTA4LjA2IiBkYXRhLW5hbWU9IkxheWVyIDEiIGlkPSJMYXllcl8xIj48dGl0bGU+YmFjay1hcnJvdzwvdGl0bGU+PHBhdGggZD0iTTYzLjk0LDI0LjI4YTE0LjI4LDE0LjI4LDAsMCwwLTIwLjM2LTIwTDQuMSw0NC40MmExNC4yNywxNC4yNywwLDAsMCwwLDIwbDM4LjY5LDM5LjM1YTE0LjI3LDE0LjI3LDAsMCwwLDIwLjM1LTIwTDQ4LjA2LDY4LjQxbDYwLjY2LS4yOWExNC4yNywxNC4yNywwLDEsMC0uMjMtMjguNTRsLTU5Ljg1LjI4LDE1LjMtMTUuNThaIi8+PC9zdmc+"
                       preserveAspectRatio="none" transform="rotate(-135,188,184.59)"/>
                <image className={`${theme === 'dark' ? 'dark-image' : ''}`} x="159.5" y="17.17" width="56" height="49.18"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjIuODggMTA4LjA2IiBkYXRhLW5hbWU9IkxheWVyIDEiIGlkPSJMYXllcl8xIj48dGl0bGU+YmFjay1hcnJvdzwvdGl0bGU+PHBhdGggZD0iTTYzLjk0LDI0LjI4YTE0LjI4LDE0LjI4LDAsMCwwLTIwLjM2LTIwTDQuMSw0NC40MmExNC4yNywxNC4yNywwLDAsMCwwLDIwbDM4LjY5LDM5LjM1YTE0LjI3LDE0LjI3LDAsMCwwLDIwLjM1LTIwTDQ4LjA2LDY4LjQxbDYwLjY2LS4yOWExNC4yNywxNC4yNywwLDEsMC0uMjMtMjguNTRsLTU5Ljg1LjI4LDE1LjMtMTUuNThaIi8+PC9zdmc+"
                       preserveAspectRatio="none" transform="rotate(-225,188,42.26)"/>
            </g>
        </svg>
    )
        ;
}

export function Toggle2({style}: ToggleProps) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"  width="32px"
             height="32px" viewBox="-0.5 -0.5 189 184" className="ge-export-svg-dark"
             style={{backgroundColor: 'transparent', width: '32px',}}>
            <g>
                <image x="142.87" y="-0.5" width="41.43" height="48.33"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA2IDciIGhlaWdodD0iNyIgd2lkdGg9IjYiPiYjeGE7PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik01LjczNTg0IDYuMjM1NjVDNS42NTIzIDYuMzE5NjUgNS41NTI5MiA2LjM4NjI0IDUuNDQzNDcgNi40MzE1OEM1LjMzNDAxIDYuNDc2OTIgNS4yMTY2NSA2LjUwMDExIDUuMDk4MTggNi40OTk3OUgwLjg4MjMyNkMwLjc5NTA5NiA2LjUwMDU3IDAuNzA4NTc1IDYuNDg0MDYgMC42Mjc3NTkgNi40NTEyMkMwLjU0Njk0MyA2LjQxODM4IDAuNDczNDM0IDYuMzY5ODUgMC40MTE0NzUgNi4zMDg0NUMwLjM0OTUxNiA2LjI0NzA0IDAuMzAwMzM0IDYuMTczOTYgMC4yNjY3NzEgNi4wOTM0NEMwLjIzMzIwOCA2LjAxMjkzIDAuMjE1OTI4IDUuOTI2NTYgMC4yMTU5MjggNS44MzkzMkMwLjIxNTkyOCA1Ljc1MjA5IDAuMjMzMjA4IDUuNjY1NzIgMC4yNjY3NzEgNS41ODUyQzAuMzAwMzM0IDUuNTA0NjggMC4zNDk1MTYgNS40MzE2IDAuNDExNDc1IDUuMzcwMkMwLjQ3MzQzNCA1LjMwODc5IDAuNTQ2OTQzIDUuMjYwMjYgMC42Mjc3NTkgNS4yMjc0MkMwLjcwODU3NSA1LjE5NDU4IDAuNzk1MDk2IDUuMTc4MDcgMC44ODIzMjYgNS4xNzg4NUgzLjc0NDk2TDAuNDEyNzc1IDEuODU4NjFDMC4zNTA2NjUgMS43OTc0NSAwLjMwMTI3NSAxLjcyNDYxIDAuMjY3NDUxIDEuNjQ0MjdDMC4yMzM2MjggMS41NjM5MyAwLjIxNjA0IDEuNDc3NyAwLjIxNTcwMiAxLjM5MDUzQzAuMjE1MzY0IDEuMzAzMzcgMC4yMzIyODIgMS4yMTcgMC4yNjU0ODEgMS4xMzY0QzAuMjk4NjgxIDEuMDU1ODEgMC4zNDc1MDYgMC45ODI1NzkgMC40MDkxNCAwLjkyMDk0MkMwLjQ3MDc3MyAwLjg1OTMwNSAwLjU0Mzk5NyAwLjgxMDQ3NyAwLjYyNDU5MSAwLjc3NzI3M0MwLjcwNTE4NSAwLjc0NDA2OSAwLjc5MTU1MyAwLjcyNzE0NiAwLjg3ODcxOCAwLjcyNzQ3OUMwLjk2NTg4MyAwLjcyNzgxMiAxLjA1MjEyIDAuNzQ1Mzk1IDEuMTMyNDYgMC43NzkyMTRDMS4yMTI4IDAuODEzMDMzIDEuMjg1NjUgMC44NjI0MTggMS4zNDY4MSAwLjkyNDUyNUw0LjY3ODk5IDQuMjQ0NzdWMS4zODIxNEM0LjY4MDU1IDEuMjA3OTkgNC43NTA4MiAxLjA0MTUxIDQuODc0NTEgMC45MTg5MTJDNC45OTgyMSAwLjc5NjMxOSA1LjE2NTMxIDAuNzI3NTM5IDUuMzM5NDcgMC43Mjc1MzlDNS41MTM2MiAwLjcyNzUzOSA1LjY4MDcyIDAuNzk2MzE5IDUuODA0NDIgMC45MTg5MTJDNS45MjgxMSAxLjA0MTUxIDUuOTk4MzggMS4yMDc5OSA1Ljk5OTk0IDEuMzgyMTRWNS41OTgwM0M2LjAwMDI4IDUuNzE2NSA1Ljk3NzEyIDUuODMzODYgNS45MzE3OSA1Ljk0MzMxQzUuODg2NDUgNi4wNTI3NiA1LjgxOTg1IDYuMTUyMTIgNS43MzU4NCA2LjIzNTY1WiIvPiYjeGE7PC9zdmc+"
                       preserveAspectRatio="none" transform="rotate(-90,164.08,24.16)"/>
                <image x="142.5" y="134.1" width="42.16" height="49.17"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA2IDciIGhlaWdodD0iNyIgd2lkdGg9IjYiPiYjeGE7PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik01LjczNTg0IDYuMjM1NjVDNS42NTIzIDYuMzE5NjUgNS41NTI5MiA2LjM4NjI0IDUuNDQzNDcgNi40MzE1OEM1LjMzNDAxIDYuNDc2OTIgNS4yMTY2NSA2LjUwMDExIDUuMDk4MTggNi40OTk3OUgwLjg4MjMyNkMwLjc5NTA5NiA2LjUwMDU3IDAuNzA4NTc1IDYuNDg0MDYgMC42Mjc3NTkgNi40NTEyMkMwLjU0Njk0MyA2LjQxODM4IDAuNDczNDM0IDYuMzY5ODUgMC40MTE0NzUgNi4zMDg0NUMwLjM0OTUxNiA2LjI0NzA0IDAuMzAwMzM0IDYuMTczOTYgMC4yNjY3NzEgNi4wOTM0NEMwLjIzMzIwOCA2LjAxMjkzIDAuMjE1OTI4IDUuOTI2NTYgMC4yMTU5MjggNS44MzkzMkMwLjIxNTkyOCA1Ljc1MjA5IDAuMjMzMjA4IDUuNjY1NzIgMC4yNjY3NzEgNS41ODUyQzAuMzAwMzM0IDUuNTA0NjggMC4zNDk1MTYgNS40MzE2IDAuNDExNDc1IDUuMzcwMkMwLjQ3MzQzNCA1LjMwODc5IDAuNTQ2OTQzIDUuMjYwMjYgMC42Mjc3NTkgNS4yMjc0MkMwLjcwODU3NSA1LjE5NDU4IDAuNzk1MDk2IDUuMTc4MDcgMC44ODIzMjYgNS4xNzg4NUgzLjc0NDk2TDAuNDEyNzc1IDEuODU4NjFDMC4zNTA2NjUgMS43OTc0NSAwLjMwMTI3NSAxLjcyNDYxIDAuMjY3NDUxIDEuNjQ0MjdDMC4yMzM2MjggMS41NjM5MyAwLjIxNjA0IDEuNDc3NyAwLjIxNTcwMiAxLjM5MDUzQzAuMjE1MzY0IDEuMzAzMzcgMC4yMzIyODIgMS4yMTcgMC4yNjU0ODEgMS4xMzY0QzAuMjk4NjgxIDEuMDU1ODEgMC4zNDc1MDYgMC45ODI1NzkgMC40MDkxNCAwLjkyMDk0MkMwLjQ3MDc3MyAwLjg1OTMwNSAwLjU0Mzk5NyAwLjgxMDQ3NyAwLjYyNDU5MSAwLjc3NzI3M0MwLjcwNTE4NSAwLjc0NDA2OSAwLjc5MTU1MyAwLjcyNzE0NiAwLjg3ODcxOCAwLjcyNzQ3OUMwLjk2NTg4MyAwLjcyNzgxMiAxLjA1MjEyIDAuNzQ1Mzk1IDEuMTMyNDYgMC43NzkyMTRDMS4yMTI4IDAuODEzMDMzIDEuMjg1NjUgMC44NjI0MTggMS4zNDY4MSAwLjkyNDUyNUw0LjY3ODk5IDQuMjQ0NzdWMS4zODIxNEM0LjY4MDU1IDEuMjA3OTkgNC43NTA4MiAxLjA0MTUxIDQuODc0NTEgMC45MTg5MTJDNC45OTgyMSAwLjc5NjMxOSA1LjE2NTMxIDAuNzI3NTM5IDUuMzM5NDcgMC43Mjc1MzlDNS41MTM2MiAwLjcyNzUzOSA1LjY4MDcyIDAuNzk2MzE5IDUuODA0NDIgMC45MTg5MTJDNS45MjgxMSAxLjA0MTUxIDUuOTk4MzggMS4yMDc5OSA1Ljk5OTk0IDEuMzgyMTRWNS41OTgwM0M2LjAwMDI4IDUuNzE2NSA1Ljk3NzEyIDUuODMzODYgNS45MzE3OSA1Ljk0MzMxQzUuODg2NDUgNi4wNTI3NiA1LjgxOTg1IDYuMTUyMTIgNS43MzU4NCA2LjIzNTY1WiIvPiYjeGE7PC9zdmc+"
                       preserveAspectRatio="none"/>
                <image x="2.5" y="137.13" width="39.55" height="46.14"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA2IDciIGhlaWdodD0iNyIgd2lkdGg9IjYiPiYjeGE7PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik01LjczNTg0IDYuMjM1NjVDNS42NTIzIDYuMzE5NjUgNS41NTI5MiA2LjM4NjI0IDUuNDQzNDcgNi40MzE1OEM1LjMzNDAxIDYuNDc2OTIgNS4yMTY2NSA2LjUwMDExIDUuMDk4MTggNi40OTk3OUgwLjg4MjMyNkMwLjc5NTA5NiA2LjUwMDU3IDAuNzA4NTc1IDYuNDg0MDYgMC42Mjc3NTkgNi40NTEyMkMwLjU0Njk0MyA2LjQxODM4IDAuNDczNDM0IDYuMzY5ODUgMC40MTE0NzUgNi4zMDg0NUMwLjM0OTUxNiA2LjI0NzA0IDAuMzAwMzM0IDYuMTczOTYgMC4yNjY3NzEgNi4wOTM0NEMwLjIzMzIwOCA2LjAxMjkzIDAuMjE1OTI4IDUuOTI2NTYgMC4yMTU5MjggNS44MzkzMkMwLjIxNTkyOCA1Ljc1MjA5IDAuMjMzMjA4IDUuNjY1NzIgMC4yNjY3NzEgNS41ODUyQzAuMzAwMzM0IDUuNTA0NjggMC4zNDk1MTYgNS40MzE2IDAuNDExNDc1IDUuMzcwMkMwLjQ3MzQzNCA1LjMwODc5IDAuNTQ2OTQzIDUuMjYwMjYgMC42Mjc3NTkgNS4yMjc0MkMwLjcwODU3NSA1LjE5NDU4IDAuNzk1MDk2IDUuMTc4MDcgMC44ODIzMjYgNS4xNzg4NUgzLjc0NDk2TDAuNDEyNzc1IDEuODU4NjFDMC4zNTA2NjUgMS43OTc0NSAwLjMwMTI3NSAxLjcyNDYxIDAuMjY3NDUxIDEuNjQ0MjdDMC4yMzM2MjggMS41NjM5MyAwLjIxNjA0IDEuNDc3NyAwLjIxNTcwMiAxLjM5MDUzQzAuMjE1MzY0IDEuMzAzMzcgMC4yMzIyODIgMS4yMTcgMC4yNjU0ODEgMS4xMzY0QzAuMjk4NjgxIDEuMDU1ODEgMC4zNDc1MDYgMC45ODI1NzkgMC40MDkxNCAwLjkyMDk0MkMwLjQ3MDc3MyAwLjg1OTMwNSAwLjU0Mzk5NyAwLjgxMDQ3NyAwLjYyNDU5MSAwLjc3NzI3M0MwLjcwNTE4NSAwLjc0NDA2OSAwLjc5MTU1MyAwLjcyNzE0NiAwLjg3ODcxOCAwLjcyNzQ3OUMwLjk2NTg4MyAwLjcyNzgxMiAxLjA1MjEyIDAuNzQ1Mzk1IDEuMTMyNDYgMC43NzkyMTRDMS4yMTI4IDAuODEzMDMzIDEuMjg1NjUgMC44NjI0MTggMS4zNDY4MSAwLjkyNDUyNUw0LjY3ODk5IDQuMjQ0NzdWMS4zODIxNEM0LjY4MDU1IDEuMjA3OTkgNC43NTA4MiAxLjA0MTUxIDQuODc0NTEgMC45MTg5MTJDNC45OTgyMSAwLjc5NjMxOSA1LjE2NTMxIDAuNzI3NTM5IDUuMzM5NDcgMC43Mjc1MzlDNS41MTM2MiAwLjcyNzUzOSA1LjY4MDcyIDAuNzk2MzE5IDUuODA0NDIgMC45MTg5MTJDNS45MjgxMSAxLjA0MTUxIDUuOTk4MzggMS4yMDc5OSA1Ljk5OTk0IDEuMzgyMTRWNS41OTgwM0M2LjAwMDI4IDUuNzE2NSA1Ljk3NzEyIDUuODMzODYgNS45MzE3OSA1Ljk0MzMxQzUuODg2NDUgNi4wNTI3NiA1LjgxOTg1IDYuMTUyMTIgNS43MzU4NCA2LjIzNTY1WiIvPiYjeGE7PC9zdmc+"
                       preserveAspectRatio="none" transform="rotate(90,22.78,160.7)"/>
                <image x="0.6200000000000001" y="-0.5" width="41.43" height="48.33"
                       xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA2IDciIGhlaWdodD0iNyIgd2lkdGg9IjYiPiYjeGE7PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik01LjczNTg0IDYuMjM1NjVDNS42NTIzIDYuMzE5NjUgNS41NTI5MiA2LjM4NjI0IDUuNDQzNDcgNi40MzE1OEM1LjMzNDAxIDYuNDc2OTIgNS4yMTY2NSA2LjUwMDExIDUuMDk4MTggNi40OTk3OUgwLjg4MjMyNkMwLjc5NTA5NiA2LjUwMDU3IDAuNzA4NTc1IDYuNDg0MDYgMC42Mjc3NTkgNi40NTEyMkMwLjU0Njk0MyA2LjQxODM4IDAuNDczNDM0IDYuMzY5ODUgMC40MTE0NzUgNi4zMDg0NUMwLjM0OTUxNiA2LjI0NzA0IDAuMzAwMzM0IDYuMTczOTYgMC4yNjY3NzEgNi4wOTM0NEMwLjIzMzIwOCA2LjAxMjkzIDAuMjE1OTI4IDUuOTI2NTYgMC4yMTU5MjggNS44MzkzMkMwLjIxNTkyOCA1Ljc1MjA5IDAuMjMzMjA4IDUuNjY1NzIgMC4yNjY3NzEgNS41ODUyQzAuMzAwMzM0IDUuNTA0NjggMC4zNDk1MTYgNS40MzE2IDAuNDExNDc1IDUuMzcwMkMwLjQ3MzQzNCA1LjMwODc5IDAuNTQ2OTQzIDUuMjYwMjYgMC42Mjc3NTkgNS4yMjc0MkMwLjcwODU3NSA1LjE5NDU4IDAuNzk1MDk2IDUuMTc4MDcgMC44ODIzMjYgNS4xNzg4NUgzLjc0NDk2TDAuNDEyNzc1IDEuODU4NjFDMC4zNTA2NjUgMS43OTc0NSAwLjMwMTI3NSAxLjcyNDYxIDAuMjY3NDUxIDEuNjQ0MjdDMC4yMzM2MjggMS41NjM5MyAwLjIxNjA0IDEuNDc3NyAwLjIxNTcwMiAxLjM5MDUzQzAuMjE1MzY0IDEuMzAzMzcgMC4yMzIyODIgMS4yMTcgMC4yNjU0ODEgMS4xMzY0QzAuMjk4NjgxIDEuMDU1ODEgMC4zNDc1MDYgMC45ODI1NzkgMC40MDkxNCAwLjkyMDk0MkMwLjQ3MDc3MyAwLjg1OTMwNSAwLjU0Mzk5NyAwLjgxMDQ3NyAwLjYyNDU5MSAwLjc3NzI3M0MwLjcwNTE4NSAwLjc0NDA2OSAwLjc5MTU1MyAwLjcyNzE0NiAwLjg3ODcxOCAwLjcyNzQ3OUMwLjk2NTg4MyAwLjcyNzgxMiAxLjA1MjEyIDAuNzQ1Mzk1IDEuMTMyNDYgMC43NzkyMTRDMS4yMTI4IDAuODEzMDMzIDEuMjg1NjUgMC44NjI0MTggMS4zNDY4MSAwLjkyNDUyNUw0LjY3ODk5IDQuMjQ0NzdWMS4zODIxNEM0LjY4MDU1IDEuMjA3OTkgNC43NTA4MiAxLjA0MTUxIDQuODc0NTEgMC45MTg5MTJDNC45OTgyMSAwLjc5NjMxOSA1LjE2NTMxIDAuNzI3NTM5IDUuMzM5NDcgMC43Mjc1MzlDNS41MTM2MiAwLjcyNzUzOSA1LjY4MDcyIDAuNzk2MzE5IDUuODA0NDIgMC45MTg5MTJDNS45MjgxMSAxLjA0MTUxIDUuOTk4MzggMS4yMDc5OSA1Ljk5OTk0IDEuMzgyMTRWNS41OTgwM0M2LjAwMDI4IDUuNzE2NSA1Ljk3NzEyIDUuODMzODYgNS45MzE3OSA1Ljk0MzMxQzUuODg2NDUgNi4wNTI3NiA1LjgxOTg1IDYuMTUyMTIgNS43MzU4NCA2LjIzNTY1WiIvPiYjeGE7PC9zdmc+"
                       preserveAspectRatio="none" transform="rotate(-180,21.83,24.16)"/>
            </g>
        </svg>
    )
        ;
}


