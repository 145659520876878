import React, { useContext } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';

interface ScrollToStartIconProps {
    disabled: boolean;
}

const ScrollToStartIcon = ({disabled}: ScrollToStartIconProps) => {
    const { theme } = useContext(ThemeContext)!;

    const color = disabled 
    ? (theme === 'light' ? '#677C9F' : '#677C9F')
    : (theme === 'light' ? '#242546' : '#EBEFF7'); 

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0.5H2.33333V11.5H0.5V0.5ZM3.70833 6L11.5 11.5V0.5L3.70833 6Z" fill={color}/>
    </svg>
  )
}

export default ScrollToStartIcon