import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const FraudLow = () => {
  const { themedColors, theme } = useContext(ThemeContext)!;

  const color = theme === 'light' ? '#2AA300' : '#AAF490';

  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6667 6.88723V7.50056C14.6658 8.93818 14.2003 10.337 13.3395 11.4884C12.4788 12.6399 11.2688 13.4822 9.89022 13.8898C8.5116 14.2974 7.03815 14.2485 5.68963 13.7503C4.3411 13.2521 3.18975 12.3313 2.40729 11.1253C1.62482 9.91925 1.25317 8.4926 1.34776 7.0581C1.44235 5.6236 1.99812 4.25811 2.93217 3.16528C3.86622 2.07244 5.1285 1.31082 6.53077 0.993991C7.93304 0.677167 9.40016 0.822119 10.7133 1.40723M14.6667 2.16723L7.99998 8.84056L5.99998 6.84056" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default FraudLow