import React, { useContext } from 'react';
import { Card } from '@tremor/react';
import { UserContext } from '../../../context/UserContext/UserContext';

interface CustomCardReportLayoutProps {
    children: React.ReactNode;
    id: null | string | number | undefined;
}

const CustomCardReportLayout = ({ children, id }: CustomCardReportLayoutProps) => {
    const { superTenant, isSuperAdmin } = useContext(UserContext)!;

    if (isSuperAdmin && !superTenant) {
        return
    }

    return (
        <div key={id}>
            <Card className={'ring-0 bg-white dark:bg-odin-dark-blue-dark overflow-x-hidden mt-2 mb-2 !shadow-none !p-0 tablet-size:!p-6'}>
                {children}
            </Card>
        </div>
    );
};

export default CustomCardReportLayout;
