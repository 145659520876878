import React, { useState } from 'react'
import SettingsButtonMobile from '../../UI/selection/UI/SettingsButtonMobile';
import { SelectChangeEvent } from '@mui/material';
import Utils from '../../../odinForgeService/Utils';
import { MenuItem } from '../../pages/reports/DeclineResponseReport/utils/config';
import FullScreenMobileModalDeclineReport from './FullScreenMobileModalDeclineReport';

interface MobileSelectDropdownDeclineReportProps {
    handleBlur: () => void;
    groupings: string[];
    setGroupings: (event: string[]) => void;
    names: string[];
    maxSelections?: number;
    label?: string;
    multiple?: boolean;
    // data for filter dropdown
  menuItems?: MenuItem[];
  onSelectionChange?: (newSelectedItems: {
    [key: string]: {
        [subItemId: string]: boolean;
    };
    }) => void;
  selectedItems?: {
        [key: string]: {
            [subItemId: string]: boolean;
        };
    }
    setSelectedItems: React.Dispatch<React.SetStateAction<{
        [key: string]: {
            [subItemId: string]: boolean;
        };
    }>>
    handleApplyMobileSelect: ((groupings: string[], filters: {
      [key: string]: {
          [subItemId: string]: boolean;
      };
    }) => void) | undefined
}

const MobileSelectDropdownDeclineReport = ({ 
    handleBlur, 
    groupings,
    setGroupings,
    names,
    maxSelections,
    multiple,
    menuItems,
    onSelectionChange,
    selectedItems,
    setSelectedItems,
    handleApplyMobileSelect
}:MobileSelectDropdownDeclineReportProps) => {
    const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const handleSelectChange = (event: SelectChangeEvent<typeof groupings>) => {
    const {
      target: { value },
    } = event;

    let selectedValues = typeof value === 'string' ? value.split(',') : value;

    if (maxSelections !== undefined && selectedValues.length > maxSelections) {
      selectedValues = selectedValues.slice(0, maxSelections);
      Utils.instance().onFailure(`You can select up to ${maxSelections} options.`);
    }
  };

    const handleOpenModal = () => {
        setIsMobileModalOpen(true);
        document.body.style.overflow = "hidden";
      
        const baseDashboardContainer = document.getElementById("baseDashboardContainer");
        if (baseDashboardContainer) {
          baseDashboardContainer.style.zIndex = "100";
        }
      
        const mainContainer = document.getElementsByClassName("main-content")[0] as HTMLElement;
        if (mainContainer) {
          mainContainer.style.zIndex = "100";
        }
      };
    
      const handleCloseModal = () => {
        setIsMobileModalOpen(false);
        document.body.style.overflow = "visible";
      
        const baseDashboardContainer = document.getElementById("baseDashboardContainer");
        if (baseDashboardContainer) {
          baseDashboardContainer.style.zIndex = "1";
        }
      
        const mainContainer = document.getElementsByClassName("main-content")[0] as HTMLElement;
        if (mainContainer) {
          mainContainer.style.zIndex = "";
        }
      };

      const countSelecteedItems = () => {
        let count = 0;
        for (const key in selectedItems) {
          count += Object.keys(selectedItems[key]).filter((subKey) => selectedItems[key][subKey]).length;
        }
        count += groupings.length > 0 ? groupings.length : 0;

        return count;
      };

  return (
    <div className="block tablet-size:hidden">
        <div onClick={handleOpenModal}>
          <SettingsButtonMobile
            isSelected={countSelecteedItems() === 0 ? false : true}
            numberOfSelectedOptions={
                countSelecteedItems()
            }
          />
        </div>

        {isMobileModalOpen && (
          <FullScreenMobileModalDeclineReport
            onClose={handleCloseModal}
            handleSelectChange={handleSelectChange}
            groupings={groupings}
            setGroupings={setGroupings}
            handleBlur={handleBlur}
            names={names}
            maxSelections={maxSelections}
            multiple={multiple}
            menuItems={menuItems ?? []}
            selectedItems={selectedItems ?? {}}
            onSelectionChange={onSelectionChange ?? (() => {})}
            setSelectedItems={setSelectedItems}
            handleApplyMobileSelect={handleApplyMobileSelect}
          />
        )}
      </div>
  )
}

export default MobileSelectDropdownDeclineReport



