import React from 'react'

const SortedArrowAscending = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.22303 5.3834L1.22305 5.38341L1.22022 5.38624C1.18307 5.42339 1.17509 5.44067 1.17357 5.44409C1.17355 5.44413 1.17353 5.44418 1.1735 5.44424C1.17261 5.44616 1.16666 5.45904 1.16666 5.49935C1.16666 5.53966 1.17261 5.55254 1.1735 5.55446C1.17353 5.55452 1.17355 5.55457 1.17357 5.55461C1.17509 5.55803 1.18307 5.57531 1.22022 5.61246C1.25737 5.64961 1.27465 5.65759 1.27807 5.65911C1.27811 5.65913 1.27816 5.65915 1.27822 5.65918C1.28014 5.66007 1.29302 5.66602 1.33333 5.66602H8.66666C8.70697 5.66602 8.71985 5.66007 8.72177 5.65918L8.72193 5.65911C8.72535 5.65759 8.74263 5.64961 8.77978 5.61246L9.13333 5.96602L8.77978 5.61246C8.78957 5.60267 8.80571 5.58045 8.81909 5.54699C8.82528 5.53153 8.82929 5.5175 8.83156 5.50676C8.83222 5.50363 8.83265 5.50116 8.83293 5.49935C8.83265 5.49754 8.83222 5.49507 8.83156 5.49194C8.82929 5.4812 8.82528 5.46717 8.81909 5.45171C8.80571 5.41825 8.78957 5.39603 8.77978 5.38624L8.77696 5.3834L5 1.54551L1.22303 5.3834Z" fill="#677C9F" stroke="#677C9F"/>
    </svg>

  )
}

export default SortedArrowAscending