// @ts-nocheck
import { v4 as uuidv4 } from 'uuid';
import getSymbolFromCurrency from 'currency-symbol-map';
import { ForecastedRebillsReportData } from '../types/forecasted-rebills-report.types';

export function transformData(data: string | number | ForecastedRebillsReportData | ForecastedRebillsReportData[] | null): ForecastedRebillsReportData {
    const result = { currency: [] };

    function collectStats(stats) {
        return {
            rebills: stats?.rebills || 0,
            revenue: stats?.revenue || 0,
            currency: stats?.currency || '',
            formatted: `${stats?.rebills || 0} - ${(stats?.currency && getSymbolFromCurrency(stats?.currency)) || ''}${stats?.revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        };
    }

    function addStats(a, b) {
        return {
            rebills: (a.rebills || 0) + (b.rebills || 0),
            revenue: (a.revenue || 0) + (b.revenue || 0),
            currency: a.currency || b.currency,
        };
    }

    function processNode(node, container, path = []) {
        if (node.stats) {
            const statsDescription = collectStats(node.stats);
            container.stats[path[0] || 'total'] = statsDescription;
            if (path.length === 0) {
                container.stats['total'] = statsDescription;
            }
        }

        Object.keys(node).forEach(key => {
            if (key !== 'stats' && typeof node[key] === 'object') {
                if (key === 'next_rebill_date') {
                    Object.keys(node[key]).forEach(date => {
                        processNode(node[key][date], container, [...path, date]);
                    });
                } else {
                    Object.keys(node[key]).forEach(subKey => {
                        const subNode = node[key][subKey];
                        if (!container[key]) {
                            container[key] = [];
                        }
                        let found = container[key].find(item => item.name === subKey);
                        if (!found) {
                            found = { name: subKey, stats: {}, id: uuidv4() };
                            container[key].push(found);
                        }
                        processNode(subNode, found, [...path, key, subKey]);

                        // Aggregate totals
                        if (!found.stats.total) {
                            found.stats.total = { rebills: 0, revenue: 0, currency: '' };
                        }
                        found.stats.total = addStats(found.stats.total, subNode.stats);
                        found.stats.total.formatted = `${found.stats.total.rebills} - ${found.stats.total.currency && getSymbolFromCurrency(found.stats.total.currency)}${found.stats.total.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                    });
                }
            }
        });
    }

    Object.keys(data).forEach(currencyKey => {
        const currencyContainer = { name: currencyKey, stats: {}, currency: currencyKey, id: uuidv4() };
        processNode(data[currencyKey], currencyContainer);
        result.currency.push(currencyContainer);
    });

    return result;
}

export function extractDates(data): string[]  {
  const dateSet = new Set();

  function recurse(node) {
      if (node.stats) {
          Object.keys(node.stats).forEach(key => {
              if (key !== 'total') {
                  dateSet.add(key);
              }
          });
      }

      if (node.geo) {
          node.geo.forEach(subNode => {
              recurse(subNode);
          });
      }

      if (node.affiliate) {
          node.affiliate.forEach(subNode => {
              recurse(subNode);
          });
      }

      if (node.gateway) {
          node.gateway.forEach(subNode => {
              recurse(subNode);
          });
      }
  }

  data.currency.forEach(currency => {
      recurse(currency);
  });

  return Array.from(dateSet).sort((a, b) => new Date(a) - new Date(b));
}
