import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BannedByBackend from '../../components/organism/BannedByBackend/BannedByBackend';
import { setShowModalGlobal } from '../../globals';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';

interface ModalContextProps {
    showModal: (message: string) => void;
    hideModal: () => void;
    modalMessage: string | null;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [modalMessage, setModalMessage] = useState<string | null>(null);
    const userContext = useContext(UserContext);
    const { isSuperAdmin } = userContext!;
    const navigate = useNavigate();

    const showModal = (message: string) => {
        setModalMessage(message);
    };

    const hideModal = () => {
        const url = process.env.REACT_APP_BASE_URL
        
        const tooManyRequestsValue = localStorage.getItem('tooManyRequests');

        localStorage.clear();

        if (tooManyRequestsValue !== null) {
            localStorage.setItem('tooManyRequests', tooManyRequestsValue);
        }

        setModalMessage(null);

        if (isSuperAdmin) { 
            navigate('/');
        } else if (url === 'https://staging-api.odinforgebi.com/') {
            window.location.href = 'https://crm2.staging.swisspriv.com/login'; 
        } else {
            window.location.href = 'https://dashboard.swisscrm.com/login'
        }
        
    };

    useEffect(() => {
        setShowModalGlobal(showModal);

        return () => {
            setShowModalGlobal(() => {});
        };
    }, []);

    return (
        <ModalContext.Provider value={{ showModal, hideModal, modalMessage }}>
            {children}
            {modalMessage && (
                <BannedByBackend onClose={hideModal} message={modalMessage} />
            )}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};
