import React, { useContext } from "react";
import AccessDeniedIcon from "../../../assets/icons/AccessDeniedIcon/AccessDeniedIcon";
import {useNavigate} from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../../UI/buttons/DefaultButton";
import { BackArrowIcon } from "../../../assets/icons/BackArrowIcon";

const AccessDeniedPage = () => {
    const { themedColors } = useContext(ThemeContext)!;

    const navigate = useNavigate()

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-white dark:bg-odin-dark-blue-dark px-4">
            <div className='w-full flex justify-center max-w-[400px]'>
            <AccessDeniedIcon />
            </div>

            <p className="text-[32px] tablet-size:text-[48px] font-bold text-black mb-8 text-center mt-8 tablet-size:mt-10" style={{color: themedColors.content}} >Access Denied</p>
            <p className="text-[16px] tablet-size:text-[20px] text-gray-500 mb-8  text-center" style={{color: themedColors.content}} >
            You don’t have permission to view this page .
            </p>
            <div className="flex flex-wrap mobile-size:flex-nowrap gap-4 w-full max-w-[400px]">
                <DefaultButton type='button' variant="outlined" onClick={() => navigate(-1)} label="GO BACK" startIcon={<BackArrowIcon />} className='!h-[40px] flex-grow !text-sm py-2 w-full'  />
                <DefaultButton type='button' onClick={() => navigate('/')} label="TAKE ME HOME" className='!h-[40px] flex-grow !text-sm py-2 w-full' />
            </div>
        </div>
    )    
}

export default AccessDeniedPage