import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

const CloseIcon = () => {
  const { theme } = useContext(ThemeContext)!;

  let color = theme === "dark" ? '#495D80' : '#ACBFDF';

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
