
import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import { menuClasses, MenuItem, outlinedInputClasses, Select, SelectChangeEvent, selectClasses } from '@mui/material';
import { ChevronDownIcon } from '@heroicons/react/outline';

interface  FilterDropDownProps {
    filterList: string[];
    chartFilter: string;
    setChartFilter: React.Dispatch<React.SetStateAction<string>>
}

const FilterDropDown = ({filterList,chartFilter,setChartFilter}:FilterDropDownProps) => {
    const { themedColors, theme } = useContext(ThemeContext)!;

    const handleChange = (e: SelectChangeEvent) => {
        setChartFilter(filterList[parseInt(e.target.value as string, 10)]);
    }

    return <Select
    className="mui-select-daterange-picker"
    variant="outlined"
    inputProps={{MenuProps: {disableScrollLock: true}}}
    MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      sx: {
        marginBlock: "0.5rem",
        
        [`& .${menuClasses.paper}`]: {
          borderRadius: "6px",
          width: 150,
        },
        [`& .${menuClasses.list}`]: {
          paddingTop: 0,
          paddingBottom: 0,
          background: themedColors.background_1,
          "& li": {
            paddingTop: "12px",
            paddingBottom: "12px",
            justifyContent: "space-between",
            background: themedColors.background_1,
            fontWeight: 400,
            fontSize: "14px",
          },
          "& li:hover": {
            background: themedColors.background_1,
          },
          "& li.Mui-selected": {
            fontWeight: 500,
            background: themedColors.background_1,
          },
        },
      },
    }}
    IconComponent={ChevronDownIcon}
    value={filterList.indexOf(chartFilter).toString()}
    renderValue={(selected) => {
        return filterList[parseInt(selected as string, 10)];
    }}
    sx={{
      [`& .${selectClasses.select}`]: {
        background: themedColors.background_1,
        color: themedColors.content,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: "20px",
        paddingLeft: "20px",
        paddingRight: "55px !important",
        paddingTop: "11px",
        paddingBottom: "10px",
        transitionDuration: "0.3s",
        "&:focus": {
          borderRadius: "12px",
          background: themedColors.background_1,
          border: 'none'
        },
      },
      [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: "#0079FF",
        borderWidth: 1,
      },
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: themedColors.gray,
        borderWidth: 1,
        borderRadius: 12,
      },
      "&:hover": {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "#0079FF",
        },
      },
      "& .MuiSelect-icon": {
        width: "20px",
        color: themedColors.content,
        fontSize: "20px",
        right: "23px",
        top: "11px",
      },
      "& .MuiSelect-iconOpen": {
        color: "#0079FF",
      },
      "&.Mui-focused": {
        border: 'none !important',
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme === 'light' ? '#ACBFDF' : '#495D80',
        borderRadius: '50px',
      },
    }}
    onChange={handleChange}
>
  {filterList.map((range: string, index: number) => (
      <MenuItem 
        value={index.toString()}
        key={index} 
        style={{color: themedColors.content}}
        sx={{
          "&.MuiMenuItem-root.MuiMenuItem-root:hover": {
            backgroundColor: theme === 'light' ? "rgba(25, 118, 210, 0.08)" : "rgba(144, 202, 249, 0.16)",
          }
        }}
      >
        {range}
      </MenuItem>
  ))}
</Select>
}


export default FilterDropDown