type LineColors = {
    [key in `color${number}`]: string;
};

export const lineColors: LineColors = {
    color1: '#FFB871',
    color2: '#6FD979',
    color3: '#A288E6',
    color4: '#A8F7FF',
    color5: '#FF6683',
    color6: '#FFFFA8',
    color7: '#8495D1',
    color8: '#5DBE5D',
    color9: '#F7A35C',
    color10: '#90ED7D',
    color11: '#8085E9',
    color12: '#F45B5B',
    color13: '#91E8E1',
    color14: '#F15C80',
    color15: '#E4D354',
    color16: '#2B908F',
    color17: '#F4E542',
    color18: '#1F77B4',
    color19: '#FF7F0E',
    color20: '#D62728',
    color21: '#9467BD',
    color22: '#8C564B',
    color23: '#E377C2',
    color24: '#7F7F7F',
    color25: '#BCBD22',
    color26: '#17BECF',
    color27: '#1EA055',
    color28: '#B5CE48',
    color29: '#BD9E39',
    color30: '#55BF3B',
};


