import { AffiliateReportData } from "../components/pages/reports/AffiliateSalesReport/types/affiliate-report.types";
import { CancellationReportData } from "../components/pages/reports/cancellationReasons/types/cancellation-report.types";
import { ForecastedRebillsReportData } from "../components/pages/reports/forecastedRebills/types/forecasted-rebills-report.types";
import { RetentionReportData } from "../components/pages/reports/retentionReport/types/retention-report.types";
import { SalesPaceReportData } from "../components/pages/reports/salesPaceReport/types/sales-pace-report.types";
import { SalesPerformanceReportData } from "../components/pages/reports/SalesPerformanceReport/types/sales-performance-report.types";
import { TransactionReportData } from "../components/pages/reports/transactionPerformanceReport/types/transaction-report.types";

type NamesEnum = { [key: string]: string };

type Data  = [] | SalesPaceReportData | ForecastedRebillsReportData | AffiliateReportData | CancellationReportData | RetentionReportData | SalesPerformanceReportData | TransactionReportData | any;

function sortData(data: Data, field: string, sortState: number | null, namesEnum: NamesEnum): Data {
    function parseValue(value: any): number {
        return typeof value === 'string' ? parseFloat(value) : value;
    }

    function compare(a: any, b: any): number {
        const valueA = parseValue(a[field] !== undefined ? a[field] : a.stats ? a.stats[field] : a[field]);
        const valueB = parseValue(b[field] !== undefined ? b[field] : b.stats ? b.stats[field] : b[field]);
        if (valueA < valueB) {
            return sortState === 1 ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortState === 1 ? 1 : -1;
        }
        return 0;
    }

    function sortArray(arr: any[]): any[] {
        return arr.map(item => {
            const nestedField = Object.values(namesEnum).find(key => Array.isArray(item[key]));
            if (nestedField && Array.isArray(item[nestedField])) {
                return {
                    ...item,
                    [nestedField]: sortArray(item[nestedField])
                };
            }
            return item;
        }).sort(compare);
    }

    const sortedData: ForecastedRebillsReportData = {};

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            // @ts-ignore
            sortedData[key] = sortArray(data[key]);
        }
    }

    return sortedData;
}

function sortDataForRebillsReport(data: ForecastedRebillsReportData | [], date: string, ascending: boolean = true, namesEnum: NamesEnum): ForecastedRebillsReportData | [] {

    function parseValue(value: any): number {
        return typeof value === 'string' ? parseFloat(value) : value;
    }

    function compare(a: any, b: any): number {
        const valueA = parseValue(a.stats?.[date]?.rebills ?? 0);
        const valueB = parseValue(b.stats?.[date]?.rebills ?? 0);
        if (valueA < valueB) return ascending ? -1 : 1;
        if (valueA > valueB) return ascending ? 1 : -1;
        return 0;
    }

    function sortArray(arr: any[]): any[] {
        return arr.sort(compare).map(item => {
            const nestedField = Object.values(namesEnum).find(key => Array.isArray(item[key]));
            if (nestedField && Array.isArray(item[nestedField])) {
                return {
                    ...item,
                    [nestedField]: sortArray(item[nestedField])
                };
            }
            return item;
        });
    }

    const sortedData: ForecastedRebillsReportData = {};

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            // @ts-ignore
            sortedData[key] = sortArray(data[key]);
        }
    }

    return sortedData;
}

type SortBy = 'initial' | 'cycle' | 'total';

function sortDataForRetention(data: any, field: string, sortState: number, namesEnum: any, sortBy: SortBy = 'initial'): any {
    function parseValue(value: any): number {
        return typeof value === 'string' ? parseFloat(value) : value;
    }

    function compare(a: any, b: any): number {
        let valueA = 0, valueB = 0;

        if (sortBy === 'initial') {
            valueA = parseValue(a[field] !== undefined ? a[field] : a.stats ? a.stats[field] : a[field]);
            valueB = parseValue(b[field] !== undefined ? b[field] : b.stats ? b.stats[field] : b[field]);
        } else if (sortBy === 'cycle') {
            valueA = parseValue(a.cycles ? a.cycles[field] : a[field]);
            valueB = parseValue(b.cycles ? b.cycles[field] : b[field]);
        } else if (sortBy === 'total') {
            valueA = parseValue(a.total ? a.total[field] : a[field]);
            valueB = parseValue(b.total ? b.total[field] : b[field]);
        }

        if (valueA < valueB) {
            return sortState === 1 ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortState === 1 ? 1 : -1;
        }
        return 0;
    }

    function sortArray(arr: any[]): any[] {
        return arr.map(item => {
            // @ts-ignore
            const nestedField = Object.values(namesEnum).find(key => Array.isArray(item[key]));
            // @ts-ignore
            if (nestedField && Array.isArray(item[nestedField])) {
                return {
                    ...item,
                    // @ts-ignore
                    [nestedField]: sortArray(item[nestedField])
                };
            }
            return item;
        }).sort(compare);
    }

    const sortedData: any = {};

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            sortedData[key] = sortArray(data[key]);
        }
    }

    return sortedData;
}




export { sortData, sortDataForRebillsReport, sortDataForRetention };
