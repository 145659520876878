import { ForecastedRebillsReportData } from "../types/forecasted-rebills-report.types";

interface Stats {
    [key: string]: {
        rebills: number;
        revenue: number;
        currency: string;
        formatted: string;
    };
}

interface DataItem {
    name: string;
    stats: Stats;
    id: string;
    [key: string]: string | Stats | DataItem[];
}

function transformDataToCSVFormat(
  data: ForecastedRebillsReportData,
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string }
) {
  const result: { [key: string]: string }[] = [];
  const groupingLevels = new Set<number>();
  const datesSet = new Set<string>();

  function processItem(item: DataItem, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string } = {};

    // Collect all dates
    Object.keys(item.stats).forEach((date) => {
      if (date !== 'total') {
        datesSet.add(date);
      }
    });

    // Initialize columns for all dates and total
    Object.keys(item.stats).forEach((date) => {
      if (date === 'total') {
        baseItem[`Total`] = item.stats.total.formatted || '';
      } else {
        baseItem[`${date}`] = item.stats[date].formatted || '';
      }
    });

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    baseItem[`Grouping Level ${level + 1}`] = item.name || '';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        // @ts-ignore
        item[nestedKey].forEach((nestedItem: DataItem) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  if (data.currency && Array.isArray(data.currency)) {
    // @ts-ignore
    data.currency.forEach((currencyItem: DataItem) => processItem(currencyItem));
  }

  const groupingFields = Array.from(groupingLevels).map(
    (level) => `Grouping Level ${level + 1}`
  );

  const dateFields = Array.from(datesSet);

  return result.map((item) => {
    const transformedItem: { [key: string]: string } = {};
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field];
    });
    dateFields.forEach((dateField) => {
      transformedItem[dateField] = item[dateField];
    });
    transformedItem["Total"] = item["Total"];
    return transformedItem;
  });
}

export default transformDataToCSVFormat;
