import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const FraudMedium = () => {
  const { theme } = useContext(ThemeContext)!;

  const color = theme === 'light' ? '#FF9500' : '#FFC87B';

  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00001 5.50014V8.1668M8.00001 10.8335H8.00668M6.86001 2.07347L1.21335 11.5001C1.09693 11.7018 1.03533 11.9303 1.03467 12.1631C1.03402 12.396 1.09434 12.6249 1.20963 12.8272C1.32492 13.0294 1.49116 13.198 1.69182 13.316C1.89247 13.4341 2.12055 13.4976 2.35335 13.5001H13.6467C13.8795 13.4976 14.1076 13.4341 14.3082 13.316C14.5089 13.198 14.6751 13.0294 14.7904 12.8272C14.9057 12.6249 14.966 12.396 14.9654 12.1631C14.9647 11.9303 14.9031 11.7018 14.7867 11.5001L9.14001 2.07347C9.02117 1.87754 8.85383 1.71555 8.65415 1.60313C8.45446 1.4907 8.22917 1.43164 8.00001 1.43164C7.77086 1.43164 7.54557 1.4907 7.34588 1.60313C7.1462 1.71555 6.97886 1.87754 6.86001 2.07347Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default FraudMedium