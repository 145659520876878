import { ConversationReportData, CsvConversationReportData } from "./types/conversation-report.types";

function transformDataToCSVFormat(data: ConversationReportData[], namesEnum: { [key: string]: string }) : {[key: string]: string | number;}[] {
    const isNeedAdditionalFields = data.some((item: ConversationReportData) => item.clicks !== undefined);

    const formattedNamesEnum = {
        "Potential Fraud": "potential_fraud",
        ...namesEnum,
    };

    if (isNeedAdditionalFields) {
        const additionalFields = {
            "Partials Percentage": "partials_percentage" as keyof ConversationReportData,
            "Sales Percentage": "sales_percentage" as keyof ConversationReportData,
            "Declines Percentage": "declines_percentage" as keyof ConversationReportData,
          };
        Object.assign(formattedNamesEnum, additionalFields);
    }

    const transformedData = data.map((item: ConversationReportData) => {
        const transformedItem : CsvConversationReportData | any = {};
        Object.keys(formattedNamesEnum).forEach(key => {
            const field = formattedNamesEnum[key as keyof typeof formattedNamesEnum];

            if (field === 'potential_fraud') {
                transformedItem[key] = item[field] === 1
                  ? 'High'
                  : (item[field] === 0 ? 'Low' : item[field] === 2 ? 'Medium' : null);
              } else {
                transformedItem[key] = item[field as keyof ConversationReportData] !== undefined
                  ? String(item[field as keyof ConversationReportData])
                  : null;
              }
            });
        
        return transformedItem;
    });

    return transformedData;
}

export default transformDataToCSVFormat;
