import React, { useContext } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';

interface ScrollToEndIconProps {
    disabled: boolean;
}

const ScrollToEndIcon = ({disabled}: ScrollToEndIconProps) => {
    const { theme } = useContext(ThemeContext)!;

    const color = disabled 
    ? (theme === 'light' ? '#677C9F' : '#677C9F')
    : (theme === 'light' ? '#242546' : '#EBEFF7'); 


  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 11.5L8.29167 6L0.5 0.5V11.5ZM9.66667 0.5V11.5H11.5V0.5H9.66667Z" fill={color}/>
    </svg>
  )
}

export default ScrollToEndIcon