export const columnWidth = {
    1: 350,
    2: 390,
    3: 430,
    4: 470,
    5: 510,
    6: 550,
    7: 480,
    8: 550
}

export const getFirsrColimnWidth = (selectedGroupings: number, groupIndex: number) => {
    return `${(columnWidth as { [key: number]: number })[selectedGroupings] - 150 - (groupIndex * 20)}px`
}