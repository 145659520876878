import React from 'react';
import { Navigate } from 'react-router-dom';
import OdinForgeService from "../../../odinForgeService/OdinForgeService";

interface PrivateRouteProps {
    component: React.ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {
    if (OdinForgeService.instance().isAuthenticated()) {
        return <Component />;
    } else {
        return <Navigate to='/' />;
    }
};

export default PrivateRoute;
