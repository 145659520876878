import React, { createContext, useState, useContext, useEffect } from "react";
import SessionExpiredModal from "./SessionExpiredModal";
import SessionManager from "../../../odinForgeService/SessionManager";
import { useNavigate, useLocation } from "react-router-dom";
import Utils from "../../../odinForgeService/Utils";
import OdinForgeService from "../../../odinForgeService/OdinForgeService";
import { UserContext } from "../../../context/UserContext/UserContext";
import SessionExpiredWarning from "./SessionExpiredWarning";

interface SessionExpirationContextType {
    isSessionExpired: boolean;
    showSessionExpiredModal: () => void;
    hideSessionExpiredModal: () => void;
}

interface SessionExpirationProviderProps {
    children: React.ReactNode;
}

const SessionExpirationContext = createContext<SessionExpirationContextType>({
    isSessionExpired: false,
    showSessionExpiredModal: () => {},
    hideSessionExpiredModal: () => {},
});

export const useSessionExpiration = (): SessionExpirationContextType =>
    useContext(SessionExpirationContext);

const securedRoutes = [
    "/dashboard",
    "/dashboard/sales-pace-report-table",
    "/dashboard/sales-pace-report-line-chart",
    "/dashboard/sales-performance-report",
    "/dashboard/sales-performance-report-line-chart",
    "/dashboard/transaction-performance-report",
    "/dashboard/affiliate-sales-summary",
    "/dashboard/profileInfo",
    "/dashboard/retention-report",
    "/dashboard/cancellationReasons",
    "/dashboard/forecasted-rebills-report",
    "/dashboard/conversion-report",
    "/dashboard/decline-response-report",
    "/dashboard/dashboard-overview",
    "/dashboard/variance-report",
];   

export const SessionExpirationProvider: React.FC<SessionExpirationProviderProps> = ({
                                                                                        children,
                                                                                    }: SessionExpirationProviderProps) => {
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [isSessionWarningVisible, setIsSessionWarningVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const userContext = useContext(UserContext);
    const { isSuperAdmin } = userContext!;

    useEffect(() => {
        const sessionManager = SessionManager.instance();
        const checkSession = () => {
            if (sessionManager.getToken() === null) {
                setIsSessionWarningVisible(false);
                setIsSessionExpired(true);
            }
        };

        const checkWarningSession = () => {
            const tokenExpiry = localStorage.getItem('tokenExpiry');

            if (tokenExpiry) {
                const expiryTime = parseInt(tokenExpiry);
                const timeRemaining = expiryTime - Date.now();
                const timeToShowWarning = timeRemaining - 2 * 60 * 1000; // 2 minutes before session expires

                if (timeToShowWarning < 0) {
                    setIsSessionWarningVisible(true);
                }
            }
        }
    
        const isSecuredRoute = securedRoutes.some(routePattern => location.pathname.startsWith(routePattern));

        if (isSecuredRoute) {
            const sessionIntervalId = setInterval(checkSession, 2 * 60 * 1000); // Every 2 minutes
            const warningIntervalId = setInterval(checkWarningSession, 1 * 60 * 1000); // Every 1 minute
    
            return () => {
                clearInterval(sessionIntervalId);
                clearInterval(warningIntervalId);
            };
        }

    }, [location.pathname]);

    useEffect(() => {
        let isChecking = false;

        const checkToken = async () => {  
            if (isChecking) return;

            isChecking = true;        

            try {
                const response = await OdinForgeService.instance().checkToken();
            } catch (error) {
            } finally {
                isChecking = false;
            }
        };

        function handleFocus() {
            if(!isChecking) {
                checkToken();
            }
        }

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                checkToken();
            }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener("focus", handleFocus);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
        };
    }, [location.pathname]);

    const showSessionExpiredModal = () => setIsSessionExpired(true);
    const hideSessionExpiredModal = () => {
        const url = process.env.REACT_APP_BASE_URL

        if (location.pathname !== "/") {
            if (isSuperAdmin) { 
                navigate('/');
            } else if (url === 'https://staging-api.odinforgebi.com/') {
                window.location.href = 'https://crm2.staging.swisspriv.com/login'; 
            } else {
                window.location.href = 'https://dashboard.swisscrm.com/login'
            }
        }
        setIsSessionExpired(false);
    };

    

    const handleSessionRenew = async () => {
        console.log("Renewing session1");
        try {
            await SessionManager.instance().renewSession();
            setIsSessionWarningVisible(false);
        } catch (error) {
            console.error("Failed to renew session", error);
        }
    };


    return (
        <SessionExpirationContext.Provider
            value={{ isSessionExpired, showSessionExpiredModal, hideSessionExpiredModal }}
        >
            {children}
            {isSessionExpired && <SessionExpiredModal onClose={hideSessionExpiredModal} />}
            {isSessionWarningVisible && <SessionExpiredWarning onClose={handleSessionRenew} />}
        </SessionExpirationContext.Provider>
    );
};
