import ReportName from "../odinForgeService/Enums/ReportName";
import Utils from "../odinForgeService/Utils";

export function createHash( generateObjectToHash: () => object, data: object, reportName: ReportName | string, cacheTime?: number) {
    try {
        const dataSize = new Blob([JSON.stringify(data)]).size;
        if (dataSize < 5000000) {
            Utils.instance().createHash(generateObjectToHash(), data, reportName, cacheTime);
        } else {
            console.warn('Data is too large to be stored in localStorage');
        }
    } catch (error) {}
}