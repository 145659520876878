import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Toggle1, Toggle2 } from "../../../assets/icons/ToggleButtons";
import React, { FC, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import './expandButtons.css';

interface ExpandButtonsProps {
    collapse: string;
    setCollapse: (value: string) => void;
    groupings: string[];
    isAdditionalCurrencyField?: boolean;
    transformedGroupings?: string[];
}
const ExpandButtons: FC<ExpandButtonsProps> = ({
        collapse,
        setCollapse,
        groupings,
        isAdditionalCurrencyField = false,
        transformedGroupings,
    }) => {
    const { themedColors } = useContext(ThemeContext)!;

    const handleButtonChange = (
        newValue: string
    ) => { 
        if (isAdditionalCurrencyField && newValue === null) {
            console.log('check-1', newValue, collapse);
            setCollapse('1');
            return 
        }
        if (newValue > collapse) {
            // Если выбираем уровень выше текущего, расширяем до этого уровня
            setCollapse(newValue);
            return
        } else {
            // Если отключаем уровень, сворачиваем до предыдущего уровня
            setCollapse((Number(newValue) - 1).toString());
            return
        }

    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={collapse}
            exclusive
            // onChange={handleButtonChange}
            aria-label="Platform"
            className="flex flex-wrap"
        >
            {(transformedGroupings ? transformedGroupings.slice(1) : groupings).map((item, index: number) => {

                const level = (index + (isAdditionalCurrencyField ? 2 : 1)).toString();
                const isSelected = Number(level)  <= Number(collapse);

                return (
                    (
                        <ToggleButton
                            key={index}
                            selected={isSelected}
                            value={`${index + (isAdditionalCurrencyField ? 2 : 1)}`}
                            onClick={() => handleButtonChange(level)}
                            sx={{
                                "&.MuiToggleButton-root": {
                                    borderRadius: '8px',
                                    padding: '8px',
                                    color: '#FF3B30',
                                    transitionDuration: '0.3s',
                                },
                                "&.Mui-selected": { backgroundColor: '#0079FF',  },
                                "&.Mui-selected:hover": { backgroundColor: '#0060cc',  },
                            }}
                            className={"w-[40px] h-[40px] relative top-[0px]"}
                        >
                            {isSelected ? (
                                <Toggle2 style={{ width: '100%' }} />
                            ) : (
                                <Toggle1 style={{ width: '100%'}} />
                            )}
                            <div className={"absolute z-999"}>
                                <p style={{ color: isSelected ? 'white' : themedColors.content, fontSize: '12px' }}>{index + 1}</p>
                            </div>
                        </ToggleButton>
                    )
                )
            })}
        </ToggleButtonGroup>
    );
    
};

export default ExpandButtons;
