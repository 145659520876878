import React, { useContext, useEffect, useState } from "react";
import SettingsIcon from "../../../../assets/icons/SettingsIcon";
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";

const SettingsButtonMobile = ({
  isSelected = false,
  numberOfSelectedOptions,
}: {
  isSelected?: boolean;
  numberOfSelectedOptions?: number | null | undefined;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { theme } = useContext(ThemeContext)!;

  const baseStyle = "rounded-full px-4 py-2 transition-colors";
  const defaultStyle = `${theme === 'light' ? "bg-[#EAEEF6]" : "bg-[#2B384B]"} text-gray-700 hover:bg-[#0079FF]`;
  const selectedStyle = "bg-[#0079FF] text-white";

  const getButtonStyle = () => {
    if (isSelected) {
      return `${baseStyle} ${selectedStyle}`;
    }
    return `${baseStyle} ${defaultStyle}`;
  };

  useEffect(() => {
    checkIfSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  const checkIfSelected = () => {
    isSelected ? setIsHovered(true) : setIsHovered(false);
  };

  return (
    <button
      className={getButtonStyle()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => checkIfSelected()}
    >
      <div className="flex items-center gap-2 relative">
        <SettingsIcon isHovered={isHovered} />
        {isSelected && (
          <span className="absolute top-[-14px] right-[-25px] items-center justify-center px-2 py-1 text-xs font-medium leading-none text-black bg-white dark:bg-[#17202E] dark:text-[#EBEFF7] border border-[#0079FF] rounded-full">
            {numberOfSelectedOptions}
          </span>
        )}
      </div>
    </button>
  );
};

export default SettingsButtonMobile;
