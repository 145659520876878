enum ReportName {
    SalesPaceReportTable = 'Sales Pace Report',
    SalesPaceReportLineChart = 'Sales Pace Report Line Chart',
    SalesPerformanceReport = 'Sales Performance Report',
    SalesPerformanceReportLineChart = 'Sales Performance Report Line Chart',
    TransactionPerformanceReport = 'Transaction Performance Report',
    RetentionReport = 'Retention Report',
    AffiliateSalesSummary = 'Affiliate Sales Summary',
    CancellationReasons = 'Cancellation Reasons (Orders)',
    ForecastedRebillReport = 'Forecasted Rebills Report',
    DashboardOverview = 'Dashboard Overview',
    VarianceReport = 'Variance Report',
    ConversionReport = 'Conversion Report',
    DeclineResponseReport = 'Decline Response Report',
};

enum ReportIds {
    SalesPaceReportTable = 1,
    SalesPaceReportLineChart = 2,
    SalesPerformanceReport = 4,
    SalesPerformanceReportLineChart = 6,
    AffiliateSalesSummary = 7,
    RetentionReport = 8,
    CancellationReasons = 9,
    ForecastedRebillReport = 11,
    TransactionPerformanceReport = 13,
    ConversionReport = 14,
    DeclineResponseReport = 16,
}

export default ReportName;

export { ReportIds };