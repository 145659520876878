import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Sub',
    'Offer',
    'Merchant Name',
    'Mid Group',
    'Campaign Type',
    // 'Template Name'
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Name': 'mid_name',
    'Mid Group': 'mid_category',
    'Offer': 'funnel_name',
    'Campaign Type': 'campaign_type',
    'Sub': 'c2',
};

export const fields = {
    "State": "state",
    "Sales": "total_sales",
    "24h Sales": "total_24Hours_sales",
    "Revenue": "total_revenue",
    "24h Rev": "total_24Hours_revenue",
    "AOV": "total_aov",
    "24h AOV": "total_24Hours_aov",
    "1h %": "total_one_percent",
    "24h %": "total_24Hours_percent",
    "7d %": "total_sevenDays_percent",
    // "Last 7 Day Sales": "total_sevenDays_sales",
    // "Last 7 Days": "total_last_seven_day_sales",
} as const;

export const firstRowData = [
    // { id: 1, name: 'State', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: false, align: 'flex-start'},
    { id: 2, name: 'Sales', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 3, name: '24h Sales', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 4, name: 'Revenue', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 5, name: '24h Rev', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 6, name: 'AOV', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 7, name: '24h AOV', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 8, name: '1h %', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 9, name: '24h %', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 10, name: '7d %', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    // { id: 11, name: 'Last 7 Day Sales', fontSize: "12px", width: "120px", padding: "8px 8px",minWidth: '95px',lineHeight: '18px', isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    // { id: 12, name: 'Last 7 Days', fontSize: "12px", width: "120px", padding: "8px 8px",minWidth: '95px',lineHeight: '18px', isTips: false, tips: "", hasSortField: false, align: 'flex-start' },
];

export const fieldsForCSV = {
    "State": "state",
    "Sales": "total_sales",
    "24h Sales": "total_24Hours_sales",
    "Revenue": "total_revenue",
    "24h Rev": "total_24Hours_revenue",
    "AOV": "total_aov",
    "24h AOV": "total_24Hours_aov",
    "1h %": "total_one_percent",
    "24h %": "total_24Hours_percent",
    "7d %": "total_sevenDays_percent",
    // "Last 7 Day Sales": "total_sevenDays_sales",
};

export const dateRangeList = [
    "Custom Range",
    "Today",
    "Yesterday",
    "Last 7 days",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz() },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().subtract(7, "d"), endDate: dayjs.tz() },
    4: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz() },
    5: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz() },
    6: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz() },
    7: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().endOf('w').subtract(1, "w") },
    8: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    9: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
      1: { startDate: dayjs.tz().startOf('day').tz(timezone), endDate: dayjs.tz().tz(timezone) },
      2: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().endOf('day').subtract(1, "day").tz(timezone) },
      3: { startDate: dayjs.tz().subtract(7, "d").tz(timezone), endDate: dayjs.tz().tz(timezone) },
      4: { startDate: dayjs.tz().startOf('week').tz(timezone), endDate: dayjs.tz().tz(timezone) }, 
      5: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().tz(timezone) },
      6: { startDate: dayjs.tz().startOf('year').tz(timezone), endDate: dayjs.tz().tz(timezone) },
      7: { startDate: dayjs.tz().startOf('week').subtract(1, "week").tz(timezone), endDate: dayjs.tz().endOf('week').subtract(1, "week").tz(timezone) },
      8: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "second").tz(timezone) },
      9: { startDate: dayjs.tz().startOf('year').subtract(1, "year").tz(timezone), endDate: dayjs.tz().startOf('year').subtract(1, "second").tz(timezone) }
    };
  };