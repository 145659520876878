import * as React from "react"
import { SVGProps } from "react"
function CopyIcon (props: SVGProps<SVGSVGElement>) {
    const {fill} = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                stroke={fill ? fill : '#677C9F'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M4 14H3a2 2 0 01-2-2V3a2 2 0 012-2h9a2 2 0 012 2v1m-4 4h9a2 2 0 012 2v9a2 2 0 01-2 2h-9a2 2 0 01-2-2v-9a2 2 0 012-2z"
            ></path>
        </svg>
    );
}
export default CopyIcon