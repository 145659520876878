import React, { useContext } from 'react';
import PaginationButton from './UI/PaginationButton';
import ScrollToStartIcon from './assets/ScrollToStartIcon';
import ScrollToPreviousIcon from './assets/ScrollToPreviousIcon';
import ScrollToNextIcon from './assets/ScrollToNextIcon';
import ScrollToEndIcon from './assets/ScrollToEndIcon';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import PaginationSelect from './UI/PaginationSelect';
import PaginationNumberPageSelect from './UI/PaginationNumberPageSelect';

interface ServerPaginationComponentProps {
  firstPage: () => void;
  previousPage: () => void;
  getCanPreviousPage: () => boolean;
  getCanNextPage: () => boolean;
  nextPage: () => void;
  lastPage: () => void;
  getPageCount: () => number;
  pageIndex: number;
  setPageIndex: (page: number) => void;
  setPageSize: (size: number) => void;
  pageSize: number;
  getRowCount: () => number;
  getLastPage: () => number
}

const ServerPaginationComponent = ({
  getCanPreviousPage,
  getCanNextPage,
  getPageCount,
  pageIndex,
  setPageIndex,
  setPageSize,
  pageSize,
  getRowCount,
  getLastPage,
}: ServerPaginationComponentProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  const rowCount = getRowCount();
  const startRow = (pageIndex - 1) * pageSize + 1;
  const endRow = Math.min((pageIndex) * pageSize, rowCount);

  return (
    <div className="flex flex-wrap items-center gap-2 justify-between p-4">
      <div className="flex gap-2 items-center">
        <span style={{ color: themedColors.content }} className="text-sm">
          Rows per page
        </span>
        <PaginationSelect
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        />
        <p className="text-sm text-[#677C9F]">
          {startRow}-{endRow} of {rowCount.toLocaleString()}
        </p>
      </div>

      <div className="flex items-center gap-8">
        <div className="flex items-center gap-2">
          <p style={{ color: themedColors.content }} className="text-sm">
            Page
          </p>
          <PaginationNumberPageSelect
            value={pageIndex}
            onChange={(e) => setPageIndex(Number(e.target.value) - 1)}
            min={1}
            max={getPageCount()}
          />
          <span
            className="flex items-center gap-1 text-sm"
            style={{ color: themedColors.content }}
          >
            of {getPageCount().toLocaleString()}
          </span>
        </div>

        <div className="flex items-center gap-4">
          <PaginationButton
            onClick={() => setPageIndex(0)}
            disabled={!getCanPreviousPage()}
            icon={<ScrollToStartIcon disabled={!getCanPreviousPage()} />}
            className="p-2"
          />
          <PaginationButton
            onClick={() => setPageIndex((pageIndex - 2))}
            disabled={!getCanPreviousPage()}
            icon={<ScrollToPreviousIcon disabled={!getCanPreviousPage()} />}
            className="p-2"
          />
          <PaginationButton
            onClick={() => setPageIndex((pageIndex))}
            disabled={!getCanNextPage()}
            icon={<ScrollToNextIcon disabled={!getCanNextPage()} />}
            className="p-2"
          />
          <PaginationButton
            onClick={() => setPageIndex(getLastPage() - 1)}
            disabled={!getCanNextPage()}
            icon={<ScrollToEndIcon disabled={!getCanNextPage()} />}
            className="p-2"
          />
        </div>
      </div>
    </div>
  );
};

export default ServerPaginationComponent;