import React, { PropsWithChildren, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';

interface InlineWrapperWithMarginProps {
  title: string;
}

const FilterBarChartSceleton = ({ title }: InlineWrapperWithMarginProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  function InlineWrapperWithMargin({ children }: PropsWithChildren<unknown>) {
    return <span style={{ marginRight: '0.5rem' }}>{children}</span>;
  }

  return (
    <>
      <div
        className={
          'flex flex-col tablet-size:flex-row justify-between gap-4 tablet-size:gap-4 flex-wrap tablet-size:items-center '
        }
      >
        <div
          style={{ color: themedColors.content }}
          className={'text-xl font-extrabold truncate text-wrap'}
        >
          {title}
        </div>
        <div className='hidden tablet-size:flex gap-4 flex-row'>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={4}
            height={30}
            width={170}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
        </div>
        <div className='flex tablet-size:hidden gap-4 flex-row flex-nowrap'>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={2}
            height={30}
            width={120}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
        </div>
      </div>

      <div className='flex flex-row justify-between flex-wrap gap-4 mt-10'>
        <div className='hidden tablet-size:flex gap-4 flex-row'>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={5}
            height={30}
            width={120}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
        </div>
        <div className='hidden tablet-size:flex gap-4 flex-row'>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={3}
            height={30}
            width={70}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
        </div>

        <div className='flex tablet-size:hidden gap-4 flex-row'>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={1}
            height={30}
            width={70}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
        </div>
        <div className='flex tablet-size:hidden gap-4 flex-row'>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={3}
            height={30}
            width={70}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
        </div>
      </div>
    </>
  );
};

export default FilterBarChartSceleton;
