export const mockDataConfig = {
    "current_page": 1,
    "data": [
      {
        "id": 62,
        "group_sequence": "{\"date_range\":0,\"id_name\":[\"Geo\"],\"offer_name\":[]}",
        "report_name": "Dashboard Overview",
        "type": "default",
        "created_at": "2024-02-11T18:36:47.000000Z",
        "updated_at": "2024-02-23T13:25:41.000000Z"
      }
    ],
    "first_page_url": "https://staging-api.odinforgebi.com/api/report/config/search?page=1",
    "from": 1,
    "last_page": 1,
    "last_page_url": "https://staging-api.odinforgebi.com/api/report/config/search?page=1",
    "links": [
      {
        "url": null,
        "label": "&laquo; Previous",
        "active": false
      },
      {
        "url": "https://staging-api.odinforgebi.com/api/report/config/search?page=1",
        "label": "1",
        "active": true
      },
      {
        "url": null,
        "label": "Next &raquo;",
        "active": false
      }
    ],
    "next_page_url": null,
    "path": "https://staging-api.odinforgebi.com/api/report/config/search",
    "per_page": 10,
    "prev_page_url": null,
    "to": 1,
    "total": 1
}

export const dashboardOverviewFilterData = {
    "id_name": [
        {
            "id": 1,
            "name": "Geo"
        },
        {
            "id": 2,
            "name": "Passval1"
        },
    ],
    "offer": [
        {
            "id": 1,
            "name": "Keto 1"
        },
        {
            "id": 2,
            "name": "Keto 2"
        },
        {
            "id": 3,
            "name": "Keto 3"
        },
        {
            "id": 4,
            "name": "Keto 4"
        },
        {
            "id": 5,
            "name": "Keto 5"
        
        }
    ],
}

export const mockDashboardData = {
    "conversionRate": {
      "amount": "40.04",
      "description": "861 - Partials 575 - Sales",
      "subValue": '3.14%',
      "subDescription": "All Orders (Declines Ect.)"
    },
    "averageOrderValue": {
      "amount": "230",
      "description": "Average Order Value",
      "subDescription": "All Completed Orders"
    },
    "creditCardOverview": [
      {
        "type": "0",
        "count": 7,
        "ratio": "1.22%"
      },
      {
        "type": "MasterCard",
        "count": 267,
        "ratio": "46.43%"
      },
      {
        "type": "Visa",
        "count": 274,
        "ratio": "47.65%"
      }
    ],
    "creditCardDecline": [
      {
        "type": "0",
        "count": 5,
        "ratio": "1.30%"
      },
      {
        "type": "AMEX",
        "count": 13,
        "ratio": "3.37%"
      },
      {
        "type": "DISCOVER",
        "count": 17,
        "ratio": "4.40%"
      },
      {
        "type": "MASTERCARD",
        "count": 79,
        "ratio": "20.47%"
      },
      {
        "type": "VISA",
        "count": 272,
        "ratio": "70.47%"
      },
      {
        "type": "VISA",
        "count": 272,
        "ratio": "70.47%"
      },
      {
        "type": "VISA",
        "count": 272,
        "ratio": "70.47%"
      },
      {
        "type": "VISA",
        "count": 272,
        "ratio": "70.47%"
      },
      {
        "type": "VISA",
        "count": 272,
        "ratio": "70.47%"
      },
      {
        "type": "VISA",
        "count": 272,
        "ratio": "70.47%"
      },
      {
        "type": "VISA",
        "count": 272,
        "ratio": "70.47%"
      },
    ],
    "productBreakdown": [
      {
        "type": "Product",
        "name": "Keto-2 Bottles",
        "count": 86,
      },
      {
        "type": "Product",
        "name": "Keto-4 Bottles",
        "count": 82,
      },
      {
        "type": "Product",
        "name": "Keto-6 Bottles",
        "count": 380,
      },
      {
        "type": "Product",
        "name": "Keto-6 Bottles",
        "count": 380,
      }
    ],
    "miscStatsOverview": {
      "ordersWithSameIP": {
        "name": "Orders with same IP",
        "value": "3 orders with the same IP",
      },
      "zipMatch": {
        "name": "Zip Match",
        "value": "84.80%",
      },
      "areaCodeMatch": {
        "name": "Area Code Match",
        "value": "84.80%",
      },
      "voidCancelledOrders": {
        "name": "Void/Cancelled Orders",
        "value": "3.14%",
        "amount": 16
      },
      
      "partialRefundPercent": {
        "name": "PARTIAL Refund Percent",
        "value": "13.39%",
        "amount": 7
      },
      "fullRefundPercent": {
        "name": "FULL Refund Percent",
        "value": "10.11%",
        "amount": 5
      },
      "numberPastedEntries": {
        "name": "Number of Pasted Entries",
        "value": "10%",
        "amount": 35
      }
    }
  }
  