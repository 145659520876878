import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';

interface ReportsBtnProps {
    isReportsActive?: boolean;
    dropdownOpen?: boolean;
    toggleDropdown: (e: React.MouseEvent<HTMLButtonElement>) => void;
    buttonClass: (arg: string) => string;
    handleButtonClick: (arg: string) => void;
}

const ReportsBtn = ({ isReportsActive, dropdownOpen, toggleDropdown, buttonClass, handleButtonClick }: ReportsBtnProps ) => {
    const { theme, themedColors } = useContext(ThemeContext)!;
    const [isHovered, setIsHovered] = useState(false);

    let color = theme === "light" ? themedColors.content : themedColors.content;

    if (isHovered) {
      color = "#0079FF";
    }

  return (
    <div onClick={() => handleButtonClick('reports')}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    >
        <button 
            className={`
                ${isReportsActive && '`sidebar-button rounded-tr-lg rounded-br-lg border-l-2 !bg-blue-200 dark:!bg-odin-dark-blue-medium_2 border-[#0079FF] flex items-center p-2 pl-4 text-odin-light-content dark:text-odin-dark-content`'}
                flex justify-between items-center gap-4 p-2 pl-4 w-[90%] tablet-size:w-full
                ${isReportsActive ? 'border-l-2 border-[#0079FF]' : ''}
            `}
            onClick={toggleDropdown}
        >  
            <div className='flex gap-2 items-center'>
                {
                    isReportsActive ? (
                        <>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.69141 13.2188C3.69141 12.9274 3.45542 12.6914 3.16406 12.6914H0.527344C0.235986 12.6914 0 12.9274 0 13.2188V17.4727C0 17.764 0.235986 18 0.527344 18H3.16406C3.45542 18 3.69141 17.764 3.69141 17.4727V13.2188Z" fill="#0079FF"/>
                            <path d="M7.94531 9.52734H5.30859C5.01724 9.52734 4.78125 9.76333 4.78125 10.0547V17.4727C4.78125 17.764 5.01724 18 5.30859 18H7.94531C8.23667 18 8.47266 17.764 8.47266 17.4727V10.0547C8.47266 9.76333 8.23667 9.52734 7.94531 9.52734Z" fill="#0079FF"/>
                            <path d="M12.6914 8.47266H10.0547C9.76333 8.47266 9.52734 8.70864 9.52734 9V17.4727C9.52734 17.764 9.76333 18 10.0547 18H12.6914C12.9828 18 13.2188 17.764 13.2188 17.4727V9C13.2188 8.70864 12.9828 8.47266 12.6914 8.47266Z" fill="#0079FF"/>
                            <path d="M17.4727 4.25391H14.8359C14.5446 4.25391 14.3086 4.48989 14.3086 4.78125V17.4727C14.3086 17.764 14.5446 18 14.8359 18H17.4727C17.764 18 18 17.764 18 17.4727V4.78125C18 4.48989 17.764 4.25391 17.4727 4.25391Z" fill="#0079FF"/>
                            <path d="M1.88086 11.6016C2.75317 11.6016 3.46289 10.8918 3.46289 10.0195C3.46289 9.87133 3.43586 9.73081 3.39763 9.5948L5.45229 8.14439C5.71025 8.32753 6.02358 8.4375 6.36328 8.4375C7.16364 8.4375 7.81996 7.83809 7.924 7.06575L10.0279 6.62278C10.3064 7.07673 10.8027 7.38281 11.373 7.38281C12.2454 7.38281 12.9551 6.6731 12.9551 5.80078C12.9551 5.58984 12.9118 5.38923 12.8366 5.2051L15.3605 2.96169C15.5869 3.08661 15.8428 3.16406 16.1191 3.16406C16.9915 3.16406 17.7012 2.45435 17.7012 1.58203C17.7012 0.709717 16.9915 0 16.1191 0C15.2468 0 14.5371 0.709717 14.5371 1.58203C14.5371 1.79297 14.5804 1.99358 14.6555 2.17771L12.1317 4.42112C11.9053 4.2962 11.6494 4.21875 11.373 4.21875C10.5727 4.21875 9.91637 4.81816 9.81233 5.5905L7.70845 6.03347C7.42994 5.57952 6.93358 5.27344 6.36328 5.27344C5.49097 5.27344 4.78125 5.98315 4.78125 6.85547C4.78125 7.00367 4.80828 7.14419 4.84651 7.2802L2.79185 8.73062C2.53389 8.54747 2.22056 8.4375 1.88086 8.4375C1.00854 8.4375 0.298828 9.14722 0.298828 10.0195C0.298828 10.8918 1.00854 11.6016 1.88086 11.6016Z" fill="#0079FF"/>
                            </svg>
                        </>
                    ) : (
                        <>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.16406 12.6914H0.527344C0.236069 12.6914 0 12.9275 0 13.2188V17.4727C0 17.7639 0.236069 18 0.527344 18H3.16406C3.45534 18 3.69141 17.7639 3.69141 17.4727V13.2188C3.69141 12.9275 3.45534 12.6914 3.16406 12.6914ZM2.63672 16.9453H1.05469V13.7461H2.63672V16.9453Z" fill={color}/>
                            <path d="M7.94531 9.52734H5.30859C5.01732 9.52734 4.78125 9.76341 4.78125 10.0547V17.4727C4.78125 17.7639 5.01732 18 5.30859 18H7.94531C8.23659 18 8.47266 17.7639 8.47266 17.4727V10.0547C8.47266 9.76341 8.23659 9.52734 7.94531 9.52734ZM7.41797 16.9453H5.83594V10.582H7.41797V16.9453Z" fill={color}/>
                            <path d="M12.6914 8.47266H10.0547C9.76341 8.47266 9.52734 8.70872 9.52734 9V17.4727C9.52734 17.7639 9.76341 18 10.0547 18H12.6914C12.9827 18 13.2188 17.7639 13.2188 17.4727V9C13.2188 8.70872 12.9827 8.47266 12.6914 8.47266ZM12.1641 16.9453H10.582V9.52734H12.1641V16.9453Z" fill={color}/>
                            <path d="M17.4727 4.25391H14.8359C14.5447 4.25391 14.3086 4.48997 14.3086 4.78125V17.4727C14.3086 17.7639 14.5447 18 14.8359 18H17.4727C17.7639 18 18 17.7639 18 17.4727V4.78125C18 4.48997 17.7639 4.25391 17.4727 4.25391ZM16.9453 16.9453H15.3633V5.30859H16.9453V16.9453Z" fill={color}/>
                            <path d="M1.8457 11.6367C2.71802 11.6367 3.42773 10.927 3.42773 10.0547C3.42773 9.90692 3.40686 9.76396 3.36882 9.62814L5.44743 8.17946C5.70616 8.36375 6.02216 8.47266 6.36328 8.47266C7.16473 8.47266 7.82858 7.87335 7.93117 7.0995L10.0227 6.65909C10.3008 7.11392 10.802 7.41797 11.373 7.41797C12.2454 7.41797 12.9551 6.70825 12.9551 5.83594C12.9551 5.62486 12.9132 5.4234 12.8378 5.23911L15.3905 2.96686C15.6171 3.09238 15.8774 3.16406 16.1543 3.16406C17.0266 3.16406 17.7363 2.45435 17.7363 1.58203C17.7363 0.709717 17.0266 0 16.1543 0C15.282 0 14.5723 0.709717 14.5723 1.58203C14.5723 1.79311 14.6142 1.99457 14.6895 2.17886L12.1369 4.45111C11.9103 4.32559 11.6499 4.25391 11.373 4.25391C10.5715 4.25391 9.90775 4.85321 9.80516 5.62706L7.71364 6.06747C7.43555 5.61264 6.9343 5.30859 6.36328 5.30859C5.49097 5.30859 4.78125 6.01831 4.78125 6.89062C4.78125 7.03839 4.80212 7.18135 4.84016 7.31717L2.76155 8.76585C2.50282 8.58156 2.18683 8.47266 1.8457 8.47266C0.973389 8.47266 0.263672 9.18237 0.263672 10.0547C0.263672 10.927 0.973389 11.6367 1.8457 11.6367ZM16.1543 1.05469C16.445 1.05469 16.6816 1.29131 16.6816 1.58203C16.6816 1.87276 16.445 2.10938 16.1543 2.10938C15.8636 2.10938 15.627 1.87276 15.627 1.58203C15.627 1.29131 15.8636 1.05469 16.1543 1.05469ZM11.373 5.30859C11.6638 5.30859 11.9004 5.54521 11.9004 5.83594C11.9004 6.12666 11.6638 6.36328 11.373 6.36328C11.0823 6.36328 10.8457 6.12666 10.8457 5.83594C10.8457 5.54521 11.0823 5.30859 11.373 5.30859ZM6.36328 6.36328C6.65401 6.36328 6.89062 6.5999 6.89062 6.89062C6.89062 7.18135 6.65401 7.41797 6.36328 7.41797C6.07256 7.41797 5.83594 7.18135 5.83594 6.89062C5.83594 6.5999 6.07256 6.36328 6.36328 6.36328ZM1.8457 9.52734C2.13643 9.52734 2.37305 9.76396 2.37305 10.0547C2.37305 10.3454 2.13643 10.582 1.8457 10.582C1.55498 10.582 1.31836 10.3454 1.31836 10.0547C1.31836 9.76396 1.55498 9.52734 1.8457 9.52734Z" fill={color}/>
                            </svg>
                        </>
                    )
                }
                <span className={`flex items-center ml-1 w-[33%] text-[${color}] text-sm ${isReportsActive ? 'font-semibold' : ''} `}
                style={{color: !isHovered ? themedColors.content : "#0079FF" }}
                >
                    Reports
                </span>
            </div>
            <div>
                <FontAwesomeIcon
                    icon={dropdownOpen ? faChevronDown : faChevronRight}
                    style={{color: themedColors.content}}
                />
            </div>
        </button>
    </div>

  )
}

export default ReportsBtn