import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

const LightModeIcon = () => {
  const { theme } = useContext(ThemeContext)!;

  const color = theme === "light" ? "#0079FF" : "#677C9F";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="relative z-[3] left-[-2px]"
    >
      <path
        d="M10.0002 0.833008V2.49967M10.0002 17.4997V19.1663M3.51683 3.51634L4.70016 4.69967M15.3002 15.2997L16.4835 16.483M0.833496 9.99967H2.50016M17.5002 9.99967H19.1668M3.51683 16.483L4.70016 15.2997M15.3002 4.69967L16.4835 3.51634M14.1668 9.99967C14.1668 12.3009 12.3013 14.1663 10.0002 14.1663C7.69898 14.1663 5.8335 12.3009 5.8335 9.99967C5.8335 7.69849 7.69898 5.83301 10.0002 5.83301C12.3013 5.83301 14.1668 7.69849 14.1668 9.99967Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LightModeIcon;
