import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext/UserContext";
import OdinForgeService from "../odinForgeService/OdinForgeService";
import Utils from "../odinForgeService/Utils";

export const useUserProfile = () => {
    const userContext = useContext(UserContext)!;
    const { setPermissions, setEmail, setAvatar, setName, setLoading, email, name, setTenantId, setIsSuperAdmin, setIsAdmin } = userContext;
    const [profileLoaded, setProfileLoaded] = useState(false);
    const location = useLocation();
  
    useEffect(() => {
      const fetchProfileInfo = async () => {
        if (!location.pathname.startsWith("/dashboard")) {
          setLoading(false);
          return;
        }
  
        if (profileLoaded || (email && name)) {
          setLoading(false);
          return;
        }
  
        try {
          setLoading(true);
          const response = await OdinForgeService.instance().getProfileInfo();
          setEmail(response.data.email);
          setPermissions(response.data.reports);
          setAvatar(response.data.avatar ?? null);
          setName(response.data.name);
          setIsSuperAdmin(response.data.isSuperAdmin ?? false);
          setProfileLoaded(true);
          setTenantId(response.data.tenant ?? null);
          setIsAdmin(response.data.isAdmin ?? false);
        } catch (error) {
          Utils.instance().onFailure("There was a problem fetching profile info.");
        } finally {
          setLoading(false);
        }
      };
  
      fetchProfileInfo();
    }, [location.pathname, profileLoaded, email, name, setEmail, setPermissions, setAvatar, setName, setIsSuperAdmin, setLoading]);
  
    return userContext;
  };
