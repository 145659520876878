import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import { ThemeContext } from "../../../../../context/ThemeContext/ThemeContext";
import CustomToggleButtonGroup from "../../../../UI/CustomToggleButtonGroup/CustomToggleButtonGroup";
import DefaultButton from "../../../../UI/buttons/DefaultButton";
import FilterDropdown from "../../../../UI/filterDropdown/FilterDropdown";
import { SelectChangeEvent } from "@mui/material";

interface FullScreenChartMobileModalProps {
  filterConfig: {
    label: string;
    name: string;
    options: string[] | undefined;
    itemName: string[];
    handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  }[];
  onClose: () => void;
  enableFiltersApplyButton: boolean;
  getLineChartData: () => void;
  clearOptions: () => void;
  isDisableNotNeeded?: boolean;
  // FraudToggle props
  isFraudToggle?: boolean;
  selectedType?: string;
  handleChange?: (newValue: string) => void;
  toggleOptions?: { value: string; label: string }[];
}

const ConversionReportMobileModal = ({
  filterConfig,
  onClose,
  enableFiltersApplyButton,
  getLineChartData,
  clearOptions,
  isDisableNotNeeded = false,
  // FraudToggle props
  selectedType,
  handleChange,
  toggleOptions,
  isFraudToggle = false,
}: FullScreenChartMobileModalProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  const [selectedItems, setSelectedItems] = useState<{ [key: string]: { [subItemId: string]: boolean } }>({});

  useEffect(() => {
    const initialSelectedItems: { [key: string]: { [subItemId: string]: boolean } } = {};

    filterConfig.forEach((filter) => {
      initialSelectedItems[filter.name] = filter.itemName.reduce((acc, itemName) => {
        acc[itemName] = true;
        return acc;
      }, {} as { [subItemId: string]: boolean });
    });

    setSelectedItems(initialSelectedItems);
  }, [filterConfig]);

  const handleClear = () => {
    clearOptions();
    setSelectedItems({});
  };

  const handleApply = () => {
    Object.keys(selectedItems).forEach((key) => {
      const selectedOptions = Object.keys(selectedItems[key]).filter((subKey) => selectedItems[key][subKey]);
  
      const simulatedEvent: SelectChangeEvent<string[]> = {
        target: {
          value: selectedOptions,
          name: key,
        },
      } as SelectChangeEvent<string[]>;
  
      const filter = filterConfig.find((f) => f.name === key);
      if (filter) {
        filter.handleSelectChange(simulatedEvent);
      }
    });
   
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSelectionChange = (newSelectedItems: { [key: string]: { [subItemId: string]: boolean } }) => {
    setSelectedItems(newSelectedItems);
  };

  return (
    <>
      <div className="fixed inset-0 z-50 bg-white overflow-y-auto" style={{backgroundColor: themedColors.background_1}}>
        <div className="relative min-h-screen flex flex-col justify-start items-center">
          <div className="flex justify-between w-full border-b border-borderColor-semi-light-blue dark:border-odin-dark-blue-dark_2 p-4">
            <h2 style={{color: themedColors.content}} className="text-xl font-bold text-[#1A1F36]">Filters</h2>
            <button className="p-2 text-black" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>

          <div className="tablet-size:hidden p-4 w-full">
            {
              selectedType && handleChange && toggleOptions && (
                <CustomToggleButtonGroup
                  selectedValue={selectedType}
                  onChange={handleChange}
                  options={toggleOptions}
                />
              )
            }
          </div>

          <div className={`flex flex-col w-full justify-between `}
              style={{
                minHeight: `calc(100vh - ${isFraudToggle ? 140 : 100}px)`,
              }}
          >
            <div className="flex flex-col gap-6 p-4">
              {
                filterConfig.length === 0 ? (
                  <p className="text-center text-gray-500">No filters available.</p>
                ) : (
                  <FilterDropdown
                    menuItems={filterConfig.map((filter) => ({
                      id: filter.name,
                      label: filter.label,
                      subItems: filter.options?.map((option) => ({
                        id: option,
                        label: option,
                      })),
                    }))}
                    selectedItems={selectedItems}
                    onSelectionChange={handleSelectionChange}
                  />
                )
              }
            </div>

            <div className="p-4 flex justify-between pt-4 w-full gap-2"  style={{borderTop: '1px solid', borderColor: themedColors.border}}>
              <DefaultButton disabled={false} type='button' label='Clear Filter' variant='outlined'  className="w-full h-9" onClick={handleClear} />
              <div  className="w-full h-9">
                  <DefaultButton disabled={isDisableNotNeeded && !enableFiltersApplyButton} type='button' label='Apply' className="w-full h-9" onClick={handleApply} />  
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ConversionReportMobileModal;
