import React, { useContext } from 'react';
import { UserContext } from '../../../context/UserContext/UserContext';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

const DateTimeTimezone = () => {
  const userContext = useContext(UserContext)!;
  const { timezone } = userContext;
  const { theme, themedColors } = useContext(ThemeContext)!;

  const userTime = dayjs().tz(timezone).format('DD-MMM-YY HH:mm');

  const timezoneCity = timezone?.split('/')?.pop()!.replace('_', ' ');

  return (
    <div
        className='text-xs'
        style={{
            color: themedColors.content
        }}
    >
      <p>{`${userTime}, ${timezoneCity}`}</p>
    </div>
  );
}

export default DateTimeTimezone;
