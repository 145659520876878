import {VarianceMockData, VarianceMetric, VarianceStat} from "../VarianceReport";

function generateRandomMetric(previousBase: number, varianceLimit: number = 0.5): VarianceMetric {
    const variance = Math.random() * varianceLimit * (Math.random() > 0.5 ? 1 : -1);
    const current = previousBase + previousBase * variance;
    return {
        previous: previousBase,
        current: Math.round(current),
        variance: +(current / previousBase - 1).toFixed(2),
    };
}

export function generateMockVarianceData(clientNames: string[], hours: number[]): VarianceMockData {
    const data: VarianceMockData = {
        data: {
            partner: [],
        },
    };

    clientNames.forEach((name) => {
        const stats: VarianceStat[] = hours.map((hour) => {
            return {
                hour,
                clicks: generateRandomMetric(550),
                conversions: generateRandomMetric(231),
                events: generateRandomMetric(165),
                revenue: generateRandomMetric(15000, 1),
                ep_something: generateRandomMetric(12),
            };
        });

        data.data.partner.push({
            name,
            stats,
        });
    });

    return data;
}

