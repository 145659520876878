// @ts-nocheck

function determineNestingLevels(data, level = 1, parentKey = 'currency', namesEnum = {}) {
    let result = {};

    if (typeof data !== 'object' || data === null) {
        return result;
    }

    const addToResult = (key, name, id) => {
        if (!result[key]) {
            result[key] = { level, count: 0, names: [], expanded: false };
        }
        result[key].count += 1;

        if (name) {
            const existing = result[key].names.find(item => item.name === name && item.id === id);
            if (!existing) {
                result[key].names.push({ name, id, expanded: false });
            }
        }
    };

    if (Array.isArray(data)) {
        data.forEach(item => {
            let nestedResult = determineNestingLevels(item, level, parentKey, namesEnum);
            for (let key in nestedResult) {
                if (result[key]) {
                    result[key].count += nestedResult[key].count;
                    nestedResult[key].names.forEach(nestedName => {
                        const existing = result[key].names.find(item => item.name === nestedName.name && item.id === nestedName.id);
                        if (!existing) {
                            result[key].names.push(nestedName);
                        }
                    });
                } else {
                    result[key] = nestedResult[key];
                }
            }
        });
    } else {
        for (let key in data) {
            if (data.hasOwnProperty(key) && Object.values(namesEnum).includes(key)) {
                const value = data[key];
                if (typeof value === 'object' || Array.isArray(value)) {
                    let nestedResult = determineNestingLevels(value, level + 1, key, namesEnum);
                    for (let nestedKey in nestedResult) {
                        if (result[nestedKey]) {
                            result[nestedKey].count += nestedResult[nestedKey].count;
                            nestedResult[nestedKey].names.forEach(nestedName => {
                                const existing = result[nestedKey].names.find(item => item.name === nestedName.name && item.id === nestedName.id);
                                if (!existing) {
                                    result[nestedKey].names.push(nestedName);
                                }
                            });
                        } else {
                            result[nestedKey] = nestedResult[nestedKey];
                        }
                    }
                } else if (key === parentKey) {
                    addToResult(key, data.name, data.id);
                }
            }
        }
    }

    if (Object.values(namesEnum).includes(parentKey)) {
        addToResult(parentKey, data.name, data.id);
    }

    return result;
}

function reorderLevels(result) {
    const entries = Object.entries(result);
    entries.sort((a, b) => a[1].level - b[1].level);

    let orderedResult = {};
    let currentLevel = 1;

    entries.forEach(([key, value]) => {
        orderedResult[key] = { ...value, level: currentLevel };
        currentLevel++;
    });
    return orderedResult;
}

function updateExpandedState(nestedLevels, collapseLevel) {
    let updatedLevels = { ...nestedLevels };

    for (let key in updatedLevels) {
        let levelInfo = updatedLevels[key];
        if (levelInfo.level <= collapseLevel) {
            levelInfo.expanded = true;
            levelInfo.names.forEach(nameInfo => {
                if (key === 'currency') {
                    nameInfo.expanded = true;
                    return
                }
                if (levelInfo.level === collapseLevel) {
                    nameInfo.expanded = false;
                    return
                }
                nameInfo.expanded = true;
            });
        } else {
            levelInfo.expanded = false;
            levelInfo.names.forEach(nameInfo => {
                if (key === 'currency') nameInfo.expanded = true;
                nameInfo.expanded = false;
            });
        }
    }

    return updatedLevels;
}

function handleExpand(id: string, level: number, setNestedLevels: any) {
    setNestedLevels((prevLevels: any) => {
        const updatedLevels = { ...prevLevels };

        for (let key in updatedLevels) {
            if (updatedLevels[key].level === level) {
                const levelInfo = updatedLevels[key];
                const nameInfo = levelInfo.names.find((name: any) => name.id === id);
                if (nameInfo) {
                    nameInfo.expanded = !nameInfo.expanded;
                }
                break;
            }
        }
        return updatedLevels;
    });
}

function handleMainExpand(parentKey: string, setNestedLevels) {
    setNestedLevels((prevLevels: any) => {
        const updatedLevels = { ...prevLevels };
        const levelInfo = updatedLevels[parentKey];
    
        if (levelInfo) {
            levelInfo.expanded = !levelInfo.expanded;
            for (let name in levelInfo.names) {
                levelInfo.names[name].expanded = levelInfo.expanded;
            }
        }
        return updatedLevels;
    });
}

export { determineNestingLevels, reorderLevels, updateExpandedState, handleExpand, handleMainExpand };

// function determineNestingLevels(data, level = 1, parentKey = 'currency') {
//     let result = {};

//     if (typeof data !== 'object' || data === null) {
//         return result;
//     }

//     const addToResult = (key, name, id) => {
//         if (!result[key]) {
//             result[key] = { level, count: 0, names: {}, expanded: false };
//         }
//         result[key].count += 1;
//         if (name && !(name in result[key].names)) {
//             result[key].names[name] = { expanded: false };
//             if (id) {
//                 result[key].names[name].id = id;
//             }
//         }
//     };

//     if (Array.isArray(data)) {
//         data.forEach(item => {
//             let nestedResult = determineNestingLevels(item, level, parentKey);
//             for (let key in nestedResult) {
//                 if (result[key]) {
//                     result[key].count += nestedResult[key].count;
//                     Object.assign(result[key].names, nestedResult[key].names);
//                 } else {
//                     result[key] = nestedResult[key];
//                 }
//             }
//         });
//     } else {
//         for (let key in data) {
//             if (data.hasOwnProperty(key)) {
//                 const value = data[key];
//                 if (typeof value === 'object' || Array.isArray(value)) {
//                     let nestedResult = determineNestingLevels(value, level + 1, key);
//                     for (let nestedKey in nestedResult) {
//                         if (result[nestedKey]) {
//                             result[nestedKey].count += nestedResult[nestedKey].count;
//                             Object.assign(result[nestedKey].names, nestedResult[nestedKey].names);
//                         } else {
//                             result[nestedKey] = nestedResult[nestedKey];
//                         }
//                     }
//                 } else if (key === parentKey) {
//                     addToResult(key, data.name, data.id);
//                 }
//             }
//         }
//     }

//     // Добавляем parentKey только в корневом вызове
//     if (parentKey !== 'currency') {
//         addToResult(parentKey, data.name, data.id);
//     }

//     return result;
// }


// function determineNestingLevels(data, level = 1) {
//     let result = {};

//     const processItems = (items, currentLevel) => {
//         let levelData = { level: currentLevel, count: items.length, expanded: false, children: {} };

//         items.forEach(item => {
//             let currentKey = item.name;

//             if (!levelData.children[currentKey]) {
//                 levelData.children[currentKey] = { expanded: false, children: {} };
//             }

//             for (let key in item) {
//                 if (Array.isArray(item[key])) {
//                     levelData.children[currentKey].children[key] = processItems(item[key], currentLevel + 1);
//                 }
//             }
//         });

//         return levelData;
//     };

//     result['currency'] = processItems(data.currency, level);
//     return result;
// }

// function determineNestingLevels(data, level = 1) {
//     let result = {};

//     const processItems = (items, currentLevel) => {
//         let levelData = { level: currentLevel, count: items.length, expanded: false, children: {} };

//         items.forEach(item => {
//             let currentKey = item.name;

//             if (!levelData.children[currentKey]) {
//                 levelData.children[currentKey] = { expanded: false, children: {} };
//                 if (item.id) {
//                     levelData.children[currentKey].id = item.id;
//                 }
//             }

//             for (let key in item) {
//                 if (Array.isArray(item[key])) {
//                     levelData.children[currentKey].children[key] = processItems(item[key], currentLevel + 1);
//                 }
//             }
//         });

//         return levelData;
//     };

//     result['currency'] = processItems(data.currency, level);
//     return result;
// }