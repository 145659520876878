import React, { useContext } from "react";
import './TableLoading.css';
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";

const TableLoading = () => {
    const { theme } = useContext(ThemeContext)!;

    return (
        <div className="tableLoading-overlay">
            <div className="tableLoading-popup">
                <div className="tableLoadingBar-cover bg-odin-light-gray-light dark:bg-odin-dark-gray-dark_bluish_2">
                    <div className="tableLoading-bar "></div>
                </div>
            </div>
        </div>
    );
};

export default TableLoading;