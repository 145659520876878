import React, { useContext } from "react";
import TableDateTimePicker from "../../UI/DatePickerComponent/TableDateTimePicker";
import TableSelectDropdown from "../../UI/selection/TableSelectDropdown";
import ExpandButtons from "../../UI/expandButtons/ExpandButtons";
import CustomActionButtons from "../../UI/actionButtons/actionButtons";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import EditTableDialog from "../../pages/reports/transactionPerformanceReport/components/EditTableDialog";
import { EditTable } from "../../../assets/icons/EditTable";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import ReportName from "../../../odinForgeService/Enums/ReportName";
import SearchByRow from "../../molecules/SearchByRow/SearchByRow";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarProps<T1, T2, T3> {
  handleBlur: () => void;
  startDate: string | Dayjs;
  endDate: string | Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  dateRange: number;
  setDateRange: (newValue: number) => void;
  groupings: string[];
  setGroupings: (newValue: string[]) => void;
  names: string[];
  collapse: string;
  selectedGroupings: number;
  resultArrays: number[][];
  resultArraysCopy: number[][];
  setTempCounter?(): number[];
  copyButton: boolean;
  freezeButton: boolean;
  buttonBackgroundColor: string;
  iconFillColor: string;
  showDelete: boolean;
  crmType: string;
  setCrmType: (newValue: string) => void;
  clonedReportId: string | number | null | undefined;
  setDeletePopup: (newValue: boolean) => void;
  passId: (id: string | number | null | undefined) => void;
  deletePopup: boolean;
  setButtonBackgroundColor: (newValue: string) => void;
  setFreezeButton: (newValue: boolean) => void;
  setCopyButton: (newValue: boolean) => void;
  setIconFillColor: (newValue: string) => void;
  clonedData: T1;
  generateConfigRequestBody: () => {
    filters: {
        group_sequence: string[];
        expand_level: string;
        date_range: number;
        start_date: string | null;
        end_date: string | null;
    };
    report_name: ReportName;
  };
  generateClonedData(reportId: number): void;
  setCollapse: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  // Additional props for TransactionReport
  isEdit?: boolean;
  openEditTableDialog?: boolean;
  setOpenEditTableDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  columnList?: number[];
  setColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  actualColumnList?: number[];
  setActualColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  dialogApplyButton?: boolean;
  setDialogApplyButton?: React.Dispatch<React.SetStateAction<boolean>>;
  maxSelections?: number;
  ///////////////////////
  startNumber?: number;
  setIsCollapsedByExandButton?: (value: boolean) => void;
  isToggle?: boolean;
  isAdditionalCurrencyField?: boolean;
  transformedGroupings?: string[];
  setIsManualExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  setIsAutoExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  // Additional props for CSV btn
  data: T2;
  fieldsForCSV?: { [key: string]: string };
  disabledCSV?: boolean;
  namesEnum?: { [key: string]: string };
  isCSV?: boolean;
  transformDataToCSVFormat?: (data: T3, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }) => {
    [key: string]: string | number;
  }[];
  disableFutureDates?: boolean;
  rangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
}
  dateRangeList?: string[];
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
  refreshKey?: number
  generateRangeConfigs?: (timezone: string) => {
    [key: string | number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
  }
  // SearchRowProps
  isSearch?: boolean;
  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
  handleSearch?: (isReset?: boolean) => void
}

const FilterBar = <T1,T2,T3,>({
  handleBlur,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  groupings,
  setGroupings,
  names,
  collapse,
  setCollapse,
  copyButton,
  freezeButton,
  buttonBackgroundColor,
  iconFillColor,
  showDelete,
  crmType,
  setCrmType,
  clonedReportId,
  setDeletePopup,
  passId,
  deletePopup,
  setButtonBackgroundColor,
  setFreezeButton,
  setCopyButton,
  setIconFillColor,
  clonedData,
  generateConfigRequestBody,
  generateClonedData,
  title,
  isAdditionalCurrencyField,
  // Additional props for TransactionReport
  isEdit = false,
  openEditTableDialog,
  setOpenEditTableDialog,
  columnList,
  setColumnList,
  actualColumnList,
  setActualColumnList,
  dialogApplyButton,
  setDialogApplyButton,
  maxSelections,
  isToggle,
  transformedGroupings,
  // Additional props for CSV btn
  data,
  fieldsForCSV,
  disabledCSV,
  namesEnum,
  isCSV,
  disableFutureDates = true,
  transformDataToCSVFormat,
  rangeConfigs,
  dateRangeList,
  setRefreshKey,
  refreshKey,
  generateRangeConfigs,
  // SearchRowProps
  isSearch = false,
  searchTerm,
  setSearchTerm,
  handleSearch,
}: FilterBarProps<T1,T2,T3>) => {
  const { themedColors } = useContext(ThemeContext)!;

  return (
    <>
      <div
        className={
          "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
        }
      >
        <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
          {title} 
        </div>
        <TableDateTimePicker
          handleBlur={handleBlur}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          disableFutureDates={disableFutureDates}
          customRangeConfigs={rangeConfigs}
          customDateRangeList={dateRangeList}
          reportName={title}
          customGenerateRangeConfigs={generateRangeConfigs}
        />
      </div>

      <div
        className={
          "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
        }
      >
        <div
          className={
            "flex flex-row items-end flex-wrap relative tablet-size:left-[-8px] gap-5"
          }
        >
          <TableSelectDropdown
            handleBlur={handleBlur}
            groupings={groupings}
            setGroupings={setGroupings}
            names={names}
            maxSelections={maxSelections}
          />
          {
            data && Object.keys(data).length > 0 && (
              <div className="relative tablet-size:mt-0 top-[2px] tablet-size:top-0">
                <ExpandButtons
                  collapse={collapse}
                  setCollapse={setCollapse}
                  groupings={groupings}
                  isAdditionalCurrencyField={isAdditionalCurrencyField}
                  transformedGroupings={transformedGroupings}
                />
              </div>
            )
          }
        </div>
        <div className="flex flex-wrap gap-3 items-end lala">
          {isEdit && setOpenEditTableDialog && (
            <button
              onClick={() => {
                setOpenEditTableDialog(!openEditTableDialog);
              }}
              className={
                "hidden big-mobile-size:flex tablet-size:flex border-2 h-10 w-44 rounded-3xl copy justify-center items-center  hover:!shadow-btnShadow dark:hover:!bg-odin-dark-blue-custom-blue-hover transition-all"
              }
            >
              <EditTable />
              <span style={{ fontSize: 16, color: "#0079FF", paddingLeft: 10 }}>
                Edit the table
              </span>
            </button>
          )}
          <CustomActionButtons
            copyButton={copyButton}
            freezeButton={freezeButton}
            buttonBackgroundColor={buttonBackgroundColor}
            iconFillColor={iconFillColor}
            showDelete={showDelete}
            crmType={crmType}
            setCrmType={setCrmType}
            clonedReportId={clonedReportId}
            setDeletePopup={setDeletePopup}
            passId={passId}
            deletePopup={deletePopup}
            setButtonBackgroundColor={setButtonBackgroundColor}
            setFreezeButton={setFreezeButton}
            setCopyButton={setCopyButton}
            setIconFillColor={setIconFillColor}
            clonedData={clonedData}
            configRequestBody={generateConfigRequestBody()}
            generateClonedData={generateClonedData}
            isToggle={isToggle}
            data={data}
            fieldsForCSV={fieldsForCSV}
            reportName={title}
            startDate={startDate}
            endDate={endDate}
            namesEnum={namesEnum}
            disabledCSV={disabledCSV}
            isCSV={isCSV}
            transformDataToCSVFormat={transformDataToCSVFormat}
            groupings={groupings}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
          />
        </div>

        {isEdit && setOpenEditTableDialog && (
          <button
            onClick={() => {
              setOpenEditTableDialog(!openEditTableDialog);
            }}
            className={
              "flex big-mobile-size:hidden border-2 h-10 w-44 rounded-3xl copy justify-center items-center self-end hover:!shadow-btnShadow"
            }
          >
            <EditTable />
            <span style={{ fontSize: 16, color: "#0079FF", paddingLeft: 10, fontWeight: 500 }}>
              Edit the table
            </span>
          </button>
        )}

        {isEdit && (
          <EditTableDialog
            openEditTableDialog={openEditTableDialog ?? false}
            setOpenEditTableDialog={setOpenEditTableDialog ?? (() => {})}
            columnList={columnList ?? []}
            setColumnList={setColumnList ?? (() => {})}
            actualColumnList={actualColumnList ?? []}
            setActualColumnList={setActualColumnList ?? (() => {})}
            dialogApplyButton={dialogApplyButton ?? false}
            setDialogApplyButton={setDialogApplyButton ?? (() => {})}
          />
        )}
      </div>
      <div className="max-w-[440px] mt-6">
        {
            isSearch && (
              <SearchByRow
                searchTerm={searchTerm ?? ""}
                setSearchTerm={setSearchTerm ?? (() => {})}
                handleSearch={handleSearch ?? (() => [])}
              />
            )
          }
      </div>
    </>
  );
};

export default FilterBar;
