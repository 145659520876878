function capitalizeSpecificWords(inputString: string): string {
    if (!inputString) return inputString;

    const wordsToCapitalize: string[] = ["Total", "Visa", "Mastercard", "Desktop", "Mobile", 'Master'];

    // Create a regex to match the words case-insensitively
    const regex = new RegExp(`\\b(${wordsToCapitalize.join('|')})\\b`, 'gi');

    // Replace each match with its capitalized version
    return inputString.replace(regex, (match) => {
        return wordsToCapitalize.find(word => word.toLowerCase() === match.toLowerCase()) || match;
    });
}

export default capitalizeSpecificWords;