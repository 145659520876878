import React, { ReactNode, useContext } from 'react';
import FraudHigh from '../../../../../assets/icons/FraudHigh';
import FraudLow from '../../../../../assets/icons/FraudLow';
import FraudMedium from '../../../../../assets/icons/FraudMedium';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';

type StatusLevel = 0 | 2 | 1;

interface StatusProps {
  level: StatusLevel;
}

const FraudStatus: React.FC<StatusProps> = ({ level }) => {
  const { theme } = useContext(ThemeContext)!;

  let backgroundColor;
  let textColor;
  let icon: ReactNode;
  let label;
  let width;

  if (level === 0) {
    backgroundColor = theme === 'light' ? 'bg-[#EAF6E5]' : 'bg-[#2F4427]';
    textColor = theme === 'light' ? 'text-[#2AA300]' : 'text-[#AAF490]';
    icon = <FraudLow />;
    label = 'Low';
    width = 'w-[70px]'
  } else if (level === 2) {
    backgroundColor = theme === 'light' ? 'bg-[#FFF4E5]' : 'bg-[#3D301F]';
    textColor = theme === 'light' ? 'text-[#FF9500]' : 'text-[#FFC87B]';
    icon = <FraudMedium />;
    label = 'Medium';
    width = 'w-[95px]'
  } else if (level === 1) {
    backgroundColor = theme === 'light' ? 'bg-[#FEF2F2]' : '#453838';
    textColor = theme === 'light' ? 'text-[#E74C3C]' : 'text-[#FDB5AE]';
    icon = <FraudHigh />;
    label = 'High';
    width = 'w-[75px]'
  }

  return (
    <div
      className={`flex items-center px-2 py-1 rounded-full ${width} ${backgroundColor} ${textColor}`}
    >
      <span className='mr-2'>{icon}</span>
      {label}
    </div>
  );
};

export default FraudStatus;
