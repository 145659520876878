import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext/UserContext";
import { routePermissions } from "../../../permissions/permissions";
import LoadingPopup from "../../UI/loading/LoadingPopup";

const ProtectedReport = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions, isSuperAdmin, loading, isAdmin } = userContext!;

  const hasRequiredPermissions = (userPermissions: string[]) => {
    const currentPath = location.pathname; // Get the current route path

    const matchingRoute = Object.keys(routePermissions).find(path => currentPath.startsWith(path));

    // If there is no explicit permission required for the route, assume everyone can access
    if (!matchingRoute) {
      return true;
    }

    const requiredPermissions = routePermissions[matchingRoute];

    return requiredPermissions.every(permission => userPermissions.includes(permission));
  };

  useEffect(() => {
    if (loading) return; // Don't navigate if loading

    if (!userContext) {
      navigate('/access-denied');
    }

    const isAllowed = isSuperAdmin || isAdmin || hasRequiredPermissions(permissions);

    if (!isAllowed) {
      navigate('/access-denied');
    }
  }, [userContext, permissions, isSuperAdmin, isAdmin, loading, location.pathname, navigate]);

  return (
    <>
      {
        loading ? <></> : <Outlet />
      }
    </>
  );
};

export default ProtectedReport;
