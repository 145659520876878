import { Button } from '@mui/material';
import React from 'react';

interface DefaultButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  label: string | JSX.Element;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
    className?: string;
    onMouseLeave?: () => void;
    onMouseEnter?: () => void;
    startIcon?: React.ReactNode;
}

const DefaultButton = ({
  onClick,
  disabled,
  type,
  label,
  variant = 'contained',
  color = 'primary',
  className,
  onMouseLeave,
  onMouseEnter,
  startIcon,
}: DefaultButtonProps) => {
  let btnClass = '';
  switch (variant) {
    case 'contained':
      btnClass = `
          !text-base !bg-odin-light-blue-medium !text-white 
          disabled:!text-odin-light-gray-bluish disabled:!bg-odin-light-gray-light 
          dark:disabled:!bg-odin-dark-gray-light
          hover:!bg-odin-light-blue-medium_hover hover:!shadow-btnShadow
        `;
      break;
    case 'outlined':
    btnClass = `
      !text-base !border-2 !border-odin-light-blue-medium 
      dark:!text-odin-light-blue-medium
      disabled:!border-odin-light-gray-light 
      dark:disabled:!border-odin-dark-gray-dark dark:disabled:!text-odin-light-gray-bluish 
      hover:!shadow-btnShadow hover:!bg-transparent dark:hover:!bg-odin-dark-blue-custom-blue-hover
    `
      break;
    case 'text':
      break;
    default:
      break;
  }
  return (
    <div 
      style={{ cursor: disabled ? 'not-allowed' : 'auto' }}
      className={className}
    >
      <Button
        variant={variant}
        color={color}
        style={{ borderRadius: '50px', boxShadow: 'none', textTransform: 'none', cursor: disabled ? 'not-allowed ' : 'pointer'}}
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={`${btnClass} ${className}`}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        startIcon={startIcon}
      >
        {label}
    </Button>
    </div>

  );
};

export default DefaultButton;
