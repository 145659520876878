import React, { useContext, useEffect, useRef, useState } from "react";
import { FormControl, Checkbox, TextField, ListItemText, MenuItem, SelectChangeEvent, OutlinedInput, Select, ListSubheader, InputAdornment } from "@mui/material";
import { ThemeContext } from "../../../../../context/ThemeContext/ThemeContext";
import CheckedCheckbox from "../../../../../assets/icons/CheckedCheckbox";
import EmptyCheckbox from "../../../../../assets/icons/EmptyCheckbox";
import { ChevronDownIcon, SearchIcon } from "@heroicons/react/outline";

type HandleSelectChangeFunction = (event: SelectChangeEvent<string[]>) => void;
interface SelectionProps {
    itemName: string[],
    handleSelectChange: HandleSelectChangeFunction,
    filterName?: string[],
    label: string,
    multiple: boolean,
    reversedOrderInItem?: boolean
}

 const ConversionSelection: React.FC<SelectionProps> = ({itemName, handleSelectChange, filterName, label, multiple, reversedOrderInItem}) => {
    const { theme, themedColors } = useContext(ThemeContext)!;
    const searchInputRef = useRef<HTMLInputElement>(null);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps:{
            style:{
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            }
        },
        disableEnforceFocus: true,
        disableAutoFocusItem: true, 
    }

    const [searchText, setSearchText] = useState("");
    
    const containsText = (text: string, searchText: string) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

    const displayedOptions = filterName!.filter((option) => containsText(option, searchText))

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [searchText, filterName]); 

    const handleSearchInputClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };
    

    return(
        <div className={'flex flex-col '}>
            <label style={{ fontSize: "14px", color: themedColors.content }} className={'pb-1 text-base'}>{label}</label>
            <FormControl sx={{mr:1,width: 200, marginTop: '-2px' }}>
                <Select
                    multiple = {multiple}
                    displayEmpty
                    value={itemName}
                    onChange={handleSelectChange}
                    renderValue={(selected) => {
                        if (multiple) {
                            if (selected.length === 0) {
                                return <em style={{fontSize: '12px', color: theme === 'light' ? '#677C9F' : '#677C9F'}}>Select from the list</em>;
                            }
                            return selected.join(', ');
                        }
                        else {
                            if (!selected) {
                                return <em style={{fontSize: '12px', color: theme === 'light' ? '#677C9F' : '#677C9F'}}>Select from the list</em>;
                            }
                            return <span>{selected}</span>
                        }
                    }}
                    MenuProps={MenuProps}
                    IconComponent={ChevronDownIcon}
                    inputProps={{ 'aria-label': 'Without label', MenuProps: {disableScrollLock: true} }}
                    input={<OutlinedInput />}
                    className={"h-10"}
                    sx={{
                        '& .MuiSelect-icon, & .MuiSelect-iconOpen': {width: '20px', color: themedColors.content,},
                        '& .MuiSelect-iconOpen': { color: '#0079FF' },

                        '&.MuiOutlinedInput-root': {borderRadius:'6px'},
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                        "&.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: theme === 'light' ? "#ACBFDF" : "#495D80"}
                    }}

                    onClose={() => setSearchText("")}
                    onOpen={() => {
                        if (searchInputRef.current) {
                            searchInputRef.current.focus();
                        }
                    }}
                >
                    <ListSubheader  onClick={handleSearchInputClick}>
                        <TextField
                        size="small"
                        // Autofocus on textfield
                        autoFocus
                        placeholder="Type to search..."
                        inputRef={searchInputRef}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                            )
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                            }
                        }}
                        sx={{
                            '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#0079FF"
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart .MuiOutlinedInput-input:focus': {
                                boxShadow: 'none !important',
                            },
                            '& .MuiInputBase-inputAdornedStart.Mui-focused .MuiInputBase-adornedStart::placeholder': {
                                borderColor: "red"
                            },
                            '& .MuiInputBase-adornedStart': {
                                marginTop: '10px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme === 'light' ? "#ACBFDF" : "#495D80",
                            },
                            '& .MuiOutlinedInput-notchedOutline:active': {
                                borderColor: "#0079FF"
                            },
                            '& .MuiOutlinedInput-notchedOutline:hover': {
                                borderColor: "#0079FF"
                            },
                        }}
                        />
                    </ListSubheader>
                    {filterName && filterName.length > 0 ? displayedOptions.map((filterName1: string) => {
                        return (
                            (
                                <MenuItem key={filterName1} value={filterName1}
                                        sx={{
                                            '&.MuiMenuItem-root': {
                                             
                                                '&.MuiMenuItem-root:hover, &.Mui-selected': {
                                                    backgroundColor: themedColors.background_1,
                                                },
                                                '& .MuiTypography-root': {
                                                    fontWeight: itemName.includes(filterName1) ? 500 : 400,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '14px !important',
                                                }
                                            }
                                        }}
                                >
                                    {reversedOrderInItem ? <>
                                        <ListItemText primary={filterName1}  />
                                        <Checkbox sx={{fontSize: '12px'}} checked={itemName.indexOf(filterName1) > -1}
                                                  icon={<EmptyCheckbox/>}
                                                  checkedIcon={<CheckedCheckbox />}
                                        />
                                    </>:<>
                                        <Checkbox sx={{fontSize: '12px'}} checked={itemName.indexOf(filterName1) > -1}
                                                  icon={<EmptyCheckbox/>}
                                                  checkedIcon={<CheckedCheckbox />}
                                        />
                                        <ListItemText primary={filterName1}  />
                                    </>}
        
                                </MenuItem>
                            )
                        )
                    }): (
                        <MenuItem disabled>
                            <ListItemText primary="No data available" sx={{
                                "& span": {
                                    fontSize: '14px',
                                }
                            }} />
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
}

export default ConversionSelection;

