import { useState, useEffect } from 'react';

type UseMediaQuery = (query: string) => boolean;

const useMediaQuery: UseMediaQuery = (query) => {
    const [matches, setMatches] = useState<boolean>(false);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query);

        const updateMatchStatus = () => {
            setMatches(mediaQueryList.matches);
        };

        mediaQueryList.addEventListener('change', updateMatchStatus);

        updateMatchStatus();

        return () => {
            mediaQueryList.removeEventListener('change', updateMatchStatus);
        };
    }, [query]);

    return matches;
};

export default useMediaQuery;