const chartColorEnum: { [key: number]: string } = {
    0: "#3b82f6",
    1: "#6366f1",
    2: "#a7a9f8",
    3: "#a855f7",
    4: "#06b6d4",
    5: "#5acbff",
    6: "#0ea4e9",
    7: "#0095ac",
    8: "#10c075",
    9: "#e5e911",
    10: "#e8b90e",
    11: "#e9920c",
    12: "#e96a0f",
    13: "indigo",
    14: "fuchsia",
    15: "lime",
};


export default chartColorEnum;
