import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'Affiliate',
    'Gateway',
    'GEO',
    'Card Type', 
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate',
    'Gateway': 'gateway',
    'Card Type': 'card_type', 
};

export const fieldsForCSV = {
    "Rebills": "rebills",
    "Revenue": "revenue",
};

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    // 1: { startDate: dayjs.tz(), endDate: dayjs.tz().endOf('D') }, // Today
    1: { startDate: dayjs.tz().add(1, 'day').startOf('day'), endDate: dayjs.tz().add(1, 'day').endOf('day') }, // Tomorrow
    2: { startDate: dayjs.tz(), endDate: dayjs.tz().endOf('w') }, // This Week
    3: { startDate: dayjs.tz(), endDate: dayjs.tz().endOf('M') }, // This Month
    4: { startDate: dayjs.tz(), endDate: dayjs.tz().endOf('y') }, // This Year
    5: { startDate: dayjs.tz().startOf('week').add(1, "week"), endDate: dayjs.tz().endOf('week').add(1, "week") }, // Next Week
    6: { startDate: dayjs.tz().startOf('month').add(1, "month"), endDate: dayjs.tz().endOf('month').add(1, "month") }, // Next Month
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        // 1: { startDate: dayjs.tz().tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) }, // Today
        1: { startDate: dayjs.tz().add(1, 'day').startOf('day').tz(timezone), endDate: dayjs.tz().add(1, 'day').endOf('day').tz(timezone) }, // Tomorrow
        2: { startDate: dayjs.tz().tz(timezone), endDate: dayjs.tz().endOf('w').tz(timezone) }, // This Week
        3: { startDate: dayjs.tz().tz(timezone), endDate: dayjs.tz().endOf('M').tz(timezone) }, // This Month
        4: { startDate: dayjs.tz().tz(timezone), endDate: dayjs.tz().endOf('y').tz(timezone) }, // This Year
        5: { startDate: dayjs.tz().startOf('week').add(1, "week").tz(timezone), endDate: dayjs.tz().endOf('week').add(1, "week").tz(timezone) }, // Next Week
        6: { startDate: dayjs.tz().startOf('month').add(1, "month").tz(timezone), endDate: dayjs.tz().endOf('month').add(1, "month").tz(timezone) }, // Next Month
    };
};

export const dateRangeList = [
    "Custom Range",
    "Tomorrow",
    "This Week",
    "This Month",
    "This Year",
    "Next Week",
    "Next Month",
];