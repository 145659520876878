import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {ChevronRightIcon, ChevronDownIcon} from "@heroicons/react/outline";
import {tableCellClasses} from "@mui/material/TableCell";
import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import {Icon} from "@tremor/react";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import {
    borderColorEnum,
    darkBorderColorEnum,
    darkMainColorEnum,
    darkSubColorEnum,
    mainColorEnum,
    subColorEnum
} from "../../../../types/enums/TableColors"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import firstRowData from "./data/firstRowData";
import ExclamationMark from "../../../../assets/icons/ExclamationMark";
import FilterBar from "../../../organism/filterbar/FilterBar";
import {transformData} from "./utils/transformPerformanceData";
import {useTheme} from "../../../../context/ThemeContext/ThemeContext";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import ScrollContainer from "react-indiana-drag-scroll";
import { determineNestingLevels, reorderLevels, updateExpandedState, handleExpand, handleMainExpand } from "../../../../helpers/collapsingFunctions";
import { dateRangeList, fields, fieldsForCSV, generateRangeConfigs, namesEnum, rangeConfigs } from "./utils/config";
import { rowBottomBorder } from "../../../../constants/rowBottomBorder";
import { sortData } from "../../../../helpers/sortData";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import SortedArrowAscending from "../../../../assets/icons/SortedArrowAscending";
import SortedArrowDescending from "../../../../assets/icons/SortedArrowDescending";
import { getRowHoverColor } from "../../../../helpers/rowHoverColor";
import CustomCell from "../../../UI/CustomCell/CustomCell";
import ReactCountryFlag from "react-country-flag"
import { hasFlag } from 'country-flag-icons'
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import formatRowName from "../../../../helpers/formatRowName";
import { NestedLevels, SalesPerformanceReportClonedData, SalesPerformanceReportData, SalesPerformanceReportRowInterface } from "./types/sales-performance-report.types";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { columnWidth, getFirsrColimnWidth } from "../../../../helpers/getFirstColumnWidth";

dayjs.extend(utc);
dayjs.extend(timezone);

type TipsHoveredState = {
    [index: number]: boolean;
};

interface props {
    names: string[]
    clonedData: SalesPerformanceReportClonedData
    passData: (data: SalesPerformanceReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}
const SalesPerformanceReport: React.FC<props> = ({
    names,
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey
}: props) => {
    const { tailwindColors, themedColors, theme } = useTheme()
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
    const [selectedGroupings, setSelectedGroupings] = React.useState(3)
    const [collapse, setCollapse] = React.useState(clonedData['expand_level']?clonedData['expand_level']:'0');
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const params = new URLSearchParams();
    const [showTable, setShowTable] = React.useState(!!clonedData['data'])
    const [data, setData] = React.useState<SalesPerformanceReportData | []>(clonedData['data']?clonedData['data']:[]);
    const resultArrays: number[][] = Array.from({ length: groupings.length + 1 }, () => []);
    const resultArraysCopy: number[][] = Array.from({ length: groupings.length + 1 }, () => []);
    const expandLevel = collapse;
    const reportName = ReportName.SalesPerformanceReport;
    const groupSequence =  groupings;
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState(theme === 'light' ? tailwindColors.odin.light.gray.light : tailwindColors.odin.dark.gray.dark_bluish_2)
    const [iconFillColor, setIconFillColor] = useState(theme === 'light' ? tailwindColors.odin.light.gray.bluish_light : tailwindColors.odin.dark.gray.bluish);
    const reportConfigValues = useRef({ expandLevel, reportName, groupSequence });
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [crmType, setCrmType] = useState('swiss')
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    const [previousGroupings, setPreviousGroupings] = useState<string[]>([]);
    const [previousSuperTenant, setPreviousSuperTenant] = useState<string | null>(superTenant);
    const [previousStartDate, setPreviousStartDate] = useState(startDate);
    const [previousEndDate, setPreviousEndDate] = useState(endDate);
    const [previousCrmType, setPreviousCrmType] = useState(crmType);

    const isMobile = useMediaQuery('(max-width: 1023px)')

    let transformedGroupings = ['Currency', ...groupings];

    const [nestedLevels, setNestedLevels] = useState<NestedLevels>({});
    const [sortState, setSortState] = useState(0);
    const [sortField, setSortField] = useState<string | null>(null);
    const [originalData, setOriginalData] = useState<SalesPerformanceReportData | []>(clonedData['data']?clonedData['data']:[])

    const isInitialLoadCollapse = useRef(true);

    const [isHoveredRow, setIsHoveredRow] = useState<number | null | string>(null);

    const isInitialLoad = useRef(true);

    useEffect(() => {
        if (data && collapse !== undefined) {
            const updatedLevels = updateExpandedState(nestedLevels, Number(collapse));
            setNestedLevels(updatedLevels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse, data]);

    useEffect(() => {
        if (dateRange !== 0) handleBlur()
        if (clonedData.group_sequence){
            setIconFillColor(theme === 'light' ? '#fff' : tailwindColors.odin.dark.gray.bluish)
            setButtonBackgroundColor(theme === 'light' ? tailwindColors.odin.light.blue.medium : tailwindColors.odin.dark.gray.dark_bluish_2)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
        }    },[startDate,endDate])

    function newTable(row: SalesPerformanceReportRowInterface, groupIndex: number, parents: string[]) {
        if ((namesEnum)[transformedGroupings[groupIndex+1]] in row){
            return GetTableBody(row[(namesEnum)[transformedGroupings[groupIndex+1]]], groupIndex+1, parents)
        }
        else
            return  null;
    }

    function getFontColor(percentage:number) {
        if (percentage<0)
            return themedColors.red.bright
        else
        if (percentage>0)
            return themedColors.green.medium
        else return theme === 'light' ?'#000000' : tailwindColors.odin.dark.content
    }

    // TODO check this after removing mocks and getting real data
    // function CreatePropsForGraph(parents: string[], isFromPerformanceReport: boolean = false, endDate: string) {
    //     const filterNames: string[] = [];

    //     transformedGroupings.forEach(value => {
    //         if (value === "GEO" || value === "Affiliate" || value === "Offer") {
    //             filterNames.push(value);
    //         }
    //     });

    //     const displayFilterIndex:string[] = ["","",""];
    //     const geoIndex = transformedGroupings.indexOf('GEO');
    //     const affIndex = transformedGroupings.indexOf('Affiliate');
    //     const offerIndex = transformedGroupings.indexOf('Offer');
    //     const subIdIndex = transformedGroupings.indexOf('Subid');
    //     const formattedEndDate = dayjs(endDate).format('MM-DD-YYYY');
    //     const formattedStartDate = dayjs(startDate).format('MM-DD-YYYY');

    //     if (geoIndex >= 0) {
    //         const correspondingValue = filterNames[geoIndex];

    //         if (parents[geoIndex] != undefined) {
    //             displayFilterIndex[geoIndex] = correspondingValue;
    //         }
    //     }
    //     if (affIndex >= 0) {
    //         const correspondingValue = filterNames[affIndex];
    //         if (parents[affIndex] != undefined) {
    //             displayFilterIndex[affIndex] = correspondingValue;
    //         }
    //     }
    //     if (offerIndex >= 0) {
    //         const correspondingValue = filterNames[offerIndex];
    //         if (parents[offerIndex] != undefined) {
    //             displayFilterIndex[offerIndex] = correspondingValue;
    //         }
    //     }
    //     if (subIdIndex >= 0) {
    //         const correspondingValue = filterNames[subIdIndex];
    //         if (parents[subIdIndex] != undefined) {
    //             displayFilterIndex[subIdIndex] = correspondingValue;
    //         }
    //     }

    //     let showDisplayFilter;

    //     for (let i = displayFilterIndex.length - 1; i >= 0; i--) {
    //         if (displayFilterIndex[i] !== "") {
    //             showDisplayFilter = displayFilterIndex[i];
    //             break;
    //         }
    //     }

    //     //result.push(parents[geoIndex], parents[affIndex], parents[offerIndex], parents[subIdIndex], formattedEndDate, showDisplayFilter, isFromPerformanceReport)

    //     const additionalProps = {
    //         isFromReportTable: true,
    //         geo_name: parents[geoIndex],
    //         affiliate_name: parents[affIndex],
    //         offer_name: parents[offerIndex],
    //         formattedEndDate,
    //         data_range: dateRange,
    //         formattedStartDate
    //     }

    //     if (geoIndex >= 0 || affIndex >= 0 || offerIndex >= 0 || subIdIndex >= 0) {
    //         navigate('/dashboard/sales-performance-report/sales-performance-report-line-chart', { state: { additionalProps } });
    //     }
    // }

    function GetSubTable(row: SalesPerformanceReportRowInterface, index: number, borderColor: string, groupIndex: number, parents: string[]) {
        const staticParents = parents.slice()
        staticParents.push(row.name)

        let classNameBorderBottom = rowBottomBorder

        const isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded

        return (
            <Table key={index}>
                <TableBody>
                    <TableRow
                        style={{ padding:0, height:42 }}
                    >
                            <TableCell  style={{fontSize:'12px',padding:'0px 8px 0px 0px', width:(columnWidth as { [key: number]: number })[selectedGroupings]+52, backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme)}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}>
                            </TableCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['clicks']}></CustomCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['clicks_into_orders']}></CustomCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['partials']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['partial_rate']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '150px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['partial_conv_rate']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['conv_rate']}></CustomCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['sales']}></CustomCell>
                            <CustomCell type='item'  style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['upsell']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '150px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['take_rate']}></CustomCell>
                            <CustomCell type='item' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end'}} className={`${classNameBorderBottom}`} onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['declines']}></CustomCell>
                            <CustomCell type='percentage' style={{fontSize:'12px', padding:'0px 8px 0px 8px', width: '120px', backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme), textAlign: 'end', fontWeight: 500, color: getFontColor(parseFloat(Number(row['approval'])?.toFixed(2)))}} className={`${classNameBorderBottom}` } onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)} value={row['approval']}></CustomCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={13} style={{padding:0}}>
                            <Collapse in={
                                isExpanded
                            } timeout={'auto'} unmountOnExit>
                                <Table style={{padding:0}} sx={{
                                    "& tr:first-of-type td:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                }}>
                                    {newTable(row, groupIndex, staticParents)}
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    function GetTableBody(temp1:SalesPerformanceReportRowInterface[], groupIndex: number, parents: string[]) {
        const staticData:SalesPerformanceReportRowInterface[] = temp1;
        const color = borderColorEnum[groupIndex]
        const name = transformedGroupings[groupIndex]

        const isExpanded = nestedLevels[namesEnum[name]]?.expanded

        return <>
            <TableBody>
                <TableRow style={{fontSize:'12px', backgroundColor: theme === 'light' ? mainColorEnum[groupIndex] : darkMainColorEnum[groupIndex], borderRadius:5}}>
                    <TableCell align={'left'} colSpan={13} style={{padding:0}}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {handleMainExpand(namesEnum[name], setNestedLevels)
                            }}
                        >
                            {isExpanded ? <Icon icon={ChevronDownIcon} style={{color:'white'}}/> : <Icon icon={ChevronRightIcon} style={{color:'white'}}/>}
                        </IconButton>
                        <span style={{fontSize:'12px',}} className={'text-white font-bold'}>{name}</span>
                    </TableCell>
                </TableRow>
                <TableRow style={{backgroundColor: theme === 'light'? subColorEnum[groupIndex] : darkSubColorEnum[groupIndex]}}>
                    <TableCell colSpan={13} style={{padding:0}}>
                        <Collapse in={
                            isExpanded
                        } timeout={'auto'} unmountOnExit>
                            {staticData?.map((row:SalesPerformanceReportRowInterface, index:number) => (
                                GetSubTable(row,index,color,groupIndex, parents)
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>;
    }

    function newFirstColumn(row: SalesPerformanceReportRowInterface, groupIndex: number) {
        if ((namesEnum)[transformedGroupings[groupIndex+1]] in row){
            return GetFirstColumn(row[(namesEnum)[transformedGroupings[groupIndex+1]]], groupIndex+1)
        }
        else
            return  null;
    }

    function GetFirstColumnNames(row: SalesPerformanceReportRowInterface,index: number, borderColor: string, groupIndex: number) {
        let classNameBorderBottom = ``
        let isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.names.find((item) => item.id === row.id)?.expanded

        let isNotNeededBorder = groupIndex === selectedGroupings - 1 ? true : false

        if (isNotNeededBorder) {
            if (isExpanded) {
                classNameBorderBottom = rowBottomBorder
            } else {
                classNameBorderBottom = rowBottomBorder
            }
        } else {
            if (isExpanded) {
                classNameBorderBottom = ''
            } else {
                classNameBorderBottom = rowBottomBorder
            }
        }

        return (
            <Table key={index}>
                <TableBody>
                    <TableRow style={{ padding:0}}>
                    <Tooltip 
                            title={ formatRowName(row.name, row.currency, groupIndex)}
                            placement={isMobile ? "bottom-start" : "left"}
                            classes={{
                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                              }}
                            arrow
                            enterTouchDelay={10}
                            leaveTouchDelay={1000}
                        >
                    <TableCell
                        style={{
                            fontSize: '12px',
                            padding: '0px',
                            width: 200,
                            borderTopLeftRadius: 0,
                            backgroundColor: getRowHoverColor(isHoveredRow, row.id, theme),
                            cursor: groupIndex !== transformedGroupings.length - 1 ? 'pointer' : 'default'
                        }}
                        className={classNameBorderBottom}
                        onMouseEnter={() => setIsHoveredRow(row.id)} onMouseLeave={() => setIsHoveredRow(null)}
                        onClick={() => {
                            handleExpand(row?.id, groupIndex + 1, setNestedLevels)
                        }}
                    >
                                <div className={'flex flex-row flex-nowrap text-nowrap items-center'} >

                                    {
                                        groupIndex !== transformedGroupings.length - 1 ? (
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                disabled={true}
                                            >
                                            {isExpanded ?
                                                <Icon icon={ChevronDownIcon} className={"!text-black dark:!text-odin-dark-content"}/> :
                                                <Icon icon={ChevronRightIcon} className={"!text-black dark:!text-odin-dark-content"}/>}</IconButton>
                                        ) : (
                                            <span className="h-[42px] w-2" >

                                            </span>
                                        )
                                    }
                                    <span className={hasFlag(row.name)?'pr-3 text-center':'text-center hidden'}>
                                        {
                                            hasFlag(row.name) ? <ReactCountryFlag countryCode={row.name} svg style={{ width: '18px', height: '16px', }} /> : null
                                        } 
                                    </span>
                                    <span
                                        style={{
                                            // width: `${120 + ((selectedGroupings - groupIndex) * 80)}px`,
                                            width: getFirsrColimnWidth(selectedGroupings, groupIndex),
                                            fontSize: '12px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontWeight: 500,
                                        }}
                                        className={'pt-[3px] leading-3 dark:text-odin-dark-content'}
                                        >
                                        {
                                            formatRowName(row.name, row.currency, groupIndex)
                                        }
                                    </span>
                                </div>
                            </TableCell>
                            </Tooltip>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{padding:0}}>
                            <Collapse in={
                                isExpanded
                            } timeout={'auto'} unmountOnExit>

                                <Table style={{padding:0,marginLeft:36, minWidth:200, overflow: 'clip', borderTopLeftRadius: '5px'}} sx={{
                                    "& tr:first-of-type td:first-of-type": {
                                        borderTopLeftRadius: "10px",
                                    },
                                }}>
                                    {newFirstColumn(row, groupIndex)}
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    function GetFirstColumn(temp1:SalesPerformanceReportRowInterface[], groupIndex: number) {
        const data:SalesPerformanceReportRowInterface[] = temp1;
        const color = theme === 'dark' ? darkBorderColorEnum[groupIndex] : borderColorEnum[groupIndex]
        const isExpanded = nestedLevels[namesEnum[transformedGroupings[groupIndex]]]?.expanded

        return <>
            <TableBody>
                <TableRow style={{fontSize:'12px', backgroundColor: theme === 'light' ? mainColorEnum[groupIndex] : darkMainColorEnum[groupIndex], borderRadius:5, height: 42}}>
                    <TableCell align={'left'} style={{padding:0, cursor: groupIndex !== 0 ? 'pointer' : 'default'}} 
                        onClick={groupIndex !== 0 ? () => {handleMainExpand(namesEnum[transformedGroupings[groupIndex]], setNestedLevels)} : () => {}}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            disabled={true}
                        >
                            {groupIndex !== 0 ? isExpanded ? <Icon icon={ChevronDownIcon} className={"!text-white dark:text-odin-dark-content"}/> : <Icon icon={ChevronRightIcon} className={"!text-white dark:text-odin-dark-content"}/> : <div className="h-8" />}
                        </IconButton>
                        <span style={{fontSize:'12px',}} className={'text-white font-bold'} id={transformedGroupings[groupIndex]}>{transformedGroupings[groupIndex]}</span>
                    </TableCell>
                </TableRow>
                <TableRow style={{backgroundColor: theme === 'light' ? subColorEnum[groupIndex] : darkSubColorEnum[groupIndex]}}>
                    <TableCell style={{padding:0}}>
                        <Collapse in={
                            isExpanded
                        } timeout={'auto'} unmountOnExit>
                            {data?.map((row:SalesPerformanceReportRowInterface, index:number) => (
                                GetFirstColumnNames(row,index,color,groupIndex)
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>;
    }

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        collapse: collapse,
        dateRange: dateRange
    });

    useEffect(() => {
        const isLight = theme === 'light'
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            collapse !== clonedData['expand_level']
            || JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );
        if (valuesChanged) {
            setIconFillColor(isLight ? tailwindColors.odin.dark.blue.medium : tailwindColors.odin.light.blue.medium);
            setFreezeButton(!isButtonActive);
            setButtonBackgroundColor(isButtonActive ? isLight ? '#fff' : tailwindColors.odin.dark.gray.bluish : isLight ? '#EAEEF6' : tailwindColors.odin.dark.gray.dark_bluish_2);
        }
        if (!valuesChanged) {
            setIconFillColor(isLight ? '#fff' : tailwindColors.odin.dark.gray.bluish)
            setButtonBackgroundColor(isLight ? '#0079FF' : tailwindColors.odin.dark.gray.dark_bluish_2)
            setFreezeButton(true)
        }
        reportConfigValues.current = { expandLevel, reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.collapse !== collapse ||
            previousValues.dateRange !== dateRange
        )) {
            handleBlur();
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                collapse: collapse,
                dateRange: dateRange
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, collapse, reportName, dateRange, theme, startDate, endDate]);

    useEffect(() => {
        previousValuesRef.current = {
            groupings: JSON.stringify(groupings),
            collapse: collapse,
            dateRange: dateRange
        };
    }, [groupings, collapse, reportName, dateRange, theme]);

    function generateParams() {
        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        filters.forEach((value, index) => {
            params.append(`filters[${index}]`, value)
        })
        params.append('timezone', timezone) 
        
        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            crmType: crmType
        }

        return dataToHash;
    }

    const isDataSame = (groupings: string[], previousGroupings: string[], startDate: string | Dayjs, previousStartDate: string | Dayjs, superTenant: string | null = null, previousSuperTenant: string | null = null) => {
        if (!isSuperAdmin) {
            return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && crmType === previousCrmType);
        }
        return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && crmType === previousCrmType && superTenant === previousSuperTenant);
    };

    useEffect(() => {
        if (!isInitialLoad.current) {
            handleBlur();
        } else {
            isInitialLoad.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superTenant]);

    function handleBlur() {
        if (tenantId === null) return;
        setShowTable(false);
        setTableLoading(true);
        if (groupings.length === 0) {
            setTableLoading(false);
            return;
        }
        generateParams()
        if (!isDataSame(groupings, previousGroupings, startDate, previousStartDate, superTenant, previousSuperTenant)) {
            setData([]);
            setOriginalData([]);
            const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
            if (cachedData){
                setTableLoading(false);
                setNestedLevels(reorderLevels(determineNestingLevels(cachedData, 1, 'currency', namesEnum)))
                setData(cachedData)
                setOriginalData(cachedData)
                setSelectedGroupings(transformedGroupings.length);
                if (Number(collapse)>transformedGroupings.length){
                    setCollapse((transformedGroupings.length).toString())
                }
                setShowTable(true);
                // It is for expanding the table to the last level
                if (!isInitialLoadCollapse.current) {
                    setCollapse(transformedGroupings.length.toString());
                } else {
                    isInitialLoadCollapse.current = false;
                }
            } else {
                OdinForgeService.instance().getSalesPerformanceData(params)
                    .then((result: {data: SalesPerformanceReportData, cacheTime: number}) => {
                        const transformed = transformData(result.data)
                        setTableLoading(false);
                        if (transformed[(namesEnum)[(transformedGroupings)[0]]] && (transformed[(namesEnum)[(transformedGroupings)[0]]]! as []).length !== 0){
                            setData(transformed);
                            setOriginalData(transformed)
                            setNestedLevels(reorderLevels(determineNestingLevels(transformed, 1, 'currency', namesEnum)))
                            setSelectedGroupings(transformedGroupings.length);
                            if (Number(collapse)>transformedGroupings.length){
                                setCollapse((transformedGroupings.length).toString())
                            }
                            setShowTable(true);

                            createHash(generateObjectToHash, transformed, reportName, result.cacheTime)
                            // Utils.instance().createHash(generateObjectToHash(), transformed, reportName, result.cacheTime)
                            // It is for expanding the table to the last level
                            if (!isInitialLoadCollapse.current) {
                                setCollapse(transformedGroupings.length.toString());
                            } else {
                                isInitialLoadCollapse.current = false;
                            }
                        } else {
                            setTableLoading(false);
                            setData([]);
                            setOriginalData([]);
                            // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                        }
                    })
                    .catch((e) => {
                        setTableLoading(false);
                        console.error(e);
                        Utils.instance().onFailure('An error occurred while fetching the report');
                    });
            }
        } else {
            if (data.length!==0)
                setShowTable(true)
            setTableLoading(false);
        }
        setPreviousGroupings(groupings);
        setPreviousStartDate(startDate)
        setPreviousEndDate(endDate)
        setPreviousCrmType(crmType)
        setPreviousSuperTenant(superTenant);
    }

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                expand_level: collapse,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            },
            report_name: reportName
        }
    }

    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            expand_level: collapse,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
        }
        passData(dataToPass)
    }

    function FirstRow() {
        const [tipsHovered, setTipsHovered] = useState<TipsHoveredState>({});

        const handleMouseEnter = (index: number) => {
            setTipsHovered(prev => ({ ...prev, [index]: true }));
        };

        const handleMouseLeave = (index: number) => {
            setTipsHovered(prev => ({ ...prev, [index]: false }));
        };

        return (
            <>
                {
                    firstRowData.map((row, index) => (
                        <TableCell key={index} style={{ fontSize: row.fontSize, width: row.width, padding: row.padding }} className={`!leading-3 ${sortField === fields[row.name as keyof typeof fields] && (sortState !== 0 ? 'bg-[#CEE5FC] dark:bg-slate-800' :  '')}`}>
                            <div className="flex gap-2 items-center">
                                <span 
                                    className={`text-odin-dark-gray-bluish ${showTable && row.hasSortField ? "cursor-pointer hover:text-[#242546] dark:hover:text-[#EBEFF7]" : ''} flex gap-2 items-center w-full`}
                                    onClick={showTable && row.hasSortField ? () => sortTableData(fields[row.name as keyof typeof fields]) : undefined}
                                    style={{
                                        justifyContent: row.align ? row.align : 'flex-start',
                                    }}
                                >
                                    {row.name}
                                </span>
                                {
                                    row.isTips && (
                                        <Tooltip
                                            classes={{
                                                tooltip: "py-2 px-4 !bg-[#242546] text-white text-xs bg-opacity-90 rounded-md"
                                             }}
                                            title={row.tips}
                                            placement="right-start"
                                        >
                                            <span
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={() => handleMouseLeave(index)}
                                            >
                                                <ExclamationMark isTipsHovered={!!tipsHovered[index]} />
                                            </span>
                                        </Tooltip>
                                    )
                                }
                                {sortField === fields[row.name as keyof typeof fields] && (sortState === 1 ? <SortedArrowAscending /> : sortState === 2 ? <SortedArrowDescending /> : '')}
                            </div>
                        </TableCell>
                    ))
                }
            </>
        )
    }

    const sortTableData = (field: string) => {
        if (field !== sortField) {
          setSortField(field);
          setSortState(1);
          const sortedData = sortData(data, field, 1, namesEnum);
          setData(sortedData);
        } else {
          const nextState = (sortState + 1) % 3;
          setSortState(nextState);
          let sortedData = data;
          if (nextState === 0) {
            sortedData = originalData;
          } else {
            sortedData = sortData(data, field, nextState, namesEnum);
          }
          setData(sortedData);
        }
      };

      useEffect(() => {
        if (dateRange === 0) {
            handleBlur()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings]);

    return (
        <div className={"pb-10"} >
            <div className={"!p-6 tablet-size:!p-0"}>
            <FilterBar
                handleBlur={handleBlur}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                groupings={groupings}
                setGroupings={setGroupings}
                collapse={collapse}
                setCollapse={setCollapse}
                selectedGroupings={selectedGroupings}
                resultArrays={resultArrays}
                resultArraysCopy={resultArraysCopy}
                copyButton={copyButton}
                freezeButton={freezeButton}
                buttonBackgroundColor={buttonBackgroundColor}
                iconFillColor={iconFillColor}
                showDelete={showDelete}
                crmType={crmType}
                setCrmType={setCrmType}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setButtonBackgroundColor={setButtonBackgroundColor}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                setIconFillColor={setIconFillColor}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                names={names}
                title={ReportName.SalesPerformanceReport}
                isToggle={false}
                isAdditionalCurrencyField={true}
                transformedGroupings={transformedGroupings}
                maxSelections={3}
                // for CSV btn
                data={data}
                fieldsForCSV={fieldsForCSV}
                disabledCSV={!showTable}
                namesEnum={namesEnum}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                // For datepicker
                rangeConfigs={rangeConfigs}
                generateRangeConfigs={generateRangeConfigs}
                dateRangeList={dateRangeList}
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
            />
            </div>
            <ScrollContainer className="scroll-container" hideScrollbars={false} mouseScroll={{rubberBand: false}} style={{cursor: 'default'}}>
            <div className={`pt-0 tablet-size:pt-6  rounded-3xl ${isMobile && 'relative'} safari-relative`} >
                <div className={'absolute z-10 overflow-hidden tablet-size:rounded-tl-xl  shadow-[4px_0_8px_-5px_rgba(0,0,0,0.25)]'} style={{width:(columnWidth as { [key: number]: number })[selectedGroupings]}}>
                    {showTable && data ?
                    (<>
                        <TableContainer style={{overflowX:'hidden'}} >
                            <Table sx={{
                                "& .MuiTableRow-root th:first-of-type": {
                                    borderTopLeftRadius: "10px",
                                },
                                "& .MuiTableRow-root th:last-of-type": {
                                    borderTopRightRadius: "10px",
                                },
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none"
                                }
                            }}>
                                <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content"}>
                                    <TableRow>
                                        <TableCell style={{fontSize:'12px', padding: '16px 8px 16px 8px', position:'sticky'}} className={"!text-odin-dark-gray-bluish"}>Groupings</TableCell>
                                    </TableRow>
                                </TableHead>
                                {GetFirstColumn((data as SalesPerformanceReportRowInterface)[(namesEnum)[transformedGroupings[0]]], 0)}
                            </Table>
                        </TableContainer>
                    </>)
                    : null}</div>
                <TableContainer className={''} style={{minWidth:(showTable?1800:0), width:selectedGroupings>5?'120%':'100%', overflow:'hidden'}}>
                    <Table className={''} sx={{
                        "& .MuiTableRow-root th:first-of-type": {
                            borderTopLeftRadius: "10px",
                        },
                        "& .MuiTableRow-root th:last-of-type": {
                            borderTopRightRadius: "10px",
                        },
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        }
                    }}>
                        <TableHead className={"bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]"}>
                            <TableRow>
                                <TableCell style={{fontSize:'12px', height: '52px', width: (columnWidth as { [key: number]: number })[selectedGroupings]+52, padding: '16px 8px 16px 8px'}}  className={"!text-odin-dark-gray-bluish"}>{showTable ? 'Groupings' : ''}</TableCell>
                                {showTable && <FirstRow />}
                            </TableRow>
                        </TableHead>
                        {showTable && data ?
                            (<>
                                {GetTableBody((data as SalesPerformanceReportRowInterface)[(namesEnum)[transformedGroupings[0 % selectedGroupings]]], 0, [])}
                            </>)
                            : null}
                    </Table>
                </TableContainer>
                {!showTable ? 
                    <DefaultTableMessage
                        tableLoading={tableLoading}
                        state={
                            groupings.length === 0 ? 
                            'noFilters' : 
                            'noData'
                        }
                    />
                    : null
                }
            </div>
            </ScrollContainer>
        </div>
    );
}

export default SalesPerformanceReport;
