import { PaletteMode } from '@mui/material';

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === 'dark' && {
        text: {
            primary: "#EBEFF7",
        },
        background: {
          default: "#17202E",
          paper: "#17202E",
        },
        action: {
          active: '#0079FF',
          hover: '#0079FF',
          selected: '#677C9F',
          disabled: '#677C9F',
        }
      }),
      ///////////////////////
      ...(mode === 'light' && {
        text: {
            primary: "#242546",
        },
        background: {
          default: '#ffffff',
          paper:'#ffffff',
        },
        action: {
          active: '#0079FF',
          hover: '#0079FF',
          selected: '#677C9F',
          disabled: '#677C9F',
        }
      }),
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
  });
  

  export default getDesignTokens;