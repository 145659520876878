import React, {ReactNode} from "react";
import {
    Checked1,
    Checked2,
    Checked3,
    Checked4,
    Checked5,
    Checked6,
    Checked7,
    Checked8, 
    Checked9,
    Checked10
} from "../../assets/icons/CheckedNumbers";

export const checkIcons:{[key: number]: ReactNode} = {
    0: <Checked1/>,
    1: <Checked2/>,
    2: <Checked3/>,
    3: <Checked4/>,
    4: <Checked5/>,
    5: <Checked6/>,
    6: <Checked7/>,
    7: <Checked8/>,
    8: <Checked9/>,
    9: <Checked10/>
}
