import React, { useContext } from 'react';
import {
  Cell,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import { CustomColumnMeta, SubCategory } from '../../../../../types/decline-response-report.types';
import Tooltip from '@mui/material/Tooltip';
import SortedArrowAscending from '../../../../../assets/icons/SortedArrowAscending';
import SortedArrowDescending from '../../../../../assets/icons/SortedArrowDescending';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import PaginationComponent from '../../../../molecules/PaginationComponent/PaginationComponent';
import {Icon} from "@tremor/react";
import {ChevronRightIcon, ChevronDownIcon} from "@heroicons/react/outline";
import { createColumnHelper } from "@tanstack/react-table";
import { tableHeadingNames } from '../utils/config';
import { DeclineResponseReport } from '../../../../../types/decline-response-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';
import DefaultTableMessage from '../../../../organism/DefaultTableMessage/DefaultTableMessage';

interface DeclineResponseTableProps {
  data: DeclineResponseReport[];
  setTransformedTableDataCSV: React.Dispatch<React.SetStateAction<DeclineResponseReport[]>>
  heading: keyof typeof tableHeadingNames;
}

const DeclineResponseTable = ({ data, setTransformedTableDataCSV, heading }: DeclineResponseTableProps) => {
  const columns = [
    createColumnHelper<DeclineResponseReport>().accessor(row => row[tableHeadingNames[heading] as keyof DeclineResponseReport], {
      header: `${heading}`,
      cell: info =>  info.getValue() ? capitalizeSpecificWords(info.getValue() as string)  : '---',
      meta: { isFirstColumn: true },
    }),
    createColumnHelper<DeclineResponseReport>().accessor('count', {
      header: 'Count',
      cell: info => info.getValue(),
      meta: { isBorderLeft: true, isAlignTextRight: true, isLastColumn: true },
    }),
  ];
  
  const subColumns = [
    createColumnHelper<SubCategory>().accessor('reason', {
      header: 'Reason',
      cell: info => info.getValue(),
      meta: { isFirstColumn: true },
    }),
    createColumnHelper<SubCategory>().accessor('count', {
      header: 'Count',
      cell: info => info.getValue(),
      meta: { isBorderLeft: true, isAlignTextRight: true, isLastColumn: true },
    }),
  ];

  const { themedColors, theme } = useContext(ThemeContext)!;
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [initialData] = React.useState<DeclineResponseReport[]>(data);

  const isMobile = useMediaQuery('(max-width: 1023px)')

  const filteredData = React.useMemo(() => {
    const filteredData = data

    if (sorting.length > 0) {
      if (sorting[0].id === 'Card Brand') {
        filteredData.forEach((item) => {
          item.subCategories.sort((a, b) => {
            if (sorting[0].desc) {
              return a.reason < b.reason ? 1 : -1;
            } else {
              return a.reason > b.reason ? 1 : -1;

          }});
        })
      } else if (sorting[0].id === 'count') {
        filteredData.forEach((item) => {
          item.subCategories.sort((a, b) => {
            if (sorting[0].desc) {
              return a.count < b.count ? 1 : -1;
            } else {
              return a.count > b.count ? 1 : -1;
            }
          });
        }
        )
      }
    } else {
      const filteredData = initialData
      setTransformedTableDataCSV(filteredData);
      return filteredData;
    }

    setTransformedTableDataCSV(filteredData);
    return filteredData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sorting]);

  const table = useReactTable({
    columns: columns,
    data: filteredData,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    //no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    state: {
      pagination,
      sorting,
    },
    // autoResetPageIndex: false, // turn off page index reset when sorting or filtering
  });
  
  return (
    <div className='pt-0 tablet-size:pt-6 w-full relative max-w-full'>
        {
          filteredData.length > 0 ? (
            <>
            <ScrollContainer
              className='scroll-container'
              hideScrollbars={false}
              mouseScroll={{ rubberBand: false }}
              style={{ 
                cursor: 'default', 
                borderTopRightRadius: !isMobile ? 8 : 0,
                borderTopLeftRadius: !isMobile ? 8 : 0,
              }}
            >
              <table
              className='w-full relative max-w-full border border-blue-50 dark:border-odin-dark-gray-light'
              style={{
                minWidth: 400,
              }}
            >
              <thead className='bg-[#E6F2FF] dark:bg-[#1C283B] dark:text-odin-dark-content h-[47px]'>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          const isLastInGroup = (header.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                          const isFontBold = (header.column.columnDef.meta as CustomColumnMeta)?.isFontBold;
                          const headerBackground = header.column.getIsSorted() ? 'bg-[#CEE5FC] dark:bg-slate-800' : '';

                          const isBorderLeft = (header.column.columnDef.meta as CustomColumnMeta)?.isBorderLeft;
                          const isAlignTextRight = (header.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
                          const isLastColumn = (header.column.columnDef.meta as CustomColumnMeta)?.isLastColumn;
    
                          return (
                            <th key={header.id} colSpan={header.colSpan} 
                              style={{
                                borderRight: isLastInGroup ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : undefined,
                                borderBottom: theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: themedColors.gray.bluish,
                                fontWeight: isFontBold ? '600' : '600',
                                borderLeft: isBorderLeft ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : '',
                                width: isLastColumn ? '104px' : 'auto',
                              }}
                              className={`${headerBackground}`}
                            >
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                                className={`font-inherit text-left ${
                                  header.column.getCanSort() ? 'cursor-pointer' : ''
                                } 
                                  !h-[56px] !py-0 !px-2 flex justify-start items-center`}
                                  style={{
                                    justifyContent: isAlignTextRight ? 'right' : 'left',
                                    fontSize: '12px',
                                    lineHeight: '14.52px',
                                  }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <span className='ml-2'><SortedArrowAscending /></span>,
                                  desc: <span className='ml-2'><SortedArrowDescending /></span>,
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    )
                  )
                })}
              </thead>
    
              <tbody>
                {table.getRowModel().rows.map((row) => {
    
                  return (
                    <React.Fragment key={row.id}>
                        <tr key={row.id} style={{ backgroundColor: 'inherit' }} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'>
                        {row.getVisibleCells().map((cell) => {

                            function getIsTooltop(cell: Cell<DeclineResponseReport, unknown>) {
                              if (!cell.getValue()) return '';
                            const cellWidth = cell.column.getSize()
                            const charCount = Math.floor(cellWidth! / 8);

                            return cell.getValue() !== null && (cell.getValue() as string).length > charCount ? cell.getValue() : '';
                            }

                            const isFirstColumn = (cell.column.columnDef.meta as CustomColumnMeta)?.isFirstColumn;
                            const isBorderLeft = (cell.column.columnDef.meta as CustomColumnMeta)?.isBorderLeft;
                            const isAlignTextRight = (cell.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;

                            return (
                            <td
                                key={cell.id}
                                id={cell.id}
                                className='!text-sm !h-[42px] !py-0 !font-normal'
                                style={{
                                  borderBottom: row.getIsExpanded()
                                      ? ''
                                      :  theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                                  color: themedColors.content,
                                  maxWidth: 50,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: row.getIsExpanded() ? theme === 'light' ? '#DDEDFD' : '#313E56' : 'inherit',
                                  borderLeft: isBorderLeft ? theme === 'light' ? '1px solid #DFE9FE' : '1px solid #353E50' : '',
                                  paddingLeft:  8,
                                  paddingRight: 8,
                                  cursor: isFirstColumn ? 'pointer' : 'default',
                                }}
                                onClick={isFirstColumn ? () => row.toggleExpanded() : undefined}
                            >
                                <Tooltip
                                classes={{
                                    tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                    arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                                }}
                                title={getIsTooltop(cell) as string}
                                placement="left"
                                arrow
                                >
                                <span className='flex items-center gap-2' style={{justifyContent: isAlignTextRight ? 'right' : 'left', fontWeight: isFirstColumn ? '500' : '400',fontSize: '14px',lineHeight: '16.94px', }}>
                                    { isFirstColumn ? (row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}/> : <Icon icon={ChevronRightIcon} style={{color:themedColors.content}}/>) : '' }
                                    {flexRender(cell.column.columnDef.cell,cell.getContext())}
                                </span>
                                </Tooltip>
                            </td>
                            );
                        })}
                        </tr>
                        {
                            row.getIsExpanded() &&
                            row.original.subCategories.map((subCategory, index: number) => {
                                return (
                                <tr key={index} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F] bg-[#F5FAFF] dark:bg-[#202939]'>
                                    {subColumns.map((subColumn) => {
                                      const isBorderLeft = (subColumn.meta as CustomColumnMeta)?.isBorderLeft;
                                      const isAlignTextRight = (subColumn.meta as CustomColumnMeta)?.isAlignTextRight;
                                      const isFirstColumn = (subColumn.meta as CustomColumnMeta)?.isFirstColumn;

                                    return (
                                        <td
                                        key={subColumn.id}
                                        className='!text-sm !h-[42px] !py-0 !font-normal'
                                        style={{
                                            borderBottom: theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                                            color: themedColors.content,
                                            maxWidth: 50,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            paddingLeft: subColumn.accessorKey === 'reason' ? 49 : 8,
                                            borderLeft: isBorderLeft ? theme === 'light' ? '1px solid #DFE9FE' : '1px solid #353E50' : '',
                                            paddingRight: 8,
                                            textAlign: isAlignTextRight ? 'right' : 'left',
                                        }}
                                        >
                                        <Tooltip
                                            classes={{
                                            tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                            arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                                            }}
                                            title={""}
                                            placement="left-start"
                                            arrow
                                        >
                                            <span style={{fontSize: '14px',lineHeight: '16.94px', fontWeight: isFirstColumn ? '500' : '400'}}>{subColumn.accessorKey === 'reason' ? subCategory.reason : subCategory.count}</span>
                                        </Tooltip>
                                        </td>
                                    );
                                    })}
                                </tr>
                                );
                            })
                        }
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            </ScrollContainer>
            {/* Pagination */}

            <PaginationComponent
              firstPage={table.firstPage}
              previousPage={table.previousPage}
              getCanPreviousPage={table.getCanPreviousPage}
              getCanNextPage={table.getCanNextPage}
              nextPage={table.nextPage}
              lastPage={table.lastPage}
              getPageCount={table.getPageCount}
              pageIndex={table.getState().pagination.pageIndex}
              setPageIndex={table.setPageIndex}
              setPageSize={table.setPageSize}
              pageSize={table.getState().pagination.pageSize}
              getRowCount={table.getRowCount}
            />
          </>
          ) : (
            <DefaultTableMessage
              state={'noData'}
            />
          )
        }
      
    </div>
  );
};

export default DeclineResponseTable;
