import getSymbolFromCurrency from 'currency-symbol-map';
import { RetentionReportData, TransactionReportRowInterface } from '../types/retention-report.types';

function transformDataToCSVFormat(
  data: RetentionReportData,
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string }
) {
  const result: { [key: string]: string }[] = [];
  const groupingLevels = new Set<number>();

  function processItem(item: TransactionReportRowInterface, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string } = {
      cancels: parseFloat(item.cancels).toFixed(2) + ' %' || '0.00',
      chargebacks: parseFloat(item.chargebacks).toFixed(2) + ' %' || '0.00',
      rdr: parseFloat(item.rdr).toFixed(2) + ' %' || '0.00',
      alerts: parseFloat(item.alerts).toFixed(2) + ' %' || '0.00',
      gross_revenue: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.gross_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      partial_refund: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.partial_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      full_refund: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.full_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      net: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.net?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '')
    };

    if (item.cycles) {
      baseItem['Cycles 2+ Pending Orders'] = item.cycles.pending_orders?.toLocaleString() || '0';
      baseItem['Cycles 2+ Rebill Schedule Is Success'] = item.cycles.rebill_schedule_is_success?.toLocaleString() || '0';
      baseItem['Cycles 2+ Declined'] = item.cycles.declined?.toLocaleString() || '0';
      baseItem['Cycles 2+ Recycle Success'] = item.cycles.recycle_success?.toLocaleString() || '0';
      baseItem['Cycles 2+ Recycle Failed'] = item.cycles.recycle_failed?.toLocaleString() || '0';
      baseItem['Cycles 2+ Cancels'] = item.cycles.cancels?.toLocaleString() || '0';
      baseItem['Cycles 2+ Chargebacks'] = parseFloat(item.cycles.retention_chargebacks).toFixed(2) + ' %' || '0.00';
      baseItem['Cycles 2+ RDR'] = parseFloat(item.cycles.retention_rdr).toFixed(2) + ' %' || '0.00';
      baseItem['Cycles 2+ Alerts'] = parseFloat(item.cycles.retention_alert).toFixed(2) + ' %' || '0.00';
      baseItem['Cycles 2+ Gross Revenue'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_gross_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
      baseItem['Cycles 2+ Partial Refund'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_partial_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
      baseItem['Cycles 2+ Full Refund'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_full_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
      baseItem['Cycles 2+ Net'] = `${item.cycles.currency ? getSymbolFromCurrency(item.cycles.currency) : ''}${item.cycles.retention_net?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` || '';
    }

    if (item.total) {
      baseItem["Total Avg LTV"] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.total.avgLtv?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '');
      baseItem["Total Gross Revenue"] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.total.grossRevenueTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '');
      baseItem["Total Net Revenue"] = (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.total.netRevenueTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '');
    }

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    baseItem[`Grouping Level ${level + 1}`] = item.name || '';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: TransactionReportRowInterface) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  if (data.currency && Array.isArray(data.currency)) {
    // @ts-ignore
    data.currency.forEach((currencyItem: DataItem) => processItem(currencyItem));
  }

  const groupingFields = Array.from(groupingLevels).map(
    (level) => `Grouping Level ${level + 1}`
  );

  return result.map((item) => {
    const transformedItem: { [key: string]: string } = {};
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field];
    });
    Object.keys(fieldsForCSV).forEach((key) => {
      transformedItem[key] = item[fieldsForCSV[key]];
    });
    Object.keys(item).forEach((key) => {
      if (!groupingFields.includes(key) && !Object.keys(fieldsForCSV).includes(key)) {
        transformedItem[key] = item[key];
      }
    });
    return transformedItem;
  });
}

export default transformDataToCSVFormat;
