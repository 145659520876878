import React, { useContext } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';

interface ScrollToNextIconProps {
    disabled: boolean;
}


const ScrollToNextIcon = ({disabled}: ScrollToNextIconProps) => {
    const { theme } = useContext(ThemeContext)!;

    const color = disabled 
    ? (theme === 'light' ? '#677C9F' : '#677C9F')
    : (theme === 'light' ? '#242546' : '#EBEFF7'); 

  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.89626 0.5L0.60376 1.7925L4.80209 6L0.60376 10.2075L1.89626 11.5L7.39626 6L1.89626 0.5Z" fill={color}/>
    </svg>
  )
}

export default ScrollToNextIcon