import { CancellationReportData, CancellationRowInterface } from "../types/cancellation-report.types";

function transformDataToCSVFormat(
  data: CancellationReportData,
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string }
) {
  const result: { [key: string]: string | number }[] = [];
  const groupingLevels = new Set<number>();

  function processItem(item: CancellationRowInterface, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string | number } = {
      canceled_orders: item.canceled_orders || '',
      refunded: item.refunded || '',
      cancelation_percentage: item.cancelation_percentage ? Number(item.cancelation_percentage)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0.00%',
      cancelled_due_to_refund: item.cancelled_due_to_refund || '',
      blacklist: item.blacklist || '',
      cannot_afford: item.cannot_afford || '',
      did_not_order: item.did_not_order || '',
      did_not_receive: item.did_not_receive || '',
      does_not_like: item.does_not_like || '',
      possible_dispute: item.possible_dispute || '',
      not_as_expected: item.not_as_expected || '',
      chargeback: item.chargeback || '',
      doctor_advised: item.doctor_advised || '',
      not_aware_of_trial_terms: item.not_aware_of_trial_terms || '',
      product_not_working: item.product_not_working || '',
      product_returned: item.product_returned || '',
      too_much_product: item.too_much_product || '',
      ethoca_alerts: item.ethoca_alerts || '',
      unknown_cancellation_reason: item.unknown_cancellation_reason || '',
    };

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    baseItem[`Grouping Level ${level + 1}`] = item.name || '';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: CancellationRowInterface) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  // Iterate through all potential top-level keys defined in namesEnum
  Object.values(namesEnum).forEach((topLevelKey) => {
    if (data[topLevelKey] && Array.isArray(data[topLevelKey])) {
      // @ts-ignore
      data[topLevelKey].forEach((item) => processItem(item));
    }
  });

  const groupingFields = Array.from(groupingLevels).map(
    (level) => `Grouping Level ${level + 1}`
  );

  return result.map((item) => {
    const transformedItem: { [key: string]: string | number } = {};
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field];
    });
    Object.keys(fieldsForCSV).forEach((key) => {
      transformedItem[key] = item[fieldsForCSV[key]];
    });
    return transformedItem;
  });
}

export default transformDataToCSVFormat;