import React from "react";

export function Checked1() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M13.534 6.818V17h-1.542V8.36h-.06L9.498 9.95V8.48l2.54-1.66h1.497z"
            ></path>
        </svg>
    )
}

export function Checked2() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M8.686 17v-1.114l3.446-3.57c.368-.387.67-.727.91-1.018.242-.295.422-.575.542-.84.119-.266.178-.547.178-.846 0-.338-.08-.63-.238-.875a1.56 1.56 0 00-.651-.572 2.071 2.071 0 00-.93-.203c-.365 0-.683.074-.955.223-.271.15-.48.36-.626.632-.146.272-.219.59-.219.954H8.676c0-.62.143-1.161.428-1.625a2.904 2.904 0 011.173-1.08c.498-.258 1.063-.387 1.696-.387.64 0 1.203.128 1.69.383.49.252.873.596 1.148 1.034.275.434.413.925.413 1.471 0 .378-.071.748-.214 1.11-.139.36-.383.763-.73 1.207-.348.441-.832.976-1.452 1.606l-2.024 2.118v.074h4.584V17H8.686z"
            ></path>
        </svg>
    )
}

export function Checked3() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M12.012 17.14c-.682 0-1.292-.119-1.83-.354-.533-.235-.955-.562-1.267-.98a2.6 2.6 0 01-.497-1.46h1.561c.02.3.121.563.303.785.186.218.428.387.726.507.299.12.63.179.995.179.4 0 .755-.07 1.063-.209.312-.14.556-.333.731-.582.176-.252.264-.542.264-.87 0-.341-.088-.641-.264-.9-.172-.261-.426-.467-.76-.616-.332-.15-.733-.224-1.204-.224h-.86v-1.253h.86c.378 0 .71-.068.995-.204a1.63 1.63 0 00.676-.566c.162-.245.244-.532.244-.86 0-.315-.072-.589-.214-.82a1.436 1.436 0 00-.597-.552 1.938 1.938 0 00-.905-.2 2.35 2.35 0 00-.93.185c-.284.119-.516.291-.695.517a1.33 1.33 0 00-.289.8H8.632c.016-.55.179-1.034.487-1.452a3.15 3.15 0 011.233-.979 4.013 4.013 0 011.7-.353c.653 0 1.217.128 1.69.383.478.252.846.588 1.104 1.009.262.42.391.882.388 1.382.003.57-.156 1.054-.477 1.452a2.313 2.313 0 01-1.273.8v.08c.676.102 1.2.371 1.571.805.375.434.56.973.557 1.616a2.515 2.515 0 01-.467 1.506 3.181 3.181 0 01-1.278 1.05c-.54.251-1.159.377-1.855.377z"
            ></path>
        </svg>
    )
}

export function Checked4() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M8.186 15.011V13.77l4.4-6.95h.979v1.829h-.622l-3.147 4.981v.08h6.03v1.302h-7.64zM13.013 17v-2.367l.01-.566V6.818h1.457V17h-1.467z"
            ></path>
        </svg>
    )
}

export function Checked5() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M12 17.14c-.624 0-1.184-.12-1.681-.359a3.053 3.053 0 01-1.183-.994 2.625 2.625 0 01-.473-1.442h1.492c.036.438.23.797.582 1.079.35.282.772.423 1.262.423.391 0 .738-.09 1.04-.269.304-.182.543-.432.715-.75.176-.319.264-.682.264-1.09 0-.414-.09-.783-.269-1.108a1.983 1.983 0 00-.74-.766 2.092 2.092 0 00-1.074-.283c-.309 0-.618.053-.93.16a2.158 2.158 0 00-.756.417l-1.407-.21.572-5.13h5.598v1.318h-4.32l-.323 2.848h.06c.198-.192.462-.353.79-.482.331-.13.686-.194 1.064-.194.62 0 1.171.148 1.655.443.487.295.87.697 1.149 1.208.281.507.42 1.09.417 1.75.004.66-.146 1.248-.447 1.765a3.24 3.24 0 01-1.243 1.223c-.527.295-1.132.442-1.815.442z"
            ></path>
        </svg>
    )
}

export function Checked6() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M12.084 17.14a4.105 4.105 0 01-1.342-.25 3.146 3.146 0 01-1.194-.795c-.358-.371-.644-.87-.86-1.496-.212-.627-.318-1.409-.318-2.347 0-.888.088-1.677.264-2.366.179-.69.435-1.271.77-1.745.335-.478.74-.84 1.213-1.09a3.383 3.383 0 011.596-.372c.607 0 1.145.12 1.616.358.47.239.852.568 1.143.99.295.42.483.9.562 1.436h-1.516a1.96 1.96 0 00-.612-1.039c-.305-.268-.702-.403-1.193-.403-.746 0-1.327.325-1.745.975-.414.65-.623 1.553-.626 2.71h.074a2.76 2.76 0 01.647-.736c.258-.206.546-.364.865-.473.321-.113.66-.169 1.014-.169.59 0 1.123.144 1.6.433.481.285.864.68 1.15 1.183.284.504.427 1.08.427 1.73a3.55 3.55 0 01-.443 1.765 3.244 3.244 0 01-1.233 1.253c-.53.305-1.15.454-1.86.447zm-.005-1.293c.391 0 .74-.096 1.049-.289a2.08 2.08 0 00.73-.775c.18-.325.27-.688.27-1.09 0-.39-.089-.746-.264-1.068a1.99 1.99 0 00-.716-.766 1.907 1.907 0 00-1.034-.283 1.985 1.985 0 00-1.477.636 2.27 2.27 0 00-.447.686c-.106.256-.16.526-.16.81a2.161 2.161 0 00.994 1.845c.312.196.664.294 1.055.294z"
            ></path>
        </svg>
    )
}

export function Checked7() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M9.269 17l4.444-8.79v-.074h-5.14V6.818h6.731V8.18L10.874 17H9.27z"
            ></path>
        </svg>
    )
}

export function Checked8() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect width="24" height="24" fill="#0079FF" rx="12"></rect>
            <path
                fill="#fff"
                d="M11.997 17.14c-.713 0-1.342-.123-1.89-.369-.543-.245-.969-.583-1.277-1.014a2.44 2.44 0 01-.457-1.471 2.593 2.593 0 011.004-2.098c.311-.246.66-.402 1.044-.468v-.06a2.055 2.055 0 01-1.218-.815 2.385 2.385 0 01-.453-1.452c-.003-.52.135-.984.413-1.392a2.88 2.88 0 011.159-.969c.49-.235 1.049-.353 1.675-.353.62 0 1.173.12 1.66.358.49.235.877.558 1.159.97.281.407.424.87.427 1.386a2.419 2.419 0 01-.467 1.452c-.308.421-.71.693-1.203.816v.06c.381.066.724.221 1.029.466.308.243.553.546.736.91.185.362.28.758.283 1.189-.003.55-.159 1.04-.467 1.471-.308.431-.736.769-1.283 1.014-.543.246-1.168.368-1.874.368zm0-1.259c.42 0 .785-.07 1.094-.208.308-.143.546-.34.716-.592.169-.255.255-.553.258-.895a1.676 1.676 0 00-.278-.94 1.86 1.86 0 00-.731-.64 2.316 2.316 0 00-1.06-.235c-.4 0-.756.078-1.068.234-.312.156-.557.37-.736.641a1.64 1.64 0 00-.263.94c-.004.341.078.64.243.895.17.252.41.45.721.592.312.139.68.208 1.104.208zm0-4.738c.338 0 .638-.068.9-.203.262-.136.467-.325.616-.567.153-.242.23-.525.234-.85a1.575 1.575 0 00-.229-.835 1.453 1.453 0 00-.611-.552 1.988 1.988 0 00-.91-.2c-.351 0-.66.067-.925.2-.262.129-.465.313-.611.552a1.537 1.537 0 00-.214.835c-.003.325.07.608.219.85s.354.43.616.567c.265.136.57.204.915.204z"
            ></path>
        </svg>
    );
}

export function Checked9() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#0079FF"/>
            <path
                d="M11.9149 6.67898C12.3689 6.68229 12.8164 6.76515 13.2572 6.92756C13.698 7.08996 14.0957 7.35511 14.4504 7.72301C14.8083 8.09091 15.0934 8.58807 15.3055 9.21449C15.5209 9.83759 15.6303 10.6132 15.6336 11.5412C15.6336 12.4328 15.5441 13.2266 15.3651 13.9226C15.1862 14.6153 14.9293 15.2003 14.5945 15.6776C14.2631 16.1548 13.8604 16.5178 13.3865 16.7663C12.9125 17.0149 12.3789 17.1392 11.7856 17.1392C11.1791 17.1392 10.6405 17.0199 10.1698 16.7812C9.69919 16.5426 9.31638 16.2128 9.0214 15.7919C8.72641 15.3677 8.54247 14.8804 8.46955 14.3303H9.98588C10.0853 14.7678 10.2875 15.1224 10.5924 15.3942C10.9007 15.6626 11.2984 15.7969 11.7856 15.7969C12.5313 15.7969 13.113 15.4721 13.5306 14.8224C13.9482 14.1695 14.1587 13.258 14.162 12.0881H14.0825C13.9101 12.3731 13.6947 12.6184 13.4362 12.8239C13.181 13.0294 12.8943 13.1884 12.5761 13.3011C12.2579 13.4138 11.9182 13.4702 11.5569 13.4702C10.9703 13.4702 10.4366 13.326 9.95605 13.0376C9.47547 12.7493 9.09266 12.3532 8.80762 11.8494C8.52258 11.3456 8.38006 10.7706 8.38006 10.1243C8.38006 9.4813 8.52589 8.89796 8.81756 8.37429C9.11254 7.85062 9.52353 7.43632 10.0505 7.13139C10.5808 6.82315 11.2023 6.67235 11.9149 6.67898ZM11.9198 7.97159C11.532 7.97159 11.1824 8.06771 10.8708 8.25994C10.5626 8.44886 10.319 8.70573 10.14 9.03054C9.96103 9.35204 9.87154 9.70999 9.87154 10.1044C9.87154 10.4988 9.95771 10.8568 10.1301 11.1783C10.3057 11.4964 10.5444 11.75 10.846 11.9389C11.1509 12.1245 11.4989 12.2173 11.89 12.2173C12.1817 12.2173 12.4535 12.161 12.7053 12.0483C12.9572 11.9356 13.1776 11.7798 13.3666 11.581C13.5555 11.3788 13.703 11.1501 13.809 10.8949C13.9151 10.6397 13.9681 10.3712 13.9681 10.0895C13.9681 9.71496 13.8786 9.36695 13.6997 9.04545C13.524 8.72396 13.282 8.46544 12.9738 8.26989C12.6656 8.07102 12.3142 7.97159 11.9198 7.97159Z"
                fill="white"/>
        </svg>

    );
}

export function Checked10() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#0079FF"/>
            <path d="M9.01518 6.81818V17H7.47399V8.35938H7.41433L4.97825 9.95028V8.47869L7.51873 6.81818H9.01518ZM15.3142 17.169C14.5287 17.1657 13.8575 16.9586 13.3007 16.5476C12.7439 16.1366 12.318 15.5384 12.023 14.7528C11.728 13.9673 11.5805 13.0211 11.5805 11.9141C11.5805 10.8104 11.728 9.86742 12.023 9.08523C12.3213 8.30303 12.7488 7.70644 13.3057 7.29545C13.8658 6.88447 14.5353 6.67898 15.3142 6.67898C16.0931 6.67898 16.7609 6.88613 17.3177 7.30043C17.8746 7.71141 18.3005 8.308 18.5954 9.0902C18.8937 9.86908 19.0429 10.8104 19.0429 11.9141C19.0429 13.0244 18.8954 13.9723 18.6004 14.7578C18.3054 15.54 17.8795 16.1383 17.3227 16.5526C16.7659 16.9635 16.0964 17.169 15.3142 17.169ZM15.3142 15.8416C16.0036 15.8416 16.5422 15.5052 16.93 14.8324C17.3211 14.1596 17.5166 13.1868 17.5166 11.9141C17.5166 11.0689 17.4271 10.3546 17.2481 9.77131C17.0725 9.18466 16.8189 8.74053 16.4875 8.43892C16.1594 8.134 15.7683 7.98153 15.3142 7.98153C14.6281 7.98153 14.0895 8.3196 13.6984 8.99574C13.3073 9.67187 13.1101 10.6446 13.1068 11.9141C13.1068 12.7625 13.1946 13.4801 13.3703 14.0668C13.5493 14.6501 13.8028 15.0926 14.1309 15.3942C14.4591 15.6925 14.8535 15.8416 15.3142 15.8416Z" fill="white"/>
        </svg>

    );
}