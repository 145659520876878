/** @type {import('tailwindcss').Config} */
/* eslint-disable max-len */
module.exports = {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',

    // Path to the Tremor module
    './node_modules/@tremor/**/*.{js,ts,jsx,tsx}',
    './node_modules/flowbite/**/*.js',
  ],
  theme: {
    transparent: 'transparent',
    current: 'currentColor',
    extend: {
      colors: {
        odin: {
          light: {
            blue: {
              extra_light: '#F6FAFD',
              very_light: '#DFE9FE',
              light: '#F7F9FC',
              medium: '#0079FF',
              medium_hover: '#2a52be', // color for hover effect for btn
              medium_2: '#1C273B',
              dark: '#17202E',
            },
            red: {
              light: '#FADDDF',
              bright: '#FF3B30',
            },
            green: {
              medium: '#2AA300',
            },
            gray: {
              light: '#EAEEF6',
              light_2: '#E5E7EB',
              bluish_light: '#8D9DB7',
              bluish: '#677C9F',
              dark_bluish: '#282f39',
              dark_bluish_2: '#2B384B',
              darkest_bluish: '#212c3f',
            },
            black: '#000000',
            white: '#ffffff',
            content: '#242546',
            background_1: '#ffffff',
            border: '#EAEEF6',
            sceleton: '#f3f4f6',
          },
          dark: {
            blue: {
              extra_light: '#F6FAFD',
              very_light: '#DFE9FE',
              light: '#222E43',
              medium: '#0079FF',
              medium_2: '#1C273B',
              dark: '#17202E',
              dark_2: '#273349',
              extra_dark: '#101827',
              special: '#1B2535',
              'custom-blue-hover': 'rgba(144, 202, 249, 0.08)',
            },
            red: {
              light: '#FADDDF',
              bright: '#FF3B30',
            },
            green: {
              medium: '#2AA300',
            },
            gray: {
              light: '#273349',
              light_2: '#273349',
              bluish_light: '#8D9DB7',
              bluish: '#677C9F',
              dark: '#2B384B',
              extra_dark: '#495D80',
              dark_bluish: '#282f39',
              dark_bluish_2: '#2B384B',
              darkest_bluish: '#212c3f',
            },
            black: '#000000',
            white: '#ffffff',
            content: '#EBEFF7',
            background_1: '#17202E',
            border: '#273349',
            sceleton: '#2B384B',
          },
        },

        // light mode
        tremor: {
          brand: {
            faint: '#eff6ff', // blue-50
            muted: '#bfdbfe', // blue-200
            subtle: '#60a5fa', // blue-400
            DEFAULT: '#3b82f6', // blue-500
            emphasis: '#1d4ed8', // blue-700
            inverted: '#ffffff', // white
          },
          background: {
            muted: '#f9fafb', // gray-50
            subtle: '#f3f4f6', // gray-100
            DEFAULT: '#ffffff', // white
            emphasis: '#374151', // gray-700
          },
          border: {
            DEFAULT: '#e5e7eb', // gray-200
          },
          ring: {
            DEFAULT: '#e5e7eb', // gray-200
          },
          content: {
            subtle: '#9ca3af', // gray-400
            DEFAULT: '#6b7280', // gray-500
            emphasis: '#374151', // gray-700
            strong: '#111827', // gray-900
            inverted: '#ffffff', // white
          },
        },
        // dark mode
        'dark-tremor': {
          brand: {
            faint: '#0B1229', // custom
            muted: '#172554', // blue-950
            subtle: '#1e40af', // blue-800
            DEFAULT: '#3b82f6', // blue-500
            emphasis: '#60a5fa', // blue-400
            inverted: '#030712', // gray-950
          },
          background: {
            muted: '#131A2B', // custom
            subtle: '#1f2937', // gray-800
            DEFAULT: '#111827', // gray-900
            emphasis: '#d1d5db', // gray-300
          },
          border: {
            DEFAULT: '#1f2937', // gray-800
          },
          ring: {
            DEFAULT: '#1f2937', // gray-800
          },
          content: {
            subtle: '#4b5563', // gray-600
            DEFAULT: '#6b7280', // gray-600
            emphasis: '#e5e7eb', // gray-200
            strong: '#f9fafb', // gray-50
            inverted: '#000000', // black
          },
        },
        backgroundColor: {
          'light-blue': '#F7F9FC',
          'semi-light-blue': '#EAEEF6',
          'light-green': '#EFFDFA',
          'light-yellow': '#FFFAED',
        },
        textColor: {
          'light-gray': '#677C9F',
          'dark-gray': '#6b7280',
          black: '#111827',
        },
        borderColor: {
          'semi-light-blue': '#EAEEF6',
        },
      },
      boxShadow: {
        // light
        'tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
        'tremor-card':
          '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        'tremor-dropdown':
          '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        // dark
        'dark-tremor-input': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
        'dark-tremor-card':
          '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        'dark-tremor-dropdown':
          '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',

        btnShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      },
      borderRadius: {
        'tremor-small': '0.375rem',
        'tremor-default': '0.5rem',
        'tremor-full': '9999px',
      },
      fontSize: {
        'tremor-label': ['0.75rem'],
        'tremor-default': ['0.875rem', { lineHeight: '1.25rem' }],
        'tremor-title': ['1.125rem', { lineHeight: '1.75rem' }],
        'tremor-metric': ['1.875rem', { lineHeight: '2.25rem' }],
      },
    },
    screens: {
      xs: '280px',
      s: '320px',
      'mobile-size': '375px',
      'tablet-size': '1024px',
      'big-mobile-size': '425px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      'desktop-size': '1440px',
      '2xl': '1536px',
      '3xl': '1700px',
      '4xl': '2960px',
      tall: { raw: '(max-height: 560px) and (min-width:812px)' },
    },
  },
  safelist: [
    {
      pattern:
        /^(bg-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected'],
    },
    {
      pattern:
        /^(text-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected'],
    },
    {
      pattern:
        /^(border-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
      variants: ['hover', 'ui-selected'],
    },
    {
      pattern:
        /^(ring-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
    },
    {
      pattern:
        /^(stroke-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
    },
    {
      pattern:
        /^(fill-(?:slate|gray|zinc|neutral|stone|red|orange|amber|yellow|lime|green|emerald|teal|cyan|sky|blue|indigo|violet|purple|fuchsia|pink|rose)-(?:50|100|200|300|400|500|600|700|800|900|950))$/,
    },
    ...[
      '[#FF827C]',
      '[#0095AC]',
      '[#FBC849]',
      '[#87D0F9]',
      '[#F28397]',
      '[#92D493]',
      '[#BEA2EC]',
      '[#FFAA4FE3]',
      '[#57B3B9C9]',
      '[#EB76C3CC]',
      '[#3b82f6]',
      '[#6366f1]',
      '[#a7a9f8]',
      '[#a855f7]',
      '[#06b6d4]',
      '[#5acbff]',
      '[#0ea4e9]',
      '[#0095ac]',
      '[#10c075]',
      '[#e5e911]',
      '[#e8b90e]',
      '[#e9920c]',
      '[#e96a0f]',
      '[#e96a0f]',
    ].flatMap((customColor) => [
      `bg-${customColor}`,
      `border-${customColor}`,
      `hover:bg-${customColor}`,
      `hover:border-${customColor}`,
      `hover:text-${customColor}`,
      `fill-${customColor}`,
      `ring-${customColor}`,
      `stroke-${customColor}`,
      `text-${customColor}`,
      `ui-selected:bg-${customColor}`,
      `ui-selected:border-${customColor}`,
      `ui-selected:text-${customColor}`,
    ]),
  ],

  plugins: [require('@headlessui/tailwindcss'), require('flowbite/plugin')],
  darkMode: 'class',
};
