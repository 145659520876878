import React, { useContext } from 'react';
import { Chip } from '@mui/material';
import CloseIcon from '../../../../../assets/icons/CloseIcon';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';

interface SelectedOptionsProps {
  selectedItems: { [key: string]: { [subItemId: string]: boolean } };
  menuItems: {
    id: string;
    label: string;
    subItems?: { id: string; label: string }[];
  }[];
  onSelectionChange: (selectedItems: { [key: string]: { [subItemId: string]: boolean } }) => void;
}

const SelectedOptions = ({ selectedItems, menuItems, onSelectionChange }: SelectedOptionsProps) => {
    const { theme } = useContext(ThemeContext)!;

  const handleRemove = (parentId: string, subItemId: string) => {
    const newSelectedItems = { ...selectedItems, [parentId]: {...selectedItems[parentId], [subItemId]: false} };
    onSelectionChange(newSelectedItems);
  };

  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {menuItems.map((item) =>
        item.subItems?.map(
          (subItem) =>
            selectedItems[item.id]?.[subItem.id] && (
              <Chip
                key={`${item.id}-${subItem.id}`}
                label={subItem.label}
                onDelete={() => handleRemove(item.id, subItem.id)}
                deleteIcon={<span><CloseIcon /></span>}
                variant="outlined"
                sx={{ 
                    margin: '2px',
                    "&.MuiButtonBase-root.MuiChip-root": {
                        height: '44px',
                        padding: '11px 20px',
                        display: 'flex',
                        gap: '12px',
                        justifyContent: 'space-between',
                        borderRadius: '50px',
                        borderColor: theme === 'light' ? '#EAEEF6' : '#2B384B',
                        ".MuiChip-label": {
                            padding: '0',
                            color: theme === 'light' ? '#677C9F' : '#677C9F',
                        },
                        ".MuiChip-deleteIcon": {
                            padding: '0',
                            margin: '0',
                        }
                    },
                    "&.MuiButtonBase-root.MuiChip-root MuiChip-label": {
                        padding: '0',
                        margin: '0',
                    },
                }}
              />
            )
        )
      )}
    </div>
  );
};

export default SelectedOptions;
