
import React, { useContext } from 'react';
import { DonutChart as DonutChartComponent, DonutChartProps } from '@tremor/react';
import ChartTooltip from './ChartTooltip';
import chartColorEnum from '../../pages/reports/cancellationReasons/utils/chartColorEnum';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';

type Props = {
  data: { name: string; percent?: number }[];
  className?: string;
  variant?: 'donut' | 'pie';
  colors?: string[];
} & DonutChartProps;

const DonutChart = ({ data, className, variant = 'pie', colors,  ...props}: Props) => {
  const { theme } = useContext(ThemeContext)!;

  const allZero = data.every((item) => item.percent === null || item.percent === 0);

  return (
    <>
      {allZero ? (
          <div className='flex items-center justify-center h-44 mt-5'>
            <p className='text-sm' style={{ color: theme === "dark" ? '#64748B' : '#9CA3AF' }}>
              No data available
            </p>
          </div>
        ) : (
          <DonutChartComponent
            data={data}
            category='percent'
            index='name'
            variant={variant}
            colors={Object.values(chartColorEnum)}
            className={`[&_g]:stroke-none ${className}`}
            customTooltip={ChartTooltip}
            {...props}
          />
        )}
    </>
  );
};

export default DonutChart;  