import React, { useContext } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';

interface ScrollToPreviousIconProps {
    disabled: boolean;
}

const ScrollToPreviousIcon = ({disabled}: ScrollToPreviousIconProps) => {
    const { theme } = useContext(ThemeContext)!;

    const color = disabled 
    ? (theme === 'light' ? '#677C9F' : '#677C9F')
    : (theme === 'light' ? '#242546' : '#EBEFF7'); 

  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.39626 1.7925L6.10376 0.5L0.60376 6L6.10376 11.5L7.39626 10.2075L3.19793 6L7.39626 1.7925Z" fill={color} />
    </svg>
  )
}

export default ScrollToPreviousIcon