import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { FormControl, FormControlLabel, FormGroup, SelectChangeEvent, Checkbox } from '@mui/material';
import Utils from '../../../odinForgeService/Utils';
import CloseIcon from '../../../assets/icons/CloseIcon';
import DefaultButton from '../../UI/buttons/DefaultButton';
import EmptyCheckbox from '../../../assets/icons/EmptyCheckbox';
import CheckedFilter from '../../../assets/icons/CheckedFilter';
import { checkIcons } from '../../../types/enums/CheckIcons';
import { MenuItem } from '../../pages/reports/DeclineResponseReport/utils/config';
import FilterDropdownDeclineReport from './FilterDropdownDeclineReport';

interface SelectedFilters {
  [key: string]: {
      [subItemId: string]: boolean;
  };
}

interface FullScreenMobileModalProps {
  onClose: () => void;
  handleBlur: () => void;
  groupings: string[];
  setGroupings: (event: string[]) => void;
  names: string[];
  handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  maxSelections?: number;
  multiple?: boolean;
  menuItems?: MenuItem[];
  onSelectionChange?: (newSelectedItems: SelectedFilters) => void;
  selectedItems?: SelectedFilters;
  setSelectedItems: React.Dispatch<React.SetStateAction<SelectedFilters>>;
  handleApplyMobileSelect?: (groupings: string[], filters: SelectedFilters) => void;
}

function FullScreenMobileModalDeclineReport({
    onClose,
    groupings,
    setGroupings,
    names,
    maxSelections,
    multiple = true,
    menuItems,
    selectedItems = {},
    setSelectedItems,
    handleApplyMobileSelect
}:FullScreenMobileModalProps) {
    const { themedColors } = useContext(ThemeContext)!;
    const originalGroupings = useRef<string[]>([]);
    const originalFilters = useRef<SelectedFilters>({});
  
    const [selectedGroupings, setSelectedGroupings] = useState<string[]>(groupings);
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(selectedItems);
  
    useEffect(() => {
      originalGroupings.current = [...groupings];
      originalFilters.current = selectedItems
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const handleClose = () => {
      setGroupings(originalGroupings.current);
      setSelectedItems(originalFilters.current);
      onClose();
    };
  
    const handleClear = () => {
      setSelectedFilters({});
    };
  
    const handleApply = () => {
      handleApplyMobileSelect && handleApplyMobileSelect(selectedGroupings, selectedFilters);
      onClose();
    };
  
    const handleToggle = (value: string) => {
      let newGroupings = [...selectedGroupings];
      if (multiple) {
        const currentIndex = selectedGroupings.indexOf(value);
        if (currentIndex === -1) {
          if (maxSelections !== undefined && newGroupings.length >= maxSelections) {
            Utils.instance().onFailure(`You can select up to ${maxSelections} options.`);
          } else {
            newGroupings.push(value);
          }
        } else {
          newGroupings.splice(currentIndex, 1);
        }
      } else {
        newGroupings = [value];
      }
  
      setSelectedGroupings(newGroupings);
    };
  
    return (
      <div className="fixed inset-0 z-[10000] bg-white overflow-y-auto" style={{backgroundColor: themedColors.background_1}}>
        <div className="relative min-h-screen flex flex-col justify-start items-center">
          <div className="flex justify-between w-full border-b border-borderColor-semi-light-blue dark:border-odin-dark-blue-dark_2 p-4">
            <h2 style={{color: themedColors.content}} className="text-xl font-bold text-[#1A1F36]">Filters</h2>
            <button className="p-2 text-black" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
  
          <div className="flex flex-col w-full justify-between min-h-[calc(100vh-63px)]">
            <FormControl component="fieldset" sx={{ m: 1 }} className="p-4" style={{padding: 4}}>
              <label className="text-base mb-2" style={{ color: themedColors.content }}>Category</label>
              <FormGroup>
                {names.map((name) => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        checked={selectedGroupings.includes(name)}
                        onChange={() => handleToggle(name)}
                        name={name}
                        icon={<EmptyCheckbox />}
                        checkedIcon={multiple ? checkIcons[selectedGroupings.indexOf(name)] : <CheckedFilter />}
                      />
                    }
                    label={name}
                    sx={{ "& .MuiTypography-body1": { fontSize: "14px !important", color: themedColors.content, fontWeight: selectedGroupings.indexOf(name) > -1 ? 500 : 400 } }}
                  />
                ))}
              </FormGroup>
              <FilterDropdownDeclineReport 
                    menuItems={menuItems ?? []}
                    selectedItems={selectedFilters?? {}}
                    setSelectedFilters={setSelectedFilters}
                />
            </FormControl>
            
            <div className="p-4 flex justify-between pt-4 w-full gap-2" style={{borderTop: '1px solid', borderColor: themedColors.border}}>
              <DefaultButton disabled={false} type='button' label='Clear Filter' variant='outlined'  className="w-full h-9" onClick={handleClear} />
              <DefaultButton disabled={false} type='button' label='Apply'  className="w-full h-9" onClick={handleApply} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default FullScreenMobileModalDeclineReport;