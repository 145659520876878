
import React, { useContext } from 'react';
import './Loading.css';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';

interface LoadingPopupProps {
    loadingText: string
}

const LoadingPopup: React.FC<LoadingPopupProps> = ({ loadingText }) => {
    const { theme } = useContext(ThemeContext)!;

    return (
        <div className={`loading-overlay ${theme === 'dark' ? 'bg-[rgba(0,0,0,0.8)]' : 'bg-[rgba(105,105,105,0.8)]'}`} >
            <div className="loading-popup" >
                <div className="loading-text-cover">
                    <div className="loading-text text-odin-light-content dark:text-odin-dark-content">{loadingText}</div>
                </div>
                <div className="loadingBar-cover">
                    <div className="loading-bar"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPopup;
