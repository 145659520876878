import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const UnChecked = () => {
    const { theme } = useContext(ThemeContext)!;

  return (
    <>
     {
        theme === 'light' ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white"/>
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#677C9F"/>
                <path d="M13.5337 6.81818V17H11.9925V8.35938H11.9329L9.4968 9.95028V8.47869L12.0373 6.81818H13.5337Z" fill="white"/>
            </svg>
        ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="#17202E"/>
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#677C9F"/>
                <path d="M8.68643 17V15.8864L12.1317 12.3168C12.4996 11.929 12.8029 11.5893 13.0415 11.2976C13.2835 11.0026 13.4641 10.7225 13.5835 10.4574C13.7028 10.1922 13.7624 9.91051 13.7624 9.61222C13.7624 9.27415 13.6829 8.98248 13.5238 8.73722C13.3647 8.48864 13.1476 8.29806 12.8725 8.16548C12.5974 8.02959 12.2875 7.96165 11.9428 7.96165C11.5782 7.96165 11.2601 8.03622 10.9883 8.18537C10.7165 8.33452 10.5077 8.54498 10.3619 8.81676C10.216 9.08854 10.1431 9.40672 10.1431 9.77131H8.67649C8.67649 9.15151 8.81901 8.60961 9.10405 8.1456C9.38909 7.68158 9.78018 7.32197 10.2773 7.06676C10.7745 6.80824 11.3396 6.67898 11.9727 6.67898C12.6123 6.67898 13.1758 6.80658 13.663 7.06179C14.1535 7.31368 14.5363 7.65838 14.8114 8.09588C15.0865 8.53007 15.2241 9.0206 15.2241 9.56747C15.2241 9.94531 15.1528 10.3149 15.0103 10.6761C14.8711 11.0374 14.6275 11.4401 14.2795 11.8842C13.9315 12.325 13.4476 12.8603 12.8278 13.4901L10.8043 15.608V15.6825H15.3881V17H8.68643Z" fill="#17202E"/>
            </svg>
        )
     }
    </>
    

  )
}

export default UnChecked