import React, {useContext}  from 'react';
import { SideBar } from '../sidebar/SideBar';
import {Link, Outlet, useLocation} from "react-router-dom";
import LoadingPopup from "../../UI/loading/LoadingPopup";
import {useLoadingContext} from "../../UI/loading/LoadingContext";
import {UserContext} from "../../../context/UserContext/UserContext";
import {routePermissions} from "../../../permissions/permissions";
import Logo from '../../../assets/icons/Logo';

export function BaseDashboard() {
    const { loading } = useLoadingContext();
    const location = useLocation();
    const userContext = useContext(UserContext)!;
    const { permissions, isSuperAdmin, isAdmin } = userContext;

    const isDashboardPage = location.pathname === '/dashboard';

    const content: {name: string, link: string}[] = [
        { name: 'Sales Pace Report Table', link: '/dashboard/sales-pace-report-table' },
        { name: 'Sales Pace Report Line Chart', link: '/dashboard/sales-pace-report-line-chart' },
        { name: 'Sales Performance Report', link: '/dashboard/sales-performance-report' },
        { name: 'Sales Performance Report Line Chart', link: '/dashboard/sales-performance-report-line-chart' },
        { name: 'Transaction Performance Report', link: '/dashboard/transaction-performance-report' },
        { name: 'Retention Report', link: '/dashboard/retention-report' },
        { name: 'Affiliate Sales Summary', link: '/dashboard/affiliate-sales-summary' },
        { name: 'Cancellation Reasons (Orders)', link: '/dashboard/cancellationReasons' },
        { name: 'Forecasted Rebills Report', link: '/dashboard/forecasted-rebills-report' },
        { name: 'Conversion Report', link: '/dashboard/conversion-report' },
        { name: 'Decline Response Report', link: '/dashboard/decline-response-report' },
    ];

    const allowedContent = content.filter(item => {
        const permissionsForLink = routePermissions[item.link];
        if (!permissionsForLink) return true;
        return isSuperAdmin || isAdmin || permissionsForLink.some(permission => permissions.includes(permission));
    });

    return (
        <div className={"flex flex-col bg-odin-light-blue-extra_light dark:bg-odin-dark-blue-extra_dark min-h-screen"}>
            {loading && <LoadingPopup loadingText='Loading....'/>}
            <div className={"flex flex-row tablet-size:p-4"}>
                <div className="!absolute tablet-size:!static bg-odin-light-blue-extra_light dark:bg-odin-dark-blue-extra_dark flex-none z-50">
                    <SideBar/>
                </div>
                <div id="baseDashboardContainer" className="pb-4 mt-[50px] relative rounded-xl bg-white dark:bg-odin-dark-blue-dark  p-0 z-[1] pt-[0px] !w-full tablet-size:!w-[90%] tablet-size:p-4 tablet-size:pb-[60px] overflow-x-auto overflow-y-hidden min-h-[calc(100vh-82px)] mr-auto ml-auto tablet-size:left-[250px] tablet-size:mr-[250px] border-b-0 tablet-size:border-b-[1px] border border-borderColor-semi-light-blue dark:border-odin-dark-blue-dark_2">

                    <Outlet/>

                    {isDashboardPage && (
                        <div className={"flex flex-col justify-center items-center gap-5 mt-16 tablet-size:w-auto"}>

                            <Logo width='180px' height='62px' />

                            <div className={"flex flex-col tablet-size:flex-row flex-wrap gap-5 items-center justify-center tablet-size:justify-start max-w-[600px]"}>
                                {allowedContent.map((item, index) => (
                                    <Link key={index} to={item.link}>
                                        <button
                                            key={index}
                                            className={"w-56 s:w-64 mobile-size:w-72 h-fit p-4 rounded-xl bg-white dark:bg-transparent border border-blue-500 text-blue-500 text-center"}
                                        >
                                            {item.name}
                                        </button>
                                    </Link>
                                    ))}
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}   
