import React, { useContext, useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import {
  Checkbox,
  FormControl,
  SelectChangeEvent,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import EmptyCheckbox from "../../../../assets/icons/EmptyCheckbox";
import { checkIcons } from "../../../../types/enums/CheckIcons";
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../../buttons/DefaultButton";
import Utils from "../../../../odinForgeService/Utils";
import CheckedFilter from "../../../../assets/icons/CheckedFilter";

interface FullScreenModalProps {
  onClose: () => void;
  handleBlur: () => void;
  groupings: string[];
  setGroupings: (event: string[]) => void;
  names: string[];
  handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  maxSelections?: number;
  multiple?: boolean;
}

const FullScreenModal = ({
  onClose,
  groupings,
  setGroupings,
  names,
  maxSelections,
  multiple = true,
}: FullScreenModalProps) => {
  const { themedColors } = useContext(ThemeContext)!;
  const originalGroupings = useRef<string[]>([]);

  const [selectedGroupings, setSelectedGroupings] = useState<string[]>(groupings);

  useEffect(() => {
    originalGroupings.current = [...groupings];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setGroupings(originalGroupings.current);
    onClose();
  };

  const handleClear = () => {
    setGroupings([]);
    setSelectedGroupings([]);
  };

  const handleApply = () => {
    setGroupings(selectedGroupings);
    onClose();
  };

  const handleToggle = (value: string) => {
    let newGroupings = [...selectedGroupings];
    if (multiple) {
      const currentIndex = selectedGroupings.indexOf(value);
      if (currentIndex === -1) {
        if (maxSelections !== undefined && newGroupings.length >= maxSelections) {
          Utils.instance().onFailure(`You can select up to ${maxSelections} options.`);
        } else {
          newGroupings.push(value);
        }
      } else {
        newGroupings.splice(currentIndex, 1);
      }
    } else {
      newGroupings = [value];
    }

    setSelectedGroupings(newGroupings);
  };

  return (
    <div className="fixed inset-0 z-[10000] bg-white overflow-y-auto" style={{backgroundColor: themedColors.background_1}}>
      <div className="relative min-h-screen flex flex-col justify-start items-center">
        <div className="flex justify-between w-full border-b border-borderColor-semi-light-blue dark:border-odin-dark-blue-dark_2 p-4">
          <h2 style={{color: themedColors.content}} className="text-xl font-bold text-[#1A1F36]">Filters</h2>
          <button className="p-2 text-black" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>

        <div className="flex flex-col w-full justify-between min-h-[calc(100vh-63px)]">
          <FormControl component="fieldset" sx={{ m: 1 }} className="p-4" style={{padding: 4}}>
            <label className="text-base mb-2" style={{ color: themedColors.content }}>Group in sequence</label>
            <FormGroup>
              {names.map((name) => (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      checked={selectedGroupings.includes(name)}
                      onChange={() => handleToggle(name)}
                      name={name}
                      icon={<EmptyCheckbox />}
                      checkedIcon={multiple ? checkIcons[selectedGroupings.indexOf(name)] : <CheckedFilter />}
                    />
                  }
                  label={name}
                  sx={{ "& .MuiTypography-body1": { fontSize: "14px !important", color: themedColors.content, fontWeight: selectedGroupings.indexOf(name) > -1 ? 500 : 400 } }}
                />
              ))}
            </FormGroup>
          </FormControl>
          <div className="p-4 flex justify-between pt-4 w-full gap-2" style={{borderTop: '1px solid', borderColor: themedColors.border}}>
            <DefaultButton disabled={false} type='button' label='Clear Filter' variant='outlined'  className="w-full h-9" onClick={handleClear} />
            <DefaultButton disabled={false} type='button' label='Apply'  className="w-full h-9" onClick={handleApply} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenModal;
