import React, {FC, useContext} from "react";
import Utils from "../../../../../odinForgeService/Utils";
import {
    Checkbox, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemText
} from "@mui/material";
import { ThemeContext } from "../../../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../../../../UI/buttons/DefaultButton";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import Checked from "../../../../../assets/icons/Checked";
import UnChecked from "../../../../../assets/icons/UnChecked";

interface EditTableDialogProps {
    openEditTableDialog: boolean;
    setOpenEditTableDialog: (value: boolean) => void;
    columnList: number[]
    setColumnList: (value: number[]) => void;
    actualColumnList: number[];
    setActualColumnList: (value: number[]) => void;
    dialogApplyButton: boolean
    setDialogApplyButton: (value: boolean) => void;
}

const EditTableDialog: FC<EditTableDialogProps> = ({
                                                       openEditTableDialog,
                                                       setOpenEditTableDialog,
                                                       columnList,
                                                       setColumnList,
                                                       actualColumnList,
                                                       setActualColumnList,
                                                       dialogApplyButton,
                                                       setDialogApplyButton,
                                                   }) => {
     const { themedColors } = useContext(ThemeContext)!;

    const handleToggle = (value: number) => () => {
        if (columnList.length > 14 && columnList.indexOf(value) === -1) {
            Utils.instance().onFailure(`You must have minimum 3 columns in the table.`);
            return;
        }
        const currentIndex = columnList.indexOf(value);
        const newChecked = [...columnList];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setColumnList(newChecked);
    };

    const columns = [
        'Initial Sales',
        'Initial Declines',
        'Initial Approval %',
        'Reproc Sales',
        'Reproc Declines',
        'Reproc Approval %',
        'Rebill Sales',
        'Rebill Declines',
        'Rebill Approval %',
        'Rebill Gross',
        'CB #',
        'CB %',
        'Refunds',
        'Pending Txn',
        'Invalid Txn',
        'Refund Amount',
        'Gross Revenue',
        'Net Revenue',
    ]

    return (
        <>
            {/* Desktop */}
            <Dialog
                fullWidth={true}
                open={openEditTableDialog}
                className="hidden tablet-size:block"
                onClose={() => {
                    setColumnList(actualColumnList)
                    setOpenEditTableDialog(false)
                }}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        setActualColumnList(columnList)
                        setOpenEditTableDialog(false)
                        setDialogApplyButton(true)
                        Utils.instance().onSuccess("Changes are saved")
                    },
                    onReset: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault()
                        setColumnList(actualColumnList)
                        setOpenEditTableDialog(!openEditTableDialog)
                    },
                    sx: {
                        width: 541
                    },
                }}  
            >
                <div className={'flex flex-row justify-end absolute right-4 top-4'}>
                    <button
                        aria-label="close"
                        type={'reset'}
                        style={{
                            position: 'relative',
                            color: '#ACBFDF',
                            justifyContent: 'flex-end',
                            padding: 3
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <DialogTitle 
                    sx={{fontWeight: 'bold', paddingTop: 8, paddingLeft: 5, paddingRight: 5, paddingBottom: 0}}
                >
                    Edit the table
                </DialogTitle>
                <DialogContent style={{maxWidth: 540, paddingTop: 8, paddingLeft: 40, paddingRight: 40, paddingBottom: 0}}>
                    <DialogContentText style={{color: themedColors.content, lineHeight: '19px'}}>
                        You can hide the content that you don’t want to see in the table.
                        You can change it anytime. You must have minimum 3 columns in the table.
                    </DialogContentText>
                    <List className="!mt-6">
                        <div className={'flex flex-col tablet-size:flex-row gap-20'}>
                            <div>
                                {columns.slice(0, 9).map((value, index) => {
                                    const labelId = `checkbox-list-label-${value}`;
                                    return (
                                        <ListItem
                                            key={value}
                                            onClick={handleToggle(index)}
                                            disablePadding={true}
                                            style={{
                                                padding: 0
                                            }}
                                        >
                                            <ListItemButton dense={true}                                             
                                            sx={{
                                                '&': {
                                                    marginBottom: '12px',
                                                    padding: 0,
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                }
                                            }}>
                                                <ListItemIcon style={{minWidth: 30}}>
                                                    <Checkbox defaultChecked checked={columnList.indexOf(index) === -1}
                                                              style={{padding: 0}}
                                                              sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#0079FF',
                                                        
                                                                  },
                                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                              }}
                                                              checkedIcon={<Checked />}
                                                              icon={<UnChecked />}
                                                    ></Checkbox>
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value}/>
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </div>
                            <div>
                                {columns.slice(9).map((value, index) => {
                                    const labelId = `checkbox-list-label-${value}`;
                                    return (
                                        <ListItem
                                            key={value}
                                            onClick={handleToggle(index + 9)}
                                            disablePadding={true}
                                            style={{
                                                padding: 0
                                            }}
                                        >
                                            <ListItemButton
                                                dense={true}
                                                sx={{
                                                    '&': {
                                                        marginBottom: '12px',
                                                        padding: 0,
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                }}}
                                            >
                                                <ListItemIcon style={{minWidth: 30}} >
                                                    <Checkbox checked={columnList.indexOf(index + 9) === -1}
                                                              style={{padding: 0}}
                                                              sx={{
                                                                '&.Mui-checked': {
                                                                  color: '#0079FF',
                                                                },
                                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                              }}
                                                              checkedIcon={<Checked />}
                                                              icon={<UnChecked />}
                                                    ></Checkbox>
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value}/>
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </div>
                        </div>
                    </List>
                </DialogContent>
                <DialogActions sx={{
                    justifyContent: 'start',
                    paddingBottom: 5,
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 2,
                    gap: 1
                }}>
                    <DefaultButton disabled={dialogApplyButton} type='submit' label='Apply'  className="w-full h-10" />
                    <DefaultButton disabled={false} type={'reset'} label='Cancel' variant='outlined'  className="w-full h-10" />
                </DialogActions>
            </Dialog>


            {/* Mobile */}
            <Dialog
                fullWidth={true}
                open={openEditTableDialog}
                fullScreen
                className="block tablet-size:hidden"
                onClose={() => {
                    setColumnList(actualColumnList)
                    setOpenEditTableDialog(false)
                }}
                disableScrollLock={ true }
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        setActualColumnList(columnList)
                        setOpenEditTableDialog(false)
                        setDialogApplyButton(true)
                        Utils.instance().onSuccess("Changes are saved")
                    },
                    onReset: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault()
                        setColumnList(actualColumnList)
                        setOpenEditTableDialog(!openEditTableDialog)
                    },
                    sx: {
                        width: 541
                    },
                }}
                
            >
                <div className={'w-full border-b'} style={{borderColor: themedColors.border}}>
                    <div className="flex justify-between">
                        <DialogTitle sx={{fontWeight: 'bold', paddingLeft: 2}}>Edit the table</DialogTitle>
                        <button
                            aria-label="close"
                            type={'reset'}
                            style={{
                                position: 'relative',
                                color: '#ACBFDF',
                                justifyContent: 'flex-end',
                                paddingRight: 16
                            }}
                            className="px-6 py-4"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>

                <DialogContent style={{paddingBottom: 0}} className="!p-4">
                    <DialogContentText style={{color: themedColors.content, lineHeight: '19px'}}>
                        You can hide the content that you don’t want to see in the table.
                        You can change it anytime. You must have minimum 3 columns in the table.
                    </DialogContentText>
                    <List>
                        <div className={'flex flex-col tablet-size:flex-row'}>
                            <div>
                                {columns.slice(0, 9).map((value, index) => {
                                    const labelId = `checkbox-list-label-${value}`;
                                    return (
                                        <ListItem
                                            key={value}
                                            onClick={handleToggle(index)}
                                            disablePadding={true}
                                            style={{
                                                padding: 0,
                                            }}
                                        >
                                            <ListItemButton dense={true}
                                                sx={{
                                                    '&': {
                                                        marginBottom: 1,
                                                        padding: 0,
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    }
                                                }}                                                
                                            >
                                                <ListItemIcon style={{minWidth: 30}}>
                                                    <Checkbox checked={columnList.indexOf(index) === -1}
                                                              style={{padding: 0}}
                                                              sx={{
                                                                '&.Mui-checked': {
                                                                  color: '#0079FF',
                                                                },
                                                                '& .MuiSvgIcon-root': { fontSize: 28,},
                                                              }}
                                                              checkedIcon={<Checked />}
                                                              icon={<UnChecked />}
                                                    ></Checkbox>
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value} style={{marginLeft: 6}} sx={{
                                                    '& .MuiListItemText-primary': { fontSize: '14px' }
                                                }} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </div>
                            <div>
                                {columns.slice(9).map((value, index) => {
                                    const labelId = `checkbox-list-label-${value}`;
                                    return (
                                        <ListItem
                                            key={value}
                                            onClick={handleToggle(index + 9)}
                                            disablePadding={true}
                                            style={{
                                                padding: 0
                                            }}
                                        >
                                            <ListItemButton
                                                dense={true}
                                                sx={{
                                                    '&': {
                                                        marginBottom: 1,
                                                        padding: 0,
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    }
                                                }}    
                                            >
                                                <ListItemIcon style={{minWidth: 30}}>
                                                    <Checkbox checked={columnList.indexOf(index + 9) === -1}
                                                              style={{padding: 0}}
                                                              sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#0079FF',
                                                                },
                                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                              }}
                                                              checkedIcon={<Checked />}
                                                              icon={<UnChecked />}
                                                    ></Checkbox>
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value} style={{marginLeft: 6}} sx={{
                                                    '& .MuiListItemText-primary': { fontSize: '14px' }
                                                }} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </div>
                        </div>
                    </List>
                </DialogContent>
                <DialogActions 
                    sx={{
                        justifyContent: 'justify-between',
                        padding: 2,
                        gap: 1,
                        width: '100%'
                    }}
                    className="pt-4 border-t"
                    style={{borderColor: themedColors.border}}
                >
                    <DefaultButton disabled={false} type={'reset'} label='Cancel' variant='outlined'  className="w-full h-10" />
                    <DefaultButton disabled={dialogApplyButton} type='submit' label='Apply'  className="w-full h-10" />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditTableDialog; 