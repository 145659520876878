import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from "../../../../../context/ThemeContext/ThemeContext";
import chartColorEnum from "../utils/chartColorEnum";

interface StatsLegendComponentProps {
  data: { name: string; percent?: number }[];
  showPercent?: boolean;
}

export function StatsLegendComponent({
  data,
  showPercent,
}: StatsLegendComponentProps) {
  const { themedColors } = useContext(ThemeContext)!;
  
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-1   ${
        showPercent ? "grid-cols-1" : "mobile-size:grid-cols-2"
      } gap-1.5`}
    >
      {data.map((data, index) => (
        <div
          className="flex justify-between items-start gap-1 min-w-[150px]"
          key={index}
          style={{
            display: data.percent === 0 || !data.percent ? "none" : "flex", 
          }}
        >
          <div className="flex gap-2">
            <FontAwesomeIcon
              icon={faCircle}
              style={{
                fontSize: "8px",
                padding: "5px",
                color: chartColorEnum[index],
              }}
            />
            <div className="text-sm overflow-hidden" style={{color: themedColors.content}}>{data.name}</div>
          </div>

          {showPercent && (
            <div className="ml-5 pr-2 text-sm font-semibold overflow-hidden" style={{color: themedColors.content}}>
              {data.percent && Number(data.percent) ? (`${(data.percent).toFixed(2)}%`) : '0.00%'}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
