import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatLocalISO = (date: dayjs.Dayjs, isEndDate?: boolean) => {
    if (isEndDate) {
        return date.utc().format('YYYY-MM-DD HH:mm:ss.999');
    }
    return date.utc().format('YYYY-MM-DD HH:mm:ss');
};