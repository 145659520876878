import Utils from "./odinForgeService/Utils";

const getFontColor = (percentage:number) => {
    if (percentage<0)
        return '#FF3B30'
    else
    if (percentage>0)
        return '#2AA300'
    else return '#000000'
}

interface HourlyData {
    hour: number;
    [key: string]: number | string;
}

interface Data {
    previous: HourlyData[];
    current: HourlyData[];
}
function checkDataForNaN(data: Data) {
    const checkArray = (arr: HourlyData[]) => arr.every((item: HourlyData) => {
        const keys = Object.keys(item).filter(key => key !== 'hour');
        return keys.every(key => item[key] === "NaN");
    });

    const { previous, current } = data;

    const isPreviousInvalid = checkArray(previous);
    const isCurrentInvalid = checkArray(current);

    if (isPreviousInvalid && isCurrentInvalid) {
        Utils.instance().onFailure('We do not have data for the Swiss crm');
    }
}

export {getFontColor, checkDataForNaN}