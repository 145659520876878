import React from 'react'
import {
    SxProps,
    TableCell,
    Theme,
} from "@mui/material";
import getSymbolFromCurrency from 'currency-symbol-map';

interface CustomCellProps {
    type: 'item' | 'percentage' | 'currency';
    style?: React.CSSProperties;
    className?: string;
    value: string | number;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    currency?: string;
    align?: 'left' | 'right' | 'center';
    sx?: SxProps<Theme> | undefined;
}

const CustomCell = ({type, style, className, value, onMouseEnter, onMouseLeave, currency, align, sx}: CustomCellProps) => {
  return (
    <>
        <TableCell 
            style={style} 
            className={className} 
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            align={align}
            sx={sx}
        >
            {
                type === 'item' ? (
                    <>{ Number(value) ? Number(value)?.toLocaleString() : '0'}</>
                ) : type === 'percentage' ? (
                    <>{Number(value) ? (`${parseFloat(value.toString()).toFixed(2)}%`) : '0.00%'}</>
                ) : type === 'currency' ? (
                    <>{currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}{Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</>
                ) : (
                    <></>
                )
            }
        </TableCell>
    </>
  )
}

export default CustomCell