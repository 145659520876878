import { ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import React, { ReactNode, useContext } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';
import { ChevronDownIcon } from '@heroicons/react/outline';

interface PaginationNumberPageSelectProps {
    value: number;
    onChange: (event: SelectChangeEvent<number>, child: ReactNode) => void,
    min: number,
    max: number
}

const PaginationNumberPageSelect = ({value, onChange, min, max}: PaginationNumberPageSelectProps) => {
    const { theme, themedColors } = useContext(ThemeContext)!;

  return (
    <Select
        value={value}
        onChange={onChange}
        IconComponent={ChevronDownIcon}
        className={"!h-10 text-sm hover:!border-odin-dark-blue-medium focus:!border-odin-dark-blue-medium"}
        sx={{
              "& .MuiSelect-icon, & .MuiSelect-iconOpen": {
                top: "10px",
                width: "20px",
                color: themedColors.content,
              },
              "& .MuiSelect-icon.MuiSelect-iconOpen": {
                width: "20px",
                color: "#0079FF",
              },
              "&.MuiOutlinedInput-root": { borderRadius: "6px" },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
              "&.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
              "& .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme === 'light' ? '#ACBFDF' : '#495D80'}`,
              }
        }}
    >
        {
            Array.from({length: max - min + 1}, (_, i) => i + min).map((size) => (
                <MenuItem 
                    key={size} 
                    value={size}
                    sx={{
                        "&.MuiMenuItem-root .MuiTypography-root": {
                          backgroundColor: themedColors.background_1,
                          fontSize: "14px !important",
                        },
                        "&.MuiMenuItem-root:hover, &.Mui-selected": {
                          backgroundColor: themedColors.background_1,
                          fontSize: "14px !important",
                        },
                      }}
                >
                    <ListItemText primary={size} style={{ fontSize: "14px !important" }} />
                </MenuItem>
            ))
        
        }
        {/* {[10, 20, 30, 40, 50].map((size) => (
            <MenuItem 
                key={size} 
                value={size}
                sx={{
                    "&.MuiMenuItem-root .MuiTypography-root": {
                      backgroundColor: themedColors.background_1,
                      fontSize: "14px !important",
                    },
                    "&.MuiMenuItem-root:hover, &.Mui-selected": {
                      backgroundColor: themedColors.background_1,
                      fontSize: "14px !important",
                    },
                  }}
            >
                <ListItemText primary={size} style={{ fontSize: "14px !important" }} />
            </MenuItem>
        ))} */}
  </Select>
  )
}

export default PaginationNumberPageSelect