import { v4 as uuidv4 } from 'uuid';
import { RetentionReportData } from '../types/retention-report.types';

export const transformData = (data: RetentionReportData) => {

    if (Object.keys(data).length === 0) {
      return {};
    }

    const calculateTotals = (initialStats: any, cycleStats?: any) => {
      const grossRevenueTotal = (parseFloat(initialStats.gross_revenue) || 0) + (cycleStats ? (parseFloat(cycleStats.retention_gross_revenue) || 0) : 0);
      const netRevenueTotal = (parseFloat(initialStats.net) || 0) + (cycleStats ? (parseFloat(cycleStats.retention_net) || 0) : 0);
      const ordersTotal = (parseFloat(initialStats.orders) || 0) + (cycleStats ? (parseFloat(cycleStats.orders) || 0) : 0);
      const avgLtv = ordersTotal > 0 ? netRevenueTotal / ordersTotal : 0;
  
      return {
        grossRevenueTotal: grossRevenueTotal,
        netRevenueTotal: netRevenueTotal,
        ordersTotal,
        avgLtv: avgLtv
      };
    };
  
    const transformObject = (obj: any, objName: string, cycleObj: any = null) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      if (cycleObj) {
        transformed.cycles = cycleObj.stats;
        transformed.total = calculateTotals(obj.stats, cycleObj.stats);
      } else {
        transformed.total = calculateTotals(obj.stats);
      }
  
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value).map(
              ([nestedKey, nestedValue]) => {
                const nestedCycleValue = cycleObj && cycleObj[key] ? cycleObj[key][nestedKey] : null;
                return transformObject(nestedValue, nestedKey, nestedCycleValue);
              }
            );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    const initialData = data.initals || {};
    const cycleData = data.cycles || {};
  
    Object.entries(initialData).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue).map(
          ([nestedKey, nestedValue]) => {
            // @ts-ignore
            const cycleValue = cycleData[mainKey] && cycleData[mainKey][nestedKey] ? cycleData[mainKey][nestedKey] : null;
            return transformObject(nestedValue, nestedKey, cycleValue);
          }
        );
      }
    );
  
    return result;
};