import React from 'react'

interface PaginationButtonProps {
    onClick: () => void
    disabled: boolean
    icon: React.ReactNode
    // iconDisabled: React.ReactNode
    className?: string
}

const PaginationButton = ({ onClick, disabled, className, icon }: PaginationButtonProps) => {
  return (
    <button 
        onClick={onClick}
        disabled={disabled}
        className={className ? className : ''}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
    >
        {icon}
    </button>
  )
}

export default PaginationButton