const firstRowDataSalesPerformance = [
    {
        id: 1,
        name: 'Clicks',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 2,
        name: 'Order Clicks',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 3,
        name: 'Partials',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 4,
        name: 'Partial rate',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 5,
        name: 'Partial Conversion rate',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 6,
        name: 'Conversion rate',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 7,
        name: 'Sales',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 8,
        name: 'Upsells',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 9,
        name: 'Take rate (Upsell rate)',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 10,
        name: 'Declines',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    {
        id: 11,
        name: 'Approval %',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        hasSortField: true,
        align: 'flex-end'
    },
    // {
    //     id: 11,
    //     name: 'Last 7 days',
    //     fontSize: "12px",
    //     width : "120px",
    //     padding: "16px 8px 16px 8px",
    //     isTips: false,
    //     tips: "",
    //     hasSortField: false
    // }
];


export default firstRowDataSalesPerformance;