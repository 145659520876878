import React, { useContext } from 'react';
import PaginationButton from './UI/PaginationButton';
import ScrollToStartIcon from './assets/ScrollToStartIcon';
import ScrollToPreviousIcon from './assets/ScrollToPreviousIcon';
import ScrollToNextIcon from './assets/ScrollToNextIcon';
import ScrollToEndIcon from './assets/ScrollToEndIcon';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import PaginationSelect from './UI/PaginationSelect';
import PaginationNumberPageSelect from './UI/PaginationNumberPageSelect';

interface PaginationComponentProps {
    firstPage: () => void;
    previousPage: () => void;
    getCanPreviousPage: () => boolean
    getCanNextPage: () => boolean;
    nextPage: () => void;
    lastPage: () => void;
    getPageCount: () => number;
    pageIndex: number;
    setPageIndex: (page: number) => void;
    setPageSize: (size: number) => void;
    pageSize: number;
    getRowCount: () => number;
}

const PaginationComponent = ({ firstPage, previousPage, getCanPreviousPage, getCanNextPage, nextPage, lastPage, getPageCount, pageIndex, setPageIndex, setPageSize, pageSize, getRowCount }: PaginationComponentProps) => {
  const { themedColors, theme } = useContext(ThemeContext)!;

  const rowCount = getRowCount();
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min((pageIndex + 1) * pageSize, rowCount); 
  
  return (
    <div className='flex flex-wrap items-center gap-2 justify-between p-4'>
      <div className='flex gap-2 items-center'>
        <span style={{color: themedColors.content}} className='text-sm'>Rows per page</span>
        <PaginationSelect value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))} />
        <p className='text-sm text-[#677C9F]'>
          {startRow}-{endRow} of {rowCount.toLocaleString()}
        </p>
      </div>

      <div className='flex items-center gap-8'>
        <div className='flex items-center gap-2'>
          <p style={{color: themedColors.content}} className='text-sm'>Page</p>
          <PaginationNumberPageSelect value={pageIndex + 1} onChange={(e) => setPageIndex(Number(e.target.value) - 1)} min={1} max={getPageCount()} />
          {/* <input
            type='number'
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              setPageIndex(page);
            }}
            className='border p-1 rounded w-16 text-sm hover:!border-[#0079FF] focus:!border-none active:!border-none'
            style={{
              backgroundColor: 'transparent',
              color: themedColors.content,
              width: '50px',
              height: '40px',
              border: `1px solid ${theme === 'light' ? '#ACBFDF' : '#495D80'}`,
            }}
          /> */}
          <span className='flex items-center gap-1 text-sm' style={{color: themedColors.content}}>
              of {getPageCount().toLocaleString()}
          </span>
        </div>

        <div className='flex items-center gap-4'>
          <PaginationButton
            onClick={firstPage}
            disabled={!getCanPreviousPage()}
            icon={<ScrollToStartIcon disabled={!getCanPreviousPage()} />}
            className='p-2'
          />
          <PaginationButton
            onClick={previousPage}
            disabled={!getCanPreviousPage()}
            icon={<ScrollToPreviousIcon disabled={!getCanPreviousPage()} />}
            className='p-2'
          />
          <PaginationButton
            onClick={() => nextPage()}
            disabled={!getCanNextPage()}
            icon={<ScrollToNextIcon disabled={!getCanNextPage()} />}
            className='p-2'
          />
            <PaginationButton
            onClick={() => lastPage()}
            disabled={!getCanNextPage()}
            icon={<ScrollToEndIcon disabled={!getCanNextPage()} />}
            className='p-2'
          />
        </div>
      </div>




    </div>
  );
};

export default PaginationComponent;


