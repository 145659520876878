import { v4 as uuidv4 } from 'uuid';
import { TransactionReportData } from '../types/transaction-report.types';

// type DynamicObject = {
//     [key: string]: any;
// };

// const transformData = (data: TransactionReportData): any => {
//     if (Array.isArray(data)) {
//         return data.map(item => transformData(item));
//     } else if (typeof data === 'object' && data !== null) {
//         const newData: DynamicObject = { ...data };
//         if ('name' in newData) {
//             newData.id = uuidv4();
//         }
//         Object.keys(newData).forEach(key => {
//             if (typeof newData[key] === 'object' && newData[key] !== null) {
//                 newData[key] = transformData(newData[key]);
//             }
//         });
//         return newData;
//     }
//     return data;
// }

// export { transformData };
export const transformData = (data: TransactionReportData) => {
    if (Object.keys(data).length === 0) {
      return {};
    }
    
    const transformObject = (obj: any, objName: any) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value).map(
              ([nestedKey, nestedValue]) => {
                if (nestedKey === "") {
                  return transformObject(nestedValue, "NaN");
                }
                return transformObject(nestedValue, nestedKey);
              }
            );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    Object.entries(data).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue).map(
          ([nestedKey, nestedValue]) => {
            if (nestedKey === "") {
              return transformObject(nestedValue, "NaN");
            }
            return transformObject(nestedValue, nestedKey);
          }
        );
      }
    );
    return result;
  };
