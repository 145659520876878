import React, { SVGProps, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

const ExclamationMark = ({ props, isTipsHovered = false }: { props?: SVGProps<SVGSVGElement> , isTipsHovered?: boolean}) => {
  const { theme } = useContext(ThemeContext)!;

  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{cursor: 'pointer'}} 
    >
      <path
        d="M7.99992 4.8335V7.50016M7.99992 10.1668H8.00659M14.6666 7.50016C14.6666 11.1821 11.6818 14.1668 7.99992 14.1668C4.31802 14.1668 1.33325 11.1821 1.33325 7.50016C1.33325 3.81826 4.31802 0.833496 7.99992 0.833496C11.6818 0.833496 14.6666 3.81826 14.6666 7.50016Z"
        stroke={isTipsHovered ? (theme === 'light' ? "#242546" : "#EBEFF7") : "#677C9F"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExclamationMark;
