import React, { useContext } from 'react';
import {
  Cell,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import { tableColumns } from '../utils/config';
import { ConversationReportData, CustomColumnMeta, TabKey } from '../utils/types/conversation-report.types';
import Tooltip from '@mui/material/Tooltip';
import { getFraudTooltip } from '../utils/helpers/getFraudTooltip';
import SortedArrowAscending from '../../../../../assets/icons/SortedArrowAscending';
import SortedArrowDescending from '../../../../../assets/icons/SortedArrowDescending';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import generateFieldsForCSV from '../utils/helpers/generateFieldsForCSV';
import ServerPaginationComponent from '../../../../molecules/PaginationComponent/ServerPaginationComponent';
import PaginationState from '../utils/types/pagination.types';
import DefaultTableMessage from '../../../../organism/DefaultTableMessage/DefaultTableMessage';

interface ConversationReportTableProps {
  data: ConversationReportData[];
  fraudToggleValue: string;
  activeTab: TabKey;
  setTransformedTableDataCSV: React.Dispatch<React.SetStateAction<ConversationReportData[]>>
  setTransformedNamesEnumCSV: React.Dispatch<React.SetStateAction<{
    [key: string]: string;
  }>>
  onPageChange: (newPageIndex: number) => void
  onPageSizeChange: (newPageSize: number) => void
  paginationState: PaginationState;
}


const ConversationReportTable = ({ data, paginationState, onPageChange, onPageSizeChange, fraudToggleValue, activeTab, setTransformedTableDataCSV, setTransformedNamesEnumCSV }: ConversationReportTableProps) => {
  const filteredData = React.useMemo(() => {
    setTransformedTableDataCSV(data);
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fraudToggleValue]);

    // Function to map activeTab to its corresponding column group
    const getColumnOrder = (activeTab: TabKey) => {
      const orderMap: { [key in TabKey]: string[] } = {
        'Details': ['Initial', 'Aff', 'Order', 'Device', 'Browser', 'Fields'],
        'Devices': ['Metric_Device', 'Metric_Initial'],
        'Browser': ['Metric_Browser', 'Metric_Initial'],
        'Locations': ['Metric_Location', 'Metric_Initial'],
        'User Agents': ['Metric_User_Agents', 'Metric_Initial']
      };

      return orderMap[activeTab] || orderMap['Details'];
    };

    const getInternalColumnOrder = (groupID: string, activeTab: string) => {
      const orderMap = {
        'Initial': {
          'Locations': ['geo_country', 'geo_state', 'session_id', 'ip_address', 'campaign_name'],
          'default': ['click_id', 'ip_address', 'geo_country', 'geo_state', 'campaign_name']
        },
        'Browser': {
          'User Agents': ['user_agent', 'browser', 'browser_version'],
          'default': ['browser', 'browser_version', 'user_agent']
        },
        'Device': {
          'default': ['device_type', 'device_os']
        },
        'Aff': {
          'default': ['aff_id', 'c1', 'c2', 'c3', 'c4', 'c5']
        },
        'Order': {
          'default': ['number', 'order_status']
        },
        'Fields': {
          'default': ['pasted_fields', 'autofill_fields']
        },
        'Metric_Device': {
          'default': ['device_type']
        },
        'Metric_Browser': {
          'default': ['browser']
        },
        'Metric_Location': {
          'default': ['country_code', 'geo_state']
        },
        'Metric_User_Agents': {
          'default': ['user_agent']
        },
        'Metric_Initial': {
          'default': ['clicks_id', 'partials_id', 'declines_id','sales_id',]
        }
      };
      // @ts-ignore
      return orderMap[groupID]?.[activeTab] ?? orderMap[groupID]?.['default'] ?? [];
    };

    const columns = React.useMemo(() => {
      const customOrder = getColumnOrder(activeTab);
      
      let columnConfigurations = {
        'Initial': tableColumns.find(group => group.id === 'Initial'),
        'Aff': tableColumns.find(group => group.id === 'Aff'),
        'Order': tableColumns.find(group => group.id === 'Order'),
        'Device': tableColumns.find(group => group.id === 'Device'),
        'Browser': tableColumns.find(group => group.id === 'Browser'),
        'Fields': tableColumns.find(group => group.id === 'Fields'),
        'Metric_Device': tableColumns.find(group => group.id === 'Metric_Device'),
        'Metric_Browser': tableColumns.find(group => group.id === 'Metric_Browser'),
        'Metric_Location': tableColumns.find(group => group.id === 'Metric_Location'),
        'Metric_User_Agents': tableColumns.find(group => group.id === 'Metric_User_Agents'),
        'Metric_Initial': tableColumns.find(group => group.id === 'Metric_Initial'),
      };
      
      // Dynamically reorder columns within groups based on activeTab
      const reorderedColumns = customOrder.map((groupId: string) => {
        // @ts-ignore
        const group = columnConfigurations[groupId];
        if (!group) return null;
      
        const internalOrder = getInternalColumnOrder(groupId, activeTab);
        const reorderedGroupColumns = internalOrder.map((columnId: string) =>
          group.columns.find((col: Cell<ConversationReportData, unknown>) => col.id === columnId )
        ).filter(Boolean);
      
        reorderedGroupColumns.forEach((col: {
          meta: CustomColumnMeta;
        }) => {
          col.meta = { ...col.meta, isLastInGroup: false };  // Reset isLastInGroup
        });
        if (reorderedGroupColumns.length > 0) {
          reorderedGroupColumns[reorderedGroupColumns.length - 1].meta = {
            ...reorderedGroupColumns[reorderedGroupColumns.length - 1].meta,
            isLastInGroup: true  // Set isLastInGroup for the last element
          };
        }
      
        return { ...group, columns: reorderedGroupColumns };
      }).filter(Boolean);

      if (activeTab === 'Details') {
        setTransformedNamesEnumCSV(generateFieldsForCSV(reorderedColumns))
      } else {
        // i want to include also fields like partials_percentage, sales_percentage, declines_percentage
        setTransformedNamesEnumCSV(generateFieldsForCSV(reorderedColumns))
      }
    
      return [
        tableColumns.find(group => group.id === 'potential_fraud_group'), // Always first
        ...reorderedColumns
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

  const { themedColors, theme } = useContext(ThemeContext)!;

  const isMobile = useMediaQuery('(max-width: 1023px)')

  const table = useReactTable({
    columns,
    data: filteredData,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: paginationState.totalPages,
    rowCount: paginationState.totalItems,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex: paginationState.currentPage,
        pageSize: paginationState.pageSize,
      }
    },
    // @ts-ignore
    onPaginationChange: ({ pageIndex, pageSize } : {pageIndex: number, pageSize: number}) => {
      onPageChange(pageIndex);
      onPageSizeChange(pageSize);
    },
  });

  const getCanNextPage = () => {
    return paginationState.totalPages > 0 && paginationState.totalPages >  paginationState.pageIndex;
  }

  const getCanPreviousPage = () => {
    return paginationState.totalPages > 0 && paginationState.pageIndex > 1;
  }

  const getLastPage = () => {
    return paginationState.totalPages;
  }
  
  return (
    <div className='pt-0 tablet-size:pt-6 w-full relative max-w-full'>
        {
          filteredData.length > 0 ? (
            <>
            <ScrollContainer
              className='scroll-container'
              hideScrollbars={false}
              mouseScroll={{ rubberBand: false }}
              style={{ cursor: 'default', borderRadius: !isMobile ? 8 : 0}}
            >
              <table
              className='w-full relative max-w-full border border-blue-50 dark:border-odin-dark-gray-light'
              style={{
                minWidth: activeTab === 'Details' ? 2600 : 1000,
              }}
            >
              <thead className='bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]'>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          const isLastInGroup = (header.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                          const isFontBold = (header.column.columnDef.meta as CustomColumnMeta)?.isFontBold;
                          const headerBackground = header.column.getIsSorted() ? 'bg-[#CEE5FC] dark:bg-slate-800' : '';
                          const isAlignTextRight = (header.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
    
                          return (
                            <th key={header.id} colSpan={header.colSpan} 
                              style={{
                                borderRight: isLastInGroup ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : undefined,
                                borderBottom: theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: themedColors.gray.bluish,
                                fontWeight: isFontBold ? '600' : '500',
                              }}
                              className={`${headerBackground}`}
                            >
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                                className={`!text-sm font-inherit text-left ${
                                  header.column.getCanSort() ? 'cursor-pointer' : ''
                                } 
                                  !h-[56px] !py-0 !px-2 flex justify-start items-center`}
                                  style={{
                                    justifyContent: isAlignTextRight ? 'right' : 'left',
                                    fontSize: '12px',
                                    lineHeight: '14.52px',
                                  }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <span className='ml-2'><SortedArrowAscending /></span>,
                                  desc: <span className='ml-2'><SortedArrowDescending /></span>,
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    )
                  )
                })}
              </thead>
    
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  const isFraudulent = row.getValue('potential_fraud') === 1;
    
                  return (
                    <tr key={row.id} style={{ backgroundColor: isFraudulent ? theme === 'light' ? '#FEF2F2' : '#453838' : 'inherit' }} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'>
                      {row.getVisibleCells().map((cell) => {
                        const isLastInGroup = (cell.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                        const isFraudRow = (cell.column.columnDef.meta as CustomColumnMeta)?.isFraudRow;
                        const isAlignTextRight = (cell.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;

                        function getIsTooltop(cell: Cell<ConversationReportData, unknown>) {
                          if (isFraudRow) {
                            return getFraudTooltip(cell.getValue() as number);
                          }
                          const cellWidth = cell.column.getSize()
                          const charCount = Math.floor(cellWidth! / 8);

                          return cell?.getValue() !== null && (cell?.getValue() as string)?.length > charCount ? cell?.getValue() : '';
                        }

                        return (
                          <td
                            key={cell.id}
                            id={cell.id}
                            className='!text-sm !h-[42px] !py-0 !px-2 !font-normal'
                            style={{
                              borderBottom: row.getIsExpanded()
                                ? ''
                                :  theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                              color: themedColors.content,
                              maxWidth: '150px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              borderRight: isLastInGroup ? theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)' : undefined,
                            }}
                          >
                            <Tooltip
                              classes={{
                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                              }}
                              title={getIsTooltop(cell) as string}
                              placement="left"
                              arrow
                            >
                                <span className='' style={{justifyContent: isAlignTextRight ? 'right' : 'left',fontSize: '14px',lineHeight: '16.94px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex'}}>
                                {flexRender(cell.column.columnDef.cell,cell.getContext())}
                              </span>
                            </Tooltip>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </ScrollContainer>

          <ServerPaginationComponent
            firstPage={() => table.setPageIndex(0)}
            previousPage={() => table.previousPage()}
            getCanPreviousPage={() => getCanPreviousPage()}
            getCanNextPage={() => getCanNextPage()}
            nextPage={() => table.nextPage()}
            lastPage={() => table.setPageIndex(table.getPageCount() - 1)}
            getPageCount={() => table.getPageCount()}
            pageIndex={paginationState.pageIndex}
            setPageIndex={(pageIndex) => onPageChange(pageIndex)}
            setPageSize={(pageSize) => onPageSizeChange(pageSize)}
            pageSize={paginationState.pageSize}
            getRowCount={() => paginationState.totalItems}
            getLastPage={getLastPage}
          />
          </>
          
          ) : (
            <DefaultTableMessage
              state={'noData'}
            />
          )
        }
      
    </div>
  );
};

export default ConversationReportTable;
