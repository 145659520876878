import React from 'react';
import { TabKey } from '../../pages/reports/conversationReport/utils/types/conversation-report.types';

type FilterTabsProps = {
  tabs: TabKey[];
  activeTab: string;
  handleTabClick: (tab: TabKey) => void;
};

const FilterTabs: React.FC<FilterTabsProps> = ({ tabs, activeTab, handleTabClick }) => {
  return (
    <div className="flex border-b mt-4 border-[#DDDFE3] dark:border-[#485061] overflow-auto">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`px-4 py-[9px] text-sm font-medium ${activeTab === tab ? 'text-blue-600 border-b-2 border-[#0079FF]' : 'text-gray-500 hover:text-[#0079FF]'}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default FilterTabs;
