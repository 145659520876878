import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";

interface LoadingContextType {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export function useLoadingContext() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoadingContext must be used within a LoadingProvider");
    }
    return context;
}

export function LoadingProvider({ children }: { children: React.ReactNode }) {
    const [loading, setLoading] = useState(false);

    const setLoadingWrapper: Dispatch<SetStateAction<boolean>> = (
        value
    ) => {
        setLoading(value);
    };

    return (
        <LoadingContext.Provider value={{ loading, setLoading: setLoadingWrapper }}>
            {children}
        </LoadingContext.Provider>
    );
}
