import React, {useContext, useEffect, useState} from "react";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import {useLocation} from 'react-router-dom';
import LineChart from "./LineChart";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CustomCardReportLayout from "../../../layout/CustomCardReportLayout/CustomCardReportLayout";
import { UserContext } from "../../../../context/UserContext/UserContext";
import { generateRangeConfigs } from "../../../../types/enums/LineChartRange";
import { LineChartClonedData } from "./types/line-chart.types";
import { ConfigReport } from "../../../../types/report-config.types";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
    reportType: 'salesPerformance' | 'salesPace'
}

const LineChartBase: React.FC<Props> =({ reportType})=>{
    const [componentCount, setComponentCount] = useState(0);
    const [clonedData, setClonedData] = React.useState<LineChartClonedData[]>([{
        group_sequence: null,
        id: null,
        date_range: null,
        geo_name: null,
        affiliate_name: null,
        offer_name:null,
        display_filters:null,
        type:null,
        start_date: null,
        end_date: null,
    }]);
    const location = useLocation();
    // Reminder: These props come from performance report page, when navigating from there
    const { additionalProps } = location.state || {};
    let reportName: string

    if (reportType === 'salesPerformance') {
        reportName = ReportName.SalesPerformanceReportLineChart
    } else if (reportType === 'salesPace') {
        reportName = ReportName.SalesPaceReportLineChart
    }

    const [refreshKey, setRefreshKey] = React.useState(0);

    const userContext = useContext(UserContext)!;
    const { timezone } = userContext;

    useEffect(()=>{
        try {
            const response = OdinForgeService.instance().getLineChartConfig(reportName);
            response.then((result) => {
                if (!result) {
                    return
                }
                const configs = result.data.data
                if (configs.length === 0)
                    setComponentCount(1) 
                else {
                    
                    setComponentCount(configs.length);
                    let configArray: LineChartClonedData[] = [];
                    configs.forEach((element: ConfigReport, index: number) => {
                        const groupSequenceData = JSON.parse(element.group_sequence);

                        let start_date = groupSequenceData.start_date;
                        let end_date = groupSequenceData.end_date;
                        
                        configArray[index] = {
                            id: element.id,
                            date_range: groupSequenceData.date_range,
                            geo_name:groupSequenceData.geo_name ?? [],
                            affiliate_name: groupSequenceData.affiliate_name ?? [],
                            offer_name: groupSequenceData.offer_name ?? [],
                            type: element.type,
                            display_filters: groupSequenceData.display_filters ?? [],
                            start_date: start_date ?? undefined,
                            end_date: end_date ?? undefined
                        }

                        if (configArray[index].date_range !== 0){
                            const rangeConfigs = generateRangeConfigs(timezone);
                            // Convert the date_range to a string to match the keys in rangeConfigs
                            const dateRangeKey = configArray[index].date_range!.toString();

                            // Get the valid keys (which are strings) and find the max key
                            const validKeys = Object.keys(rangeConfigs);
                            const maxKey = validKeys[validKeys.length - 1];

                            // Ensure date_range is within the valid range
                            if (!rangeConfigs[dateRangeKey]) {
                                configArray[index].date_range = parseInt(maxKey, 10);
                            }

                            const { startDate, endDate } = rangeConfigs[configArray[index].date_range!.toString()];
                            configArray[index].start_date = dayjs.utc(startDate).tz(timezone);
                            configArray[index].end_date = dayjs.utc(endDate).tz(timezone);

                        }

                    });
                    if (additionalProps?.isFromReportTable ?? false ) {
                        configArray.forEach((item, index: number) => {
                            if (item.type !== 'default') {
                                return
                            }

                            item.geo_name = additionalProps?.geo_name ? [additionalProps.geo_name] : []
                            item.affiliate_name = additionalProps?.affiliate_name ? [additionalProps.affiliate_name] : []
                            item.offer_name = additionalProps?.offer_name ? [additionalProps.offer_name] : []
                            item.display_filters = []; 

                            const formattedStartDate = additionalProps?.formattedStartDate ? dayjs(additionalProps.formattedStartDate) : undefined;
                            const formattedEndDate = additionalProps?.formattedEndDate ? dayjs(additionalProps.formattedEndDate) : undefined;
 
                            item.start_date = formattedStartDate ? formattedStartDate.tz(timezone) : undefined;
                            item.end_date = formattedEndDate ? formattedEndDate.tz(timezone) : undefined;
                            item.display_filters = []
                            item.date_range = additionalProps?.data_range ?? 0

                            if (item.date_range !== 0){
                                const { startDate, endDate } = (generateRangeConfigs(timezone))[item.date_range!];
                                item.start_date = dayjs.utc(startDate).tz(timezone);
                                item.end_date =  dayjs.utc(endDate).tz(timezone);
                            }
                            
                            if (additionalProps?.geo_name) item.display_filters.push('Geo')
                            if (additionalProps?.affiliate_name) item.display_filters.push('Affiliate')
                            if (additionalProps?.offer_name) item.display_filters.push('Offer')
                        });
                    }
                    
                    setClonedData(configArray);
                    
                }
            })
        }catch (error){
            console.log('error', error)
        }
        // eslint-disable-next-line
    },[refreshKey, timezone]);

    function updateClonedData(data: LineChartClonedData) {
        setComponentCount((prevCount) => prevCount + 1)
        let updatedClonedData = [...clonedData]
        updatedClonedData[componentCount] = data
        setClonedData(updatedClonedData)
    }
    function deleteClonedData(id: string | number | null | undefined) {
        setComponentCount((prevCount) => prevCount - 1);
        let updatedClonedData = clonedData.filter((item:LineChartClonedData) => item.id !== id)
        setClonedData(updatedClonedData)
    }

    return(
        <>
            {[...Array(componentCount)].map((element, index:number) => {
                return (
                    <CustomCardReportLayout key={clonedData[index].id} id={clonedData[index].id}>
                        <LineChart
                            clonedData={clonedData[index]}
                            passData={updateClonedData}
                            passId={deleteClonedData}
                            reportName={reportName}
                            reportType={reportType}
                            setRefreshKey={setRefreshKey}
                            refreshKey={refreshKey}
                           ></LineChart>
                    </CustomCardReportLayout>
            )})}
        </>
    )
}

export default LineChartBase;
