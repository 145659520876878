function searchRow(data: any, searchTerm: string): any {
    if (Array.isArray(data)) {
        // Обрабатываем каждый элемент массива
        const filteredArray = data
            .map(item => searchRow(item, searchTerm))
            .filter(item => item !== null);
        return filteredArray.length > 0 ? filteredArray : null;
    } else if (typeof data === 'object' && data !== null) {
        const newData: any = { ...data };

        // Проверяем, содержит ли поле 'name' искомый термин
        if (newData.name && newData.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            // Если совпадение найдено, возвращаем текущий узел со всеми дочерними элементами
            return newData;
        }

        let matchFound = false;

        for (const key in newData) {
            const child = newData[key];

            if (typeof child === 'object' && child !== null) {
                const filteredChild = searchRow(child, searchTerm);
                if (filteredChild !== null) {
                    newData[key] = filteredChild;
                    matchFound = true;
                } else {
                    delete newData[key];
                }
            }
            // Если значение не объект или массив, оставляем его без изменений
        }

        // Если есть совпадение в дочерних элементах, возвращаем текущий узел со всеми его данными
        if (matchFound) {
            return newData;
        } else {
            // Если совпадений нет, возвращаем null
            return null;
        }
    } else {
        // Если это не объект и не массив, возвращаем null
        return null;
    }
}



export default searchRow;