import getSymbolFromCurrency from 'currency-symbol-map';
import capitalizeSpecificWords from './capitalizeSpecificWords';

function formatRowName(
    name: string | null | undefined, 
    currency: string | null | undefined, 
    groupIndex: number | null | undefined, 
): string {
    if (name === "NaN" || name === null || name === "" || name === undefined) {
        return "---";
    } else if (name !== currency) {
        return capitalizeSpecificWords(name);
    } else if (groupIndex === 0) {
        const formattedName = `${name.toUpperCase()} - ${getSymbolFromCurrency(currency) || ''} [Total]`;
        
        return currency === "NaN" 
            ? "Click without currency" 
            : capitalizeSpecificWords(formattedName);
    } else {
        return capitalizeSpecificWords(name) ?? '';
    }
}

export default formatRowName;