import React from "react";
import {ToastContainer} from "react-toastify";
import './Alerts.css'
export function Alert() {
    return (
        <>
            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
            />
        </>
    );
}
