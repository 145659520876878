import React from 'react';

interface ButtonProps {
    icon: React.ReactNode;
    text: string;
    onClick: () => void;
    additionalStyle: string; // Tailwind class for background color
}

const IconButton: React.FC<ButtonProps> = ({ icon, text, onClick, additionalStyle }) => {
    return (
        <button
            onClick={onClick}
            className={`flex items-center gap-2 px-4 py-2 cursor-pointer ${additionalStyle}`}
        >
            {icon}
            {text}
        </button>
    );
};

export default IconButton;