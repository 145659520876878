import React from "react";

export const BackArrowIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
        >
            <path
                stroke="#0079FF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 8H1m0 0 7 7M1 8l7-7"
            />
        </svg>
    );
};