import React from 'react';

type Props = {
  payload: any;
  active: boolean | undefined;
};

const ChartTooltip = ({ payload, active }: Props) => {
  const categoryPayload = payload?.[0];

  if (!categoryPayload || !active) return null;

  return (
    <div className='rounded-tremor-default border border-odin-light-border dark:border-odin-dark-border bg-odin-light-white dark:bg-odin-dark-blue-dark px-4 py-2 shadow-tremor-dropdown'>
      <div className='flex flex-1 space-x-2.5 items-center'>
        <div
          style={{ backgroundColor: categoryPayload?.color  !== 'rose' ? categoryPayload?.color : "#f43f5e" }}
          className={`flex w-2 h-2 flex-col rounded-full `}
        />
        <div className='w-full flex gap-7 text-sm text-odin-light-content dark:text-odin-dark-content'>
          <p className='whitespace-nowrap text-right font-medium'>
            {categoryPayload.value}%
          </p>
          <p className='whitespace-nowrap text-right'>{categoryPayload.name}</p>
        </div>
      </div>
    </div>
  );
};

export default ChartTooltip;
