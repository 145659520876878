import React from 'react'

const SortedArrowDescending = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.223 1.6166L1.22301 1.61659L1.22018 1.61376C1.18303 1.57661 1.17505 1.55933 1.17353 1.55591C1.17351 1.55587 1.17349 1.55582 1.17346 1.55576C1.17257 1.55384 1.16663 1.54096 1.16663 1.50065C1.16663 1.46034 1.17257 1.44746 1.17346 1.44554C1.17349 1.44548 1.17351 1.44543 1.17353 1.44539C1.17505 1.44197 1.18303 1.42469 1.22018 1.38754C1.25733 1.35039 1.27461 1.34241 1.27803 1.34089C1.27807 1.34087 1.27812 1.34085 1.27819 1.34082C1.28011 1.33993 1.29299 1.33398 1.33329 1.33398H8.66663C8.70693 1.33398 8.71981 1.33993 8.72173 1.34082L8.72189 1.34089C8.72531 1.34241 8.74259 1.35039 8.77974 1.38754L9.13329 1.03398L8.77974 1.38754C8.78953 1.39733 8.80567 1.41955 8.81905 1.45301C8.82524 1.46847 8.82926 1.4825 8.83152 1.49324C8.83218 1.49637 8.83261 1.49884 8.83289 1.50065C8.83261 1.50246 8.83218 1.50493 8.83152 1.50806C8.82926 1.5188 8.82524 1.53283 8.81905 1.54829C8.80567 1.58175 8.78953 1.60397 8.77974 1.61376L8.77692 1.6166L4.99996 5.45449L1.223 1.6166Z" fill="#677C9F" stroke="#677C9F"/>
    </svg>
  )
}

export default SortedArrowDescending