import React, { useContext } from "react";
import DatePickerLineChart from "../../UI/reportComponents/DatePickerLineChart";
import { SelectChangeEvent } from "@mui/material";
import FilterButtonsLineChart from "../../UI/reportComponents/FilterButtonsLineChart";
import ChartSelectDropdown from "../../UI/selection/ChartSelectDropdown";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { LineChartClonedData } from "../../pages/reports/LineChart/types/line-chart.types";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarChartProps {
  startDate: string | Dayjs;
  endDate: string | Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  dateRange: number;
  setDateRange: (newValue: number) => void;
  generateMappedNames: string[];
  affiliateName: string[];
  offerName: string[];
  getLineChartData: () => void;
  passData: (data: LineChartClonedData) => void;
  clonedReportId: string | number;
  passId: (id: string | number | null | undefined) => void;
  setDeletePopup: (newValue: boolean) => void;
  deletePopup: boolean;
  showDelete: boolean;
  datesApplyButton: boolean;
  getFilterData: () => void;
  copyButton: boolean;
  reportName: string;
  disableButton: boolean;
  buttonBackgroundColor: string;
  setButtonBackgroundColor: (newValue: string) => void;
  iconFillColor: string;
  setCopyButton: (newValue: boolean) => void;
  setIconFillColor: (newValue: string) => void;
  setDisableButton: (newValue: boolean) => void;
  crmType: string;
  setCrmType: (newValue: string) => void;
  clonedData: LineChartClonedData;
  enableFiltersApplyButton: boolean; // enable/disable the 'Apply' button
  filterConfig: {
    label: string;
    name: string;
    options: string[] | undefined;
    itemName: string[];
    handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  }[];
  title: string;
  clearOptions: () => void;
  isToggle?: boolean;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
  refreshKey?: number
  rangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
}
customGenerateRangeConfigs?: (timezone: string) => { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } };
}

const FilterBarChart = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  generateMappedNames,
  affiliateName,
  offerName,
  getLineChartData,
  passData,
  clonedReportId,
  passId,
  setDeletePopup,
  deletePopup,
  showDelete,
  datesApplyButton,
  getFilterData,
  copyButton,
  reportName,
  disableButton,
  buttonBackgroundColor,
  setButtonBackgroundColor,
  iconFillColor,
  setCopyButton,
  setIconFillColor,
  setDisableButton,
  crmType,
  setCrmType,
  clonedData,
  enableFiltersApplyButton,
  filterConfig,
  title,
  clearOptions,
  isToggle,
  setRefreshKey,
  refreshKey,
  rangeConfigs,
  customGenerateRangeConfigs,
}: FilterBarChartProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  return (
    <>
      <div
        className={
          "flex tablet-size:flex-row justify-between gap-4 tablet-size:gap-4 flex-wrap items-baseline"
        }
      >
        <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold truncate text-wrap"}>
          {title}
        </div>

        <div className="mr-1">
          <DatePickerLineChart
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            getFilterData={getFilterData}
            datesApplyButton={datesApplyButton}
            customRangeConfigs={rangeConfigs}
            customGenerateRangeConfigs={customGenerateRangeConfigs}
          />
        </div>
      </div>

      <div
        className={
          "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
        }
      >
        <ChartSelectDropdown
          filterConfig={filterConfig}
          getLineChartData={getLineChartData}
          enableFiltersApplyButton={enableFiltersApplyButton}
          clearOptions={clearOptions}
        />

        <div className="flex flex-wrap gap-3 items-end">
          <FilterButtonsLineChart
            copyButton={copyButton}
            reportName={reportName}
            disableButton={disableButton}
            buttonBackgroundColor={buttonBackgroundColor}
            setButtonBackgroundColor={setButtonBackgroundColor}
            iconFillColor={iconFillColor}
            setCopyButton={setCopyButton}
            geoName={generateMappedNames}
            affiliateName={affiliateName}
            offerNames={offerName}
            setIconFillColor={setIconFillColor}
            setDisableButton={setDisableButton}
            passData={passData}
            clonedReportId={clonedReportId as number}
            passId={passId}
            setDeletePopup={setDeletePopup}
            deletePopup={deletePopup}
            showDelete={showDelete}
            dateRange={dateRange}
            crmType={crmType}
            setCrmType={setCrmType}
            clonedData={clonedData}
            enableFiltersApplyButton={enableFiltersApplyButton}
            isToggle={isToggle}
            startDate={startDate}
            endDate={endDate}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
          />
        </div>
      </div>
    </>
  );
};

export default FilterBarChart;
