import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";
import { menuItems, namesEnum, tableHeadingNames } from "./utils/config";
import FilterBarDeclineResponseReport from "../../../organism/filterbar/FilterBarDeclineResponseReport";
import DeclineResponseTable from "./components/DeclineResponseTable";
import transformData from "./utils/transformData";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { DeclineResponseReport, DeclineResponseReportClonedData, DeclineResponseReportData } from "../../../../types/decline-response-report.types";
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";

dayjs.extend(utc);
dayjs.extend(timezone);


interface DeclineResponseInterface {
    names: string[]
    clonedData: DeclineResponseReportClonedData
    passData: (data: DeclineResponseReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const DeclineResponse: React.FC<DeclineResponseInterface> = ({
    names,
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}): JSX.Element => {
    const { tailwindColors, theme } = useContext(ThemeContext)!;
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']&&clonedData['group_sequence'].length > 0?clonedData['group_sequence']:[
        'Merchant Account',
    ]);
    const [selectedGroupings] = React.useState(3)
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const params = new URLSearchParams();
    const [data, setData] = React.useState<DeclineResponseReport[]>(clonedData['data']?clonedData['data']:[]);
    const resultArrays: number[][] = Array.from({ length: groupings.length + 1 }, () => []);
    const resultArraysCopy: number[][] = Array.from({ length: groupings.length + 1 }, () => []);
    const reportName = ReportName.DeclineResponseReport;
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState(theme === 'light' ? tailwindColors.odin.light.gray.light : tailwindColors.odin.dark.gray.dark_bluish_2);
    const [iconFillColor, setIconFillColor] = useState(theme === 'light' ? tailwindColors.odin.light.gray.bluish_light : tailwindColors.odin.dark.gray.bluish);
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    const [crmType, setCrmType] = useState('swiss')

    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<DeclineResponseReport[]>([]);

    const groupSequence = groupings;

    const reportConfigValues = useRef({ reportName, groupSequence });

    const [activeTab] = useState(clonedData['filterTab']?clonedData['filterTab']:'Details');

    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: { [subItemId: string]: boolean } }>(clonedData['selectedFilters']?clonedData['selectedFilters']:{});

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        dateRange: dateRange,
        selectedFilters: JSON.stringify(selectedFilters),
    });

    useEffect(() => {
        const isLight = theme === 'light'
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
            || JSON.stringify(selectedFilters) !== JSON.stringify(clonedData['selectedFilters'])
        );
        if (valuesChanged) {
            setIconFillColor(isLight ? tailwindColors.odin.dark.blue.medium : tailwindColors.odin.dark.gray.bluish);
            setFreezeButton(!isButtonActive);
            setButtonBackgroundColor(isButtonActive ? isLight ? '#fff' : tailwindColors.odin.dark.gray.dark : isLight ? '#EAEEF6' : tailwindColors.odin.dark.gray.dark_bluish_2);
        }
        if (!valuesChanged) {
            setIconFillColor(isLight ? '#fff' : tailwindColors.odin.dark.gray.bluish)
            setButtonBackgroundColor(isLight ? '#0079FF' : tailwindColors.odin.dark.gray.dark_bluish_2)
            setFreezeButton(true)
        }
        reportConfigValues.current = { reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;

        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.dateRange !== dateRange ||
            previousValues.selectedFilters !== JSON.stringify(selectedFilters)
        )) {
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                dateRange: dateRange,
                selectedFilters: JSON.stringify(selectedFilters),
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, theme, startDate, endDate, selectedFilters]);

    useEffect(() => {
        handleBlur();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dateRange, superTenant, groupings, selectedFilters])

    useEffect(() => {
        previousValuesRef.current = {
            groupings: JSON.stringify(groupings),
            dateRange: dateRange,
            selectedFilters: JSON.stringify(selectedFilters),
        };
    }, [groupings, reportName, dateRange, theme, selectedFilters]); 
    

    function generateParams() {
        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
    
        if (groupings.length > 0) {
            params.append('filter', (namesEnum)[groupings[0]]);
        }
    
        menuItems.forEach(menuItem => {
            let subItemsAdded = false;

            menuItem?.subItems?.forEach(subItem => {
                if (selectedFilters[menuItem.id]?.[subItem.id]) {
                    params.append(`${menuItem.id}[]`, subItem.id.replace('_txns', '').replace('_', ''));
                    subItemsAdded = true;
                }
            });
            if (!subItemsAdded) {
                params.append(`${menuItem.id}[]`, '');
            }
        });

        params.append('timezone', timezone) 
        
        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }
    }
    

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            dateRange: dateRange,
            selectedFilters: selectedFilters
        }

        return dataToHash;
    }

    async function handleBlur() {
        setTableLoading(true);
        generateParams()
        setData([]);
        const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
        if (cachedData){
            setTableLoading(false);
            setData(cachedData)
        } else {
            OdinForgeService.instance().getDeclineResponseReportData(params)
                .then(async (result: {data: DeclineResponseReportData}) => {
                    setTableLoading(false);
                    if (result && result?.data){

                        const transformedData = transformData(result.data, tableHeadingNames[groupings[0] as keyof typeof tableHeadingNames]);
                        setData(transformedData);

                        createHash(generateObjectToHash, transformedData, reportName)
                        // Utils.instance().createHash(generateObjectToHash(), transformedData, reportName)
                    } else {
                        setTableLoading(false);
                        setData([]);
                        Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                    }
                })
                .catch(() => {
                    setTableLoading(false);
                    Utils.instance().onFailure('An error occurred while fetching the report');
                });
            }
    }

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
                filterTab: activeTab,
                selectedFilters: selectedFilters,
            },
            report_name: reportName
        }
    }
    
    function generateClonedData(reportId: number)  {
        const dataToPass: DeclineResponseReportClonedData = {
            group_sequence: groupings,
            id: reportId,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
            filterTab: activeTab,
            selectedFilters: selectedFilters,
        }
        passData(dataToPass)
    }

    const handleSelectionChange = (newSelectedItems: { [key: string]: { [subItemId: string]: boolean } }) => {
      setSelectedFilters(newSelectedItems);
    };

    const handleApplyMobileSelect = (groupings: string[], filters: { [key: string]: { [subItemId: string]: boolean } }) => {
        setGroupings(groupings);
        setSelectedFilters(filters);
    }

    return (
        <div className={"pb-10  min-h-[450px]"} >
            <div className="!p-6 tablet-size:!p-0">
            <FilterBarDeclineResponseReport
                handleBlur={handleBlur}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                groupings={groupings}
                setGroupings={setGroupings}
                selectedGroupings={selectedGroupings}
                resultArrays={resultArrays}
                resultArraysCopy={resultArraysCopy}
                copyButton={copyButton}
                freezeButton={freezeButton}
                buttonBackgroundColor={buttonBackgroundColor}
                iconFillColor={iconFillColor}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                crmType={crmType}
                setCrmType={setCrmType}
                passId={passId}
                deletePopup={deletePopup}
                setButtonBackgroundColor={setButtonBackgroundColor}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                setIconFillColor={setIconFillColor}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                names={names}
                title={ReportName.DeclineResponseReport}
                isToggle={false}
                maxSelections={3}
                //////////////////////// for CSV btn
                data={transformedTableDataCSV}
                fieldsForCSV={{grouping: groupings[0]}}
                disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
                namesEnum={tableHeadingNames}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                // Label for select dropdown
                label={"Filter by Group:"}
                // data for filter dropdown
                menuItems={menuItems}
                handleSelectionChange={handleSelectionChange}
                selectedItems={selectedFilters}
                setSelectedItems={setSelectedFilters}
                handleApplyMobileSelect={handleApplyMobileSelect}
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                reportName={reportName}
            />
            </div>
            {
                data && data.length !== 0 ? (
                    <DeclineResponseTable data={data} setTransformedTableDataCSV={setTransformedTableDataCSV} heading={groupings[0] as keyof typeof tableHeadingNames} />
                ) : (
                    <div className="mt-6">
                        <DefaultTableMessage
                            tableLoading={tableLoading}
                            state={'noData'}
                        />
                    </div>
                )
            }
            
        </div>
    );
}

export default DeclineResponse