import * as React from "react"
import { SVGProps } from "react"
function FreezeIcon (props: SVGProps<SVGSVGElement>) {
    const {fill} = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={fill}
                d="M3.012 13.335a.555.555 0 00-.678.39.553.553 0 00.392.677l2.737.734-2.354 1.359a.554.554 0 00-.202.755.568.568 0 00.755.202l2.354-1.359-.734 2.738a.553.553 0 00.39.676.56.56 0 00.678-.39l1.02-3.805 4.077-2.355v4.709L8.662 20.45a.553.553 0 10.781.782l2.004-2.004v2.718a.553.553 0 001.106 0V19.23l2.003 2.004a.566.566 0 00.782 0 .553.553 0 000-.782l-2.785-2.785v-4.709l4.078 2.355 1.019 3.805a.563.563 0 00.677.39.552.552 0 00.39-.677l-.733-2.737 2.354 1.36a.567.567 0 00.755-.203.554.554 0 00-.202-.755l-2.354-1.36 2.737-.733a.554.554 0 00.296-.87.553.553 0 00-.582-.197l-3.805 1.02L13.105 12l4.078-2.354 3.805 1.02a.562.562 0 00.677-.392.55.55 0 00-.39-.676l-2.738-.734 2.354-1.359a.553.553 0 00-.553-.957l-2.354 1.359.733-2.738a.554.554 0 00-1.067-.286l-1.02 3.805-4.077 2.355V6.334l2.785-2.785a.553.553 0 00-.781-.782l-2.005 2.004V2.053a.553.553 0 10-1.105 0V4.77L9.443 2.767a.553.553 0 10-.781.782l2.785 2.785v4.709L7.37 8.688 6.35 4.883a.553.553 0 10-1.067.286l.734 2.738-2.354-1.36a.553.553 0 00-.553.958l2.354 1.36-2.737.733a.552.552 0 00-.391.676.563.563 0 00.677.391l3.805-1.02L10.895 12l-4.078 2.354-3.805-1.02z"
            ></path>
        </svg>
    );
}
export default FreezeIcon