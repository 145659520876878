import * as React from "react";

import {useLocation, useNavigate} from "react-router-dom";
import  OdinForgeService from "../../../odinForgeService/OdinForgeService";
import {LoginView} from "./LoginView";
import Utils from "../../../odinForgeService/Utils";
import {useContext, useEffect, useState} from "react";
import SessionManager from "../../../odinForgeService/SessionManager";
import {UserContext} from "../../../context/UserContext/UserContext";


export function LoginController() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [login1, setLogin] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [prevPassword, setPrevPassword] = useState('');
    const [attemptCount, setAttemptCount] = useState(0);
    const userContext = useContext(UserContext)!;
    const { setPermissions, setIsSuperAdmin, setIsAdmin } = userContext;
    let navigate = useNavigate();

    let location = useLocation();

    const hashPassword = (password: string) => {
        const encodedPassword = encodeURIComponent(password);
        const base64Password = btoa(encodedPassword);
        return base64Password;
    };
    
    const handleInputChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setUsername(value);
        setLogin(value === '' || password === '');
    };

    const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setPassword(value);
        setLogin(username === '' || value === '');
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const getRequestBody = () => {
        return {
            email: username,
            password: password
        };
    };

    useEffect(() => {
        const checkToken = async () => {
            const token = SessionManager.instance().getToken();
            if (token) {
                navigate('/dashboard');
            } else {
                navigate('/');
            }
        };
        checkToken().then(r => ({}));
    }, [navigate]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        const accessToken = queryParams.get('accessToken');

        if (email && accessToken) {
            SessionManager.instance().saveToken(accessToken, rememberMe);
            SessionManager.instance().saveEmail(email)

            navigate('/dashboard');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, navigate, setPermissions, setIsSuperAdmin, setIsAdmin]);

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const now = Date.now();
        const lastAttemptTime = sessionStorage.getItem('lastLoginAttemptTime');
        const timePassed = lastAttemptTime ? now - parseInt(lastAttemptTime) : null;
    
        if (timePassed !== null && timePassed < 3600000 && attemptCount >= 5) {
            Utils.instance().onFailure('Warning: Your account has exceeded allowed number of login attempts. Please try again in 1 hour.');
            return;
        }

        const tooManyRequestsData = localStorage.getItem('tooManyRequests');

        if (tooManyRequestsData) {
            const parsedData = JSON.parse(tooManyRequestsData);
            const currentTime = new Date().getTime();
        
            if (parsedData.value === 'true') {
                if (currentTime - parsedData.timestamp >= 120000) {
                    localStorage.removeItem('tooManyRequests');
                } else {
                    Utils.instance().onFailure('Your account has exceeded the allowed number of database queries, so you have been placed on a 2 minute cooldown. Please try again later.');
                    return;
                }
            }
        }
    
        if (timePassed !== null && timePassed >= 3600000 && attemptCount >= 5) {
            setAttemptCount(0);
        }
    
        const hashToCheck = hashPassword(password);
        const prevHashToCheck = hashPassword(prevPassword);
    
        if (hashToCheck === prevHashToCheck) {
            setAttemptCount(prevCount => {
                const newCount = prevCount + 1;
                if (newCount === 5) {
                    sessionStorage.setItem('lastLoginAttemptTime', now.toString());
                    Utils.instance().onFailure('Warning: Your account has exceeded allowed number of login attempts. Please try again in 1 hour.');
                    setPassword('');
                    setPrevPassword('');
                }
                return newCount;
            });
        } else {
            setPrevPassword(password);
            setAttemptCount(1);
            if (lastAttemptTime) {
                const input = getRequestBody();
                OdinForgeService.instance().login(input, rememberMe)
                    .then((response) => {
                        navigate('/dashboard');
                        Utils.instance().onSuccess('Successfully Logged in');
                        setAttemptCount(1)
                        sessionStorage.removeItem('lastLoginAttemptTime');
                        // set permissions and roles into the user context
                       setPermissions(response.data.reports)
                       setIsSuperAdmin(response.data.isSuperAdmin ?? false);
                       setIsAdmin(response.data.isAdmin ?? false);
                    })
                    .catch((error) => {
                        Utils.instance().onFailure('Login Unsuccessful');
                });

            }
        }
    
        if (attemptCount < 5) {
            const input = getRequestBody();
            OdinForgeService.instance().login(input, rememberMe)
                .then((response) => {
                    navigate('/dashboard');
                    Utils.instance().onSuccess('Successfully Logged in');
                    setAttemptCount(1)
                    sessionStorage.removeItem('lastLoginAttemptTime');
                    setPermissions(['VIEW_VARIANCE_REPORT', 'MODIFY_USER_PERMISSIONS'])
                })
                .catch((error) => {
                    Utils.instance().onFailure('Login Unsuccessful');
                });
        }
    };
    

    return (
        <LoginView
            username={username}
            password={password}
            showPassword={showPassword}
            handleInputChange1={handleInputChange1}
            handleInputChange2={handleInputChange2}
            handleTogglePassword={handleTogglePassword}
            onSubmitHandler={onSubmitHandler}
            login={login1}
            rememberMe={rememberMe}
            handleRememberMeChange={() => setRememberMe(!rememberMe)}
        />
    );
}
