import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon } from "@tremor/react";
import chevronDownIcon from "@heroicons/react/solid/esm/ChevronDownIcon";
import { Dialog, DialogActions, Checkbox, ListItemText, MenuItem } from "@mui/material";
import useMediaQuery from '../../../hooks/useMediaQuery';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import CloseIcon from "../../../assets/icons/CloseIcon";
import EmptyCheckbox from "../../../assets/icons/EmptyCheckbox";
import CheckedFilter from '../../../assets/icons/CheckedFilter';
import { UserContext } from '../../../context/UserContext/UserContext';

const TenantDropDown = () => {
    const { theme, themedColors } = useContext(ThemeContext)!;
    const userContext = useContext(UserContext)!;
    const { setSuperTenant, superTenantList = {}} = userContext;

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState<string | null>(null);
    const isMobile = useMediaQuery('(max-width: 1023px)');

    const anchorRef = useRef<HTMLDivElement | null>(null);

    const [ isHovered, setIsHovered ] = useState(false);

    // Convert the object to an array of { id, name } objects
    const tenantArray = superTenantList && typeof superTenantList === 'object' 
        ? Object.keys(superTenantList).map(key => ({
            id: key,
            name: superTenantList[key],
        }))
        : [];

    // Load superTenant from localStorage on component mount
    useEffect(() => {
        const storedSuperTenant = localStorage.getItem('superTenant');
        if (storedSuperTenant && tenantArray.some(tenant => tenant.id === storedSuperTenant)) {
            setSelectedTenant(storedSuperTenant);
            setSuperTenant(storedSuperTenant);
        } else if (tenantArray.length > 0 && !selectedTenant) {
            setSelectedTenant(tenantArray[0].id);
            setSuperTenant(tenantArray[0].id);
            localStorage.setItem('superTenant', tenantArray[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantArray, selectedTenant]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSelectTenant = (tenantId: string) => {
        setSelectedTenant(tenantId);
        setSuperTenant(tenantId);
        setOpenDialog(false);

        // Save the selected superTenant to localStorage
        localStorage.setItem('superTenant', tenantId);
    };

    return (
        <>
            <div 
                className="flex flex-row cursor-pointer relative"
                onClick={handleOpenDialog}
                ref={anchorRef} 
                onMouseOver={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <p
                    className={`block text-sm py-1 pr-0 pl-1 text-odin-light-content dark:text-odin-dark-content ${openDialog || isHovered ? "!text-odin-light-blue-medium" : ""}`}
                    style={{
                        maxWidth: isMobile ? `90px` : `200px`,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {selectedTenant && superTenantList ? superTenantList[selectedTenant] : "Tenant"}
                </p>

                <Icon 
                    className={`relative top-[-2px] block transition ease-in-out text-odin-light-content dark:text-odin-dark-content ${openDialog ? "rotate-180" : ""} ${openDialog || isHovered  ? "!text-odin-light-blue-medium" : ""}`} 
                    icon={chevronDownIcon} 
                    size={"sm"} 
                    // @ts-ignore -- "black" is not on the list of colors defined by tremor and throws a type error, but still works
                    color={"black"}
                />
            </div>

            <Dialog 
                open={openDialog} 
                onClose={handleCloseDialog}
                PaperProps={{ 
                    style: { 
                        position: 'absolute', 
                        top: isMobile ? 0 : anchorRef.current ? anchorRef.current.getBoundingClientRect().bottom + window.scrollY + 10 : 0,
                        left: isMobile ? 0 :  anchorRef.current ? anchorRef.current.getBoundingClientRect().left + window.scrollX - 30 : 0,
                        width: 'auto',
                        height: 'auto',
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'flex-start', 
                        justifyContent: isMobile ? 'flex-start' : 'center', 
                        borderRadius: isMobile ? '0px' : '8px', 
                        backgroundColor: `${theme === 'dark' ? themedColors.blue.dark : themedColors.white}`, 
                        padding: '0px 10px 0px 0px'
                    } 
                }}
                BackdropProps={{
                    invisible: true
                }}
                className="tablet-size:block flex flex-col"
                disableScrollLock={true}
            >
                <p className="tablet-size:hidden text-xl font-bold p-4 w-full left-0 top-0" style={{color: themedColors.content, borderBottom: `2px solid ${themedColors.border}`}}>
                    Tenant
                </p>

                {tenantArray.map((tenant) => (
                    <MenuItem
                        key={tenant.id}
                        value={tenant.id}
                        onClick={() => handleSelectTenant(tenant.id)}
                        sx={{
                            "&.MuiMenuItem-root": {
                                padding: "0px 5px",
                            },
                            "&.MuiMenuItem-root .MuiTypography-root": {
                                backgroundColor: 'transparent !important',
                                fontSize: "14px !important",
                                fontWeight: selectedTenant === tenant.id ? 500 : 400,
                                padding: 0
                            },
                            "&.MuiMenuItem-root:hover, &.Mui-selected": {
                                backgroundColor: 'transparent !important',
                                fontSize: "14px !important",
                            },
                        }}
                    >
                        <Checkbox
                            sx={{ fontSize: "12px" }}
                            checked={selectedTenant === tenant.id}
                            icon={<EmptyCheckbox />}
                            checkedIcon={<CheckedFilter />}
                        />
                        <ListItemText primary={tenant.name} style={{ fontSize: "14px !important" }} />
                    </MenuItem>
                ))}

                <DialogActions className="absolute tablet-size:!hidden top-4 right-4 text-[#ACBFDF] text-xl cursor-pointer" onClick={handleCloseDialog}>
                    <p className="tablet-size:hidden">
                        <CloseIcon />
                    </p>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TenantDropDown;
