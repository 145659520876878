import { RetentionReportClonedData } from "../types/retention-report.types";

export function updatePadding(
    columnWidth: {
        1: number;
        2: number;
        3: number;
        4: number;
        5: number;
        6: number;
        7: number;
        8: number;
    }, 
    selectedGroupings: number, 
    clonedData: RetentionReportClonedData,
    setPaddingLeftInitial: (value: React.SetStateAction<string>) => void,
    setPaddingLeftTotal: (value: React.SetStateAction<string>) => void,
    setPaddingLeftCycle: (value: React.SetStateAction<string>) => void,
    showCycles?: boolean
) {
    const screenWidth = window.innerWidth;
    let basePadding = (columnWidth as { [key: number]: number })[selectedGroupings] + 100;
    let basePaddingTotal = 75
    let basePaddingCycle = 70
    let dynamicPadding = 0;
    let dynamicPaddingTotal = 0;
    let dynamicPaddingCycle = 0;

    if (screenWidth > (showCycles ? 1700 : 0)) {
        basePadding = 0
        basePaddingTotal = 0
        basePaddingCycle = 0

        const firstColumn = document.getElementById(`firstColumn-${clonedData.id}`);
        const secondColumn = document.getElementById(`secondColumn-${clonedData.id}`);

        const pendingColumn = document.getElementById(`PendingColumn-${clonedData.id}`)

        const averageLTVColumm = document.getElementById(`averageLTV-${clonedData.id}`)

        if (firstColumn && secondColumn) {
            const firstColumnWidth = firstColumn.offsetWidth;
            const secondColumnWidth = secondColumn.offsetWidth;

            dynamicPadding = firstColumnWidth + (secondColumnWidth) - 55;
        }

        if (pendingColumn) {
            const pendingColumnWidth = pendingColumn.offsetWidth;

            dynamicPaddingCycle = (pendingColumnWidth) - 58;
        }

        if (averageLTVColumm) {
            const averageLTVColummWidth = averageLTVColumm.offsetWidth;

            dynamicPaddingTotal = (averageLTVColummWidth) - 55;
        }

    }

    setPaddingLeftInitial(`${basePadding + dynamicPadding + 2.5}px`);
    setPaddingLeftTotal(`${basePaddingTotal + dynamicPaddingTotal + 2.5}px`);
    setPaddingLeftCycle(`${basePaddingCycle + dynamicPaddingCycle + 2.5}px`);
}