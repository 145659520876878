const firstRowData = [
    {
        id: 1,
        name: 'Clicks',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 2,
        name: 'Order Clicks',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 3,
        name: 'Partials',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 4,
        name: 'Partials %',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: true,
        tips: "Partials / (Partials + Declines + Sales)",
        tips2: "Partials % = ",
        hasSortField: true
    },
    {
        id: 5,
        name: 'Declines',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 6,
        name: 'Declines %',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: true,
        tips: "Declines / (Declines + Sales)",
        tips2: "Declines % = ",
        hasSortField: true
    },
    {
        id: 7,
        name: 'Sales',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 8,
        name: 'Conv %',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: true,
        tips: "Sales / Clicks * 100",
        tips2: "Conv % = ",
        hasSortField: true
    },
    {
        id: 9,
        name: 'Partial Conv %',
        fontSize: "12px",
        width : "150px",
        padding: "8px 8px 8px 8px",
        isTips: true,
        tips: "Sales / (Partials + Declines + Sales)",
        tips2: "Partial Conv % = ",
        hasSortField: true
    },
    {
        id: 10,
        name: 'Sales %',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: true,
        tips: "Sales / (Sales + Declines)",
        tips2: "Sales % = ",
        hasSortField: true
    },
    {
        id: 11,
        name: 'Sales Rev',
        fontSize: "12px",
        width : "120px",
        padding: "16px 8px 16px 8px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    // {
    //     id: 12,
    //     name: 'Last 7 days',
    //     fontSize: "12px",
    //     width : "120px",
    //     padding: "16px 8px 16px 8px",
    //     isTips: false,
    //     tips: "",
    //     tips2: "",
    //     hasSortField: true
    // }
];


export default firstRowData;