import { darken, lighten } from 'polished';
import { darkSubColorEnum, subColorEnum } from '../types/enums/TableColors';

export const getRowHoverColor = (isHoveredRow: string | null | number, index: string, theme: string) => {
    return isHoveredRow === index ? theme === 'light' ? darken(0.02, `#F7F9FC`) : darken(0.22, `#495D80`) : '' 
}

export const getRowHoverBorderColor = (isHoveredRow: string | null | number, index: string, theme: string) => {
    return isHoveredRow === index ? theme === 'light' ? '#ACBFDF' : 'red' : 'transparent' 
}

interface isHoveredRowInterface {
    index: number | null | string;
    rowNumber: null | number
}

export const getRowHoverColorSalesPaceReport = (isHoveredRow: isHoveredRowInterface, index: string, theme: string, rowNumber: number) => {
    return isHoveredRow.index === index &&  isHoveredRow.rowNumber === rowNumber ? theme === 'light' ? '#F7F9FC' : darken(0.22, `#495D80`) : '' 
}

export function getRowHoverColorTotal(isHoveredRow:isHoveredRowInterface, index: string, theme: string, rowNumber: number, groupIndex: number) {
    if (isHoveredRow.index === index && isHoveredRow.rowNumber === rowNumber) {
        return theme === 'light' ? darken(0.02, `#F7F9FC`) :  darken(0.22, '#495D80');
    } else {
        return theme === 'light' ? darken(0.1, subColorEnum[groupIndex]) : lighten(0.05, darkSubColorEnum[groupIndex]);
    }
}