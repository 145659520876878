import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const FraudHigh = () => {
  const { themedColors, theme } = useContext(ThemeContext)!;

  const color = theme === 'light' ? '#E74C3C' : '#FDB5AE';

  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99998 4.83398V7.50065M7.99998 10.1673H8.00665M5.23998 0.833984H10.76L14.6666 4.74065V10.2607L10.76 14.1673H5.23998L1.33331 10.2607V4.74065L5.23998 0.833984Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default FraudHigh