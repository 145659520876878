import React, { PropsWithChildren, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';

const ChartsSkeleton = () => {
  const { themedColors } = useContext(ThemeContext)!;

  function InlineWrapperWithMargin({ children }: PropsWithChildren<unknown>) {
    return (
      <span
        style={{
          marginRight: '0.5rem',
          paddingLeft: 20,
          paddingRight: 20,
          width: '100%',
        }}
      >
        {children}
      </span>
    );
  }

  return (
    <div
      className={
        'mt-10 flex justify-between gap-10 flex-wrap !p-4 tablet-size:!p-1'
      }
    >
      <>
        <div className={'flex flex-col gap-4'}>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={1}
            height={30}
            width={120}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
          <div className='flex flex-wrap gap-6'>
            <Skeleton
              wrapper={InlineWrapperWithMargin}
              inline={true}
              wrap
              count={1}
              height={500}
              width={220}
              baseColor={`${themedColors.border}`}
              highlightColor={`${themedColors.sceleton}`}
            />
            <Skeleton
              wrapper={InlineWrapperWithMargin}
              inline={true}
              wrap
              count={2}
              height={500}
              width={220}
              baseColor={`${themedColors.border}`}
              highlightColor={`${themedColors.sceleton}`}
            />
          </div>
        </div>
        <div className={'flex flex-col gap-4'}>
          <div
            className={'text-xl font-bold	'}
            style={{ color: themedColors.content }}
          >
            <Skeleton
              wrapper={InlineWrapperWithMargin}
              inline={true}
              wrap
              count={1}
              height={30}
              width={120}
              baseColor={`${themedColors.border}`}
              highlightColor={`${themedColors.sceleton}`}
            />
          </div>
          <Skeleton
            wrapper={InlineWrapperWithMargin}
            inline={true}
            wrap
            count={1}
            height={500}
            width={220}
            baseColor={`${themedColors.border}`}
            highlightColor={`${themedColors.sceleton}`}
          />
        </div>
      </>
    </div>
  );
};

export default ChartsSkeleton;
