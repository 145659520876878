import React, { FC, useContext } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";

interface CustomToggleButtonGroupProps {
  crmType: string;
  setCrmType: (value: string) => void;
}

const CrmToggleButtonGroup: FC<CustomToggleButtonGroupProps> = ({
  crmType,
  setCrmType,
}) => {
  const { theme, themedColors } = useContext(ThemeContext)!;

  return (
    <div>
      <div className={` h-[42px] ${theme === 'light' ? 'bg-odin-light-gray-light' : 'bg-odin-dark-gray-dark'}`} style={{ borderRadius: "8px"  }}>
        <ToggleButtonGroup
          color="primary"
          value={crmType}
          exclusive
          onChange={(event, value) => {
            if (value !== null) setCrmType(value);
          }}
          className={"p-1"}
          style={{ height: "41px", padding: "3px 2px" }}
        >
          <ToggleButton
            value={"konnektive"}
            sx={{
              "&.MuiToggleButton-root": {
                border: "none",
                "&:first-child": {
                  borderRadius: "8px 8px 8px 8px",
                },
                padding: "8px 12px",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor:
                  crmType === "konnektive" ? themedColors.background_1 : "transparent",
              },
            }}
          >
            <div
              className={"text-xs"}
              style={{
                color: crmType === "konnektive" ? "#0079FF" : "#677C9F",
                textTransform: "none",
              }}
            >
              Konnektive
            </div>
          </ToggleButton>
          <ToggleButton
            value={"swiss"}
            sx={{
              "&.MuiToggleButton-root": {
                border: "none",
                "&:last-child": {
                  borderRadius: "8px 8px 8px 8px",
                },
                padding: "8px 12px",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: crmType === "swiss" ? themedColors.background_1 : "transparent",
              },
            }}
          >
            <div
              className={"text-xs"}
              style={{
                color: crmType === "swiss" ? "#0079FF" : "#677C9F",
                textTransform: "none",
              }}
            >
              Swiss
            </div>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default CrmToggleButtonGroup;
