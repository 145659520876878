const getFraudTooltip = (fraud: number) => {
    switch (fraud) {
        case 0:
        return 'Low risk of fraud';
        case 1:
        return 'High risk of fraud';
        case 2:
        return 'Medium risk of fraud';
        default:
        return 'No Fraud';
    }
}

export { getFraudTooltip }