import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

// export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
//     0: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz() },
//     1: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
//     2: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz() },
//     3: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz() },
//     4: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz() },
//     5: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().endOf('w').subtract(1, "w") },
//     6: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
//     7: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
// };

// export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
//     1: { startDate: dayjs.tz().startOf('day'), endDate: dayjs.tz(new Date(), "America/Toronto") },
//     2: { startDate: dayjs.tz().subtract(1, "day").startOf('day'), endDate: dayjs.tz().subtract(1, "day").endOf('day') },
//     3: { startDate: dayjs.tz().startOf('week'), endDate: dayjs.tz().endOf('week') },
//     4: { startDate: dayjs.tz().startOf('month'), endDate: dayjs.tz().endOf('month') },
//     5: { startDate: dayjs.tz().startOf('year'), endDate: dayjs.tz().endOf('year') },
//     6: { startDate: dayjs.tz().subtract(1, "week").startOf('week'), endDate: dayjs.tz().subtract(1, "week").endOf('week') },
//     7: { startDate: dayjs.tz().subtract(1, "month").startOf('month'), endDate: dayjs.tz().subtract(1, "month").endOf('month') },
//     8: { startDate: dayjs.tz().subtract(1, "year").startOf('year'), endDate: dayjs.tz().subtract(1, "year").endOf('year') },
// };

// export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
//     // 1: { startDate: dayjs.tz().startOf('day'), endDate: dayjs.tz(new Date(), "America/Toronto") },
//     1: { startDate: dayjs().tz("America/Toronto").startOf('day'),  endDate: dayjs().tz("America/Toronto").endOf('day') },
//     // 2: { startDate: dayjs.tz().subtract(1, "day").startOf('day'), endDate: dayjs.tz().subtract(1, "day").endOf('day') },
//     2: { startDate: dayjs().subtract(1, "day").tz("America/Toronto").startOf('day'), endDate: dayjs().subtract(1, "day").tz("America/Toronto").endOf('day') },
//     // 3: { startDate: dayjs.tz().startOf('week'), endDate: dayjs.tz().endOf('week') },
//     3: { startDate: dayjs().tz("America/Toronto").startOf('week'), endDate: dayjs().tz("America/Toronto").endOf('week') },
//     // 4: { startDate: dayjs.tz().startOf('month'), endDate: dayjs.tz().endOf('month') },
//     4: { startDate: dayjs().tz("America/Toronto").startOf('month'), endDate: dayjs().tz("America/Toronto").endOf('month') },
//     // 5: { startDate: dayjs.tz().startOf('year'), endDate: dayjs.tz().endOf('year') },
//     5: { startDate: dayjs().tz("America/Toronto").startOf('year'), endDate: dayjs().tz("America/Toronto").endOf('year') },
//     // 6: { startDate: dayjs.tz().subtract(1, "week").startOf('week'), endDate: dayjs.tz().subtract(1, "week").endOf('week') },
//     6: { startDate: dayjs().subtract(1, "week").tz("America/Toronto").startOf('week'), endDate: dayjs().subtract(1, "week").tz("America/Toronto").endOf('week') },
//     // 7: { startDate: dayjs.tz().subtract(1, "month").startOf('month'), endDate: dayjs.tz().subtract(1, "month").endOf('month') },
//     7: { startDate: dayjs().subtract(1, "month").tz("America/Toronto").startOf('month'), endDate: dayjs().subtract(1, "month").tz("America/Toronto").endOf('month') },
//     // 8: { startDate: dayjs.tz().subtract(1, "year").startOf('year'), endDate: dayjs.tz().subtract(1, "year").endOf('year') },
//     8: { startDate: dayjs().subtract(1, "year").tz("America/Toronto").startOf('year'), endDate: dayjs().subtract(1, "year").tz("America/Toronto").endOf('year') },
// };

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('w') },
    4: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('M') },
    5: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('y') },
    6: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().endOf('w').subtract(1, "w") },
    7: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    8: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('D').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('D').subtract(1, "d").tz(timezone), endDate: dayjs.tz().endOf('D').subtract(1, "d").tz(timezone) },
        3: { startDate: dayjs.tz().startOf('w').tz(timezone), endDate: dayjs.tz().endOf('w').tz(timezone) },
        4: { startDate: dayjs.tz().startOf('M').tz(timezone), endDate: dayjs.tz().endOf('M') },
        5: { startDate: dayjs.tz().startOf('y').tz(timezone), endDate: dayjs.tz().endOf('y').tz(timezone) },
        6: { startDate: dayjs.tz().startOf('w').subtract(1, "w").tz(timezone), endDate: dayjs.tz().endOf('w').subtract(1, "w").tz(timezone) },
        7: { startDate: dayjs.tz().startOf('M').subtract(1, "M").tz(timezone), endDate: dayjs.tz().startOf('M').subtract(1, "s").tz(timezone) },
        8: { startDate: dayjs.tz().startOf('y').subtract(1, "y").tz(timezone), endDate: dayjs.tz().startOf('y').subtract(1, "s").tz(timezone) }
    };
  };
