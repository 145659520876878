import React, { useContext } from 'react'
import dayjs, {Dayjs} from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';

dayjs.extend(advancedFormat)
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface LineGraphTooltipProps {
    startDate: Dayjs;
    endDate: Dayjs;
}

const LineGraphTooltip = ({startDate, endDate} : LineGraphTooltipProps) => {
    const { themedColors } = useContext(ThemeContext)!;

    const formatDateWithLabel = (date: Dayjs) => {
        let formattedDate = date.format('MMM D, YYYY');
        
        if (date.isToday()) {
            formattedDate += ' - Today';
        } else if (date.isYesterday()) {
            formattedDate += ' - Yesterday';
        }

        return formattedDate;
    };

  return (
    <div className='text-sm flex gap-3' style={{color: themedColors.content}}>
        <div className='flex gap-2 items-center'>
            <svg width="30" height="4" viewBox="0 0 30 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2L28 2" stroke="#677C9F" strokeWidth="3" strokeLinecap="round" strokeDasharray="8 8"/>
            </svg>

            {formatDateWithLabel(startDate)}
        </div>
        <div className='flex gap-2 items-center'>
            <svg width="30" height="4" viewBox="0 0 30 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2L28 2" stroke="#677C9F" strokeWidth="3" strokeLinecap="round"/>
            </svg>

            {formatDateWithLabel(endDate)}
        </div>
         
    </div>
  )
}

export default LineGraphTooltip