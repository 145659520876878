import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext/ThemeContext";

const DarkModeIcon = () => {
  const { theme } = useContext(ThemeContext)!;

  const color = theme === "light" ? "#677C9F" : "#0079FF";

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="relative z-[3] right-[-6px]"
    >
      <path
        d="M16.4999 9.65833C16.3688 11.0768 15.8365 12.4287 14.9651 13.5557C14.0938 14.6826 12.9195 15.5382 11.5797 16.0221C10.2398 16.5061 8.7899 16.5984 7.3995 16.2884C6.0091 15.9784 4.73575 15.2788 3.72844 14.2715C2.72113 13.2642 2.02153 11.9908 1.71151 10.6004C1.40148 9.21001 1.49385 7.76007 1.9778 6.42025C2.46175 5.08042 3.31728 3.90614 4.44426 3.03479C5.57125 2.16345 6.92308 1.63109 8.34158 1.5C7.5111 2.62356 7.11146 4.00787 7.21536 5.40118C7.31926 6.79448 7.9198 8.10422 8.90775 9.09217C9.8957 10.0801 11.2054 10.6807 12.5987 10.7846C13.992 10.8885 15.3764 10.4888 16.4999 9.65833Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DarkModeIcon;
