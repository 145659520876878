import React, { useContext } from "react";
import {FormControl, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, SelectChangeEvent} from "@mui/material";
import './Selection.css';
import EmptyCheckbox from "../../../assets/icons/EmptyCheckbox";
import CheckedCheckbox  from "../../../assets/icons/CheckedCheckbox";
import {ChevronDownIcon} from "@heroicons/react/outline";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";

type HandleSelectChangeFunction = (event: SelectChangeEvent<string[]>) => void;
interface SelectionProps {
    itemName: string[],
    handleSelectChange: HandleSelectChangeFunction,
    filterName?: string[],
    label: string,
    multiple: boolean,
    reversedOrderInItem?: boolean
    placeholder?: string
}

 const Selection: React.FC<SelectionProps> = ({itemName, handleSelectChange, filterName, label, multiple, reversedOrderInItem, placeholder}) => {
    const { theme, themedColors } = useContext(ThemeContext)!;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps:{
            style:{
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            }
        }
    }
    return(
        <div className={'flex flex-col '}>
            <label style={{ fontSize: "14px", color: themedColors.content }} className={'pb-1 text-base'}>{label}</label>
            <FormControl sx={{mr:1,width: 200, marginTop: '-2px' }}>
                <Select
                    multiple = {multiple}
                    displayEmpty
                    value={itemName}
                    onChange={handleSelectChange}
                    renderValue={(selected) => {
                        if (multiple) {
                            if (selected.length === 0) {
                                return <em style={{fontSize: '12px', color: theme === 'light' ? '#677C9F' : '#677C9F'}}>{placeholder ? placeholder : 'Select from the list'}</em>;
                            }
                            return selected.join(', ');
                        }
                        else {
                            if (!selected) {
                                return <em style={{fontSize: '12px', color: theme === 'light' ? '#677C9F' : '#677C9F'}}>{placeholder ? placeholder : 'Select from the list'}</em>;
                            }
                            return <span>{selected}</span>
                        }
                    }}
                    MenuProps={MenuProps}
                    IconComponent={ChevronDownIcon}
                    inputProps={{ 'aria-label': 'Without label', MenuProps: {disableScrollLock: true} }}
                    input={<OutlinedInput />}
                    className={"h-10"}
                    sx={{
                        '& .MuiSelect-icon, & .MuiSelect-iconOpen': {width: '20px', color: themedColors.content,},
                        '& .MuiSelect-iconOpen': { color: '#0079FF' },

                        '&.MuiOutlinedInput-root': {borderRadius:'6px'},
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                        "&.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: theme === 'light' ? "#ACBFDF" : "#495D80"}
                    }}

                >
                    {filterName && filterName.length > 0 ? filterName.map((filterName1) => {
                        return (
                            (
                                <MenuItem key={filterName1} value={filterName1}
                                        sx={{
                                            '&.MuiMenuItem-root': {
                                             
                                                '&.MuiMenuItem-root:hover, &.Mui-selected': {
                                                    backgroundColor: themedColors.background_1,
                                                },
                                                '& .MuiTypography-root': {
                                                    fontWeight: itemName.includes(filterName1) ? 500 : 400,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '14px !important',
                                                }
                                            }
                                        }}
                                >
                                    {reversedOrderInItem ? <>
                                        <ListItemText primary={filterName1}  />
                                        <Checkbox sx={{fontSize: '12px'}} checked={itemName.indexOf(filterName1) > -1}
                                                  icon={<EmptyCheckbox/>}
                                                  checkedIcon={<CheckedCheckbox />}
                                        />
                                    </>:<>
                                        <Checkbox sx={{fontSize: '12px'}} checked={itemName.indexOf(filterName1) > -1}
                                                  icon={<EmptyCheckbox/>}
                                                  checkedIcon={<CheckedCheckbox />}
                                        />
                                        <ListItemText primary={filterName1}  />
                                    </>}
        
                                </MenuItem>
                            )
                        )
                    }): (
                        <MenuItem disabled>
                            <ListItemText primary="No data available" sx={{
                                "& span": {
                                    fontSize: '14px',
                                }
                            }} />
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
}

export default Selection;