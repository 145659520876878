import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
  } from "@mui/material";
import { BackArrowIcon } from '../../../assets/icons/BackArrowIcon';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import DefaultButton from '../../UI/buttons/DefaultButton';
import NotFoundImage from '../../../assets/errorImages/NotFoundImage';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    const { themedColors } = useContext(ThemeContext)!;

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-white dark:bg-odin-dark-blue-dark px-4">
            {/* <h1 className="text-base font-semibold text-blue-500 mb-2 text-center">404 error</h1> */}
            <div className='w-full flex justify-center max-w-[400px]'>
                <NotFoundImage />
            </div>
            
            <p className="text-[32px] tablet-size:text-[48px] font-bold text-black mb-8 text-center mt-8 tablet-size:mt-10" style={{color: themedColors.content}} >We can't find that page</p>
            <p className="text-[16px] tablet-size:text-[20px] text-gray-500 mb-8  text-center" style={{color: themedColors.content}} >
                The address may have been typed incorrectly or the link may be broken.
            </p>
            <div className="flex flex-wrap mobile-size:flex-nowrap gap-4 w-full max-w-[400px]">
                <DefaultButton type='button' variant="outlined" onClick={() => navigate(-1)} label="GO BACK" startIcon={<BackArrowIcon />} className='!h-[40px] flex-grow !text-sm py-2 w-full'  />
                <DefaultButton type='button' onClick={() => navigate('/')} label="TAKE ME HOME" className='!h-[40px] flex-grow !text-sm py-2 w-full' />
            </div>
        </div>
    );
};

export default NotFoundPage;
