import React, { useContext } from 'react'
import DefaultButton from '../../UI/buttons/DefaultButton';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';

interface SearchByRowProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  handleSearch: (isReset?: boolean) => void
}

const SearchByRow = ({
    searchTerm,
    setSearchTerm,
    handleSearch
}: SearchByRowProps) => {
    const { theme } = useContext(ThemeContext)!;

    const handleClearSearch = () => {
        setSearchTerm('');
        handleSearch(false);
    }

  return (
    <div className="flex flex-wrap md:flex-nowrap gap-4 items-center">
        <div className='w-full bg-white dark:bg-odin-dark-blue-dark'>
        <input
          type="search"
          name="searchTerm"
          className={
            'input !h-[40px] !mb-0 !mt-0 !w-[240px] !text-[#243546] bg-white dark:bg-odin-dark-blue-dark dark:!border-odin-dark-gray-extra_dark hover:!border-odin-dark-blue-medium focus:!border-odin-dark-blue-medium placeholder:!text-[12px] placeholder:!text-[#677C9F] dark:!placeholder:!text-[#677C9F]'
          }
          placeholder={'Enter search term'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            fontSize: 14,
            WebkitBoxShadow: `0 0 0px 1000px ${
              theme === 'light' ? '#fff' : '#17202E'
            } inset`,
            WebkitTextFillColor: `${
              theme === 'dark' ? (searchTerm === '' ? '#677C9F' : '#F7EFEB') : ''
            }`,
            caretColor: `${theme === 'dark' ? '#F7EFEB' : '#000000'}`,
          }}
          id="searchTerm"
        />
        </div>
        <DefaultButton disabled={false} type='button' label='Search'  className="flex-grow !text-base max-w-[80px] !flex h-9" onClick={handleSearch} />
        <DefaultButton disabled={false} type='button' label='Reset'  className="flex-grow !text-base max-w-[80px] !flex h-9" onClick={handleClearSearch} variant="outlined" />
    </div>
  )
}

export default SearchByRow