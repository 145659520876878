import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext'

const NotFoundImage = () => {
    const {theme} = useContext(ThemeContext)!

  return (
    <>  
        {
            theme === 'light' ? (
                <svg width="401" height="279" viewBox="0 0 401 279" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M360.205 268.607H37.6486C35.1963 266.27 32.9027 263.756 30.7996 261.043C11.3887 235.999 10.5456 197.593 28.0274 170.997C34.4231 161.267 42.5367 153.405 49.8258 144.027C66.8254 122.155 61.9937 89.8742 72.8242 64.65C83.0885 40.7449 101.437 21.1131 123.542 11.7051C156.3 -2.23665 198.334 8.9091 225.814 31.7885C248.554 50.7216 265.928 69.6182 295.336 71.8768C332.384 74.7223 364.582 86.851 386.167 124.634C397.897 145.167 402.289 170.177 399.849 194.285C396.397 228.394 381.177 252.226 360.205 268.607Z" fill="#E1F2FF"/>
                    <path d="M21.33 168.307C25.2677 162.332 29.701 157.21 34.3952 151.787C37.551 148.158 40.3877 144.866 43.1497 141.31C52.9927 128.64 55.5218 112.093 57.9695 96.076C59.7335 84.5296 61.5574 72.5899 66.0907 62.0286C76.6053 37.5468 95.1645 18.1763 117.011 8.88391C132.622 2.23689 151.059 0.924763 170.324 5.09211C188.251 8.97887 205.758 17.4769 219.621 29.0202C222.963 31.7953 226.303 34.6779 229.249 37.2208C247.854 53.2531 263.922 67.0987 288.96 69.0279C309.517 70.607 326.309 74.9609 340.294 82.3368C356.793 91.0447 369.803 104.008 380.064 121.966C391.471 141.921 396.351 166.755 393.806 191.894C390.627 223.256 377.237 248.358 353.975 266.525L353.914 266.571H350.227V266.031H353.766C376.858 247.982 390.169 223.018 393.33 191.832C395.863 166.817 391.008 142.109 379.661 122.259C369.45 104.387 356.506 91.4887 340.091 82.8261C326.16 75.4788 309.425 71.1404 288.928 69.5657C263.746 67.6271 247.625 53.7349 228.958 37.649C226.013 35.1081 222.675 32.2265 219.337 29.4546C205.531 17.9578 188.092 9.49439 170.234 5.62148C151.056 1.47424 132.711 2.77681 117.179 9.39003C95.4468 18.6328 76.983 37.9052 66.5208 62.2647C62.0173 72.7563 60.2001 84.6571 58.4388 96.1823C55.9818 112.259 53.4416 128.882 43.5105 141.666C40.7402 145.232 37.8979 148.53 34.7375 152.164C30.0554 157.574 25.6324 162.683 21.7143 168.629C13.2358 181.538 8.824 197.707 9.29171 214.157C9.75843 230.729 15.1523 246.484 24.479 258.519C26.5236 261.147 28.8142 263.675 31.2879 266.031H35.0451V266.571H31.1943L31.082 266.557L31.0118 266.475C28.5071 264.092 26.1885 261.536 24.1186 258.874C14.7189 246.746 9.28297 230.871 8.81246 214.174C8.34244 197.603 12.7869 181.315 21.33 168.307Z" fill="#C6E5FD"/>
                    <path d="M325.444 236.25C325.913 240.081 326.184 243.913 326.277 247.733C326.344 250.174 326.332 252.608 326.25 255.038C326.184 257.103 326.068 259.167 325.891 261.221C325.863 261.646 325.83 262.071 325.792 262.491C325.668 263.822 325.519 265.149 325.359 266.475H333.002C334.911 263.194 336.163 259.627 337.319 255.877C340.367 246.022 341.571 235.245 339.826 224.838C338.805 218.732 336.381 212.278 330.783 208.209C327.177 205.586 323.12 207.309 322.076 210.759C320.894 214.668 322.104 218.952 323.048 222.933C324.102 227.361 324.903 231.778 325.444 236.25Z" fill="#C6E5FD"/>
                    <path d="M334.537 229.184C335.845 238.62 335.503 248.414 332.969 257.617C332.127 260.667 331.022 263.633 329.66 266.476H330.274C337.247 251.781 337.395 233.716 332.781 218.413C331.699 214.835 330.324 211.263 328.281 208.116C328.094 207.818 327.613 208.094 327.806 208.398C331.754 214.493 333.554 222.079 334.537 229.184Z" fill="#B9E0FB"/>
                    <path d="M361.273 266.473C364.262 263.746 367.378 261.139 370.655 258.696C374.337 255.952 378.164 253.429 382.166 251.094C385.771 248.996 389.785 246.87 391.839 243.249C393.65 240.046 392.065 235.845 387.599 235.028C380.653 233.764 374.188 236.507 368.96 240.052C360.055 246.086 352.943 254.555 347.815 263.737C347.304 264.65 346.814 265.561 346.338 266.473H361.273Z" fill="#C6E5FD"/>
                    <path d="M350.604 266.475C357.449 255.722 367.75 246.908 378.826 241.274C382.216 239.551 385.804 238.055 389.559 237.348C389.907 237.282 389.763 236.752 389.415 236.818C381.979 238.215 374.95 242.35 368.905 246.739C361.521 252.101 354.945 258.755 350.026 266.474L350.604 266.475Z" fill="#B9E0FB"/>
                    <path d="M307.914 231.522C316.74 241.185 321.849 253.666 324.282 266.473H324.841C322.422 253.693 317.376 241.249 308.66 231.528C308.433 231.268 308.202 231.014 307.964 230.76C307.721 230.495 307.329 230.887 307.572 231.147C307.688 231.268 307.804 231.395 307.914 231.522Z" fill="#B9E0FB"/>
                    <path d="M315.522 245.967C313.662 246.793 311.435 247.041 309.31 246.657C307.184 246.273 305.174 245.26 303.702 243.831C305.482 242.789 307.349 242.158 309.549 242.5C311.749 242.842 314.096 244.427 315.522 245.967Z" fill="#CDE8F9"/>
                    <path d="M322.49 263.669C320.605 264.291 318.478 264.33 316.558 263.778C314.637 263.227 312.935 262.087 311.808 260.6C313.657 259.767 315.514 259.331 317.512 259.849C319.51 260.367 321.427 262.08 322.49 263.669Z" fill="#CDE8F9"/>
                    <path d="M316.664 227.027C313.979 229.234 312.975 232.857 314.16 236.059C315.932 235.73 317.406 234.134 317.62 232.569C317.835 231.004 317.562 228.461 316.664 227.027Z" fill="#CDE8F9"/>
                    <path d="M310.715 236.829C307.491 239.027 303.019 238.384 300.338 235.337C303.653 233.419 307.839 234.1 310.715 236.829Z" fill="#CDE8F9"/>
                    <path d="M328.883 245.84C325.016 247.863 322.774 251.908 323.293 255.926C325.524 255.915 327.756 254.353 328.46 252.559C329.163 250.764 329.558 247.717 328.883 245.84Z" fill="#CDE8F9"/>
                    <path d="M318.92 256.097C314.405 257.995 309.194 256.287 306.827 252.134C311.37 250.585 316.226 252.276 318.92 256.097Z" fill="#CDE8F9"/>
                    <path d="M325.82 262.946C325.436 259.202 328.469 256.384 332.859 256.406C332.924 260.035 330.009 262.623 325.82 262.946Z" fill="#CDE8F9"/>
                    <path d="M300.256 223.902C302.377 224.315 304.45 225.165 306.034 226.504C307.618 227.844 308.672 229.7 308.657 231.555C306.394 231.552 303.847 230.927 302.248 229.454C300.649 227.98 300.106 225.848 300.256 223.902Z" fill="#CDE8F9"/>
                    <path d="M324.685 235.441C324.941 237.298 324.695 239.208 323.739 240.818C322.782 242.429 321.073 243.711 319.003 244.102C318.243 242.217 318.078 239.961 319.179 238.309C320.279 236.657 322.47 235.741 324.685 235.441Z" fill="#CDE8F9"/>
                    <path d="M337.438 266.475H344.498C344.528 266.414 344.563 266.359 344.593 266.296C354.139 246.185 371.662 230.578 380.467 210.132C382.336 205.791 382.675 197.836 375.634 198.657C368.6 199.476 362.771 204.498 358.298 209.989C347.428 223.331 342.793 239.074 338.93 255.606C338.15 258.944 336.853 262.633 337.367 266.119C337.386 266.248 337.416 266.354 337.438 266.475Z" fill="#CDE8F9"/>
                    <path d="M339.494 266.476C340.448 256.739 343.83 247.422 347.983 238.599C352.461 229.083 357.476 219.802 364.779 212.134C368.701 208.015 373.107 204.393 377.888 201.315C378.186 201.123 377.909 200.645 377.609 200.838C369.265 206.21 362.051 213.266 356.575 221.55C353.395 226.361 350.768 231.546 348.263 236.733C345.926 241.573 343.792 246.525 342.123 251.638C340.548 256.465 339.428 261.428 338.941 266.476H339.494Z" fill="#B9E0FB"/>
                    <path d="M59.9735 257.517C60.2164 257.815 60.4759 258.113 60.7464 258.417C61.1439 258.886 61.5634 259.372 61.9776 259.908C62.6898 260.835 63.2584 261.868 63.6671 262.961C64.0962 264.086 64.3369 265.275 64.4182 266.475H76.59C76.5917 265.843 76.5471 265.209 76.4481 264.579C76.2051 263.011 75.7855 261.443 75.8021 259.902C75.8076 259.389 75.863 258.881 75.9899 258.373C76.1556 257.721 76.4205 257.12 76.7573 256.551C77.5358 255.248 78.673 254.122 79.722 252.951C80.0367 252.604 80.3404 252.25 80.6274 251.886C81.8532 250.334 82.7034 248.507 83.1615 246.58C83.6805 244.421 83.7136 242.136 83.2388 239.977C83.0732 239.176 82.8303 238.398 82.5156 237.647C80.6218 233.076 75.0237 230.398 74.7531 224.971C74.5599 221.068 77.624 217.203 76.3045 213.52C75.3052 210.716 72.7877 208.916 70.4135 207.315C69.1879 206.487 68.2162 205.973 67.3769 204.676C66.9298 203.986 65.9027 201.838 65.9471 200.695C65.1188 202.285 64.3184 203.908 63.9153 205.653C63.54 207.287 63.6117 209.01 64.1527 210.595C64.7049 212.201 65.4832 213.631 65.5053 215.381C65.5715 219.908 61.4528 223.248 59.504 227.329C58.3224 229.791 58.1182 232.463 58.56 235.135C59.0071 237.807 60.3654 240.11 60.9009 242.738C61.2487 244.471 60.7408 246.001 60.0672 247.502C59.5757 248.612 58.9906 249.705 58.604 250.859C58.5046 251.163 58.4162 251.477 58.35 251.792C58.3389 251.825 58.3335 251.853 58.3279 251.886C57.7979 254.464 58.6813 255.96 59.9676 257.512C59.9679 257.512 59.9735 257.512 59.9735 257.517Z" fill="#C6E5FD"/>
                    <path d="M54.6591 266.473C54.1835 265.561 53.6935 264.65 53.1828 263.737C48.0538 254.555 40.9427 246.086 32.0374 240.052C26.809 236.508 20.344 233.764 13.3986 235.028C8.9322 235.845 7.34772 240.046 9.16399 243.249C11.2122 246.87 15.2261 248.996 18.8313 251.094C22.8339 253.429 26.66 255.952 30.3425 258.696C33.6193 261.139 36.7358 263.746 39.7246 266.473H54.6591Z" fill="#C6E5FD"/>
                    <path d="M50.3213 266.475H51.0488C44.1298 255.471 33.6217 246.448 22.3207 240.733C18.9308 239.016 15.3367 237.525 11.5825 236.818C11.2401 236.752 11.0912 237.282 11.4389 237.348C18.6713 238.712 25.4898 242.687 31.3972 246.916C38.7901 252.209 45.3776 258.815 50.3213 266.475Z" fill="#B9E0FB"/>
                    <path d="M79.1245 266.471C82.4527 255.243 88.0596 244.593 96.6495 236.572C96.7654 236.456 96.8869 236.351 97.0028 236.24C97.2623 236.003 96.8703 235.611 96.6108 235.854C96.3844 236.064 96.1581 236.274 95.9317 236.494C87.4251 244.571 81.8587 255.241 78.5546 266.471H79.1245Z" fill="#B9E0FB"/>
                    <path d="M88.3852 249.587C90.0729 250.504 92.1485 250.904 94.1759 250.703C96.2034 250.501 98.1698 249.699 99.6622 248.466C98.0655 247.353 96.3579 246.618 94.2631 246.775C92.1686 246.931 89.8421 248.246 88.3852 249.587Z" fill="#CDE8F9"/>
                    <path d="M80.5024 265.71C82.2293 266.436 84.2261 266.633 86.0739 266.258C87.9216 265.883 89.608 264.94 90.7792 263.625C89.1029 262.703 87.3893 262.153 85.4715 262.491C83.5535 262.828 81.6218 264.295 80.5024 265.71Z" fill="#CDE8F9"/>
                    <path d="M88.733 231.695C91.0925 233.972 91.765 237.455 90.4098 240.377C88.768 239.935 87.5013 238.324 87.4174 236.835C87.3333 235.347 87.7804 232.976 88.733 231.695Z" fill="#CDE8F9"/>
                    <path d="M93.5918 241.354C96.4594 243.662 100.713 243.393 103.463 240.729C100.49 238.677 96.5021 239.003 93.5918 241.354Z" fill="#CDE8F9"/>
                    <path d="M75.8293 248.465C79.3138 250.658 81.1187 254.631 80.3291 258.37C78.2312 258.193 76.2494 256.556 75.7224 254.816C75.1953 253.075 75.0529 250.179 75.8293 248.465Z" fill="#CDE8F9"/>
                    <path d="M84.4296 258.856C88.5331 260.98 93.5622 259.765 96.1002 256.037C91.9432 254.239 87.2497 255.465 84.4296 258.856Z" fill="#CDE8F9"/>
                    <path d="M77.425 264.785C78.0673 261.292 75.4264 258.414 71.2962 258.105C70.963 261.513 73.5101 264.166 77.425 264.785Z" fill="#CDE8F9"/>
                    <path d="M104.4 229.977C102.373 230.205 100.36 230.849 98.7699 231.99C97.1795 233.131 96.0487 234.797 95.9237 236.543C98.0526 236.71 100.495 236.314 102.109 235.048C103.724 233.782 104.394 231.817 104.4 229.977Z" fill="#CDE8F9"/>
                    <path d="M80.5579 239C80.1771 240.726 80.2655 242.542 81.0439 244.128C81.8226 245.714 83.3342 247.048 85.251 247.572C86.1078 245.856 86.4316 243.746 85.5207 242.11C84.61 240.474 82.6187 239.448 80.5579 239Z" fill="#CDE8F9"/>
                    <path d="M56.5007 266.475H63.5599C63.5824 266.354 63.6126 266.248 63.6316 266.119C64.1459 262.633 62.8488 258.944 62.0685 255.606C58.205 239.074 53.5706 223.331 42.7002 209.989C38.2272 204.498 32.3987 199.477 25.3648 198.657C18.3234 197.836 18.6622 205.791 20.5315 210.132C29.3364 230.578 46.8598 246.185 56.4052 266.296C56.4351 266.359 56.4702 266.414 56.5007 266.475Z" fill="#CDE8F9"/>
                    <path d="M44.2004 222.215C47.1737 226.779 49.6601 231.666 52.0395 236.56C54.4061 241.426 56.5717 246.403 58.2652 251.547C59.864 256.403 61.0088 261.395 61.5037 266.476H62.0568C61.1153 256.808 57.8032 247.553 53.7058 238.776C49.134 228.983 43.9775 219.434 36.4496 211.576C32.5372 207.492 28.143 203.899 23.3887 200.838C23.089 200.645 22.8119 201.123 23.1101 201.315C31.5035 206.718 38.7478 213.846 44.2004 222.215Z" fill="#B9E0FB"/>
                    <path d="M70.2315 220.705C70.0714 223.642 68.7684 225.939 67.4434 228.478C66.5655 230.157 65.8368 231.962 66.2012 233.883C66.6098 236.037 67.9513 237.869 68.9782 239.752C70.3143 242.187 70.8995 244.865 70.6567 247.631C70.2205 252.693 67.6147 257.254 67.1066 262.311C66.9681 263.696 66.9498 265.087 66.9841 266.477H67.5359C67.508 265.399 67.509 264.322 67.5814 263.244C67.9238 258.142 70.4081 253.615 71.1038 248.591C71.4241 246.25 71.2695 243.882 70.4579 241.651C69.7069 239.598 68.3819 237.842 67.4599 235.871C66.1072 232.972 66.8857 230.626 68.3542 227.954C69.4641 225.933 70.4191 223.996 70.6951 221.671C70.9932 219.203 70.811 216.365 69.8062 214.069C69.3203 212.953 68.6689 211.932 68.1002 210.861C67.2775 209.31 66.7035 207.675 66.3169 205.969C66.2396 205.622 65.7095 205.765 65.7869 206.113C66.339 208.564 67.3713 210.657 68.5694 212.837C69.9112 215.283 70.3805 217.922 70.2315 220.705Z" fill="#B9E0FB"/>
                    <path d="M200.5 278.586C310.957 278.586 400.5 273.776 400.5 267.842C400.5 261.908 310.957 257.098 200.5 257.098C90.043 257.098 0.5 261.908 0.5 267.842C0.5 273.776 90.043 278.586 200.5 278.586Z" fill="#C6E5FD"/>
                    <path d="M395.95 141.585C395.093 138.483 393.876 135.536 392.322 132.796C388.26 134.272 383.54 133.413 380.283 130.156C377.025 126.899 376.166 122.178 377.642 118.117C374.903 116.562 371.956 115.346 368.854 114.488C367.023 118.398 363.082 121.124 358.479 121.124C353.876 121.124 349.934 118.398 348.104 114.488C345.001 115.346 342.055 116.562 339.315 118.117C340.791 122.178 339.932 126.899 336.675 130.156C333.417 133.413 328.698 134.272 324.636 132.796C323.081 135.536 321.865 138.483 321.007 141.585C324.917 143.415 327.643 147.357 327.643 151.96C327.643 156.563 324.917 160.504 321.007 162.335C321.865 165.437 323.081 168.384 324.636 171.123C328.698 169.648 333.417 170.507 336.675 173.764C339.932 177.021 340.791 181.741 339.315 185.803C342.055 187.358 345.002 188.574 348.104 189.431C349.935 185.522 353.876 182.795 358.479 182.795C363.082 182.795 367.023 185.522 368.854 189.431C371.956 188.574 374.903 187.358 377.642 185.803C376.167 181.741 377.025 177.021 380.283 173.764C383.54 170.507 388.26 169.648 392.322 171.123C393.877 168.384 395.093 165.437 395.95 162.335C392.04 160.504 389.314 156.563 389.314 151.96C389.314 147.357 392.04 143.415 395.95 141.585ZM358.479 165.566C350.964 165.566 344.873 159.474 344.873 151.96C344.873 144.445 350.964 138.354 358.479 138.354C365.993 138.354 372.084 144.445 372.084 151.96C372.084 159.474 365.993 165.566 358.479 165.566Z" fill="#C6E5FD"/>
                    <path d="M184.735 58.2687C183.877 55.1664 182.661 52.2197 181.106 49.4798C177.044 50.9557 172.325 50.0966 169.067 46.8394C165.81 43.5823 164.951 38.862 166.426 34.8001C163.687 33.2455 160.74 32.0292 157.638 31.1719C155.808 35.0817 151.866 37.8079 147.263 37.8079C142.66 37.8079 138.719 35.0817 136.888 31.1719C133.786 32.0292 130.839 33.2455 128.1 34.8001C129.575 38.862 128.717 43.5821 125.459 46.8394C122.202 50.0966 117.482 50.9557 113.42 49.4798C111.865 52.2197 110.649 55.1664 109.791 58.2687C113.702 60.099 116.428 64.0405 116.428 68.6434C116.428 73.2463 113.702 77.1878 109.791 79.0184C110.649 82.1204 111.865 85.0672 113.42 87.807C117.482 86.3312 122.202 87.1902 125.459 90.4474C128.717 93.7049 129.575 98.4248 128.1 102.487C130.839 104.041 133.786 105.258 136.888 106.115C138.719 102.205 142.66 99.479 147.263 99.479C151.866 99.479 155.807 102.205 157.638 106.115C160.74 105.258 163.687 104.041 166.426 102.487C164.951 98.4248 165.81 93.7049 169.067 90.4474C172.325 87.1902 177.044 86.3312 181.106 87.807C182.661 85.0672 183.877 82.1204 184.735 79.0184C180.824 77.1878 178.099 73.2463 178.099 68.6434C178.099 64.0405 180.824 60.0988 184.735 58.2687ZM147.263 82.2494C139.749 82.2494 133.657 76.1576 133.657 68.6432C133.657 61.1289 139.749 55.0373 147.263 55.0373C154.777 55.0373 160.869 61.1291 160.869 68.6432C160.869 76.1576 154.777 82.2494 147.263 82.2494Z" fill="#C6E5FD"/>
                    <path d="M95.9321 152.157C95.0677 149.031 93.8423 146.061 92.2753 143.3C88.1823 144.788 83.4264 143.922 80.1437 140.64C76.8611 137.357 75.9958 132.601 77.4827 128.508C74.7224 126.941 71.7531 125.715 68.6268 124.852C66.7822 128.791 62.8102 131.538 58.172 131.538C53.5338 131.538 49.5618 128.791 47.7172 124.852C44.591 125.715 41.6217 126.941 38.8614 128.508C40.3483 132.601 39.4829 137.357 36.2004 140.64C32.9178 143.922 28.1619 144.788 24.0688 143.3C22.5019 146.061 21.2765 149.031 20.412 152.157C24.3525 154.001 27.0992 157.973 27.0992 162.611C27.0992 167.249 24.3525 171.221 20.412 173.066C21.2764 176.192 22.5018 179.161 24.0688 181.922C28.1618 180.435 32.9177 181.301 36.2004 184.583C39.4829 187.866 40.3479 192.622 38.8614 196.715C41.6217 198.282 44.5913 199.507 47.7172 200.371C49.5622 196.431 53.5338 193.684 58.172 193.684C62.8102 193.684 66.7819 196.431 68.6268 200.371C71.7528 199.507 74.7224 198.282 77.4827 196.715C75.9961 192.622 76.8611 187.866 80.1437 184.583C83.4262 181.301 88.1821 180.435 92.2753 181.922C93.8421 179.161 95.0675 176.192 95.9321 173.066C91.9916 171.221 89.2448 167.249 89.2448 162.611C89.2448 157.973 91.9916 154.001 95.9321 152.157ZM58.172 176.322C50.6 176.322 44.4617 170.183 44.4617 162.611C44.4617 155.039 50.6 148.901 58.172 148.901C65.7441 148.901 71.8823 155.039 71.8823 162.611C71.8823 170.183 65.7441 176.322 58.172 176.322Z" fill="#C6E5FD"/>
                    <path d="M204.817 166.336L198.314 259.435L198.241 260.498H161.299C157.925 260.498 155.346 257.504 155.835 254.166L169.013 166.336H204.817Z" fill="#60B3E5"/>
                    <path d="M205.488 166.336L198.984 259.435H163.216C159.843 259.435 157.264 256.428 157.765 253.091L170.771 166.336H205.488Z" fill="#92D0F6"/>
                    <path d="M103.039 256.57C100.199 256.57 97.8959 258.873 97.8959 261.714V264.433H141.947V256.57H103.039Z" fill="#92D0F6"/>
                    <path d="M282.677 256.57H140.424V264.433H288.811V262.704C288.811 259.317 286.064 256.57 282.677 256.57Z" fill="#77C3F0"/>
                    <path d="M289.21 230.085H86.9369C83.6636 230.085 80.9693 227.707 80.5853 224.472L78.9209 210.525L64.6482 90.8968C64.1663 86.8576 67.4289 83.2031 71.5206 83.2031H273.79C277.063 83.2031 279.758 85.5812 280.142 88.8161L294.665 210.525L296.082 222.391C296.564 226.43 293.298 230.085 289.21 230.085Z" fill="#60B3E5"/>
                    <path d="M291.669 227.198H89.3961C86.1228 227.198 83.4285 224.82 83.0445 221.585L81.3801 207.638L67.1074 88.0103C66.6255 83.971 69.8881 80.3164 73.9798 80.3164H276.249C279.523 80.3164 282.217 82.6945 282.601 85.9294L297.124 207.638L298.542 219.504C299.023 223.543 295.757 227.198 291.669 227.198Z" fill="#92D0F6"/>
                    <path d="M297.124 207.638H81.3801L67.1074 88.0103C66.6255 83.971 69.8881 80.3164 73.9798 80.3164H276.249C279.523 80.3164 282.217 82.6945 282.601 85.9294L297.124 207.638Z" fill="#77C3F0"/>
                    <path d="M289.005 200.647L275.876 87.3125H74.3748L87.504 200.647H289.005Z" fill="white"/>
                    <path d="M192.941 220.287C194.616 218.546 194.449 215.668 192.568 213.859C190.687 212.05 187.805 211.994 186.131 213.735C184.456 215.476 184.624 218.353 186.504 220.163C188.385 221.972 191.267 222.027 192.941 220.287Z" fill="white"/>
                    <path d="M86.3482 193.348L76.0048 104.023H278.7L289.044 193.348H86.3482Z" fill="#B9E0FB"/>
                    <path d="M234.448 94.6055H89.6304C88.2114 94.6055 87.1944 95.7557 87.3588 97.1747C87.5231 98.5936 88.8064 99.7438 90.2254 99.7438H235.043C236.462 99.7438 237.479 98.5936 237.315 97.1747C237.15 95.7557 235.867 94.6055 234.448 94.6055Z" fill="#B9E0FB"/>
                    <path d="M245.692 94.6055C244.273 94.6055 243.256 95.7557 243.42 97.1747C243.584 98.5936 244.868 99.7438 246.287 99.7438C247.705 99.7438 248.722 98.5936 248.558 97.1747C248.394 95.7557 247.11 94.6055 245.692 94.6055Z" fill="#B9E0FB"/>
                    <path d="M254.293 94.6055C252.874 94.6055 251.857 95.7557 252.021 97.1747C252.185 98.5936 253.469 99.7438 254.888 99.7438C256.307 99.7438 257.324 98.5936 257.159 97.1747C256.995 95.7557 255.712 94.6055 254.293 94.6055Z" fill="#B9E0FB"/>
                    <path d="M262.894 94.6055C261.475 94.6055 260.458 95.7557 260.622 97.1747C260.786 98.5936 262.07 99.7438 263.489 99.7438C264.908 99.7438 265.925 98.5936 265.76 97.1747C265.596 95.7557 264.313 94.6055 262.894 94.6055Z" fill="#B9E0FB"/>
                    <path d="M160.286 150.624L159.408 143.042H146.763L146.031 136.719L156.654 112.816H161.64L164.405 136.693H168.234L168.969 143.042H165.14L166.018 150.624H160.286ZM158.673 136.694L157.184 123.831L151.58 136.694H158.673Z" fill="white"/>
                    <path d="M178.32 112.816C181.318 112.816 183.908 114.242 186.088 117.093C188.27 119.944 189.772 124.932 190.597 132.054C191.42 139.161 191.071 144.139 189.55 146.99C188.03 149.841 185.779 151.267 182.798 151.267C179.799 151.267 177.211 149.854 175.034 147.029C172.856 144.203 171.35 139.186 170.515 131.977C169.696 124.906 170.047 119.944 171.568 117.093C173.088 114.242 175.338 112.816 178.32 112.816ZM179.026 118.805C177.857 118.805 177.014 119.576 176.496 121.116C175.978 122.657 176.052 126.303 176.718 132.054C177.384 137.806 178.154 141.448 179.027 142.98C179.901 144.512 180.923 145.278 182.092 145.278C183.278 145.278 184.126 144.508 184.635 142.967C185.144 141.426 185.067 137.789 184.403 132.054C183.737 126.303 182.97 122.657 182.104 121.116C181.239 119.576 180.212 118.805 179.026 118.805Z" fill="white"/>
                    <path d="M208.297 150.624L207.419 143.042H194.774L194.042 136.719L204.665 112.816H209.65L212.415 136.693H216.245L216.98 143.042H213.15L214.028 150.624H208.297ZM206.684 136.694L205.194 123.831L199.591 136.694H206.684Z" fill="white"/>
                    <path d="M204.918 184.556H168.437C166.878 184.556 165.467 183.292 165.287 181.733C165.106 180.174 166.224 178.91 167.783 178.91H204.264C205.823 178.91 207.234 180.174 207.414 181.733C207.595 183.292 206.477 184.556 204.918 184.556Z" fill="white"/>
                    <path d="M171.344 182.045L171.297 181.645H172.33L172.439 182.59C172.35 182.687 172.215 182.773 172.032 182.847C171.851 182.921 171.662 182.958 171.467 182.958C171.219 182.958 170.997 182.905 170.8 182.801C170.603 182.698 170.447 182.548 170.332 182.355C170.217 182.161 170.146 181.951 170.12 181.723C170.091 181.476 170.117 181.256 170.199 181.064C170.28 180.872 170.415 180.725 170.602 180.622C170.745 180.543 170.93 180.504 171.157 180.504C171.451 180.504 171.688 180.566 171.868 180.689C172.048 180.813 172.175 180.983 172.247 181.201L171.782 181.29C171.735 181.174 171.661 181.082 171.561 181.014C171.461 180.947 171.342 180.914 171.204 180.914C170.995 180.914 170.836 180.98 170.728 181.113C170.62 181.245 170.581 181.442 170.611 181.703C170.644 181.985 170.73 182.196 170.872 182.337C171.014 182.478 171.186 182.548 171.389 182.548C171.489 182.548 171.587 182.528 171.684 182.489C171.78 182.449 171.861 182.402 171.927 182.345L171.892 182.044H171.344V182.045Z" fill="#B9E0FB"/>
                    <path d="M172.851 181.745C172.823 181.504 172.836 181.301 172.89 181.137C172.93 181.016 172.991 180.907 173.073 180.812C173.155 180.715 173.249 180.644 173.355 180.598C173.495 180.536 173.662 180.504 173.856 180.504C174.205 180.504 174.497 180.613 174.732 180.829C174.967 181.046 175.107 181.348 175.152 181.734C175.196 182.117 175.127 182.417 174.943 182.633C174.76 182.849 174.494 182.957 174.146 182.957C173.793 182.957 173.5 182.85 173.267 182.634C173.034 182.419 172.895 182.123 172.851 181.745ZM173.343 181.729C173.374 181.997 173.46 182.201 173.6 182.34C173.74 182.478 173.906 182.548 174.097 182.548C174.288 182.548 174.436 182.479 174.543 182.341C174.649 182.204 174.687 181.997 174.655 181.723C174.623 181.451 174.541 181.248 174.406 181.114C174.271 180.981 174.105 180.914 173.908 180.914C173.71 180.914 173.559 180.981 173.454 181.116C173.348 181.252 173.312 181.456 173.343 181.729Z" fill="#B9E0FB"/>
                    <path d="M177.448 182.915L177.22 180.945H176.516L176.47 180.543H178.355L178.402 180.945H177.699L177.927 182.915H177.448Z" fill="#B9E0FB"/>
                    <path d="M178.812 181.745C178.783 181.504 178.797 181.301 178.85 181.137C178.89 181.016 178.95 180.907 179.033 180.812C179.115 180.715 179.209 180.644 179.315 180.598C179.455 180.536 179.622 180.504 179.816 180.504C180.165 180.504 180.457 180.613 180.692 180.829C180.927 181.046 181.067 181.348 181.111 181.734C181.156 182.117 181.087 182.417 180.903 182.633C180.72 182.849 180.454 182.957 180.106 182.957C179.753 182.957 179.46 182.85 179.227 182.634C178.994 182.419 178.855 182.123 178.812 181.745ZM179.303 181.729C179.334 181.997 179.42 182.201 179.56 182.34C179.7 182.478 179.866 182.548 180.057 182.548C180.248 182.548 180.396 182.479 180.503 182.341C180.609 182.204 180.647 181.997 180.615 181.723C180.583 181.451 180.501 181.248 180.366 181.114C180.231 180.981 180.065 180.914 179.868 180.914C179.671 180.914 179.519 180.981 179.414 181.116C179.309 181.252 179.272 181.456 179.303 181.729Z" fill="#B9E0FB"/>
                    <path d="M182.876 182.915L182.601 180.543H183.08L183.188 181.477H184.127L184.019 180.543H184.498L184.773 182.915H184.294L184.173 181.878H183.235L183.355 182.915H182.876Z" fill="#B9E0FB"/>
                    <path d="M185.201 181.745C185.173 181.504 185.186 181.301 185.239 181.137C185.279 181.016 185.34 180.907 185.422 180.812C185.505 180.715 185.598 180.644 185.705 180.598C185.845 180.536 186.012 180.504 186.205 180.504C186.555 180.504 186.846 180.613 187.082 180.829C187.317 181.046 187.456 181.348 187.501 181.734C187.545 182.117 187.476 182.417 187.293 182.633C187.11 182.849 186.844 182.957 186.496 182.957C186.143 182.957 185.85 182.85 185.616 182.634C185.384 182.419 185.245 182.123 185.201 181.745ZM185.693 181.729C185.724 181.997 185.81 182.201 185.95 182.34C186.09 182.478 186.256 182.548 186.447 182.548C186.637 182.548 186.786 182.479 186.893 182.341C186.999 182.204 187.036 181.997 187.004 181.723C186.973 181.451 186.89 181.248 186.755 181.114C186.621 180.981 186.455 180.914 186.257 180.914C186.06 180.914 185.909 180.981 185.803 181.116C185.698 181.252 185.661 181.456 185.693 181.729Z" fill="#B9E0FB"/>
                    <path d="M188.171 182.915L187.897 180.543H188.613L189.231 182.162L189.469 180.543H190.188L190.463 182.915H190.018L189.801 181.048L189.547 182.915H189.085L188.4 181.048L188.616 182.915H188.171Z" fill="#B9E0FB"/>
                    <path d="M191.104 182.915L190.83 180.543H192.588L192.635 180.945H191.355L191.416 181.471H192.607L192.653 181.87H191.462L191.537 182.516H192.862L192.908 182.915H191.104Z" fill="#B9E0FB"/>
                    <path d="M193.48 182.915L193.205 180.543H193.974C194.265 180.543 194.457 180.555 194.547 180.579C194.688 180.614 194.811 180.692 194.916 180.811C195.021 180.93 195.085 181.084 195.107 181.273C195.124 181.418 195.111 181.541 195.07 181.64C195.029 181.74 194.971 181.818 194.896 181.874C194.821 181.931 194.742 181.968 194.66 181.987C194.549 182.009 194.384 182.021 194.168 182.021H193.855L193.959 182.915H193.48ZM193.731 180.945L193.809 181.617H194.071C194.26 181.617 194.385 181.605 194.445 181.58C194.506 181.556 194.552 181.517 194.581 181.464C194.612 181.411 194.622 181.35 194.614 181.279C194.604 181.193 194.57 181.122 194.514 181.066C194.457 181.01 194.388 180.975 194.309 180.961C194.25 180.95 194.135 180.945 193.962 180.945L193.731 180.945Z" fill="#B9E0FB"/>
                    <path d="M197.75 182.915H197.229L196.959 182.377H196.011L195.878 182.915H195.37L196.019 180.543H196.526L197.75 182.915ZM196.759 181.977L196.331 181.097L196.112 181.977H196.759Z" fill="#B9E0FB"/>
                    <path d="M199.173 182.045L199.126 181.645H200.158L200.268 182.59C200.179 182.687 200.044 182.773 199.861 182.847C199.68 182.921 199.491 182.958 199.296 182.958C199.048 182.958 198.825 182.905 198.629 182.801C198.432 182.698 198.276 182.548 198.161 182.355C198.046 182.161 197.975 181.951 197.949 181.723C197.92 181.476 197.946 181.256 198.028 181.064C198.109 180.872 198.243 180.725 198.431 180.622C198.574 180.543 198.759 180.504 198.986 180.504C199.28 180.504 199.517 180.566 199.697 180.689C199.877 180.813 200.004 180.983 200.076 181.201L199.611 181.29C199.564 181.174 199.49 181.082 199.39 181.014C199.29 180.947 199.171 180.914 199.033 180.914C198.824 180.914 198.665 180.98 198.557 181.113C198.449 181.245 198.41 181.442 198.44 181.703C198.473 181.985 198.559 182.196 198.701 182.337C198.843 182.478 199.015 182.548 199.218 182.548C199.317 182.548 199.416 182.528 199.513 182.489C199.608 182.449 199.69 182.402 199.755 182.345L199.72 182.044H199.173V182.045Z" fill="#B9E0FB"/>
                    <path d="M200.913 182.915L200.639 180.543H202.398L202.444 180.945H201.164L201.225 181.471H202.416L202.462 181.87H201.271L201.346 182.516H202.671L202.718 182.915H200.913Z" fill="#B9E0FB"/>
                    <path d="M220.097 169.962H149.88L149.802 169.285H220.019L220.097 169.962Z" fill="white"/>
                    <path d="M218.093 174.071H152.835L152.756 173.395H218.015L218.093 174.071Z" fill="white"/>
                    <path d="M152.096 162.438L151.632 158.438H152.929C153.42 158.438 153.743 158.458 153.897 158.498C154.133 158.558 154.34 158.689 154.518 158.889C154.695 159.09 154.803 159.35 154.84 159.668C154.868 159.914 154.848 160.121 154.778 160.288C154.708 160.455 154.611 160.586 154.484 160.682C154.358 160.777 154.225 160.841 154.087 160.872C153.899 160.91 153.622 160.929 153.256 160.929H152.729L152.904 162.438H152.096ZM152.519 159.115L152.65 160.249H153.092C153.41 160.249 153.621 160.228 153.724 160.187C153.827 160.145 153.903 160.08 153.953 159.99C154.004 159.901 154.023 159.798 154.009 159.679C153.992 159.533 153.935 159.414 153.839 159.319C153.742 159.224 153.627 159.165 153.494 159.142C153.395 159.123 153.201 159.115 152.909 159.115H152.519Z" fill="white"/>
                    <path d="M160.134 162.438H159.256L158.802 161.529H157.202L156.977 162.438H156.12L157.216 158.438H158.07L160.134 162.438ZM158.464 160.855L157.741 159.371L157.372 160.855H158.464Z" fill="white"/>
                    <path d="M163.371 160.965L163.293 160.291H165.034L165.219 161.885C165.068 162.048 164.84 162.193 164.533 162.317C164.227 162.442 163.909 162.504 163.579 162.504C163.161 162.504 162.786 162.416 162.455 162.241C162.123 162.065 161.861 161.814 161.666 161.488C161.472 161.161 161.353 160.806 161.308 160.422C161.26 160.005 161.304 159.635 161.441 159.311C161.579 158.987 161.806 158.739 162.122 158.566C162.363 158.433 162.675 158.367 163.057 158.367C163.554 158.367 163.954 158.472 164.257 158.68C164.561 158.888 164.774 159.176 164.896 159.543L164.111 159.693C164.032 159.497 163.908 159.342 163.739 159.228C163.571 159.114 163.37 159.057 163.137 159.057C162.784 159.057 162.516 159.169 162.334 159.393C162.151 159.617 162.086 159.949 162.137 160.389C162.192 160.864 162.338 161.22 162.577 161.458C162.815 161.695 163.106 161.814 163.448 161.814C163.617 161.814 163.783 161.781 163.945 161.714C164.108 161.648 164.245 161.567 164.355 161.473L164.296 160.965H163.371V160.965Z" fill="white"/>
                    <path d="M167.145 162.438L166.682 158.438H169.649L169.727 159.115H167.568L167.671 160.001H169.68L169.758 160.675H167.749L167.875 161.764H170.11L170.188 162.438H167.145Z" fill="white"/>
                    <path d="M174.67 162.438L174.206 158.438H174.993L176.939 161.109L176.63 158.438H177.38L177.843 162.438H177.033L175.118 159.829L175.42 162.438H174.67Z" fill="white"/>
                    <path d="M179.423 160.46C179.376 160.053 179.397 159.711 179.487 159.434C179.554 159.231 179.657 159.048 179.796 158.886C179.934 158.724 180.093 158.604 180.271 158.525C180.508 158.42 180.789 158.367 181.115 158.367C181.705 158.367 182.198 158.55 182.594 158.916C182.99 159.282 183.226 159.79 183.301 160.441C183.376 161.087 183.259 161.592 182.95 161.957C182.641 162.322 182.193 162.504 181.605 162.504C181.01 162.504 180.516 162.323 180.123 161.96C179.73 161.597 179.497 161.097 179.423 160.46ZM180.253 160.433C180.305 160.886 180.449 161.23 180.686 161.463C180.922 161.697 181.201 161.814 181.523 161.814C181.845 161.814 182.096 161.698 182.275 161.465C182.455 161.234 182.518 160.886 182.464 160.422C182.411 159.963 182.271 159.622 182.044 159.396C181.817 159.171 181.537 159.057 181.204 159.057C180.871 159.057 180.616 159.172 180.438 159.4C180.261 159.628 180.199 159.972 180.253 160.433Z" fill="white"/>
                    <path d="M186.181 162.438L185.796 159.115H184.609L184.531 158.438H187.71L187.788 159.115H186.604L186.988 162.438H186.181Z" fill="white"/>
                    <path d="M192.488 162.438L192.025 158.438H194.767L194.845 159.115H192.91L193.02 160.061H194.691L194.769 160.738H193.098L193.295 162.438L192.488 162.438Z" fill="white"/>
                    <path d="M196.622 160.46C196.575 160.053 196.596 159.711 196.685 159.434C196.753 159.231 196.856 159.048 196.994 158.886C197.133 158.724 197.291 158.604 197.47 158.525C197.707 158.42 197.988 158.367 198.314 158.367C198.904 158.367 199.397 158.55 199.792 158.916C200.189 159.282 200.425 159.79 200.5 160.441C200.575 161.087 200.458 161.592 200.148 161.957C199.84 162.322 199.391 162.504 198.804 162.504C198.209 162.504 197.715 162.323 197.322 161.96C196.929 161.597 196.696 161.097 196.622 160.46ZM197.451 160.433C197.504 160.886 197.648 161.23 197.884 161.463C198.12 161.697 198.399 161.814 198.722 161.814C199.043 161.814 199.294 161.698 199.474 161.465C199.653 161.234 199.717 160.886 199.663 160.422C199.61 159.963 199.469 159.622 199.242 159.396C199.015 159.171 198.735 159.057 198.402 159.057C198.069 159.057 197.815 159.172 197.637 159.4C197.46 159.628 197.398 159.972 197.451 160.433Z" fill="white"/>
                    <path d="M202.01 158.438H202.818L203.069 160.604C203.109 160.949 203.145 161.171 203.176 161.273C203.23 161.437 203.327 161.568 203.469 161.667C203.611 161.766 203.794 161.816 204.02 161.816C204.249 161.816 204.416 161.769 204.522 161.675C204.628 161.582 204.684 161.466 204.692 161.33C204.7 161.194 204.685 160.967 204.649 160.651L204.393 158.438H205.201L205.444 160.539C205.499 161.019 205.517 161.359 205.496 161.557C205.476 161.755 205.414 161.922 205.313 162.059C205.211 162.195 205.067 162.304 204.88 162.385C204.693 162.466 204.441 162.506 204.124 162.506C203.743 162.506 203.448 162.462 203.24 162.374C203.032 162.286 202.863 162.171 202.732 162.03C202.601 161.889 202.509 161.741 202.454 161.586C202.375 161.357 202.309 161.019 202.257 160.571L202.01 158.438Z" fill="white"/>
                    <path d="M207.641 162.438L207.178 158.438H207.964L209.911 161.109L209.601 158.438H210.352L210.815 162.438H210.004L208.089 159.829L208.392 162.438H207.641Z" fill="white"/>
                    <path d="M212.32 158.438H213.797C214.13 158.438 214.386 158.463 214.567 158.514C214.81 158.584 215.025 158.706 215.213 158.882C215.401 159.059 215.553 159.275 215.67 159.531C215.787 159.787 215.867 160.102 215.91 160.476C215.949 160.805 215.94 161.089 215.886 161.328C215.82 161.618 215.705 161.854 215.54 162.035C215.416 162.171 215.238 162.277 215.009 162.354C214.838 162.41 214.602 162.438 214.304 162.438H212.784L212.32 158.438ZM213.207 159.115L213.514 161.764H214.117C214.342 161.764 214.504 161.751 214.6 161.726C214.728 161.693 214.83 161.638 214.908 161.56C214.985 161.481 215.041 161.353 215.074 161.173C215.108 160.994 215.107 160.75 215.071 160.441C215.035 160.131 214.981 159.894 214.907 159.728C214.834 159.563 214.742 159.434 214.633 159.341C214.524 159.248 214.392 159.185 214.237 159.152C214.122 159.127 213.899 159.115 213.57 159.115H213.207V159.115Z" fill="white"/>
                    <path d="M83.9289 179.387L38.8242 264.625H129.034L83.9289 179.387Z" fill="#C0E2FC"/>
                    <path d="M131.546 263.294L86.4401 178.056C85.9474 177.122 84.9851 176.543 83.9296 176.543C82.8742 176.543 81.9108 177.122 81.418 178.055L36.3137 263.294C35.851 264.168 35.8798 265.238 36.3904 266.089C36.901 266.937 37.8344 267.464 38.8244 267.464H129.034C130.024 267.464 130.958 266.937 131.468 266.089C131.98 265.24 132.009 264.169 131.546 263.294ZM43.5422 261.782L83.9296 185.459L124.317 261.782H43.5422Z" fill="#70BEED"/>
                    <path d="M81.7497 243.729L79.7357 223.205V213.715H88.122V223.205L86.136 243.729H81.7497ZM80.0666 254.157V246.405H87.8185V254.157H80.0666Z" fill="#70BEED"/>
                    <path d="M303.136 245.898L302.889 253.167C302.852 254.251 302.9 255.215 301.9 255.634L296.506 258.282C293.691 259.463 294.535 262.564 297.588 262.564H311.679V258.27L311.987 245.898H303.136Z" fill="#FFC5BD"/>
                    <path d="M310.238 257.933C310.459 257.808 310.963 257.519 311.129 257.452C311.693 257.224 312.256 257.774 312.256 258.378V265.542C312.256 266.145 311.766 266.635 311.163 266.635H284.625C283.834 266.635 283.193 265.994 283.193 265.202C283.193 265.108 283.212 264.995 283.23 264.901C283.325 264.467 283.626 264.09 284.06 263.902L299.866 256.242C300.741 255.808 301.414 256.145 301.583 256.924C301.86 258.207 302.974 258.988 305.604 258.955C307.514 258.93 309.18 258.534 310.238 257.933Z" fill="#193368"/>
                    <path d="M312.256 264.899V265.539C312.256 266.142 311.766 266.632 311.163 266.632H284.625C283.834 266.632 283.193 265.992 283.193 265.2C283.193 265.106 283.212 264.993 283.23 264.898L312.256 264.899Z" fill="#193972"/>
                    <path d="M337.504 244.704L338.563 251.666C338.664 252.74 338.747 253.601 337.934 254.32L333.678 258.561C331.391 260.584 333.187 263.249 336.078 262.268L349.422 257.74L347.781 252.835L345.886 241.859L337.504 244.704Z" fill="#FFC5BD"/>
                    <path d="M323.308 264.896C323.417 264.458 323.71 264.074 324.148 263.891L332.046 259.633L336.195 255.539C336.889 254.845 337.639 254.955 338.041 255.649C338.223 255.96 338.461 256.197 338.753 256.38C339.229 256.691 339.868 256.819 340.709 256.709C341.221 256.654 341.842 256.508 342.518 256.271C344.309 255.631 345.753 254.717 346.575 253.822C346.74 253.62 347.124 253.182 347.252 253.072C347.727 252.67 348.421 253.018 348.622 253.584L349.993 257.605L350.925 260.364C351.126 260.949 350.815 261.57 350.249 261.753L338.287 265.817C336.696 266.357 335.027 266.633 333.347 266.633H324.715C323.91 266.633 323.271 265.993 323.271 265.189C323.271 265.097 323.289 264.988 323.308 264.896Z" fill="#193368"/>
                    <path d="M323.308 264.901H334.802C335.337 264.901 335.869 264.813 336.375 264.641L350.724 259.766L350.925 260.369C351.126 260.954 350.816 261.575 350.249 261.758L336.649 266.377C336.143 266.549 335.612 266.637 335.077 266.637H324.715C323.91 266.637 323.271 265.997 323.271 265.193C323.271 265.102 323.289 264.993 323.308 264.901Z" fill="#193972"/>
                    <path d="M313.838 254.598L319.849 201.846L326.396 146.968L314.262 141.133L301.906 148.614L301.353 201.846L300.806 254.598H313.838Z" fill="#193368"/>
                    <path d="M348.842 250.404L342.347 200.96L341.184 147.749L326.396 141.242L313.056 148.22L323.938 202.293L336.62 252.84L348.842 250.404Z" fill="#193972"/>
                    <path d="M302.803 86.3215C300.456 89.6037 301.106 94.1235 304.214 96.6196C304.325 96.7085 304.439 96.795 304.556 96.8787C307.955 99.3101 312.681 98.5257 315.113 95.1264C317.544 91.7273 316.76 87.0006 313.361 84.5692C309.961 82.1379 305.235 82.9224 302.803 86.3215Z" fill="#FFC5BD"/>
                    <path d="M294.473 119.658C296.738 117.716 297.001 114.306 295.06 112.04C293.118 109.774 289.707 109.512 287.442 111.453C285.176 113.395 284.913 116.805 286.855 119.071C288.796 121.337 292.207 121.6 294.473 119.658Z" fill="#FFC5BD"/>
                    <path d="M315.111 95.1254C308.596 104.356 303.199 110.34 295.464 118.575L295.361 118.685L286.572 112.399C293.203 103.251 297.435 96.4339 302.731 86.4538L302.801 86.3203L315.111 95.1254Z" fill="#FFC5BD"/>
                    <path d="M300.756 88.0677C303.977 83.0047 309.18 81.436 313.414 84.274C316.485 86.3329 317.166 91.0895 316.445 94.2171C316.23 95.1461 315.069 97.2149 314.593 98.022C312.936 100.834 305.468 111.275 301.766 115.796L289.026 107.895C293.044 101.449 297.405 93.3345 300.756 88.0677Z" fill="#63B6D3"/>
                    <path d="M268.027 102.97L271.816 97.3008C274.705 99.1824 279.509 102.728 283.475 105.116C287.901 107.782 291.815 110.008 294.038 111.147L288.092 120.047C281.562 115.617 272.682 108.231 268.027 102.97Z" fill="#FFC5BD"/>
                    <path d="M294.95 119.089C296.911 116.877 296.708 113.495 294.496 111.534C292.285 109.573 288.903 109.777 286.942 111.988C284.981 114.199 285.184 117.582 287.396 119.543C289.607 121.503 292.989 121.3 294.95 119.089Z" fill="#FFC5BD"/>
                    <path d="M269.822 103.483C271.705 103.483 273.231 101.956 273.231 100.073C273.231 98.1904 271.705 96.6641 269.822 96.6641C267.939 96.6641 266.413 98.1904 266.413 100.073C266.413 101.956 267.939 103.483 269.822 103.483Z" fill="#FFC5BD"/>
                    <path d="M340.194 118.109C340.194 111.134 340.654 94.7048 340.654 94.7048C340.654 85.8047 334.346 81.0521 324.849 78.8203L313.409 79.3138C310.362 80.6531 305.496 83.3697 302.874 85.5367L304.17 105.992C303.279 120.787 302.39 138.852 301.426 153.986H341.855C341.393 146.894 340.194 124.898 340.194 118.109Z" fill="#63B6D3"/>
                    <path d="M322.864 93.6197C323.567 97.721 327.353 100.522 331.436 100.054C331.581 100.037 331.728 100.016 331.874 99.9913C336.121 99.2626 338.974 95.2286 338.245 90.9811C337.517 86.7337 333.482 83.881 329.235 84.6097C324.987 85.338 322.135 89.3721 322.864 93.6197Z" fill="#FFC5BD"/>
                    <path d="M343.213 126.574C343.516 123.512 341.279 120.784 338.216 120.482C335.154 120.179 332.427 122.417 332.124 125.479C331.822 128.541 334.059 131.269 337.121 131.571C340.183 131.874 342.911 129.636 343.213 126.574Z" fill="#FFC5BD"/>
                    <path d="M338.243 90.9805C340.355 100.944 343.068 115.286 343.124 124.938L343.13 125.094L332.147 126.978C329.892 116.008 326.987 104.73 322.907 93.7681L322.861 93.6194L338.243 90.9805Z" fill="#FFC5BD"/>
                    <path d="M322.582 93.2838C321.867 88.8767 325.243 84.6628 330.122 83.8716C333.904 83.2585 337.267 85.0232 339.017 87.8355C339.537 88.6709 339.839 89.5999 340.078 90.5361C340.909 93.7974 341.896 96.9381 342.603 100.244C343.592 104.868 344.531 111.201 345.049 115.906L329.488 119.053C327.889 111.41 325.046 103.096 323.172 95.6345L322.582 93.2838Z" fill="#63B6D3"/>
                    <path d="M303.845 113.098C304.098 113.098 304.302 112.894 304.302 112.641V103.859C304.302 103.607 304.098 103.402 303.845 103.402C303.593 103.402 303.389 103.607 303.389 103.859V112.641C303.389 112.894 303.593 113.098 303.845 113.098Z" fill="#37A6BF"/>
                    <path d="M329.116 119.597L330.332 119.352C330.58 119.302 330.739 119.061 330.69 118.813C330.64 118.566 330.448 118.403 330.15 118.456L329.86 118.515L326.641 99.688C326.599 99.4388 326.359 99.2939 326.113 99.3144C325.865 99.357 325.698 99.5928 325.74 99.842L329.116 119.597Z" fill="#37A6BF"/>
                    <path d="M314.098 86.3398C314.292 86.3398 314.496 86.3335 314.71 86.3211C318.476 86.1034 323.964 84.1 326.714 80.817L327.014 80.4574C327.176 80.2639 327.151 79.9755 326.957 79.814C326.764 79.6524 326.475 79.6756 326.314 79.872L326.014 80.2307C323.427 83.3165 318.231 85.2021 314.657 85.409C313.12 85.4965 311.169 85.2564 310.7 83.5921C310.568 83.1156 310.584 82.5658 310.751 81.9591C310.818 81.7155 310.675 81.4637 310.432 81.3968C310.179 81.3316 309.936 81.4745 309.87 81.7163C309.659 82.4855 309.641 83.1995 309.82 83.8384C310.148 85.0021 311.162 86.3398 314.098 86.3398Z" fill="#37A6BF"/>
                    <path d="M324.849 78.8259C322.565 81.3479 317.959 82.8831 314.688 82.8831C314.158 82.8831 312.549 82.8831 312.348 81.9328C312.129 80.891 313.409 79.3195 313.409 79.3195L311.435 63.9315L321.669 61.2266L322.838 67.7328L324.849 78.8259Z" fill="#FFC5BD"/>
                    <path d="M322.838 67.7328C321.468 72.8866 318.069 77.1448 313.408 79.3195L311.434 63.9315L321.668 61.2266L322.838 67.7328Z" fill="#EF9B8F"/>
                    <path d="M313.968 63.0395C313.064 62.2401 311.941 61.7118 310.692 61.4462C308.213 60.9194 306.264 62.0577 304.651 59.5477C303.852 58.3029 303.502 56.7811 303.371 55.2676C303.201 53.3055 303.398 51.2313 304.35 49.508C305.301 47.7847 307.121 46.4978 308.973 46.6627C310.548 46.8029 311.348 47.1682 312.791 47.7084C314.011 48.1651 315.419 47.9662 316.717 48.0478C318.848 48.1817 321.03 47.698 323.115 48.3477C324.853 48.889 326.451 50.3194 326.693 52.2289C328.344 52.699 329.31 54.6611 329.806 56.3946C330.302 58.128 330.123 60.0321 329.608 61.7861C328.826 64.4482 327.758 66.2146 326.076 68.3094C325.273 69.3084 323.415 71.0109 323.415 71.0109L323.032 71.2188C323.032 71.2184 314.792 63.7685 313.968 63.0395Z" fill="#193368"/>
                    <path d="M326.941 62.2162C325.871 67.4515 322.511 72.3496 316.868 74.6326C313.833 75.8604 309.498 76.0027 307.795 73.2577C306.277 70.8112 306.036 67.6886 306.25 64.7929C306.476 61.7318 306.946 58.6899 307.704 55.7196C308.778 51.5148 313.555 48.8881 319.34 50.082C325.843 51.4243 328.05 56.7915 326.941 62.2162Z" fill="#FFC5BD"/>
                    <path d="M327.482 62.3333C326.772 65.807 325.165 68.7277 323.032 71.2106L321.826 70.9701C322.153 69.4845 322.333 67.9025 321.97 67.2127L318.804 66.0057L319.671 61.7622C318.839 61.0883 318.171 59.609 318.142 58.2038L318.098 58.2323C314.688 58.673 310.374 58.2387 306.849 56.9585C306.944 56.493 307.058 56.031 307.167 55.5871C308.359 50.9435 313.628 48.346 319.429 49.532C325.656 50.8236 328.811 55.8337 327.482 62.3333Z" fill="#193368"/>
                    <path d="M323.02 67.5847C323.736 66.2383 323.344 64.6303 322.146 63.993C320.947 63.3558 319.395 63.9306 318.679 65.277C317.963 66.6234 318.355 68.2314 319.554 68.8687C320.752 69.506 322.304 68.9311 323.02 67.5847Z" fill="#FFC5BD"/>
                    <path d="M319.446 66.59C319.437 66.5887 319.428 66.5864 319.42 66.5841C319.298 66.551 319.227 66.4254 319.26 66.3041C319.323 66.0718 319.427 65.8549 319.569 65.6593C320.051 64.9941 320.901 64.6694 321.617 64.9125C321.739 64.9521 321.857 65.009 321.966 65.0819C322.184 65.2275 322.357 65.4303 322.468 65.6687C322.521 65.7833 322.471 65.9192 322.356 65.972C322.242 66.0238 322.106 65.9767 322.054 65.8611C321.976 65.6966 321.862 65.5624 321.713 65.4617C321.639 65.4125 321.558 65.3739 321.474 65.3464C320.94 65.1692 320.309 65.4193 319.94 65.9277C319.83 66.0786 319.75 66.246 319.7 66.4243C319.67 66.538 319.559 66.6075 319.446 66.59Z" fill="#EF9B8F"/>
                    <path d="M306.894 62.769C306.499 62.6249 306.061 62.828 305.917 63.2231C305.773 63.6182 305.976 64.0554 306.371 64.1998C306.767 64.344 307.204 64.1408 307.348 63.7456C307.492 63.3507 307.289 62.9134 306.894 62.769Z" fill="#193368"/>
                    <path d="M311.399 63.1558C311.003 63.0115 310.566 63.2148 310.422 63.6099C310.278 64.0049 310.481 64.4424 310.876 64.5866C311.271 64.731 311.708 64.5276 311.853 64.1325C311.997 63.7375 311.794 63.3002 311.399 63.1558Z" fill="#193368"/>
                    <path d="M314.018 61.0753C313.993 60.9137 313.796 60.8076 313.61 60.7656C312.74 60.5698 311.855 60.6075 310.982 60.7341C310.675 60.7786 309.846 60.8284 309.945 61.2441C310.01 61.5139 310.475 61.741 310.778 61.7342C311.455 61.7189 312.13 61.6873 312.803 61.6384C313.185 61.6109 314.1 61.6073 314.018 61.0753Z" fill="#193368"/>
                    <path d="M303.967 60.2269C304.019 60.072 304.231 60.0007 304.422 59.9911C305.313 59.9459 306.178 60.1332 307.017 60.4063C307.312 60.5025 308.121 60.6925 307.952 61.0852C307.843 61.3401 307.345 61.4848 307.048 61.4266C306.384 61.2967 305.724 61.1506 305.069 60.9882C304.698 60.8964 303.796 60.7373 303.967 60.2269Z" fill="#193368"/>
                    <path d="M307.746 67.8756C307.716 67.8711 307.687 67.8603 307.658 67.8432C307.235 67.5787 306.977 67.2583 306.894 66.8917C306.819 66.5596 306.894 66.2145 307.115 65.8675C307.239 65.6717 307.42 65.4314 307.624 65.1628C307.87 64.8376 308.151 64.4699 308.393 64.0901C308.46 63.9848 308.6 63.9512 308.709 64.0211C308.814 64.0888 308.846 64.23 308.778 64.3369C308.526 64.7315 308.241 65.1076 307.988 65.4389C307.793 65.6962 307.619 65.9248 307.5 66.1125C307.347 66.3536 307.292 66.5819 307.34 66.7903C307.393 67.0304 307.589 67.261 307.902 67.4562C308.007 67.5229 308.041 67.6637 307.975 67.7708C307.924 67.8507 307.834 67.8892 307.746 67.8756Z" fill="#EF9B8F"/>
                    <path d="M307.768 69.0508L311.788 69.1652C310.92 70.7792 308.24 70.7254 307.768 69.0508Z" fill="white"/>
                    <path d="M269.954 103.399C269.954 103.399 268.041 103.607 267.735 103.584C267.184 103.557 266.841 102.785 266.302 102.873L261.264 96.41L261.474 92.2718L261.505 92.1689C261.541 92.0459 261.515 91.9043 261.484 91.7831C261.447 91.6826 261.383 91.5969 261.319 91.5109L261.325 91.4904C261.033 91.1144 260.547 90.7926 260.262 90.6196C259.784 90.345 259.253 90.1 258.885 89.6789C258.511 89.2789 258.318 88.6413 258.552 88.1512C258.648 87.978 258.805 87.8231 258.994 87.7895C259.472 87.684 260.097 88.292 260.484 88.4954C260.81 88.6805 261.162 88.8509 261.487 89.0362C261.66 89.1315 261.833 89.2273 262.006 89.3223C262.042 89.3555 262.062 89.3613 262.103 89.3736C262.443 89.5848 262.789 89.776 263.129 89.9876C263.892 90.4347 264.639 90.8558 265.357 91.3798C266.437 92.1885 266.943 93.4321 267.795 94.4869C268.653 95.5207 271.085 96.9019 271.085 96.9019L269.954 103.399Z" fill="#FFC5BD"/>
                    <path d="M272.693 101.844C273.677 100.277 273.204 98.2089 271.637 97.2249C270.07 96.2409 268.003 96.7134 267.019 98.2802C266.035 99.847 266.507 101.915 268.074 102.899C269.641 103.883 271.709 103.41 272.693 101.844Z" fill="#FFC5BD"/>
                    <g opacity="0.7">
                    <path d="M269.833 36.5369C269.967 37.3915 270.066 38.2528 270.126 39.1217C270.229 40.3851 270.273 41.6406 270.242 42.9112C270.175 48.5392 268.877 54.2432 266.209 59.6119C266.091 59.8845 265.952 60.1429 265.817 60.4192C265.237 61.533 264.614 62.6173 263.905 63.6617C262.497 65.8818 260.859 67.9189 259.062 69.7389C257.968 70.8611 256.817 71.8985 255.615 72.8703C254.942 73.428 254.24 73.9341 253.538 74.4402C247.129 78.9379 239.489 81.4238 231.659 81.5529C228.286 81.6419 224.878 81.2779 221.51 80.4461C218.544 79.7056 215.602 78.6154 212.774 77.1377C198.573 69.7434 190.777 54.901 191.666 39.8909C191.868 36.5354 192.505 33.1487 193.62 29.8575C194.26 27.9078 195.077 25.9793 196.055 24.0952C199.986 16.5356 206.03 10.8088 213.064 7.2197C215.392 6.03924 217.806 5.09042 220.309 4.39247C221.796 3.9763 223.32 3.64853 224.858 3.39446C230.484 2.50426 236.341 2.85126 242.005 4.52083C242.19 4.57945 242.352 4.62336 242.536 4.68179C243.354 4.93756 244.177 5.2118 244.991 5.54504C246.377 6.04979 247.749 6.67253 249.098 7.37651C260.711 13.4373 268.062 24.4793 269.833 36.5369Z" fill="#C0E2FC"/>
                    <path d="M263.905 63.6481C262.497 65.8682 260.859 67.9053 259.062 69.7253L213.064 7.20613C215.392 6.02567 217.806 5.07685 220.309 4.37891L263.905 63.6481Z" fill="#E1F2FF"/>
                    <path d="M231.66 81.5548C228.286 81.6438 224.879 81.2798 221.51 80.448L191.666 39.8928C191.868 36.5373 192.505 33.1506 193.62 29.8594L231.66 81.5548Z" fill="#E1F2FF"/>
                    <path d="M270.242 42.9111C270.175 48.5392 268.877 54.2432 266.209 59.6119L224.859 3.39446C230.484 2.50426 236.341 2.85126 242.006 4.52083L270.242 42.9111Z" fill="#E1F2FF"/>
                    </g>
                    <path d="M258.706 70.5412L250.523 76.7227L313.85 160.559L322.033 154.378L258.706 70.5412Z" fill="#8CCDF6"/>
                    <path d="M267.37 82.2277L259.436 88.2201C258.122 89.2132 257.861 91.0836 258.854 92.3978L310.779 161.139C311.771 162.453 313.641 162.714 314.956 161.721L322.89 155.729C324.204 154.736 324.464 152.865 323.472 151.551L271.547 82.81C270.555 81.4957 268.684 81.235 267.37 82.2277Z" fill="#51A9DE"/>
                    <path d="M247.689 77.6191L249.582 80.1244C250.104 80.8163 251.089 80.9536 251.78 80.4309L261.968 72.7354C262.66 72.2127 262.797 71.2285 262.275 70.5366L260.382 68.0312L247.689 77.6191Z" fill="#51A9DE"/>
                    <path d="M256.149 75.6293C237.742 89.5338 211.452 85.8699 197.546 67.4602C183.64 49.0504 187.304 22.761 205.712 8.85651C224.122 -5.04948 250.411 -1.38559 264.317 17.0242C278.223 35.4339 274.559 61.7234 256.149 75.6293ZM208.74 12.865C192.542 25.1002 189.318 48.2324 201.555 64.4324C213.791 80.6324 236.924 83.8562 253.122 71.6209C269.322 59.3841 272.545 36.2519 260.308 20.0519C248.072 3.85195 224.94 0.628161 208.74 12.865Z" fill="#51A9DE"/>
                    <path d="M262.064 99.6997C262.114 100.01 262.151 100.328 262.298 100.599C262.575 101.112 263.17 101.348 263.719 101.545C263.632 102.192 264.042 102.83 264.593 103.183C265.144 103.535 265.811 103.651 266.461 103.712C267.252 103.786 268.499 103.863 269.192 103.395C269.755 103.015 269.528 102.253 268.934 102.069C268.656 101.983 268.361 101.99 268.071 101.977C267.667 101.959 267.265 101.9 266.872 101.801C267.385 101.625 267.897 101.448 268.409 101.272C268.663 101.185 268.92 101.096 269.141 100.945C269.588 100.642 269.837 100.06 269.451 99.6364C268.939 99.0726 268.191 99.2051 267.511 99.1584C267.193 99.1365 266.874 99.1128 266.556 99.0794C266.407 99.0638 265.875 98.9067 265.757 98.9746C266.166 98.7396 266.628 98.6193 267.068 98.4518C267.508 98.2842 267.948 98.0525 268.216 97.665C268.484 97.2777 268.525 96.7015 268.202 96.3592C267.884 96.0237 267.361 96.019 266.9 96.0379C265.941 96.0771 264.983 96.1163 264.024 96.1555C264.648 95.8952 265.272 95.6347 265.896 95.3744C266.246 95.2285 266.683 95.0747 266.801 94.6921C266.905 94.3542 266.792 93.8792 266.535 93.6385C266.255 93.3754 265.845 93.3198 265.463 93.2768C264.328 93.1492 263.137 92.8898 262.002 92.8829C260.454 92.8733 258.938 93.9968 260.011 95.6728C260.138 95.8718 260.301 96.0479 260.413 96.2561C260.741 96.8668 260.6 97.6852 261.046 98.2158C261.258 98.468 261.577 98.6157 261.777 98.8775C261.956 99.1126 262.016 99.4025 262.064 99.6997Z" fill="#FFC5BD"/>
                    <path d="M319.087 149.237L314.052 144.19C317.104 141.146 321.267 136.335 325.273 131.467C328.682 127.324 331.82 123.805 333.673 121.957L341.575 129.878C333.91 137.03 326.72 143.019 319.135 149.197L319.087 149.237Z" fill="#FFC5BD"/>
                    <path d="M337.535 131.603C340.625 131.603 343.13 129.099 343.13 126.009C343.13 122.919 340.625 120.414 337.535 120.414C334.445 120.414 331.94 122.919 331.94 126.009C331.94 129.099 334.445 131.603 337.535 131.603Z" fill="#FFC5BD"/>
                    <path d="M319.201 149.126C320.478 147.628 320.3 145.378 318.803 144.101C317.305 142.824 315.055 143.002 313.778 144.499C312.501 145.997 312.679 148.247 314.176 149.524C315.674 150.801 317.924 150.623 319.201 149.126Z" fill="#FFC5BD"/>
                    <path d="M316.528 150.39C318.507 150.373 320.098 148.755 320.081 146.775C320.063 144.796 318.445 143.206 316.466 143.223C314.486 143.24 312.896 144.858 312.913 146.838C312.93 148.817 314.549 150.408 316.528 150.39Z" fill="#FFC5BD"/>
                    <path d="M314.795 143.668C314.539 143.816 313.846 143.954 313.601 143.954C313.358 143.954 312.035 143.714 311.63 143.551C310.9 143.254 310.144 142.948 309.297 142.926L308.972 142.922C308.258 142.922 307.546 142.979 306.836 143.038L305.623 143.137C305.292 143.162 304.965 143.204 304.637 143.246L304.006 143.321C303.939 143.327 303.862 143.328 303.778 143.328L303.506 143.327C303.098 143.327 302.642 143.349 302.388 143.613C302.233 143.776 302.165 144.018 302.201 144.276C302.284 144.901 302.864 145.301 303.4 145.419C303.585 145.461 303.786 145.482 304.014 145.482C304.256 145.482 304.498 145.458 304.741 145.435L305.001 145.411C305.103 145.403 305.231 145.397 305.372 145.397C305.709 145.397 306 145.435 306.214 145.51C306.429 145.579 306.556 145.678 306.606 145.823C306.63 145.879 306.636 145.95 306.625 146.037C306.486 147.136 305.217 147.93 304.094 148.519C303.73 148.708 303.363 148.901 302.998 149.133C302.687 149.331 302.366 149.535 302.087 149.641L301.991 149.486L301.866 149.289L301.715 149.467C301.422 149.811 301.458 150.337 301.54 150.718C301.632 151.149 301.826 151.525 302.1 151.805C302.35 152.061 302.667 152.231 302.996 152.289C302.847 152.808 302.983 153.429 303.225 153.827C303.393 154.101 303.624 154.331 303.876 154.474C304.06 154.58 304.281 154.628 304.476 154.671L304.549 154.688C304.613 155.075 304.81 155.44 305.1 155.703C305.369 155.948 305.716 156.105 306 156.105C306.053 156.105 306.104 156.1 306.152 156.088C306.187 156.15 306.282 156.412 306.348 156.591L306.419 156.78C306.661 157.333 307.137 157.65 307.726 157.65L307.812 157.648C309.757 157.553 311.325 156.627 312.893 155.605C314.12 154.806 314.909 153.81 315.664 152.839C316.429 151.856 318.047 150.097 318.534 149.705C318.534 149.705 314.796 143.668 314.795 143.668Z" fill="#FFC5BD"/>
                    <g clip-path="url(#clip0_7523_316758)">
                    <path d="M309.619 97.2852C310.192 97.2852 310.728 97.5368 310.978 97.8077L310.575 98.405C310.364 98.2225 310.025 98.1036 309.711 98.1036C309.394 98.1036 309.194 98.181 309.194 98.2999C309.194 98.6787 311.103 98.3358 311.103 99.5303C311.103 100.191 310.525 100.62 309.631 100.62C309.003 100.62 308.414 100.352 308.133 100.05L308.536 99.4529C308.789 99.663 309.181 99.7985 309.539 99.7985C309.908 99.7985 310.147 99.7073 310.147 99.5718C310.147 99.193 308.239 99.5497 308.239 98.2612C308.239 97.6695 308.783 97.2852 309.619 97.2852Z" fill="white"/>
                    <path d="M314.067 97.3594L314.647 99.2644L315.228 97.3594H316.175L315.2 100.547H314.253L314.258 100.539L313.678 98.6396L313.097 100.539L313.103 100.547H312.156L311.181 97.3594H312.128L312.708 99.2644L313.289 97.3594H314.067Z" fill="white"/>
                    <path d="M317.347 97.3594V100.547H316.442V97.3594H317.347Z" fill="white"/>
                    <path d="M320.544 97.809L320.142 98.4062C319.931 98.2237 319.592 98.1048 319.278 98.1048C318.961 98.1048 318.761 98.1822 318.761 98.3011C318.761 98.6799 320.669 98.3371 320.669 99.5315C320.669 100.192 320.092 100.621 319.197 100.621C318.569 100.621 317.981 100.353 317.7 100.051L318.103 99.4541C318.356 99.6643 318.747 99.7998 319.106 99.7998C319.475 99.7998 319.714 99.7085 319.714 99.573C319.714 99.1942 317.806 99.5509 317.806 98.2624C317.806 97.6707 318.35 97.2892 319.186 97.2892C319.758 97.2836 320.294 97.5352 320.544 97.809Z" fill="white"/>
                    <path d="M323.731 97.809L323.328 98.4062C323.117 98.2237 322.778 98.1048 322.464 98.1048C322.147 98.1048 321.947 98.1822 321.947 98.3011C321.947 98.6799 323.856 98.3371 323.856 99.5315C323.856 100.192 323.278 100.621 322.383 100.621C321.756 100.621 321.167 100.353 320.886 100.051L321.289 99.4541C321.542 99.6643 321.933 99.7998 322.292 99.7998C322.661 99.7998 322.9 99.7085 322.9 99.573C322.9 99.1942 320.992 99.5509 320.992 98.2624C320.992 97.6707 321.536 97.2892 322.372 97.2892C322.942 97.2836 323.478 97.5352 323.731 97.809Z" fill="white"/>
                    <path d="M305.95 100.894C307.027 100.894 307.9 100.025 307.9 98.9527C307.9 97.8807 307.027 97.0117 305.95 97.0117C304.873 97.0117 304 97.8807 304 98.9527C304 100.025 304.873 100.894 305.95 100.894Z" fill="#233A71"/>
                    <path d="M305.525 98.5285V97.6797H306.378V98.5285H307.231V99.3774H306.378V100.226H305.525V99.3774H304.672V98.5285H305.525Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_7523_316758">
                    <rect width="20" height="4" fill="white" transform="translate(304 97)"/>
                    </clipPath>
                    </defs>
                </svg>

            ) : (
                <svg width="401" height="279" viewBox="0 0 401 279" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M360.203 268.607H37.646C35.1937 266.27 32.9002 263.756 30.797 261.043C11.3861 235.999 10.543 197.593 28.0249 170.997C34.4205 161.267 42.5341 153.405 49.8233 144.027C66.8229 122.155 61.9912 89.8743 72.8217 64.65C83.086 40.7449 101.435 21.1131 123.54 11.7051C156.298 -2.23665 198.331 8.9091 225.811 31.7885C248.552 50.7216 265.926 69.6183 295.334 71.8769C332.382 74.7224 364.58 86.851 386.164 124.634C397.895 145.167 402.287 170.178 399.847 194.285C396.394 228.394 381.175 252.226 360.203 268.607Z" fill="#3E5983"/>
                    <path d="M21.3307 168.308C25.2684 162.332 29.7016 157.21 34.3959 151.787C37.5516 148.159 40.3884 144.866 43.1503 141.31C52.9933 128.64 55.5225 112.093 57.9702 96.076C59.7342 84.5297 61.558 72.59 66.0914 62.0286C76.606 37.5468 95.1652 18.1763 117.012 8.88391C132.623 2.23689 151.06 0.924762 170.325 5.09211C188.252 8.97887 205.759 17.477 219.622 29.0202C222.964 31.7953 226.304 34.6779 229.25 37.2209C247.855 53.2531 263.923 67.0988 288.96 69.028C309.518 70.607 326.31 74.961 340.295 82.3369C356.794 91.0448 369.804 104.008 380.065 121.966C391.471 141.921 396.352 166.755 393.807 191.894C390.628 223.256 377.238 248.358 353.976 266.525L353.915 266.571H350.228V266.031H353.767C376.859 247.982 390.17 223.018 393.331 191.833C395.864 166.817 391.009 142.109 379.662 122.259C369.451 104.387 356.507 91.4888 340.092 82.8262C326.161 75.4788 309.426 71.1405 288.928 69.5657C263.746 67.6271 247.625 53.735 228.959 37.649C226.014 35.1082 222.675 32.2266 219.337 29.4546C205.531 17.9579 188.093 9.49439 170.235 5.62148C151.057 1.47424 132.712 2.77681 117.18 9.39004C95.4475 18.6328 76.9837 37.9052 66.5215 62.2647C62.018 72.7563 60.2007 84.6571 58.4395 96.1824C55.9824 112.259 53.4423 128.882 43.5112 141.666C40.7409 145.232 37.8985 148.53 34.7381 152.164C30.0561 157.574 25.633 162.683 21.7149 168.629C13.2365 181.538 8.82464 197.707 9.29235 214.157C9.75907 230.73 15.153 246.484 24.4797 258.519C26.5243 261.147 28.8148 263.675 31.2886 266.031H35.0458V266.571H31.1949L31.0827 266.557L31.0124 266.475C28.5077 264.092 26.1891 261.536 24.1193 258.874C14.7196 246.746 9.28361 230.871 8.8131 214.174C8.34308 197.603 12.7876 181.315 21.3307 168.308Z" fill="#033052"/>
                    <path d="M325.443 236.25C325.913 240.081 326.183 243.913 326.277 247.733C326.343 250.174 326.332 252.608 326.249 255.038C326.183 257.103 326.067 259.167 325.89 261.221C325.863 261.646 325.83 262.071 325.791 262.491C325.667 263.822 325.519 265.149 325.358 266.475H333.002C334.91 263.194 336.163 259.627 337.319 255.877C340.367 246.022 341.57 235.245 339.826 224.838C338.804 218.732 336.38 212.278 330.782 208.209C327.177 205.586 323.119 207.309 322.076 210.759C320.894 214.668 322.103 218.952 323.047 222.933C324.102 227.361 324.902 231.778 325.443 236.25Z" fill="#033052"/>
                    <path d="M334.537 229.184C335.845 238.62 335.503 248.414 332.969 257.617C332.128 260.667 331.022 263.633 329.66 266.476H330.274C337.247 251.781 337.395 233.716 332.781 218.413C331.699 214.835 330.324 211.263 328.281 208.116C328.094 207.818 327.613 208.094 327.807 208.398C331.754 214.493 333.554 222.079 334.537 229.184Z" fill="#05395E"/>
                    <path d="M361.272 266.473C364.261 263.746 367.378 261.139 370.655 258.696C374.337 255.952 378.163 253.429 382.166 251.094C385.771 248.996 389.785 246.87 391.839 243.249C393.65 240.046 392.065 235.845 387.598 235.028C380.653 233.764 374.188 236.507 368.959 240.052C360.054 246.086 352.943 254.556 347.814 263.737C347.303 264.65 346.814 265.561 346.338 266.473H361.272Z" fill="#033052"/>
                    <path d="M350.604 266.475C357.449 255.722 367.75 246.908 378.826 241.274C382.216 239.551 385.804 238.055 389.559 237.348C389.906 237.282 389.763 236.752 389.415 236.818C381.978 238.215 374.95 242.35 368.905 246.739C361.52 252.101 354.945 258.755 350.025 266.474L350.604 266.475Z" fill="#05395E"/>
                    <path d="M307.917 231.522C316.742 241.185 321.851 253.666 324.284 266.473H324.843C322.424 253.693 317.379 241.249 308.662 231.528C308.436 231.268 308.204 231.014 307.967 230.76C307.724 230.495 307.332 230.887 307.574 231.147C307.69 231.268 307.806 231.395 307.917 231.522Z" fill="#05395E"/>
                    <path d="M315.522 245.963C313.661 246.79 311.434 247.037 309.309 246.653C307.184 246.269 305.174 245.256 303.701 243.827C305.482 242.786 307.348 242.154 309.548 242.496C311.748 242.838 314.095 244.423 315.522 245.963Z" fill="#093049"/>
                    <path d="M322.491 263.665C320.605 264.287 318.479 264.326 316.558 263.775C314.637 263.223 312.935 262.083 311.809 260.596C313.658 259.763 315.514 259.327 317.512 259.845C319.51 260.363 321.427 262.076 322.491 263.665Z" fill="#093049"/>
                    <path d="M316.664 227.023C313.979 229.23 312.974 232.853 314.16 236.055C315.932 235.726 317.406 234.13 317.62 232.565C317.835 231 317.562 228.457 316.664 227.023Z" fill="#093049"/>
                    <path d="M310.715 236.826C307.49 239.023 303.019 238.38 300.338 235.333C303.653 233.416 307.838 234.096 310.715 236.826Z" fill="#093049"/>
                    <path d="M328.882 245.836C325.016 247.859 322.774 251.904 323.293 255.922C325.524 255.911 327.756 254.35 328.46 252.555C329.163 250.76 329.558 247.713 328.882 245.836Z" fill="#093049"/>
                    <path d="M318.919 256.093C314.404 257.991 309.193 256.283 306.826 252.13C311.37 250.581 316.225 252.272 318.919 256.093Z" fill="#093049"/>
                    <path d="M325.82 262.938C325.436 259.195 328.469 256.376 332.859 256.399C332.923 260.027 330.008 262.615 325.82 262.938Z" fill="#093049"/>
                    <path d="M300.256 223.895C302.378 224.307 304.45 225.157 306.034 226.497C307.618 227.836 308.673 229.692 308.658 231.547C306.394 231.544 303.847 230.919 302.248 229.446C300.649 227.972 300.107 225.84 300.256 223.895Z" fill="#093049"/>
                    <path d="M324.686 235.438C324.943 237.294 324.696 239.204 323.74 240.815C322.784 242.425 321.074 243.707 319.005 244.098C318.244 242.213 318.08 239.957 319.18 238.305C320.281 236.653 322.471 235.737 324.686 235.438Z" fill="#093049"/>
                    <path d="M337.441 266.475H344.5C344.531 266.414 344.566 266.359 344.595 266.297C354.141 246.185 371.664 230.578 380.469 210.132C382.338 205.791 382.677 197.836 375.636 198.657C368.602 199.476 362.774 204.498 358.301 209.989C347.43 223.331 342.796 239.074 338.932 255.606C338.152 258.944 336.855 262.633 337.369 266.119C337.388 266.248 337.418 266.354 337.441 266.475Z" fill="#093049"/>
                    <path d="M339.495 266.476C340.449 256.739 343.831 247.422 347.983 238.599C352.461 229.083 357.477 219.802 364.779 212.134C368.701 208.015 373.108 204.393 377.888 201.315C378.186 201.123 377.91 200.645 377.61 200.838C369.265 206.21 362.052 213.266 356.575 221.55C353.395 226.361 350.768 231.546 348.263 236.733C345.926 241.573 343.792 246.525 342.124 251.638C340.548 256.465 339.429 261.429 338.941 266.476H339.495Z" fill="#05395E"/>
                    <path d="M59.976 257.517C60.2188 257.815 60.4783 258.114 60.7488 258.417C61.1463 258.886 61.5659 259.372 61.98 259.908C62.6922 260.835 63.2608 261.868 63.6695 262.961C64.0986 264.086 64.3393 265.275 64.4206 266.475H76.5925C76.5941 265.843 76.5496 265.209 76.4505 264.579C76.2075 263.011 75.7879 261.443 75.8046 259.902C75.81 259.389 75.8654 258.881 75.9924 258.373C76.158 257.722 76.423 257.12 76.7598 256.551C77.5383 255.248 78.6755 254.122 79.7245 252.951C80.0392 252.604 80.3429 252.25 80.6299 251.886C81.8556 250.334 82.7058 248.507 83.164 246.58C83.683 244.421 83.7161 242.136 83.2413 239.977C83.0756 239.176 82.8328 238.398 82.5181 237.647C80.6243 233.076 75.0261 230.398 74.7556 224.971C74.5624 221.068 77.6265 217.203 76.3069 213.52C75.3077 210.716 72.7901 208.916 70.4159 207.315C69.1904 206.487 68.2187 205.973 67.3794 204.676C66.9323 203.986 65.9052 201.838 65.9495 200.695C65.1213 202.285 64.3209 203.908 63.9178 205.653C63.5424 207.287 63.6141 209.01 64.1552 210.595C64.7073 212.201 65.4856 213.631 65.5077 215.381C65.574 219.908 61.4553 223.248 59.5064 227.329C58.3249 229.791 58.1206 232.463 58.5625 235.135C59.0096 237.807 60.3678 240.11 60.9033 242.738C61.2512 244.471 60.7432 246.001 60.0696 247.502C59.5782 248.612 58.9931 249.705 58.6065 250.859C58.5071 251.163 58.4187 251.477 58.3524 251.792C58.3414 251.825 58.3359 251.853 58.3303 251.886C57.8003 254.464 58.6838 255.96 59.97 257.512C59.9704 257.512 59.976 257.512 59.976 257.517Z" fill="#033052"/>
                    <path d="M54.6606 266.473C54.185 265.561 53.695 264.65 53.1843 263.737C48.0553 254.556 40.9442 246.086 32.0389 240.052C26.8105 236.508 20.3455 233.764 13.4001 235.028C8.93366 235.845 7.34918 240.046 9.16545 243.249C11.2137 246.87 15.2276 248.996 18.8327 251.094C22.8354 253.429 26.6615 255.952 30.344 258.696C33.6207 261.139 36.7373 263.746 39.726 266.473H54.6606Z" fill="#033052"/>
                    <path d="M50.3209 266.475H51.0485C44.1294 255.471 33.6214 246.448 22.3203 240.733C18.9304 239.016 15.3363 237.525 11.5821 236.818C11.2397 236.752 11.0908 237.282 11.4385 237.348C18.671 238.712 25.4894 242.687 31.3969 246.916C38.7897 252.209 45.3773 258.815 50.3209 266.475Z" fill="#05395E"/>
                    <path d="M79.1266 266.471C82.4548 255.243 88.0617 244.593 96.6516 236.572C96.7675 236.456 96.889 236.351 97.0049 236.24C97.2644 236.003 96.8724 235.611 96.6129 235.854C96.3865 236.064 96.1602 236.274 95.9338 236.494C87.4271 244.571 81.8608 255.241 78.5566 266.471H79.1266Z" fill="#05395E"/>
                    <path d="M88.3867 249.587C90.0744 250.504 92.15 250.904 94.1775 250.703C96.2049 250.501 98.1714 249.699 99.6637 248.466C98.067 247.353 96.3594 246.618 94.2647 246.775C92.1701 246.931 89.8436 248.246 88.3867 249.587Z" fill="#093049"/>
                    <path d="M80.5039 265.71C82.2308 266.436 84.2276 266.633 86.0754 266.258C87.9231 265.883 89.6095 264.94 90.7807 263.625C89.1044 262.703 87.3908 262.153 85.473 262.491C83.555 262.828 81.6233 264.295 80.5039 265.71Z" fill="#093049"/>
                    <path d="M88.7357 231.691C91.0952 233.968 91.7677 237.451 90.4125 240.373C88.7707 239.931 87.5041 238.32 87.4202 236.831C87.3361 235.343 87.7832 232.972 88.7357 231.691Z" fill="#093049"/>
                    <path d="M93.5957 241.354C96.4633 243.662 100.717 243.393 103.467 240.729C100.494 238.677 96.506 239.003 93.5957 241.354Z" fill="#093049"/>
                    <path d="M75.8315 248.465C79.316 250.658 81.1209 254.631 80.3312 258.37C78.2333 258.193 76.2515 256.556 75.7245 254.816C75.1974 253.075 75.055 250.179 75.8315 248.465Z" fill="#093049"/>
                    <path d="M84.4336 258.856C88.5371 260.98 93.5662 259.765 96.1043 256.037C91.9473 254.239 87.2537 255.465 84.4336 258.856Z" fill="#093049"/>
                    <path d="M77.4296 264.785C78.0719 261.292 75.4309 258.414 71.3007 258.105C70.9675 261.513 73.5146 264.166 77.4296 264.785Z" fill="#093049"/>
                    <path d="M104.404 229.977C102.377 230.205 100.364 230.849 98.7739 231.99C97.1835 233.131 96.0527 234.797 95.9277 236.543C98.0566 236.71 100.499 236.314 102.113 235.048C103.728 233.782 104.398 231.817 104.404 229.977Z" fill="#093049"/>
                    <path d="M80.5626 239C80.1818 240.726 80.2701 242.542 81.0486 244.128C81.8272 245.714 83.3389 247.048 85.2557 247.572C86.1125 245.856 86.4363 243.746 85.5254 242.11C84.6147 240.474 82.6234 239.448 80.5626 239Z" fill="#093049"/>
                    <path d="M56.5029 266.475H63.5621C63.5847 266.354 63.6148 266.248 63.6338 266.119C64.1482 262.633 62.8511 258.945 62.0708 255.606C58.2073 239.074 53.5728 223.331 42.7024 209.989C38.2294 204.498 32.4009 199.477 25.367 198.657C18.3256 197.836 18.6644 205.791 20.5337 210.132C29.3386 230.578 46.862 246.185 56.4075 266.297C56.4373 266.359 56.4724 266.415 56.5029 266.475Z" fill="#093049"/>
                    <path d="M44.2008 222.215C47.174 226.779 49.6604 231.666 52.0399 236.56C54.4065 241.426 56.5721 246.403 58.2655 251.547C59.8643 256.403 61.0091 261.395 61.504 266.476H62.0572C61.1156 256.808 57.8036 247.553 53.7061 238.776C49.1344 228.983 43.9779 219.434 36.45 211.576C32.5375 207.492 28.1433 203.899 23.389 200.838C23.0893 200.645 22.8122 201.123 23.1104 201.315C31.5038 206.718 38.7481 213.846 44.2008 222.215Z" fill="#05395E"/>
                    <path d="M70.2355 220.705C70.0754 223.642 68.7724 225.939 67.4474 228.478C66.5695 230.157 65.8408 231.962 66.2052 233.883C66.6139 236.037 67.9553 237.869 68.9823 239.752C70.3183 242.187 70.9036 244.865 70.6607 247.631C70.2245 252.693 67.6187 257.254 67.1106 262.311C66.9721 263.696 66.9538 265.087 66.9881 266.477H67.5399C67.512 265.399 67.513 264.322 67.5854 263.244C67.9278 258.142 70.4121 253.615 71.1078 248.591C71.4281 246.25 71.2735 243.882 70.4619 241.651C69.7109 239.598 68.386 237.842 67.4639 235.871C66.1112 232.973 66.8897 230.626 68.3583 227.954C69.4681 225.933 70.4231 223.996 70.6991 221.671C70.9972 219.203 70.815 216.365 69.8102 214.069C69.3243 212.953 68.673 211.932 68.1042 210.861C67.2816 209.31 66.7075 207.675 66.3209 205.969C66.2436 205.622 65.7136 205.765 65.7909 206.113C66.343 208.564 67.3754 210.657 68.5734 212.837C69.9152 215.283 70.3846 217.922 70.2355 220.705Z" fill="#05395E"/>
                    <path d="M200.5 278.586C310.957 278.586 400.5 273.776 400.5 267.842C400.5 261.908 310.957 257.098 200.5 257.098C90.0431 257.098 0.5 261.908 0.5 267.842C0.5 273.776 90.0431 278.586 200.5 278.586Z" fill="#033052"/>
                    <path d="M395.951 141.585C395.094 138.483 393.877 135.536 392.323 132.796C388.261 134.272 383.541 133.413 380.284 130.156C377.026 126.899 376.167 122.178 377.643 118.117C374.904 116.562 371.957 115.346 368.855 114.488C367.024 118.398 363.082 121.124 358.48 121.124C353.877 121.124 349.935 118.398 348.105 114.488C345.002 115.346 342.055 116.562 339.316 118.117C340.792 122.178 339.933 126.899 336.676 130.156C333.418 133.413 328.698 134.272 324.637 132.796C323.082 135.536 321.866 138.483 321.008 141.585C324.918 143.415 327.644 147.357 327.644 151.96C327.644 156.563 324.918 160.504 321.008 162.335C321.866 165.437 323.082 168.384 324.637 171.123C328.698 169.648 333.418 170.507 336.676 173.764C339.933 177.021 340.792 181.741 339.316 185.803C342.055 187.358 345.003 188.574 348.105 189.431C349.935 185.522 353.877 182.795 358.48 182.795C363.082 182.795 367.024 185.522 368.855 189.431C371.957 188.574 374.904 187.358 377.643 185.803C376.168 181.741 377.026 177.021 380.284 173.764C383.541 170.507 388.261 169.648 392.323 171.123C393.878 168.384 395.094 165.437 395.951 162.335C392.041 160.504 389.315 156.563 389.315 151.96C389.315 147.357 392.041 143.415 395.951 141.585ZM358.479 165.566C350.965 165.566 344.874 159.474 344.874 151.96C344.874 144.445 350.965 138.354 358.479 138.354C365.994 138.354 372.085 144.445 372.085 151.96C372.085 159.474 365.994 165.566 358.479 165.566Z" fill="#033052"/>
                    <path d="M184.734 58.2687C183.877 55.1664 182.66 52.2197 181.106 49.4798C177.044 50.9557 172.324 50.0966 169.067 46.8394C165.809 43.5823 164.95 38.862 166.426 34.8001C163.687 33.2455 160.74 32.0292 157.638 31.1719C155.807 35.0817 151.866 37.8079 147.263 37.8079C142.66 37.8079 138.718 35.0817 136.888 31.1719C133.785 32.0292 130.839 33.2455 128.099 34.8001C129.575 38.862 128.716 43.5821 125.459 46.8394C122.201 50.0966 117.482 50.9557 113.42 49.4798C111.865 52.2197 110.649 55.1664 109.791 58.2687C113.702 60.099 116.427 64.0405 116.427 68.6434C116.427 73.2463 113.702 77.1878 109.791 79.0185C110.649 82.1205 111.865 85.0672 113.42 87.807C117.482 86.3312 122.201 87.1903 125.459 90.4474C128.716 93.7049 129.575 98.4249 128.099 102.487C130.839 104.041 133.786 105.258 136.888 106.115C138.719 102.205 142.66 99.479 147.263 99.479C151.866 99.479 155.807 102.205 157.638 106.115C160.74 105.258 163.687 104.041 166.426 102.487C164.951 98.4249 165.809 93.7049 169.067 90.4474C172.324 87.1903 177.044 86.3312 181.106 87.807C182.661 85.0672 183.877 82.1205 184.734 79.0185C180.824 77.1878 178.098 73.2463 178.098 68.6434C178.098 64.0405 180.824 60.0989 184.734 58.2687ZM147.263 82.2494C139.748 82.2494 133.657 76.1576 133.657 68.6433C133.657 61.1289 139.748 55.0373 147.263 55.0373C154.777 55.0373 160.869 61.1291 160.869 68.6433C160.868 76.1576 154.777 82.2494 147.263 82.2494Z" fill="#033052"/>
                    <path d="M95.9342 152.157C95.0698 149.031 93.8444 146.061 92.2774 143.3C88.1844 144.788 83.4285 143.922 80.1458 140.64C76.8633 137.357 75.9979 132.601 77.4848 128.508C74.7245 126.941 71.7552 125.715 68.6289 124.852C66.7843 128.791 62.8123 131.538 58.1741 131.538C53.5359 131.538 49.5639 128.791 47.7193 124.852C44.5931 125.715 41.6237 126.941 38.8635 128.508C40.3504 132.601 39.485 137.357 36.2025 140.64C32.9199 143.922 28.164 144.788 24.0708 143.3C22.504 146.061 21.2786 149.031 20.4141 152.157C24.3546 154.001 27.1013 157.973 27.1013 162.611C27.1013 167.25 24.3546 171.221 20.4141 173.066C21.2784 176.192 22.5038 179.161 24.0708 181.922C28.1638 180.435 32.9198 181.301 36.2025 184.583C39.485 187.866 40.35 192.622 38.8635 196.715C41.6237 198.282 44.5934 199.507 47.7193 200.371C49.5643 196.431 53.5359 193.684 58.1741 193.684C62.8123 193.684 66.784 196.431 68.6289 200.371C71.7549 199.507 74.7245 198.282 77.4848 196.715C75.9982 192.622 76.8633 187.866 80.1458 184.583C83.4283 181.301 88.1842 180.435 92.2774 181.922C93.8443 179.161 95.0697 176.192 95.9342 173.066C91.9937 171.221 89.2469 167.25 89.2469 162.611C89.2469 157.973 91.9937 154.001 95.9342 152.157ZM58.1741 176.322C50.6021 176.322 44.4638 170.184 44.4638 162.611C44.4638 155.039 50.6021 148.901 58.1741 148.901C65.7462 148.901 71.8845 155.039 71.8845 162.611C71.8845 170.184 65.7462 176.322 58.1741 176.322Z" fill="#033052"/>
                    <path d="M204.815 166.336L198.312 259.435L198.239 260.499H161.297C157.923 260.499 155.344 257.504 155.833 254.167L169.011 166.336H204.815Z" fill="#76BDE9"/>
                    <path d="M205.485 166.336L198.981 259.435H163.214C159.84 259.435 157.261 256.428 157.762 253.091L170.768 166.336H205.485Z" fill="#92D0F6"/>
                    <path d="M103.04 256.57C100.199 256.57 97.8965 258.873 97.8965 261.714V264.433H141.947V256.57H103.04Z" fill="#92D0F6"/>
                    <path d="M282.68 256.57H140.428V264.433H288.814V262.704C288.814 259.317 286.068 256.57 282.68 256.57Z" fill="#77C4F0"/>
                    <path d="M289.211 230.085H86.9376C83.6643 230.085 80.97 227.707 80.586 224.472L78.9216 210.525L64.6489 90.8968C64.167 86.8576 67.4296 83.2031 71.5213 83.2031H273.791C277.064 83.2031 279.759 85.5812 280.143 88.8161L294.666 210.525L296.083 222.391C296.565 226.43 293.299 230.085 289.211 230.085Z" fill="#76BDE9"/>
                    <path d="M291.67 227.198H89.3966C86.1233 227.198 83.4289 224.82 83.045 221.585L81.3805 207.638L67.1079 88.0103C66.626 83.971 69.8886 80.3164 73.9803 80.3164H276.25C279.523 80.3164 282.218 82.6945 282.601 85.9294L297.125 207.638L298.542 219.504C299.024 223.544 295.758 227.198 291.67 227.198Z" fill="#92D0F6"/>
                    <path d="M297.125 207.638H81.3805L67.1079 88.0103C66.626 83.971 69.8886 80.3164 73.9803 80.3164H276.25C279.523 80.3164 282.218 82.6945 282.601 85.9294L297.125 207.638Z" fill="#77C4F0"/>
                    <path d="M289.006 200.647L275.877 87.3125H74.375L87.5042 200.647H289.006Z" fill="#0D0D0D"/>
                    <path d="M192.94 220.287C194.614 218.546 194.447 215.668 192.567 213.859C190.686 212.05 187.804 211.994 186.13 213.735C184.455 215.476 184.622 218.353 186.503 220.163C188.384 221.972 191.266 222.027 192.94 220.287Z" fill="#0D0D0D"/>
                    <path d="M86.3453 193.348L76.002 104.023H278.698L289.041 193.348H86.3453Z" fill="#17202E"/>
                    <path d="M234.449 94.6094H89.6308C88.2119 94.6094 87.1949 95.7596 87.3592 97.1786C87.5236 98.5975 88.8069 99.7478 90.2258 99.7478H235.044C236.463 99.7478 237.48 98.5975 237.315 97.1786C237.151 95.7596 235.868 94.6094 234.449 94.6094Z" fill="#05395E"/>
                    <path d="M245.691 94.6094C244.272 94.6094 243.255 95.7596 243.42 97.1786C243.584 98.5975 244.867 99.7478 246.286 99.7478C247.705 99.7478 248.722 98.5975 248.558 97.1786C248.394 95.7596 247.11 94.6094 245.691 94.6094Z" fill="#05395E"/>
                    <path d="M254.291 94.6094C252.872 94.6094 251.855 95.7596 252.019 97.1786C252.184 98.5975 253.467 99.7478 254.886 99.7478C256.305 99.7478 257.322 98.5975 257.158 97.1786C256.993 95.7596 255.71 94.6094 254.291 94.6094Z" fill="#05395E"/>
                    <path d="M262.891 94.6094C261.472 94.6094 260.455 95.7596 260.619 97.1786C260.783 98.5975 262.067 99.7478 263.486 99.7478C264.905 99.7478 265.922 98.5975 265.757 97.1786C265.593 95.7596 264.31 94.6094 262.891 94.6094Z" fill="#05395E"/>
                    <path d="M160.286 150.628L159.408 143.046H146.763L146.031 136.723L156.654 112.82H161.64L164.405 136.697H168.234L168.969 143.046H165.14L166.018 150.628H160.286ZM158.673 136.697L157.184 123.835L151.58 136.697H158.673Z" fill="#C9E5F1"/>
                    <path d="M178.32 112.82C181.319 112.82 183.909 114.246 186.089 117.097C188.27 119.948 189.773 124.935 190.598 132.058C191.42 139.165 191.072 144.143 189.551 146.994C188.031 149.845 185.78 151.27 182.799 151.27C179.8 151.27 177.212 149.858 175.035 147.033C172.857 144.207 171.351 139.19 170.516 131.981C169.697 124.91 170.048 119.948 171.568 117.097C173.088 114.246 175.339 112.82 178.32 112.82ZM179.027 118.809C177.858 118.809 177.014 119.58 176.496 121.12C175.979 122.661 176.053 126.307 176.719 132.058C177.385 137.81 178.154 141.452 179.028 142.984C179.902 144.516 180.923 145.282 182.093 145.282C183.279 145.282 184.127 144.512 184.635 142.971C185.145 141.43 185.068 137.793 184.404 132.058C183.737 126.307 182.971 122.661 182.105 121.12C181.24 119.58 180.213 118.809 179.027 118.809Z" fill="#C9E5F1"/>
                    <path d="M208.296 150.628L207.418 143.046H194.773L194.041 136.723L204.664 112.82H209.65L212.415 136.697H216.244L216.979 143.046H213.15L214.027 150.628H208.296ZM206.683 136.697L205.194 123.835L199.59 136.697H206.683Z" fill="#C9E5F1"/>
                    <path d="M204.92 184.56H168.439C166.879 184.56 165.469 183.296 165.289 181.737C165.108 180.178 166.226 178.914 167.785 178.914H204.266C205.825 178.914 207.236 180.178 207.416 181.737C207.597 183.296 206.479 184.56 204.92 184.56Z" fill="#C9E5F1"/>
                    <path d="M171.341 182.048L171.294 181.649H172.326L172.436 182.594C172.347 182.691 172.212 182.777 172.029 182.85C171.848 182.925 171.659 182.961 171.464 182.961C171.216 182.961 170.993 182.909 170.797 182.805C170.6 182.701 170.444 182.552 170.329 182.359C170.214 182.165 170.143 181.955 170.117 181.727C170.088 181.48 170.114 181.26 170.196 181.068C170.277 180.876 170.411 180.729 170.599 180.626C170.742 180.547 170.927 180.508 171.154 180.508C171.448 180.508 171.685 180.569 171.865 180.693C172.045 180.817 172.172 180.987 172.244 181.205L171.779 181.294C171.732 181.177 171.658 181.086 171.558 181.018C171.458 180.951 171.339 180.917 171.201 180.917C170.992 180.917 170.833 180.984 170.725 181.116C170.617 181.249 170.578 181.446 170.608 181.707C170.641 181.988 170.727 182.2 170.869 182.341C171.011 182.482 171.183 182.552 171.386 182.552C171.486 182.552 171.584 182.532 171.681 182.493C171.776 182.453 171.858 182.406 171.924 182.349L171.889 182.048H171.341V182.048Z" fill="#05395E"/>
                    <path d="M172.846 181.749C172.818 181.508 172.831 181.304 172.885 181.141C172.925 181.02 172.985 180.911 173.068 180.815C173.15 180.719 173.244 180.648 173.35 180.602C173.49 180.539 173.657 180.508 173.851 180.508C174.2 180.508 174.492 180.616 174.727 180.833C174.962 181.05 175.102 181.351 175.146 181.738C175.191 182.121 175.122 182.421 174.938 182.637C174.755 182.853 174.489 182.961 174.141 182.961C173.788 182.961 173.495 182.854 173.262 182.638C173.029 182.423 172.89 182.127 172.846 181.749ZM173.338 181.733C173.369 182.001 173.455 182.205 173.595 182.344C173.735 182.482 173.901 182.552 174.092 182.552C174.283 182.552 174.431 182.483 174.538 182.345C174.644 182.208 174.682 182.001 174.65 181.726C174.618 181.455 174.535 181.251 174.401 181.118C174.266 180.984 174.1 180.917 173.903 180.917C173.705 180.917 173.554 180.985 173.449 181.12C173.343 181.256 173.307 181.46 173.338 181.733Z" fill="#05395E"/>
                    <path d="M177.447 182.919L177.219 180.948H176.515L176.469 180.547H178.354L178.401 180.948H177.698L177.926 182.919H177.447Z" fill="#05395E"/>
                    <path d="M178.809 181.749C178.781 181.508 178.794 181.304 178.848 181.141C178.888 181.02 178.948 180.911 179.031 180.815C179.113 180.719 179.207 180.648 179.313 180.602C179.453 180.539 179.62 180.508 179.813 180.508C180.163 180.508 180.455 180.616 180.69 180.833C180.925 181.05 181.065 181.351 181.109 181.738C181.154 182.121 181.084 182.421 180.901 182.637C180.718 182.853 180.452 182.961 180.104 182.961C179.751 182.961 179.458 182.854 179.224 182.638C178.992 182.423 178.853 182.127 178.809 181.749ZM179.301 181.733C179.332 182.001 179.418 182.205 179.558 182.344C179.698 182.482 179.864 182.552 180.055 182.552C180.246 182.552 180.394 182.483 180.501 182.345C180.607 182.208 180.645 182.001 180.613 181.726C180.581 181.455 180.498 181.251 180.364 181.118C180.229 180.984 180.063 180.917 179.866 180.917C179.668 180.917 179.517 180.985 179.412 181.12C179.306 181.256 179.27 181.46 179.301 181.733Z" fill="#05395E"/>
                    <path d="M182.874 182.919L182.6 180.547H183.079L183.187 181.481H184.126L184.017 180.547H184.496L184.771 182.919H184.292L184.172 181.882H183.233L183.353 182.919H182.874Z" fill="#05395E"/>
                    <path d="M185.202 181.749C185.174 181.508 185.187 181.304 185.24 181.141C185.28 181.02 185.341 180.911 185.423 180.815C185.505 180.719 185.599 180.648 185.705 180.602C185.846 180.539 186.013 180.508 186.206 180.508C186.555 180.508 186.847 180.616 187.083 180.833C187.317 181.05 187.457 181.351 187.502 181.738C187.546 182.121 187.477 182.421 187.294 182.637C187.11 182.853 186.845 182.961 186.496 182.961C186.144 182.961 185.851 182.854 185.617 182.638C185.384 182.423 185.246 182.127 185.202 181.749ZM185.694 181.733C185.725 182.001 185.811 182.205 185.951 182.344C186.091 182.482 186.257 182.552 186.447 182.552C186.638 182.552 186.787 182.483 186.894 182.345C187 182.208 187.037 182.001 187.005 181.726C186.974 181.455 186.891 181.251 186.756 181.118C186.622 180.984 186.455 180.917 186.258 180.917C186.061 180.917 185.91 180.985 185.804 181.12C185.699 181.256 185.662 181.46 185.694 181.733Z" fill="#05395E"/>
                    <path d="M188.169 182.919L187.895 180.547H188.611L189.229 182.165L189.467 180.547H190.186L190.461 182.919H190.016L189.799 181.052L189.545 182.919H189.083L188.398 181.052L188.614 182.919H188.169Z" fill="#05395E"/>
                    <path d="M191.105 182.919L190.83 180.547H192.589L192.635 180.948H191.356L191.417 181.475H192.607L192.653 181.874H191.463L191.538 182.52H192.863L192.909 182.919H191.105Z" fill="#05395E"/>
                    <path d="M193.48 182.919L193.205 180.547H193.973C194.265 180.547 194.456 180.559 194.547 180.583C194.687 180.618 194.81 180.695 194.916 180.815C195.021 180.934 195.085 181.088 195.106 181.277C195.123 181.422 195.111 181.545 195.07 181.644C195.028 181.743 194.97 181.821 194.895 181.878C194.82 181.935 194.742 181.972 194.66 181.991C194.548 182.013 194.384 182.025 194.167 182.025H193.855L193.958 182.919H193.48ZM193.731 180.948L193.809 181.621H194.07C194.26 181.621 194.384 181.609 194.445 181.584C194.506 181.56 194.551 181.521 194.581 181.467C194.611 181.415 194.622 181.354 194.614 181.283C194.604 181.197 194.57 181.126 194.513 181.069C194.456 181.014 194.388 180.978 194.308 180.964C194.25 180.954 194.135 180.949 193.962 180.949L193.731 180.948Z" fill="#05395E"/>
                    <path d="M197.75 182.919H197.228L196.959 182.38H196.011L195.878 182.919H195.369L196.019 180.547H196.525L197.75 182.919ZM196.759 181.981L196.33 181.101L196.112 181.981H196.759Z" fill="#05395E"/>
                    <path d="M199.171 182.048L199.125 181.649H200.157L200.266 182.594C200.177 182.691 200.042 182.777 199.859 182.85C199.678 182.925 199.489 182.961 199.294 182.961C199.046 182.961 198.823 182.909 198.627 182.805C198.43 182.701 198.274 182.552 198.159 182.359C198.044 182.165 197.973 181.955 197.947 181.727C197.918 181.48 197.944 181.26 198.026 181.068C198.107 180.876 198.242 180.729 198.429 180.626C198.572 180.547 198.757 180.508 198.984 180.508C199.278 180.508 199.515 180.569 199.695 180.693C199.875 180.817 200.002 180.987 200.074 181.205L199.609 181.294C199.562 181.177 199.488 181.086 199.388 181.018C199.288 180.951 199.169 180.917 199.031 180.917C198.822 180.917 198.663 180.984 198.555 181.116C198.447 181.249 198.408 181.446 198.438 181.707C198.471 181.988 198.557 182.2 198.699 182.341C198.841 182.482 199.013 182.552 199.216 182.552C199.316 182.552 199.414 182.532 199.511 182.493C199.607 182.453 199.688 182.406 199.754 182.349L199.719 182.048H199.171V182.048Z" fill="#05395E"/>
                    <path d="M200.913 182.919L200.639 180.547H202.398L202.444 180.948H201.164L201.225 181.475H202.416L202.462 181.874H201.271L201.346 182.52H202.671L202.718 182.919H200.913Z" fill="#05395E"/>
                    <path d="M220.098 169.966H149.881L149.803 169.289H220.02L220.098 169.966Z" fill="#C9E5F1"/>
                    <path d="M218.091 174.075H152.832L152.754 173.398H218.013L218.091 174.075Z" fill="#C9E5F1"/>
                    <path d="M152.098 162.442L151.635 158.441H152.932C153.422 158.441 153.745 158.462 153.899 158.502C154.135 158.562 154.342 158.692 154.52 158.893C154.698 159.094 154.805 159.354 154.842 159.672C154.87 159.918 154.85 160.125 154.78 160.292C154.711 160.459 154.613 160.59 154.487 160.686C154.36 160.781 154.227 160.845 154.09 160.876C153.901 160.914 153.624 160.933 153.258 160.933H152.731L152.906 162.442H152.098ZM152.521 159.118L152.652 160.253H153.094C153.413 160.253 153.624 160.232 153.726 160.191C153.829 160.149 153.905 160.084 153.956 159.994C154.006 159.905 154.025 159.802 154.012 159.683C153.995 159.537 153.938 159.418 153.841 159.323C153.745 159.228 153.63 159.169 153.496 159.146C153.397 159.127 153.203 159.118 152.912 159.118H152.521Z" fill="#C9E5F1"/>
                    <path d="M160.133 162.442H159.254L158.8 161.533H157.201L156.976 162.442H156.119L157.214 158.441H158.068L160.133 162.442ZM158.462 160.859L157.74 159.375L157.371 160.859H158.462Z" fill="#C9E5F1"/>
                    <path d="M163.371 160.969L163.293 160.295H165.034L165.218 161.889C165.068 162.052 164.84 162.197 164.533 162.321C164.226 162.446 163.909 162.508 163.579 162.508C163.161 162.508 162.786 162.42 162.455 162.245C162.123 162.069 161.86 161.818 161.666 161.492C161.471 161.165 161.352 160.81 161.308 160.426C161.26 160.009 161.304 159.639 161.441 159.315C161.579 158.991 161.805 158.743 162.122 158.57C162.363 158.437 162.675 158.371 163.057 158.371C163.553 158.371 163.953 158.475 164.257 158.684C164.56 158.892 164.773 159.18 164.896 159.547L164.111 159.697C164.032 159.501 163.907 159.345 163.739 159.232C163.571 159.118 163.37 159.061 163.137 159.061C162.784 159.061 162.516 159.173 162.334 159.397C162.151 159.621 162.086 159.953 162.137 160.393C162.192 160.868 162.338 161.224 162.577 161.461C162.815 161.699 163.106 161.818 163.448 161.818C163.617 161.818 163.783 161.785 163.945 161.718C164.108 161.652 164.244 161.571 164.355 161.477L164.296 160.968H163.371V160.969Z" fill="#C9E5F1"/>
                    <path d="M167.145 162.442L166.682 158.441H169.648L169.727 159.118H167.568L167.671 160.005H169.68L169.758 160.679H167.749L167.875 161.768H170.11L170.188 162.442H167.145Z" fill="#C9E5F1"/>
                    <path d="M174.67 162.442L174.207 158.441H174.993L176.94 161.113L176.63 158.441H177.381L177.844 162.442H177.033L175.119 159.833L175.421 162.442H174.67Z" fill="#C9E5F1"/>
                    <path d="M179.421 160.464C179.374 160.056 179.395 159.715 179.484 159.438C179.552 159.235 179.655 159.052 179.793 158.89C179.932 158.728 180.09 158.608 180.269 158.529C180.506 158.424 180.787 158.371 181.113 158.371C181.703 158.371 182.196 158.554 182.591 158.919C182.988 159.285 183.224 159.794 183.299 160.445C183.374 161.091 183.257 161.596 182.947 161.961C182.639 162.326 182.19 162.508 181.603 162.508C181.008 162.508 180.514 162.327 180.121 161.964C179.728 161.601 179.495 161.101 179.421 160.464ZM180.25 160.437C180.303 160.89 180.447 161.234 180.683 161.467C180.919 161.7 181.198 161.818 181.521 161.818C181.842 161.818 182.093 161.702 182.273 161.469C182.452 161.238 182.516 160.89 182.462 160.426C182.409 159.967 182.268 159.626 182.041 159.4C181.814 159.174 181.534 159.061 181.201 159.061C180.868 159.061 180.614 159.176 180.436 159.404C180.259 159.632 180.197 159.976 180.25 160.437Z" fill="#C9E5F1"/>
                    <path d="M186.182 162.442L185.797 159.118H184.61L184.531 158.441H187.71L187.789 159.118H186.604L186.989 162.442H186.182Z" fill="#C9E5F1"/>
                    <path d="M192.489 162.442L192.025 158.441H194.768L194.846 159.118H192.911L193.021 160.065H194.692L194.77 160.742H193.099L193.296 162.442L192.489 162.442Z" fill="#C9E5F1"/>
                    <path d="M196.62 160.464C196.573 160.056 196.594 159.715 196.684 159.438C196.751 159.235 196.854 159.052 196.993 158.89C197.131 158.728 197.29 158.608 197.468 158.529C197.705 158.424 197.986 158.371 198.312 158.371C198.902 158.371 199.395 158.554 199.791 158.919C200.187 159.285 200.423 159.794 200.498 160.445C200.573 161.091 200.456 161.596 200.147 161.961C199.838 162.326 199.389 162.508 198.802 162.508C198.207 162.508 197.713 162.327 197.32 161.964C196.927 161.601 196.694 161.101 196.62 160.464ZM197.449 160.437C197.502 160.89 197.646 161.234 197.882 161.467C198.118 161.7 198.397 161.818 198.72 161.818C199.041 161.818 199.292 161.702 199.472 161.469C199.651 161.238 199.715 160.89 199.661 160.426C199.608 159.967 199.467 159.626 199.24 159.4C199.014 159.174 198.733 159.061 198.401 159.061C198.068 159.061 197.813 159.176 197.635 159.404C197.458 159.632 197.396 159.976 197.449 160.437Z" fill="#C9E5F1"/>
                    <path d="M202.01 158.441H202.818L203.069 160.608C203.109 160.953 203.144 161.175 203.176 161.277C203.23 161.441 203.327 161.572 203.469 161.671C203.61 161.77 203.794 161.82 204.02 161.82C204.249 161.82 204.416 161.773 204.522 161.679C204.627 161.586 204.684 161.47 204.692 161.334C204.7 161.198 204.685 160.971 204.648 160.654L204.392 158.441H205.2L205.443 160.543C205.499 161.023 205.517 161.363 205.496 161.561C205.475 161.759 205.414 161.926 205.312 162.063C205.211 162.199 205.066 162.308 204.88 162.389C204.692 162.47 204.44 162.51 204.124 162.51C203.742 162.51 203.447 162.466 203.239 162.378C203.032 162.289 202.863 162.175 202.732 162.034C202.601 161.893 202.508 161.745 202.454 161.59C202.374 161.361 202.309 161.023 202.257 160.575L202.01 158.441Z" fill="#C9E5F1"/>
                    <path d="M207.641 162.442L207.178 158.441H207.964L209.91 161.113L209.601 158.441H210.351L210.814 162.442H210.004L208.089 159.833L208.391 162.442H207.641Z" fill="#C9E5F1"/>
                    <path d="M212.32 158.441H213.797C214.13 158.441 214.386 158.467 214.567 158.518C214.81 158.587 215.025 158.71 215.213 158.886C215.401 159.063 215.553 159.279 215.67 159.535C215.787 159.79 215.867 160.106 215.91 160.48C215.948 160.809 215.94 161.093 215.886 161.332C215.82 161.622 215.705 161.858 215.54 162.039C215.416 162.175 215.238 162.281 215.009 162.358C214.838 162.414 214.602 162.442 214.304 162.442H212.784L212.32 158.441ZM213.207 159.118L213.513 161.768H214.117C214.342 161.768 214.504 161.755 214.6 161.73C214.728 161.697 214.83 161.642 214.907 161.564C214.985 161.485 215.041 161.357 215.074 161.177C215.108 160.998 215.107 160.754 215.071 160.444C215.035 160.135 214.981 159.898 214.907 159.732C214.833 159.567 214.742 159.438 214.633 159.345C214.524 159.252 214.392 159.189 214.237 159.156C214.122 159.131 213.899 159.119 213.57 159.119H213.207V159.118Z" fill="#C9E5F1"/>
                    <path d="M83.931 179.387L38.8262 264.625H129.036L83.931 179.387Z" fill="#043357"/>
                    <path d="M131.548 263.294L86.4425 178.056C85.9497 177.122 84.9874 176.543 83.932 176.543C82.8765 176.543 81.9131 177.122 81.4203 178.055L36.316 263.294C35.8533 264.168 35.8821 265.238 36.3927 266.089C36.9033 266.937 37.8367 267.464 38.8267 267.464H129.037C130.027 267.464 130.96 266.937 131.471 266.089C131.982 265.24 132.012 264.169 131.548 263.294ZM43.5445 261.782L83.932 185.459L124.319 261.782H43.5445Z" fill="#87C8F0"/>
                    <path d="M81.7503 243.729L79.7363 223.205V213.715H88.1226V223.205L86.1366 243.729H81.7503ZM80.0672 254.157V246.405H87.8191V254.157H80.0672Z" fill="#87C8F0"/>
                    <path d="M303.137 245.902L302.89 253.171C302.853 254.255 302.901 255.218 301.901 255.638L296.508 258.286C293.692 259.467 294.536 262.568 297.589 262.568H311.681V258.274L311.988 245.902H303.137Z" fill="#FFC5BD"/>
                    <path d="M310.235 257.937C310.455 257.812 310.96 257.523 311.126 257.456C311.69 257.228 312.253 257.778 312.253 258.381V265.546C312.253 266.149 311.763 266.639 311.159 266.639H284.622C283.83 266.639 283.189 265.998 283.189 265.206C283.189 265.112 283.208 264.999 283.227 264.905C283.321 264.471 283.623 264.094 284.056 263.906L299.862 256.246C300.738 255.812 301.411 256.149 301.579 256.928C301.856 258.211 302.97 258.992 305.601 258.959C307.511 258.934 309.177 258.538 310.235 257.937Z" fill="#1E3D7D"/>
                    <path d="M312.253 264.906V265.547C312.253 266.15 311.763 266.64 311.159 266.64H284.622C283.83 266.64 283.189 265.999 283.189 265.208C283.189 265.114 283.208 265 283.227 264.906L312.253 264.906Z" fill="#1E4487"/>
                    <path d="M337.502 244.704L338.561 251.666C338.662 252.74 338.745 253.601 337.932 254.32L333.676 258.561C331.389 260.584 333.185 263.249 336.076 262.268L349.42 257.74L347.778 252.835L345.884 241.859L337.502 244.704Z" fill="#FFC5BD"/>
                    <path d="M323.308 264.896C323.418 264.458 323.71 264.074 324.149 263.891L332.047 259.633L336.196 255.539C336.89 254.845 337.639 254.955 338.041 255.649C338.224 255.96 338.462 256.197 338.754 256.38C339.229 256.691 339.869 256.819 340.71 256.709C341.222 256.654 341.843 256.508 342.519 256.271C344.31 255.631 345.754 254.717 346.576 253.822C346.741 253.62 347.124 253.182 347.253 253.072C347.728 252.67 348.422 253.018 348.623 253.584L349.994 257.605L350.926 260.364C351.127 260.949 350.816 261.57 350.249 261.753L338.288 265.817C336.697 266.357 335.028 266.633 333.348 266.633H324.715C323.911 266.633 323.271 265.993 323.271 265.189C323.272 265.097 323.29 264.988 323.308 264.896Z" fill="#1E3D7D"/>
                    <path d="M323.308 264.901H334.803C335.338 264.901 335.869 264.813 336.376 264.641L350.725 259.766L350.926 260.369C351.127 260.954 350.816 261.575 350.25 261.758L336.65 266.377C336.143 266.549 335.612 266.637 335.077 266.637H324.715C323.911 266.637 323.271 265.997 323.271 265.193C323.272 265.102 323.29 264.993 323.308 264.901Z" fill="#1E4487"/>
                    <path d="M313.837 254.598L319.848 201.846L326.395 146.968L314.261 141.133L301.905 148.614L301.352 201.846L300.805 254.598H313.837Z" fill="#1E3D7D"/>
                    <path d="M348.841 250.404L342.346 200.96L341.183 147.749L326.395 141.242L313.055 148.22L323.937 202.293L336.619 252.84L348.841 250.404Z" fill="#1E4487"/>
                    <path d="M302.808 86.3254C300.46 89.6076 301.111 94.1274 304.218 96.6235C304.329 96.7124 304.443 96.7989 304.56 96.8826C307.959 99.314 312.686 98.5296 315.117 95.1303C317.549 91.7312 316.764 87.0045 313.365 84.5731C309.966 82.1419 305.239 82.9263 302.808 86.3254Z" fill="#FFC5BD"/>
                    <path d="M294.477 119.658C296.743 117.716 297.006 114.306 295.064 112.04C293.122 109.774 289.712 109.512 287.446 111.453C285.18 113.395 284.918 116.805 286.859 119.071C288.801 121.337 292.212 121.6 294.477 119.658Z" fill="#FFC5BD"/>
                    <path d="M315.113 95.1293C308.598 104.36 303.201 110.344 295.466 118.579L295.363 118.689L286.574 112.403C293.205 103.255 297.437 96.4378 302.733 86.4577L302.803 86.3242L315.113 95.1293Z" fill="#FFC5BD"/>
                    <path d="M300.757 88.0677C303.978 83.0047 309.182 81.436 313.416 84.274C316.487 86.3329 317.168 91.0895 316.446 94.2171C316.232 95.1462 315.071 97.2149 314.595 98.022C312.938 100.834 305.47 111.275 301.768 115.796L289.027 107.895C293.046 101.449 297.407 93.3345 300.757 88.0677Z" fill="#77BFD9"/>
                    <path d="M268.027 102.97L271.815 97.3008C274.705 99.1824 279.509 102.728 283.475 105.116C287.901 107.782 291.815 110.008 294.038 111.147L288.092 120.047C281.562 115.617 272.682 108.231 268.027 102.97Z" fill="#FFC5BD"/>
                    <path d="M294.951 119.089C296.912 116.877 296.709 113.495 294.497 111.534C292.286 109.573 288.904 109.777 286.943 111.988C284.982 114.199 285.185 117.582 287.397 119.543C289.608 121.503 292.991 121.3 294.951 119.089Z" fill="#FFC5BD"/>
                    <path d="M269.823 103.483C271.706 103.483 273.233 101.956 273.233 100.073C273.233 98.1904 271.706 96.6641 269.823 96.6641C267.94 96.6641 266.414 98.1904 266.414 100.073C266.414 101.956 267.94 103.483 269.823 103.483Z" fill="#FFC5BD"/>
                    <path d="M340.194 118.109C340.194 111.134 340.654 94.7048 340.654 94.7048C340.654 85.8047 334.346 81.0521 324.849 78.8203L313.408 79.3138C310.362 80.6531 305.495 83.3697 302.873 85.5367L304.17 105.992C303.279 120.787 302.389 138.852 301.426 153.986H341.855C341.393 146.894 340.194 124.898 340.194 118.109Z" fill="#77BFD9"/>
                    <path d="M322.862 93.6197C323.565 97.7211 327.351 100.522 331.434 100.054C331.58 100.037 331.726 100.016 331.872 99.9913C336.119 99.2626 338.972 95.2286 338.243 90.9811C337.515 86.7337 333.481 83.881 329.233 84.6097C324.985 85.338 322.133 89.3721 322.862 93.6197Z" fill="#FFC5BD"/>
                    <path d="M343.209 126.574C343.512 123.512 341.274 120.784 338.212 120.482C335.15 120.179 332.422 122.417 332.12 125.479C331.817 128.541 334.055 131.269 337.117 131.571C340.179 131.874 342.907 129.636 343.209 126.574Z" fill="#FFC5BD"/>
                    <path d="M338.241 90.9805C340.354 100.944 343.066 115.286 343.122 124.938L343.128 125.094L332.146 126.978C329.891 116.008 326.986 104.73 322.906 93.7681L322.859 93.6194L338.241 90.9805Z" fill="#FFC5BD"/>
                    <path d="M322.582 93.2838C321.867 88.8767 325.243 84.6628 330.123 83.8716C333.904 83.2585 337.268 85.0232 339.017 87.8355C339.537 88.6709 339.839 89.5999 340.078 90.5361C340.909 93.7974 341.896 96.9381 342.603 100.244C343.592 104.868 344.531 111.201 345.049 115.906L329.489 119.053C327.889 111.41 325.046 103.096 323.172 95.6345L322.582 93.2838Z" fill="#77BFD9"/>
                    <path d="M303.844 113.098C304.096 113.098 304.3 112.894 304.3 112.641V103.859C304.3 103.607 304.096 103.402 303.844 103.402C303.591 103.402 303.387 103.607 303.387 103.859V112.641C303.387 112.894 303.591 113.098 303.844 113.098Z" fill="#46B2CA"/>
                    <path d="M329.113 119.597L330.329 119.352C330.577 119.302 330.736 119.061 330.687 118.813C330.637 118.566 330.445 118.403 330.147 118.456L329.857 118.515L326.638 99.688C326.596 99.4388 326.356 99.2939 326.11 99.3144C325.862 99.357 325.695 99.5928 325.737 99.842L329.113 119.597Z" fill="#46B2CA"/>
                    <path d="M314.097 86.3398C314.292 86.3398 314.496 86.3335 314.71 86.3211C318.475 86.1034 323.964 84.1 326.714 80.817L327.014 80.4574C327.176 80.2639 327.151 79.9755 326.956 79.814C326.764 79.6524 326.475 79.6756 326.314 79.872L326.014 80.2307C323.426 83.3165 318.231 85.2021 314.656 85.409C313.119 85.4965 311.169 85.2564 310.699 83.5921C310.567 83.1156 310.583 82.5658 310.751 81.9591C310.817 81.7155 310.674 81.4637 310.431 81.3968C310.178 81.3316 309.935 81.4745 309.869 81.7163C309.659 82.4855 309.641 83.1995 309.819 83.8384C310.148 85.0021 311.161 86.3398 314.097 86.3398Z" fill="#46B2CA"/>
                    <path d="M324.848 78.8259C322.564 81.3479 317.958 82.8831 314.687 82.8831C314.157 82.8831 312.548 82.8831 312.347 81.9328C312.128 80.8911 313.408 79.3195 313.408 79.3195L311.434 63.9315L321.668 61.2266L322.837 67.7328L324.848 78.8259Z" fill="#FFC5BD"/>
                    <path d="M322.838 67.7328C321.467 72.8866 318.068 77.1448 313.408 79.3195L311.434 63.9315L321.668 61.2266L322.838 67.7328Z" fill="#EF9C8F"/>
                    <path d="M313.964 63.0395C313.06 62.2401 311.937 61.7118 310.688 61.4462C308.209 60.9194 306.26 62.0577 304.648 59.5477C303.848 58.3029 303.498 56.7811 303.367 55.2676C303.198 53.3055 303.395 51.2313 304.346 49.508C305.298 47.7847 307.117 46.4978 308.97 46.6627C310.544 46.8029 311.344 47.1682 312.787 47.7084C314.008 48.1651 315.415 47.9662 316.714 48.0478C318.844 48.1817 321.027 47.698 323.112 48.3477C324.85 48.889 326.447 50.3194 326.689 52.2289C328.341 52.699 329.307 54.6611 329.803 56.3946C330.299 58.128 330.119 60.0321 329.604 61.7861C328.823 64.4482 327.754 66.2147 326.072 68.3094C325.27 69.3084 323.411 71.0109 323.411 71.0109L323.028 71.2188C323.028 71.2185 314.788 63.7685 313.964 63.0395Z" fill="#1E3D7D"/>
                    <path d="M326.942 62.2162C325.872 67.4515 322.512 72.3496 316.869 74.6327C313.834 75.8604 309.499 76.0027 307.796 73.2577C306.278 70.8112 306.037 67.6886 306.251 64.793C306.477 61.7318 306.947 58.6899 307.705 55.7196C308.779 51.5148 313.556 48.8881 319.341 50.082C325.844 51.4243 328.051 56.7915 326.942 62.2162Z" fill="#FFC5BD"/>
                    <path d="M327.479 62.3333C326.768 65.807 325.162 68.7277 323.029 71.2106L321.823 70.9701C322.15 69.4845 322.329 67.9025 321.967 67.2127L318.8 66.0057L319.668 61.7622C318.835 61.0884 318.168 59.609 318.138 58.2038L318.095 58.2323C314.684 58.673 310.371 58.2387 306.846 56.9585C306.941 56.493 307.054 56.031 307.163 55.5871C308.355 50.9435 313.625 48.346 319.426 49.532C325.653 50.8236 328.807 55.8337 327.479 62.3333Z" fill="#1E3D7D"/>
                    <path d="M323.018 67.5847C323.734 66.2383 323.342 64.6303 322.143 63.993C320.945 63.3557 319.393 63.9306 318.677 65.277C317.961 66.6234 318.353 68.2314 319.551 68.8687C320.75 69.506 322.302 68.9311 323.018 67.5847Z" fill="#FFC5BD"/>
                    <path d="M319.443 66.59C319.435 66.5887 319.426 66.5864 319.417 66.5841C319.296 66.551 319.225 66.4254 319.258 66.3041C319.321 66.0718 319.425 65.8549 319.567 65.6593C320.049 64.9941 320.899 64.6694 321.615 64.9125C321.737 64.9521 321.854 65.009 321.964 65.0819C322.182 65.2275 322.355 65.4303 322.466 65.6687C322.518 65.7833 322.469 65.9192 322.354 65.972C322.239 66.0238 322.104 65.9767 322.051 65.8611C321.974 65.6966 321.859 65.5624 321.71 65.4617C321.637 65.4125 321.556 65.3739 321.472 65.3464C320.937 65.1692 320.307 65.4193 319.937 65.9277C319.827 66.0786 319.747 66.246 319.698 66.4243C319.668 66.538 319.556 66.6075 319.443 66.59Z" fill="#EF9C8F"/>
                    <path d="M306.892 62.7769C306.497 62.6327 306.06 62.8358 305.916 63.2309C305.771 63.626 305.974 64.0632 306.37 64.2076C306.765 64.3518 307.202 64.1486 307.346 63.7534C307.49 63.3585 307.288 62.9212 306.892 62.7769Z" fill="#1E3D7D"/>
                    <path d="M311.396 63.1558C311.001 63.0115 310.564 63.2148 310.419 63.6099C310.275 64.0049 310.479 64.4424 310.873 64.5866C311.269 64.731 311.706 64.5276 311.851 64.1325C311.995 63.7375 311.791 63.3002 311.396 63.1558Z" fill="#1E3D7D"/>
                    <path d="M314.016 61.0753C313.991 60.9137 313.795 60.8076 313.608 60.7656C312.739 60.5698 311.854 60.6075 310.981 60.7341C310.674 60.7786 309.844 60.8284 309.944 61.2441C310.008 61.5139 310.474 61.741 310.777 61.7342C311.453 61.7189 312.129 61.6873 312.802 61.6384C313.183 61.6109 314.099 61.6074 314.016 61.0753Z" fill="#1E3D7D"/>
                    <path d="M303.965 60.2269C304.017 60.072 304.228 60.0007 304.42 59.9911C305.31 59.9459 306.175 60.1332 307.014 60.4063C307.309 60.5025 308.118 60.6925 307.95 61.0853C307.84 61.3401 307.343 61.4848 307.045 61.4266C306.381 61.2967 305.721 61.1507 305.066 60.9882C304.695 60.8964 303.793 60.7373 303.965 60.2269Z" fill="#1E3D7D"/>
                    <path d="M307.743 67.8756C307.713 67.8711 307.684 67.8603 307.656 67.8432C307.232 67.5787 306.974 67.2583 306.892 66.8917C306.816 66.5596 306.891 66.2145 307.112 65.8675C307.236 65.6717 307.417 65.4314 307.621 65.1628C307.868 64.8376 308.148 64.4699 308.391 64.0901C308.457 63.9848 308.598 63.9512 308.706 64.0211C308.811 64.0888 308.844 64.23 308.775 64.3369C308.523 64.7315 308.238 65.1076 307.985 65.4389C307.79 65.6962 307.616 65.9248 307.497 66.1125C307.344 66.3536 307.29 66.5819 307.337 66.7903C307.39 67.0304 307.586 67.261 307.899 67.4562C308.005 67.523 308.039 67.6637 307.972 67.7708C307.922 67.8507 307.831 67.8892 307.743 67.8756Z" fill="#EF9C8F"/>
                    <path d="M307.766 69.0508L311.786 69.1652C310.918 70.7792 308.238 70.7254 307.766 69.0508Z" fill="#ACBAC0"/>
                    <path d="M269.951 103.399C269.951 103.399 268.038 103.607 267.732 103.584C267.181 103.557 266.838 102.785 266.299 102.873L261.261 96.41L261.471 92.2718L261.502 92.1689C261.538 92.0459 261.512 91.9043 261.481 91.7831C261.443 91.6827 261.38 91.5969 261.316 91.5109L261.322 91.4904C261.03 91.1144 260.544 90.7926 260.259 90.6196C259.781 90.345 259.25 90.1 258.882 89.6789C258.508 89.2789 258.315 88.6413 258.549 88.1512C258.645 87.978 258.802 87.8231 258.991 87.7895C259.469 87.684 260.094 88.292 260.481 88.4954C260.807 88.6805 261.158 88.8509 261.484 89.0362C261.657 89.1315 261.83 89.2273 262.003 89.3223C262.038 89.3555 262.058 89.3613 262.1 89.3736C262.44 89.5848 262.786 89.776 263.126 89.9876C263.888 90.4347 264.636 90.8558 265.354 91.3798C266.434 92.1885 266.94 93.4321 267.792 94.4869C268.65 95.5207 271.082 96.9019 271.082 96.9019L269.951 103.399Z" fill="#FFC5BD"/>
                    <path d="M272.692 101.844C273.676 100.277 273.203 98.2088 271.637 97.2249C270.07 96.2409 268.002 96.7133 267.018 98.2802C266.034 99.847 266.506 101.915 268.073 102.899C269.64 103.883 271.708 103.41 272.692 101.844Z" fill="#FFC5BD"/>
                    <g opacity="0.7">
                    <path d="M269.829 36.5369C269.963 37.3915 270.062 38.2528 270.123 39.1217C270.225 40.3851 270.269 41.6406 270.238 42.9112C270.171 48.5392 268.873 54.2433 266.205 59.612C266.088 59.8845 265.948 60.1429 265.813 60.4193C265.233 61.533 264.61 62.6173 263.902 63.6617C262.493 65.8818 260.855 67.9189 259.058 69.7389C257.964 70.8612 256.813 71.8986 255.611 72.8704C254.938 73.4281 254.236 73.9342 253.534 74.4402C247.125 78.938 239.485 81.4239 231.655 81.553C228.282 81.6419 224.874 81.278 221.506 80.4462C218.54 79.7057 215.598 78.6155 212.77 77.1378C198.569 69.7435 190.773 54.9011 191.662 39.891C191.864 36.5354 192.501 33.1488 193.616 29.8575C194.256 27.9078 195.073 25.9793 196.051 24.0952C199.982 16.5356 206.026 10.8088 213.06 7.2197C215.388 6.03924 217.802 5.09042 220.305 4.39247C221.792 3.9763 223.316 3.64853 224.855 3.39446C230.48 2.50426 236.337 2.85126 242.001 4.52083C242.186 4.57945 242.348 4.62336 242.532 4.68179C243.35 4.93756 244.173 5.2118 244.987 5.54504C246.373 6.04979 247.745 6.67254 249.094 7.37652C260.707 13.4373 268.058 24.4794 269.829 36.5369Z" fill="#0E3A5C"/>
                    <path d="M263.902 63.6481C262.493 65.8683 260.855 67.9054 259.059 69.7253L213.061 7.20613C215.389 6.02567 217.803 5.07685 220.305 4.37891L263.902 63.6481Z" fill="#1E1A1A"/>
                    <path d="M231.655 81.5548C228.282 81.6438 224.874 81.2798 221.506 80.4481L191.662 39.8928C191.864 36.5373 192.5 33.1506 193.616 29.8594L231.655 81.5548Z" fill="#1E1A1A"/>
                    <path d="M270.237 42.9112C270.17 48.5392 268.872 54.2433 266.204 59.612L224.854 3.39446C230.479 2.50426 236.336 2.85126 242 4.52083L270.237 42.9112Z" fill="#1E1A1A"/>
                    </g>
                    <path d="M258.705 70.5412L250.521 76.7227L313.848 160.559L322.032 154.378L258.705 70.5412Z" fill="#8CCDF6"/>
                    <path d="M267.366 82.2277L259.433 88.2201C258.118 89.2132 257.858 91.0836 258.851 92.3978L310.775 161.139C311.768 162.453 313.638 162.714 314.953 161.721L322.886 155.729C324.201 154.736 324.461 152.865 323.469 151.551L271.544 82.81C270.551 81.4957 268.681 81.235 267.366 82.2277Z" fill="#66B3E2"/>
                    <path d="M247.684 77.6191L249.576 80.1244C250.099 80.8163 251.083 80.9536 251.775 80.4309L261.963 72.7354C262.655 72.2127 262.792 71.2285 262.269 70.5366L260.377 68.0312L247.684 77.6191Z" fill="#66B3E2"/>
                    <path d="M256.147 75.6294C237.74 89.5339 211.45 85.87 197.544 67.4602C183.638 49.0504 187.302 22.761 205.71 8.85652C224.12 -5.04948 250.409 -1.38559 264.315 17.0242C278.221 35.434 274.557 61.7234 256.147 75.6294ZM208.738 12.865C192.539 25.1003 189.316 48.2324 201.553 64.4324C213.789 80.6325 236.922 83.8562 253.119 71.6209C269.319 59.3841 272.543 36.252 260.306 20.052C248.07 3.85195 224.937 0.628161 208.738 12.865Z" fill="#66B3E2"/>
                    <path d="M262.06 99.6997C262.111 100.01 262.148 100.328 262.294 100.599C262.571 101.112 263.167 101.348 263.715 101.545C263.628 102.192 264.039 102.83 264.589 103.183C265.14 103.535 265.807 103.651 266.458 103.712C267.248 103.786 268.495 103.863 269.188 103.395C269.751 103.015 269.524 102.253 268.93 102.069C268.653 101.983 268.357 101.99 268.068 101.977C267.663 101.959 267.261 101.9 266.869 101.801C267.381 101.625 267.893 101.448 268.406 101.272C268.659 101.185 268.916 101.096 269.138 100.945C269.584 100.642 269.833 100.06 269.448 99.6364C268.936 99.0726 268.188 99.2051 267.508 99.1584C267.189 99.1365 266.871 99.1128 266.553 99.0794C266.403 99.0638 265.872 98.9068 265.754 98.9746C266.162 98.7396 266.624 98.6193 267.064 98.4518C267.505 98.2842 267.944 98.0526 268.212 97.665C268.48 97.2777 268.522 96.7015 268.198 96.3592C267.881 96.0237 267.358 96.019 266.897 96.0379C265.938 96.0771 264.979 96.1163 264.02 96.1555C264.644 95.8952 265.268 95.6347 265.892 95.3744C266.242 95.2285 266.679 95.0747 266.797 94.6921C266.901 94.3542 266.788 93.8792 266.532 93.6385C266.252 93.3754 265.841 93.3198 265.459 93.2768C264.324 93.1492 263.133 92.8898 261.999 92.8829C260.451 92.8733 258.934 93.9968 260.007 95.6728C260.134 95.8718 260.297 96.0479 260.409 96.2561C260.737 96.8668 260.596 97.6852 261.042 98.2158C261.254 98.468 261.573 98.6157 261.773 98.8775C261.952 99.1126 262.012 99.4025 262.06 99.6997Z" fill="#FFC5BD"/>
                    <path d="M319.083 149.237L314.049 144.19C317.101 141.146 321.264 136.335 325.27 131.467C328.679 127.324 331.817 123.805 333.669 121.957L341.572 129.878C333.906 137.03 326.717 143.019 319.132 149.197L319.083 149.237Z" fill="#FFC5BD"/>
                    <path d="M337.534 131.603C340.624 131.603 343.129 129.099 343.129 126.009C343.129 122.919 340.624 120.414 337.534 120.414C334.444 120.414 331.939 122.919 331.939 126.009C331.939 129.099 334.444 131.603 337.534 131.603Z" fill="#FFC5BD"/>
                    <path d="M319.199 149.126C320.476 147.628 320.298 145.378 318.8 144.101C317.303 142.824 315.053 143.002 313.776 144.499C312.498 145.997 312.677 148.247 314.174 149.524C315.672 150.801 317.921 150.623 319.199 149.126Z" fill="#FFC5BD"/>
                    <path d="M316.527 150.394C318.506 150.377 320.097 148.759 320.08 146.779C320.062 144.8 318.444 143.209 316.465 143.227C314.485 143.244 312.895 144.862 312.912 146.842C312.929 148.821 314.548 150.411 316.527 150.394Z" fill="#FFC5BD"/>
                    <path d="M314.794 143.672C314.538 143.82 313.845 143.958 313.6 143.958C313.357 143.958 312.033 143.718 311.629 143.555C310.899 143.258 310.143 142.952 309.296 142.93L308.971 142.926C308.256 142.926 307.545 142.983 306.834 143.042L305.622 143.141C305.291 143.166 304.964 143.208 304.636 143.249L304.005 143.325C303.938 143.331 303.861 143.332 303.776 143.332L303.505 143.331C303.097 143.331 302.641 143.353 302.387 143.617C302.232 143.78 302.164 144.022 302.199 144.28C302.283 144.905 302.863 145.304 303.399 145.423C303.584 145.465 303.785 145.486 304.013 145.486C304.254 145.486 304.497 145.462 304.74 145.439L304.999 145.415C305.101 145.407 305.23 145.401 305.37 145.401C305.708 145.401 305.998 145.439 306.213 145.513C306.428 145.583 306.555 145.682 306.605 145.827C306.629 145.883 306.635 145.954 306.624 146.041C306.484 147.139 305.216 147.934 304.092 148.523C303.729 148.712 303.362 148.905 302.997 149.137C302.686 149.335 302.364 149.539 302.086 149.645L301.99 149.49L301.865 149.293L301.714 149.471C301.421 149.815 301.457 150.341 301.538 150.722C301.631 151.153 301.825 151.529 302.099 151.809C302.349 152.065 302.666 152.235 302.995 152.293C302.846 152.812 302.981 153.433 303.224 153.831C303.392 154.105 303.623 154.335 303.875 154.478C304.059 154.584 304.28 154.632 304.475 154.675L304.548 154.692C304.611 155.079 304.809 155.443 305.099 155.707C305.368 155.952 305.715 156.109 305.999 156.109C306.052 156.109 306.102 156.103 306.151 156.092C306.186 156.154 306.28 156.415 306.347 156.595L306.418 156.784C306.66 157.337 307.136 157.654 307.725 157.654L307.811 157.652C309.756 157.557 311.324 156.631 312.892 155.609C314.118 154.81 314.908 153.813 315.663 152.843C316.427 151.86 318.046 150.101 318.533 149.709C318.533 149.709 314.795 143.672 314.794 143.672Z" fill="#FFC5BD"/>
                    <g clip-path="url(#clip0_6746_238518)">
                    <path d="M309.619 97.2852C310.191 97.2852 310.727 97.5368 310.977 97.8077L310.574 98.405C310.363 98.2225 310.024 98.1036 309.711 98.1036C309.394 98.1036 309.194 98.181 309.194 98.2999C309.194 98.6787 311.102 98.3358 311.102 99.5303C311.102 100.191 310.524 100.62 309.63 100.62C309.002 100.62 308.413 100.352 308.133 100.05L308.536 99.4529C308.788 99.663 309.18 99.7985 309.538 99.7985C309.908 99.7985 310.147 99.7073 310.147 99.5718C310.147 99.193 308.238 99.5497 308.238 98.2612C308.238 97.6695 308.783 97.2852 309.619 97.2852Z" fill="white"/>
                    <path d="M314.066 97.3594L314.646 99.2644L315.227 97.3594H316.174L315.199 100.547H314.252L314.257 100.539L313.677 98.6396L313.096 100.539L313.102 100.547H312.155L311.18 97.3594H312.127L312.707 99.2644L313.288 97.3594H314.066Z" fill="white"/>
                    <path d="M317.347 97.3594V100.547H316.441V97.3594H317.347Z" fill="white"/>
                    <path d="M320.544 97.809L320.141 98.4062C319.93 98.2237 319.591 98.1048 319.277 98.1048C318.96 98.1048 318.76 98.1822 318.76 98.3011C318.76 98.6799 320.669 98.3371 320.669 99.5315C320.669 100.192 320.091 100.621 319.196 100.621C318.569 100.621 317.98 100.353 317.699 100.051L318.102 99.4541C318.355 99.6643 318.746 99.7998 319.105 99.7998C319.474 99.7998 319.713 99.7085 319.713 99.573C319.713 99.1942 317.805 99.5509 317.805 98.2624C317.805 97.6707 318.349 97.2892 319.185 97.2892C319.758 97.2836 320.294 97.5352 320.544 97.809Z" fill="white"/>
                    <path d="M323.729 97.809L323.326 98.4062C323.115 98.2237 322.776 98.1048 322.463 98.1048C322.146 98.1048 321.946 98.1822 321.946 98.3011C321.946 98.6799 323.854 98.3371 323.854 99.5315C323.854 100.192 323.276 100.621 322.382 100.621C321.754 100.621 321.165 100.353 320.885 100.051L321.288 99.4541C321.54 99.6643 321.932 99.7998 322.29 99.7998C322.66 99.7998 322.899 99.7085 322.899 99.573C322.899 99.1942 320.99 99.5509 320.99 98.2624C320.99 97.6707 321.535 97.2892 322.371 97.2892C322.94 97.2836 323.476 97.5352 323.729 97.809Z" fill="white"/>
                    <path d="M305.95 100.894C307.027 100.894 307.9 100.025 307.9 98.9527C307.9 97.8807 307.027 97.0117 305.95 97.0117C304.873 97.0117 304 97.8807 304 98.9527C304 100.025 304.873 100.894 305.95 100.894Z" fill="#233A71"/>
                    <path d="M305.525 98.5285V97.6797H306.377V98.5285H307.23V99.3774H306.377V100.226H305.525V99.3774H304.672V98.5285H305.525Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_6746_238518">
                    <rect width="20" height="4" fill="white" transform="translate(304 97)"/>
                    </clipPath>
                    </defs>
                </svg>
            )
        }
    
    </>
  )
}

export default NotFoundImage