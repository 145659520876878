// @ts-nocheck
import { LineChartData } from "./types/line-chart.types";

function transformData(data: {previous: LineChartData[], current: LineChartData[]}, isToday = false): {previous: LineChartData[], current: LineChartData[]} | null {
    // return if hour: null
    if (data.current.some(entry => entry.hour === null) || data.previous.some(entry => entry.hour === null)) {
        return null
    }

    // need to sort data by hours field
    Object.keys(data).forEach(key => {
        data[key] = data[key].sort((a, b) => a.hour - b.hour);
    });

    const allKeys = Object.keys(data).reduce((keys, key) => {
        data[key].forEach(entry => {
            Object.keys(entry).forEach(k => {
                if (!keys.includes(k) && k !== 'hour') {
                    keys.push(k);
                }
            });
        });
        return keys;
    }, []);

    Object.keys(data).forEach(key => {
        if(data[key].length === 0) return
        
        
        const firstHourWithData = Math.min(...data[key].map(entry => entry.hour));
        for (let i = 0; i < firstHourWithData; i++) {
            let nullEntry = { hour: i };
            allKeys.forEach(k => {
                nullEntry[k] = 0;
            });
            data[key].unshift(nullEntry);
        }
    });

    function initializeData(maxHour = 24) {
        const initialData = [];
        for (let i = 0; i < maxHour; i++) {
            initialData.push({ hour: i });
        }
        return initialData;
    }

    function accumulateData(data, maxHour = 24) {
    
        const accumulatedData = initializeData(maxHour);
        const totals = {};
    
        data.forEach(entry => {
            const hour = entry.hour;
    
            Object.keys(entry).forEach(key => {
                if (key !== 'hour') {
                    if (!totals[key]) {
                        totals[key] = 0;
                    }
                    totals[key] += entry[key];
                }
            });
    
            Object.keys(totals).forEach(key => {
                accumulatedData[hour][key] = totals[key];
            });
        });
    
        // This loop ensures that missing hours carry forward the previous value
        for (let i = 1; i < maxHour; i++) {
            Object.keys(totals).forEach(key => {
                if (accumulatedData[i][key] === undefined) {
                    accumulatedData[i][key] = accumulatedData[i - 1][key];
                }
            });
        }
    
        return accumulatedData;
    }
    

    const transformedData = {};
    Object.keys(data).forEach(key => {
        let maxHour = 24;

        if (isToday && key === 'current') {
            const lastHourWithData = Math.max(...data[key].map(entry => entry.hour));

            const filledData = [];
            for (let i = 0; i <= lastHourWithData; i++) {
                const existingEntry = data[key].find(entry => entry.hour === i);
                if (existingEntry) {
                    filledData.push(existingEntry);
                } else {
                    const missingEntry = { hour: i };
                    Object.keys(data[key][0]).forEach(k => {
                        if (k !== 'hour') {
                            missingEntry[k] = 0;
                        }
                    });
                    filledData.push(missingEntry);
                }
            }
            
            transformedData[key] = accumulateData(filledData, lastHourWithData + 1);
        } else {
            transformedData[key] = accumulateData(data[key], maxHour);
        }
    });

    if (isToday && data.current.length > 0) {
        const lastHourWithData = Math.max(...data.current.map(entry => entry.hour));

        for (let i = lastHourWithData + 1; i < 24; i++) {
            let nullEntry = { hour: i };
            transformedData.current.push(nullEntry);
        }

    }

    interface DataEntry {
        hour: number;
        US?: number;
    }
    
    interface Data {
        current: DataEntry[];
        previous: DataEntry[];
    }
    
    function incrementHours(data: Data): Data {
        const incrementedData: Data = {
            current: data.current.map(entry => ({
                ...entry,
                hour: (entry.hour + 1)
            })),
            previous: data.previous.map(entry => ({
                ...entry,
                hour: (entry.hour + 1)
            }))
        };
    
        return incrementedData;
    }

    return incrementHours(transformedData);  
}


export default transformData;