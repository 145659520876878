import React, { FC, useContext } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";

// example:

// const [selectedType, setSelectedType] = React.useState('all');

// const options = [
//   { value: 'all', label: 'All' },
//   { value: 'fraud', label: 'Fraud' },
// ];

// const handleChange = (newValue: string) => {
//   setSelectedType(newValue);
// };

// <CustomToggleButtonGroup
  // selectedValue={selectedType}
  // onChange={handleChange}
  // options={options}
// />

interface Option {
  value: string;
  label: string;
  colorLight?: string;
  colorDark?: string;
}

interface CustomToggleButtonGroupProps {
  selectedValue: string;
  onChange: (value: string) => void;
  options: Option[];
}

const CustomToggleButtonGroup: FC<CustomToggleButtonGroupProps> = ({
  selectedValue,
  onChange,
  options
}) => {
  const { theme, themedColors } = useContext(ThemeContext)!;

  return (
    <div className={`h-[42px] ${theme === 'light' ? 'bg-odin-light-gray-light' : 'bg-odin-dark-gray-dark'}`} style={{ borderRadius: "8px" }}>
      <ToggleButtonGroup
        color="primary"
        value={selectedValue}
        exclusive
        onChange={(event, value) => {
          if (value !== null) onChange(value);
        }}
        className="p-1"
        style={{ height: "41px", padding: "3px 4px", width: "100%" }}
      >
        {options.map(option => (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={{
              "&.MuiToggleButton-root": {
                border: "none",
                width: "100%",
                "&:last-child": {
                  borderRadius: "8px 8px 8px 8px !important",
                },
                "&:first-child": {
                  borderRadius: "8px 8px 8px 8px",
                },
                padding: "8px 16px",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: selectedValue === option.value ? themedColors.background_1 : "transparent",
              }
            }}
          >
            <div
              className="text-xs"
              style={{
                color: selectedValue === option.value ? option.colorLight ? theme === 'light' ? option.colorLight : option.colorDark : "#0079FF" : "#677C9F",
                textTransform: "none"
              }}
            >
              {option.label}
            </div>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default CustomToggleButtonGroup;
