import * as React from "react"
import { SVGProps } from "react"
function DeleteIcon (props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#D80027"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M3 6h2m0 0h16M5 6v14a2 2 0 002 2h10a2 2 0 002-2V6H5zm3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"
            ></path>
        </svg>
    );
}

export default DeleteIcon