interface Column {
    accessorKey: string;
    header: string;
    id: string;
    meta: {
        isLastInGroup: boolean;
    };
  }
  
  interface ColumnGroup {
    id: string;
    meta: {
        isLastInGroup: boolean;
        isFontBold: boolean;
    };
    columns: Column[];
  }

const generateFieldsForCSV = (columnGroups: ColumnGroup[]) => {
    if (!columnGroups) return {};
    const fieldsForCSV: { [key: string]: string } = {};

    columnGroups.forEach((group) => {
        group.columns.forEach((column) => {
            fieldsForCSV[column.header] = column.accessorKey;
        });
    });

    return fieldsForCSV;
};

export default generateFieldsForCSV;