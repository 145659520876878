
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz().endOf('D') },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz().endOf('w') },
    4: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('M') },
    5: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz().endOf('y') },
    6: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().endOf('w').subtract(1, "w") },
    7: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    8: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};